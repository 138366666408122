import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";
import CopyBtn from "src/components/Icon/CopyBtn";

import "./index.scss";
import { Trans, useTranslation } from "react-i18next";
import { useLogin } from "src/App";
import classNames from 'classnames';

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};

const HowToEarnModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
  const {currentLanguage, handlecopy, loginData}=useLogin();
  
  const uid = loginData?.User?.Id ?? "";
  return (
    <Modal
      title={t("Trade.How_To_Earn")}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="how-to-earn-modal"
      style={{
        maxHeight: "80%",
      }}
    >
      <div className="how-to-earn-modal-content">
        <div className="uid-block">
          UID: {uid}
          <CopyToClipboard
            text={uid}
            onCopy={() => handlecopy()}
          >
            <CopyBtn className="copy-btn" />
          </CopyToClipboard>
        </div>
        <div className="text-1">
          <p>{t("HowToEarnModal.text_1")}</p>
        </div>
        <div className="text-2">{t("HowToEarnModal.text_2")}</div>
        <div className="text-3 step-title">{t("HowToEarnModal.Step_01")}</div>
        <div className={"screen-shoot-2 play-screenshot_"+currentLanguage}></div>
        <div className="text-4">{t('GameRules.Step_01')}</div>
        
        <div className="text-5 step-title">{t("HowToEarnModal.Step_02")}</div>
        <div className={" play-buy_"+currentLanguage}></div>
        <div className="text-6">{t('GameRules.Step_02')}</div>

        
        <div className="text-7 step-title">{t("HowToEarnModal.Step_03")}</div>
        <div className="screen-shoot-1 game_play_en-US"></div>
        <div className="text-8">{t('GameRules.Step_03')}</div>

        
        <div className="text-7 step-title">{t("GameRules.Step_04_Title")}</div>
        <div className={" play-sell_"+currentLanguage}></div>
        <div className="text-8">{t('GameRules.Step_04')}<br/><br/>
        {t('GameRules.Step_04_1')}
        </div>
      </div>
    </Modal>
  );
};

export default HowToEarnModal;
