/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Rugbull = $root.Rugbull = (() => {

    /**
     * Namespace Rugbull.
     * @exports Rugbull
     * @namespace
     */
    const Rugbull = {};

    Rugbull.Protocols = (function() {

        /**
         * Namespace Protocols.
         * @memberof Rugbull
         * @namespace
         */
        const Protocols = {};

        Protocols.GameBetInfo = (function() {

            /**
             * Properties of a GameBetInfo.
             * @memberof Rugbull.Protocols
             * @interface IGameBetInfo
             * @property {string|null} [UserName] GameBetInfo UserName
             * @property {string|null} [Amount] GameBetInfo Amount
             * @property {string|null} [Multiplier] GameBetInfo Multiplier
             * @property {string|null} [PhotoUrl] GameBetInfo PhotoUrl
             * @property {string|null} [Round] GameBetInfo Round
             * @property {string|null} [BetTime] GameBetInfo BetTime
             * @property {number|null} [Id] GameBetInfo Id
             */

            /**
             * Constructs a new GameBetInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a GameBetInfo.
             * @implements IGameBetInfo
             * @constructor
             * @param {Rugbull.Protocols.IGameBetInfo=} [properties] Properties to set
             */
            function GameBetInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GameBetInfo UserName.
             * @member {string} UserName
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.UserName = "";

            /**
             * GameBetInfo Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.Amount = "";

            /**
             * GameBetInfo Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.Multiplier = "";

            /**
             * GameBetInfo PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.PhotoUrl = "";

            /**
             * GameBetInfo Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.Round = "";

            /**
             * GameBetInfo BetTime.
             * @member {string} BetTime
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.BetTime = "";

            /**
             * GameBetInfo Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             */
            GameBetInfo.prototype.Id = 0;

            /**
             * Creates a new GameBetInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Rugbull.Protocols.IGameBetInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.GameBetInfo} GameBetInfo instance
             */
            GameBetInfo.create = function create(properties) {
                return new GameBetInfo(properties);
            };

            /**
             * Encodes the specified GameBetInfo message. Does not implicitly {@link Rugbull.Protocols.GameBetInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Rugbull.Protocols.IGameBetInfo} message GameBetInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GameBetInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserName != null && Object.hasOwnProperty.call(message, "UserName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserName);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Multiplier);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.PhotoUrl);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Round);
                if (message.BetTime != null && Object.hasOwnProperty.call(message, "BetTime"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.BetTime);
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.Id);
                return writer;
            };

            /**
             * Encodes the specified GameBetInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.GameBetInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Rugbull.Protocols.IGameBetInfo} message GameBetInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GameBetInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GameBetInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.GameBetInfo} GameBetInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GameBetInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.GameBetInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserName = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 4: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 5: {
                            message.Round = reader.string();
                            break;
                        }
                    case 6: {
                            message.BetTime = reader.string();
                            break;
                        }
                    case 7: {
                            message.Id = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GameBetInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.GameBetInfo} GameBetInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GameBetInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GameBetInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GameBetInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    if (!$util.isString(message.UserName))
                        return "UserName: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.BetTime != null && message.hasOwnProperty("BetTime"))
                    if (!$util.isString(message.BetTime))
                        return "BetTime: string expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                return null;
            };

            /**
             * Creates a GameBetInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.GameBetInfo} GameBetInfo
             */
            GameBetInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.GameBetInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.GameBetInfo();
                if (object.UserName != null)
                    message.UserName = String(object.UserName);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.BetTime != null)
                    message.BetTime = String(object.BetTime);
                if (object.Id != null)
                    message.Id = object.Id | 0;
                return message;
            };

            /**
             * Creates a plain object from a GameBetInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {Rugbull.Protocols.GameBetInfo} message GameBetInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GameBetInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.UserName = "";
                    object.Amount = "";
                    object.Multiplier = "";
                    object.PhotoUrl = "";
                    object.Round = "";
                    object.BetTime = "";
                    object.Id = 0;
                }
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    object.UserName = message.UserName;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.BetTime != null && message.hasOwnProperty("BetTime"))
                    object.BetTime = message.BetTime;
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                return object;
            };

            /**
             * Converts this GameBetInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.GameBetInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GameBetInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GameBetInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.GameBetInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GameBetInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.GameBetInfo";
            };

            return GameBetInfo;
        })();

        Protocols.BetHistoryInfo = (function() {

            /**
             * Properties of a BetHistoryInfo.
             * @memberof Rugbull.Protocols
             * @interface IBetHistoryInfo
             * @property {string|null} [Time] BetHistoryInfo Time
             * @property {string|null} [Amount] BetHistoryInfo Amount
             * @property {string|null} [Multiplier] BetHistoryInfo Multiplier
             * @property {string|null} [Round] BetHistoryInfo Round
             * @property {number|null} [Id] BetHistoryInfo Id
             * @property {string|null} [sign] BetHistoryInfo sign
             */

            /**
             * Constructs a new BetHistoryInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a BetHistoryInfo.
             * @implements IBetHistoryInfo
             * @constructor
             * @param {Rugbull.Protocols.IBetHistoryInfo=} [properties] Properties to set
             */
            function BetHistoryInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BetHistoryInfo Time.
             * @member {string} Time
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.Time = "";

            /**
             * BetHistoryInfo Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.Amount = "";

            /**
             * BetHistoryInfo Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.Multiplier = "";

            /**
             * BetHistoryInfo Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.Round = "";

            /**
             * BetHistoryInfo Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.Id = 0;

            /**
             * BetHistoryInfo sign.
             * @member {string} sign
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             */
            BetHistoryInfo.prototype.sign = "";

            /**
             * Creates a new BetHistoryInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Rugbull.Protocols.IBetHistoryInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.BetHistoryInfo} BetHistoryInfo instance
             */
            BetHistoryInfo.create = function create(properties) {
                return new BetHistoryInfo(properties);
            };

            /**
             * Encodes the specified BetHistoryInfo message. Does not implicitly {@link Rugbull.Protocols.BetHistoryInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Rugbull.Protocols.IBetHistoryInfo} message BetHistoryInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BetHistoryInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Time);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Multiplier);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Round);
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.Id);
                if (message.sign != null && Object.hasOwnProperty.call(message, "sign"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.sign);
                return writer;
            };

            /**
             * Encodes the specified BetHistoryInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.BetHistoryInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Rugbull.Protocols.IBetHistoryInfo} message BetHistoryInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BetHistoryInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BetHistoryInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.BetHistoryInfo} BetHistoryInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BetHistoryInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.BetHistoryInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Time = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 4: {
                            message.Round = reader.string();
                            break;
                        }
                    case 5: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 6: {
                            message.sign = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BetHistoryInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.BetHistoryInfo} BetHistoryInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BetHistoryInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BetHistoryInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BetHistoryInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Time != null && message.hasOwnProperty("Time"))
                    if (!$util.isString(message.Time))
                        return "Time: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.sign != null && message.hasOwnProperty("sign"))
                    if (!$util.isString(message.sign))
                        return "sign: string expected";
                return null;
            };

            /**
             * Creates a BetHistoryInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.BetHistoryInfo} BetHistoryInfo
             */
            BetHistoryInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.BetHistoryInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.BetHistoryInfo();
                if (object.Time != null)
                    message.Time = String(object.Time);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.sign != null)
                    message.sign = String(object.sign);
                return message;
            };

            /**
             * Creates a plain object from a BetHistoryInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {Rugbull.Protocols.BetHistoryInfo} message BetHistoryInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BetHistoryInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Time = "";
                    object.Amount = "";
                    object.Multiplier = "";
                    object.Round = "";
                    object.Id = 0;
                    object.sign = "";
                }
                if (message.Time != null && message.hasOwnProperty("Time"))
                    object.Time = message.Time;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.sign != null && message.hasOwnProperty("sign"))
                    object.sign = message.sign;
                return object;
            };

            /**
             * Converts this BetHistoryInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BetHistoryInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BetHistoryInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.BetHistoryInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BetHistoryInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.BetHistoryInfo";
            };

            return BetHistoryInfo;
        })();

        Protocols.PushGameBets = (function() {

            /**
             * Properties of a PushGameBets.
             * @memberof Rugbull.Protocols
             * @interface IPushGameBets
             * @property {number|null} [OrderCount] PushGameBets OrderCount
             * @property {Array.<Rugbull.Protocols.IGameBetInfo>|null} [Infos] PushGameBets Infos
             */

            /**
             * Constructs a new PushGameBets.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushGameBets.
             * @implements IPushGameBets
             * @constructor
             * @param {Rugbull.Protocols.IPushGameBets=} [properties] Properties to set
             */
            function PushGameBets(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushGameBets OrderCount.
             * @member {number} OrderCount
             * @memberof Rugbull.Protocols.PushGameBets
             * @instance
             */
            PushGameBets.prototype.OrderCount = 0;

            /**
             * PushGameBets Infos.
             * @member {Array.<Rugbull.Protocols.IGameBetInfo>} Infos
             * @memberof Rugbull.Protocols.PushGameBets
             * @instance
             */
            PushGameBets.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new PushGameBets instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Rugbull.Protocols.IPushGameBets=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushGameBets} PushGameBets instance
             */
            PushGameBets.create = function create(properties) {
                return new PushGameBets(properties);
            };

            /**
             * Encodes the specified PushGameBets message. Does not implicitly {@link Rugbull.Protocols.PushGameBets.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Rugbull.Protocols.IPushGameBets} message PushGameBets message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushGameBets.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        $root.Rugbull.Protocols.GameBetInfo.encode(message.Infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.OrderCount != null && Object.hasOwnProperty.call(message, "OrderCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.OrderCount);
                return writer;
            };

            /**
             * Encodes the specified PushGameBets message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushGameBets.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Rugbull.Protocols.IPushGameBets} message PushGameBets message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushGameBets.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushGameBets message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushGameBets} PushGameBets
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushGameBets.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushGameBets();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.OrderCount = reader.int32();
                            break;
                        }
                    case 1: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push($root.Rugbull.Protocols.GameBetInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushGameBets message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushGameBets} PushGameBets
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushGameBets.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushGameBets message.
             * @function verify
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushGameBets.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.OrderCount != null && message.hasOwnProperty("OrderCount"))
                    if (!$util.isInteger(message.OrderCount))
                        return "OrderCount: integer expected";
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i) {
                        let error = $root.Rugbull.Protocols.GameBetInfo.verify(message.Infos[i]);
                        if (error)
                            return "Infos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PushGameBets message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushGameBets} PushGameBets
             */
            PushGameBets.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushGameBets)
                    return object;
                let message = new $root.Rugbull.Protocols.PushGameBets();
                if (object.OrderCount != null)
                    message.OrderCount = object.OrderCount | 0;
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.PushGameBets.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i) {
                        if (typeof object.Infos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushGameBets.Infos: object expected");
                        message.Infos[i] = $root.Rugbull.Protocols.GameBetInfo.fromObject(object.Infos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PushGameBets message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {Rugbull.Protocols.PushGameBets} message PushGameBets
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushGameBets.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (options.defaults)
                    object.OrderCount = 0;
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = $root.Rugbull.Protocols.GameBetInfo.toObject(message.Infos[j], options);
                }
                if (message.OrderCount != null && message.hasOwnProperty("OrderCount"))
                    object.OrderCount = message.OrderCount;
                return object;
            };

            /**
             * Converts this PushGameBets to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushGameBets
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushGameBets.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushGameBets
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushGameBets
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushGameBets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushGameBets";
            };

            return PushGameBets;
        })();

        Protocols.PushMultiplier = (function() {

            /**
             * Properties of a PushMultiplier.
             * @memberof Rugbull.Protocols
             * @interface IPushMultiplier
             * @property {Array.<string>|null} [Infos] PushMultiplier Infos
             */

            /**
             * Constructs a new PushMultiplier.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushMultiplier.
             * @implements IPushMultiplier
             * @constructor
             * @param {Rugbull.Protocols.IPushMultiplier=} [properties] Properties to set
             */
            function PushMultiplier(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushMultiplier Infos.
             * @member {Array.<string>} Infos
             * @memberof Rugbull.Protocols.PushMultiplier
             * @instance
             */
            PushMultiplier.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new PushMultiplier instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Rugbull.Protocols.IPushMultiplier=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushMultiplier} PushMultiplier instance
             */
            PushMultiplier.create = function create(properties) {
                return new PushMultiplier(properties);
            };

            /**
             * Encodes the specified PushMultiplier message. Does not implicitly {@link Rugbull.Protocols.PushMultiplier.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Rugbull.Protocols.IPushMultiplier} message PushMultiplier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushMultiplier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.Infos[i]);
                return writer;
            };

            /**
             * Encodes the specified PushMultiplier message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushMultiplier.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Rugbull.Protocols.IPushMultiplier} message PushMultiplier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushMultiplier.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushMultiplier message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushMultiplier} PushMultiplier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushMultiplier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushMultiplier();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushMultiplier message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushMultiplier} PushMultiplier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushMultiplier.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushMultiplier message.
             * @function verify
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushMultiplier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i)
                        if (!$util.isString(message.Infos[i]))
                            return "Infos: string[] expected";
                }
                return null;
            };

            /**
             * Creates a PushMultiplier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushMultiplier} PushMultiplier
             */
            PushMultiplier.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushMultiplier)
                    return object;
                let message = new $root.Rugbull.Protocols.PushMultiplier();
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.PushMultiplier.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i)
                        message.Infos[i] = String(object.Infos[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a PushMultiplier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {Rugbull.Protocols.PushMultiplier} message PushMultiplier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushMultiplier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = message.Infos[j];
                }
                return object;
            };

            /**
             * Converts this PushMultiplier to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushMultiplier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushMultiplier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushMultiplier
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushMultiplier
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushMultiplier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushMultiplier";
            };

            return PushMultiplier;
        })();

        Protocols.PushBetHistory = (function() {

            /**
             * Properties of a PushBetHistory.
             * @memberof Rugbull.Protocols
             * @interface IPushBetHistory
             * @property {Array.<Rugbull.Protocols.IBetHistoryInfo>|null} [Infos] PushBetHistory Infos
             */

            /**
             * Constructs a new PushBetHistory.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushBetHistory.
             * @implements IPushBetHistory
             * @constructor
             * @param {Rugbull.Protocols.IPushBetHistory=} [properties] Properties to set
             */
            function PushBetHistory(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushBetHistory Infos.
             * @member {Array.<Rugbull.Protocols.IBetHistoryInfo>} Infos
             * @memberof Rugbull.Protocols.PushBetHistory
             * @instance
             */
            PushBetHistory.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new PushBetHistory instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Rugbull.Protocols.IPushBetHistory=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushBetHistory} PushBetHistory instance
             */
            PushBetHistory.create = function create(properties) {
                return new PushBetHistory(properties);
            };

            /**
             * Encodes the specified PushBetHistory message. Does not implicitly {@link Rugbull.Protocols.PushBetHistory.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Rugbull.Protocols.IPushBetHistory} message PushBetHistory message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushBetHistory.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        $root.Rugbull.Protocols.BetHistoryInfo.encode(message.Infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PushBetHistory message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushBetHistory.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Rugbull.Protocols.IPushBetHistory} message PushBetHistory message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushBetHistory.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushBetHistory message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushBetHistory} PushBetHistory
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushBetHistory.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushBetHistory();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push($root.Rugbull.Protocols.BetHistoryInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushBetHistory message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushBetHistory} PushBetHistory
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushBetHistory.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushBetHistory message.
             * @function verify
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushBetHistory.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i) {
                        let error = $root.Rugbull.Protocols.BetHistoryInfo.verify(message.Infos[i]);
                        if (error)
                            return "Infos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PushBetHistory message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushBetHistory} PushBetHistory
             */
            PushBetHistory.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushBetHistory)
                    return object;
                let message = new $root.Rugbull.Protocols.PushBetHistory();
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.PushBetHistory.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i) {
                        if (typeof object.Infos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushBetHistory.Infos: object expected");
                        message.Infos[i] = $root.Rugbull.Protocols.BetHistoryInfo.fromObject(object.Infos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PushBetHistory message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {Rugbull.Protocols.PushBetHistory} message PushBetHistory
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushBetHistory.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = $root.Rugbull.Protocols.BetHistoryInfo.toObject(message.Infos[j], options);
                }
                return object;
            };

            /**
             * Converts this PushBetHistory to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushBetHistory
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushBetHistory.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushBetHistory
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushBetHistory
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushBetHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushBetHistory";
            };

            return PushBetHistory;
        })();

        Protocols.PushGameWin = (function() {

            /**
             * Properties of a PushGameWin.
             * @memberof Rugbull.Protocols
             * @interface IPushGameWin
             * @property {string|null} [Multiplier] PushGameWin Multiplier
             * @property {string|null} [Amount] PushGameWin Amount
             */

            /**
             * Constructs a new PushGameWin.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushGameWin.
             * @implements IPushGameWin
             * @constructor
             * @param {Rugbull.Protocols.IPushGameWin=} [properties] Properties to set
             */
            function PushGameWin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushGameWin Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.PushGameWin
             * @instance
             */
            PushGameWin.prototype.Multiplier = "";

            /**
             * PushGameWin Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.PushGameWin
             * @instance
             */
            PushGameWin.prototype.Amount = "";

            /**
             * Creates a new PushGameWin instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Rugbull.Protocols.IPushGameWin=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushGameWin} PushGameWin instance
             */
            PushGameWin.create = function create(properties) {
                return new PushGameWin(properties);
            };

            /**
             * Encodes the specified PushGameWin message. Does not implicitly {@link Rugbull.Protocols.PushGameWin.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Rugbull.Protocols.IPushGameWin} message PushGameWin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushGameWin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Multiplier);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                return writer;
            };

            /**
             * Encodes the specified PushGameWin message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushGameWin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Rugbull.Protocols.IPushGameWin} message PushGameWin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushGameWin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushGameWin message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushGameWin} PushGameWin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushGameWin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushGameWin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushGameWin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushGameWin} PushGameWin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushGameWin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushGameWin message.
             * @function verify
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushGameWin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                return null;
            };

            /**
             * Creates a PushGameWin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushGameWin} PushGameWin
             */
            PushGameWin.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushGameWin)
                    return object;
                let message = new $root.Rugbull.Protocols.PushGameWin();
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                return message;
            };

            /**
             * Creates a plain object from a PushGameWin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {Rugbull.Protocols.PushGameWin} message PushGameWin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushGameWin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Multiplier = "";
                    object.Amount = "";
                }
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                return object;
            };

            /**
             * Converts this PushGameWin to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushGameWin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushGameWin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushGameWin
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushGameWin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushGameWin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushGameWin";
            };

            return PushGameWin;
        })();

        Protocols.SCPrevGameBets = (function() {

            /**
             * Properties of a SCPrevGameBets.
             * @memberof Rugbull.Protocols
             * @interface ISCPrevGameBets
             * @property {Array.<Rugbull.Protocols.IGameBetInfo>|null} [Infos] SCPrevGameBets Infos
             */

            /**
             * Constructs a new SCPrevGameBets.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCPrevGameBets.
             * @implements ISCPrevGameBets
             * @constructor
             * @param {Rugbull.Protocols.ISCPrevGameBets=} [properties] Properties to set
             */
            function SCPrevGameBets(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCPrevGameBets Infos.
             * @member {Array.<Rugbull.Protocols.IGameBetInfo>} Infos
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @instance
             */
            SCPrevGameBets.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new SCPrevGameBets instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Rugbull.Protocols.ISCPrevGameBets=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCPrevGameBets} SCPrevGameBets instance
             */
            SCPrevGameBets.create = function create(properties) {
                return new SCPrevGameBets(properties);
            };

            /**
             * Encodes the specified SCPrevGameBets message. Does not implicitly {@link Rugbull.Protocols.SCPrevGameBets.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Rugbull.Protocols.ISCPrevGameBets} message SCPrevGameBets message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCPrevGameBets.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        $root.Rugbull.Protocols.GameBetInfo.encode(message.Infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCPrevGameBets message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCPrevGameBets.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Rugbull.Protocols.ISCPrevGameBets} message SCPrevGameBets message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCPrevGameBets.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCPrevGameBets message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCPrevGameBets} SCPrevGameBets
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCPrevGameBets.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCPrevGameBets();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push($root.Rugbull.Protocols.GameBetInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCPrevGameBets message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCPrevGameBets} SCPrevGameBets
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCPrevGameBets.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCPrevGameBets message.
             * @function verify
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCPrevGameBets.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i) {
                        let error = $root.Rugbull.Protocols.GameBetInfo.verify(message.Infos[i]);
                        if (error)
                            return "Infos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCPrevGameBets message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCPrevGameBets} SCPrevGameBets
             */
            SCPrevGameBets.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCPrevGameBets)
                    return object;
                let message = new $root.Rugbull.Protocols.SCPrevGameBets();
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.SCPrevGameBets.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i) {
                        if (typeof object.Infos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCPrevGameBets.Infos: object expected");
                        message.Infos[i] = $root.Rugbull.Protocols.GameBetInfo.fromObject(object.Infos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCPrevGameBets message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {Rugbull.Protocols.SCPrevGameBets} message SCPrevGameBets
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCPrevGameBets.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = $root.Rugbull.Protocols.GameBetInfo.toObject(message.Infos[j], options);
                }
                return object;
            };

            /**
             * Converts this SCPrevGameBets to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCPrevGameBets.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCPrevGameBets
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCPrevGameBets
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCPrevGameBets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCPrevGameBets";
            };

            return SCPrevGameBets;
        })();

        Protocols.PushFaild = (function() {

            /**
             * Properties of a PushFaild.
             * @memberof Rugbull.Protocols
             * @interface IPushFaild
             * @property {string|null} [message] PushFaild message
             */

            /**
             * Constructs a new PushFaild.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushFaild.
             * @implements IPushFaild
             * @constructor
             * @param {Rugbull.Protocols.IPushFaild=} [properties] Properties to set
             */
            function PushFaild(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushFaild message.
             * @member {string} message
             * @memberof Rugbull.Protocols.PushFaild
             * @instance
             */
            PushFaild.prototype.message = "";

            /**
             * Creates a new PushFaild instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Rugbull.Protocols.IPushFaild=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushFaild} PushFaild instance
             */
            PushFaild.create = function create(properties) {
                return new PushFaild(properties);
            };

            /**
             * Encodes the specified PushFaild message. Does not implicitly {@link Rugbull.Protocols.PushFaild.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Rugbull.Protocols.IPushFaild} message PushFaild message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushFaild.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                return writer;
            };

            /**
             * Encodes the specified PushFaild message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushFaild.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Rugbull.Protocols.IPushFaild} message PushFaild message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushFaild.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushFaild message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushFaild} PushFaild
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushFaild.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushFaild();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.message = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushFaild message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushFaild} PushFaild
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushFaild.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushFaild message.
             * @function verify
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushFaild.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.message != null && message.hasOwnProperty("message"))
                    if (!$util.isString(message.message))
                        return "message: string expected";
                return null;
            };

            /**
             * Creates a PushFaild message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushFaild} PushFaild
             */
            PushFaild.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushFaild)
                    return object;
                let message = new $root.Rugbull.Protocols.PushFaild();
                if (object.message != null)
                    message.message = String(object.message);
                return message;
            };

            /**
             * Creates a plain object from a PushFaild message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {Rugbull.Protocols.PushFaild} message PushFaild
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushFaild.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.message = "";
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                return object;
            };

            /**
             * Converts this PushFaild to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushFaild
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushFaild.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushFaild
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushFaild
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushFaild.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushFaild";
            };

            return PushFaild;
        })();

        Protocols.CSGetTrade = (function() {

            /**
             * Properties of a CSGetTrade.
             * @memberof Rugbull.Protocols
             * @interface ICSGetTrade
             * @property {number|null} [Id] CSGetTrade Id
             * @property {string|null} [Sign] CSGetTrade Sign
             */

            /**
             * Constructs a new CSGetTrade.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSGetTrade.
             * @implements ICSGetTrade
             * @constructor
             * @param {Rugbull.Protocols.ICSGetTrade=} [properties] Properties to set
             */
            function CSGetTrade(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSGetTrade Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.CSGetTrade
             * @instance
             */
            CSGetTrade.prototype.Id = 0;

            /**
             * CSGetTrade Sign.
             * @member {string} Sign
             * @memberof Rugbull.Protocols.CSGetTrade
             * @instance
             */
            CSGetTrade.prototype.Sign = "";

            /**
             * Creates a new CSGetTrade instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Rugbull.Protocols.ICSGetTrade=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSGetTrade} CSGetTrade instance
             */
            CSGetTrade.create = function create(properties) {
                return new CSGetTrade(properties);
            };

            /**
             * Encodes the specified CSGetTrade message. Does not implicitly {@link Rugbull.Protocols.CSGetTrade.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Rugbull.Protocols.ICSGetTrade} message CSGetTrade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetTrade.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.Sign != null && Object.hasOwnProperty.call(message, "Sign"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Sign);
                return writer;
            };

            /**
             * Encodes the specified CSGetTrade message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSGetTrade.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Rugbull.Protocols.ICSGetTrade} message CSGetTrade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetTrade.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSGetTrade message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSGetTrade} CSGetTrade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetTrade.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSGetTrade();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Sign = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSGetTrade message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSGetTrade} CSGetTrade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetTrade.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSGetTrade message.
             * @function verify
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSGetTrade.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    if (!$util.isString(message.Sign))
                        return "Sign: string expected";
                return null;
            };

            /**
             * Creates a CSGetTrade message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSGetTrade} CSGetTrade
             */
            CSGetTrade.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSGetTrade)
                    return object;
                let message = new $root.Rugbull.Protocols.CSGetTrade();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.Sign != null)
                    message.Sign = String(object.Sign);
                return message;
            };

            /**
             * Creates a plain object from a CSGetTrade message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {Rugbull.Protocols.CSGetTrade} message CSGetTrade
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSGetTrade.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.Sign = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    object.Sign = message.Sign;
                return object;
            };

            /**
             * Converts this CSGetTrade to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSGetTrade
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSGetTrade.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSGetTrade
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSGetTrade
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSGetTrade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSGetTrade";
            };

            return CSGetTrade;
        })();

        Protocols.SCGetTrade = (function() {

            /**
             * Properties of a SCGetTrade.
             * @memberof Rugbull.Protocols
             * @interface ISCGetTrade
             * @property {number|null} [Id] SCGetTrade Id
             * @property {string|null} [UserName] SCGetTrade UserName
             * @property {string|null} [Amount] SCGetTrade Amount
             * @property {string|null} [Multiplier] SCGetTrade Multiplier
             * @property {string|null} [PhotoUrl] SCGetTrade PhotoUrl
             * @property {string|null} [Round] SCGetTrade Round
             * @property {string|null} [BetTime] SCGetTrade BetTime
             * @property {string|null} [RoundMultiplier] SCGetTrade RoundMultiplier
             * @property {string|null} [WinAmount] SCGetTrade WinAmount
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] SCGetTrade CoinType
             * @property {string|null} [InviteCode] SCGetTrade InviteCode
             * @property {number|null} [TradeId] SCGetTrade TradeId
             * @property {string|null} [Sign] SCGetTrade Sign
             */

            /**
             * Constructs a new SCGetTrade.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCGetTrade.
             * @implements ISCGetTrade
             * @constructor
             * @param {Rugbull.Protocols.ISCGetTrade=} [properties] Properties to set
             */
            function SCGetTrade(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCGetTrade Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.Id = 0;

            /**
             * SCGetTrade UserName.
             * @member {string} UserName
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.UserName = "";

            /**
             * SCGetTrade Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.Amount = "";

            /**
             * SCGetTrade Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.Multiplier = "";

            /**
             * SCGetTrade PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.PhotoUrl = "";

            /**
             * SCGetTrade Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.Round = "";

            /**
             * SCGetTrade BetTime.
             * @member {string} BetTime
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.BetTime = "";

            /**
             * SCGetTrade RoundMultiplier.
             * @member {string} RoundMultiplier
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.RoundMultiplier = "";

            /**
             * SCGetTrade WinAmount.
             * @member {string} WinAmount
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.WinAmount = "";

            /**
             * SCGetTrade CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.CoinType = 0;

            /**
             * SCGetTrade InviteCode.
             * @member {string} InviteCode
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.InviteCode = "";

            /**
             * SCGetTrade TradeId.
             * @member {number} TradeId
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.TradeId = 0;

            /**
             * SCGetTrade Sign.
             * @member {string} Sign
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             */
            SCGetTrade.prototype.Sign = "";

            /**
             * Creates a new SCGetTrade instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Rugbull.Protocols.ISCGetTrade=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCGetTrade} SCGetTrade instance
             */
            SCGetTrade.create = function create(properties) {
                return new SCGetTrade(properties);
            };

            /**
             * Encodes the specified SCGetTrade message. Does not implicitly {@link Rugbull.Protocols.SCGetTrade.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Rugbull.Protocols.ISCGetTrade} message SCGetTrade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetTrade.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.UserName != null && Object.hasOwnProperty.call(message, "UserName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.UserName);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Amount);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Multiplier);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.PhotoUrl);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Round);
                if (message.BetTime != null && Object.hasOwnProperty.call(message, "BetTime"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.BetTime);
                if (message.RoundMultiplier != null && Object.hasOwnProperty.call(message, "RoundMultiplier"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.RoundMultiplier);
                if (message.WinAmount != null && Object.hasOwnProperty.call(message, "WinAmount"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.WinAmount);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.CoinType);
                if (message.InviteCode != null && Object.hasOwnProperty.call(message, "InviteCode"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.InviteCode);
                if (message.TradeId != null && Object.hasOwnProperty.call(message, "TradeId"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.TradeId);
                if (message.Sign != null && Object.hasOwnProperty.call(message, "Sign"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.Sign);
                return writer;
            };

            /**
             * Encodes the specified SCGetTrade message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCGetTrade.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Rugbull.Protocols.ISCGetTrade} message SCGetTrade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetTrade.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCGetTrade message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCGetTrade} SCGetTrade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetTrade.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCGetTrade();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.UserName = reader.string();
                            break;
                        }
                    case 3: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 4: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 5: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 6: {
                            message.Round = reader.string();
                            break;
                        }
                    case 7: {
                            message.BetTime = reader.string();
                            break;
                        }
                    case 8: {
                            message.RoundMultiplier = reader.string();
                            break;
                        }
                    case 9: {
                            message.WinAmount = reader.string();
                            break;
                        }
                    case 10: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    case 11: {
                            message.InviteCode = reader.string();
                            break;
                        }
                    case 12: {
                            message.TradeId = reader.int32();
                            break;
                        }
                    case 13: {
                            message.Sign = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCGetTrade message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCGetTrade} SCGetTrade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetTrade.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCGetTrade message.
             * @function verify
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCGetTrade.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    if (!$util.isString(message.UserName))
                        return "UserName: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.BetTime != null && message.hasOwnProperty("BetTime"))
                    if (!$util.isString(message.BetTime))
                        return "BetTime: string expected";
                if (message.RoundMultiplier != null && message.hasOwnProperty("RoundMultiplier"))
                    if (!$util.isString(message.RoundMultiplier))
                        return "RoundMultiplier: string expected";
                if (message.WinAmount != null && message.hasOwnProperty("WinAmount"))
                    if (!$util.isString(message.WinAmount))
                        return "WinAmount: string expected";
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    if (!$util.isString(message.InviteCode))
                        return "InviteCode: string expected";
                if (message.TradeId != null && message.hasOwnProperty("TradeId"))
                    if (!$util.isInteger(message.TradeId))
                        return "TradeId: integer expected";
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    if (!$util.isString(message.Sign))
                        return "Sign: string expected";
                return null;
            };

            /**
             * Creates a SCGetTrade message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCGetTrade} SCGetTrade
             */
            SCGetTrade.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCGetTrade)
                    return object;
                let message = new $root.Rugbull.Protocols.SCGetTrade();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.UserName != null)
                    message.UserName = String(object.UserName);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.BetTime != null)
                    message.BetTime = String(object.BetTime);
                if (object.RoundMultiplier != null)
                    message.RoundMultiplier = String(object.RoundMultiplier);
                if (object.WinAmount != null)
                    message.WinAmount = String(object.WinAmount);
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                if (object.InviteCode != null)
                    message.InviteCode = String(object.InviteCode);
                if (object.TradeId != null)
                    message.TradeId = object.TradeId | 0;
                if (object.Sign != null)
                    message.Sign = String(object.Sign);
                return message;
            };

            /**
             * Creates a plain object from a SCGetTrade message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {Rugbull.Protocols.SCGetTrade} message SCGetTrade
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCGetTrade.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.UserName = "";
                    object.Amount = "";
                    object.Multiplier = "";
                    object.PhotoUrl = "";
                    object.Round = "";
                    object.BetTime = "";
                    object.RoundMultiplier = "";
                    object.WinAmount = "";
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                    object.InviteCode = "";
                    object.TradeId = 0;
                    object.Sign = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    object.UserName = message.UserName;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.BetTime != null && message.hasOwnProperty("BetTime"))
                    object.BetTime = message.BetTime;
                if (message.RoundMultiplier != null && message.hasOwnProperty("RoundMultiplier"))
                    object.RoundMultiplier = message.RoundMultiplier;
                if (message.WinAmount != null && message.hasOwnProperty("WinAmount"))
                    object.WinAmount = message.WinAmount;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    object.InviteCode = message.InviteCode;
                if (message.TradeId != null && message.hasOwnProperty("TradeId"))
                    object.TradeId = message.TradeId;
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    object.Sign = message.Sign;
                return object;
            };

            /**
             * Converts this SCGetTrade to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCGetTrade
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCGetTrade.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCGetTrade
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCGetTrade
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCGetTrade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCGetTrade";
            };

            return SCGetTrade;
        })();

        /**
         * TaskType enum.
         * @name Rugbull.Protocols.TaskType
         * @enum {number}
         * @property {number} TASK_TYPE_NONE=0 TASK_TYPE_NONE value
         * @property {number} TASK_TYPE_DAILY=1 TASK_TYPE_DAILY value
         * @property {number} TASK_TYPE_ACHIEVEMENT=2 TASK_TYPE_ACHIEVEMENT value
         * @property {number} TASK_TYPE_ADVERTISEMENT=3 TASK_TYPE_ADVERTISEMENT value
         */
        Protocols.TaskType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TASK_TYPE_NONE"] = 0;
            values[valuesById[1] = "TASK_TYPE_DAILY"] = 1;
            values[valuesById[2] = "TASK_TYPE_ACHIEVEMENT"] = 2;
            values[valuesById[3] = "TASK_TYPE_ADVERTISEMENT"] = 3;
            return values;
        })();

        /**
         * Tag enum.
         * @name Rugbull.Protocols.Tag
         * @enum {number}
         * @property {number} Official=0 Official value
         * @property {number} Business=1 Business value
         * @property {number} Kol=2 Kol value
         * @property {number} CheaterParent=3 CheaterParent value
         * @property {number} CheaterChild=4 CheaterChild value
         * @property {number} Utm=5 Utm value
         */
        Protocols.Tag = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Official"] = 0;
            values[valuesById[1] = "Business"] = 1;
            values[valuesById[2] = "Kol"] = 2;
            values[valuesById[3] = "CheaterParent"] = 3;
            values[valuesById[4] = "CheaterChild"] = 4;
            values[valuesById[5] = "Utm"] = 5;
            return values;
        })();

        /**
         * SearchDetailType enum.
         * @name Rugbull.Protocols.SearchDetailType
         * @enum {number}
         * @property {number} Normal=0 Normal value
         * @property {number} DirectCount=1 DirectCount value
         * @property {number} GroupCount=2 GroupCount value
         * @property {number} GroupChildCount=3 GroupChildCount value
         */
        Protocols.SearchDetailType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Normal"] = 0;
            values[valuesById[1] = "DirectCount"] = 1;
            values[valuesById[2] = "GroupCount"] = 2;
            values[valuesById[3] = "GroupChildCount"] = 3;
            return values;
        })();

        /**
         * UserState enum.
         * @name Rugbull.Protocols.UserState
         * @enum {number}
         * @property {number} FirstTime=0 FirstTime value
         * @property {number} OldBack=1 OldBack value
         * @property {number} NormalLogin=2 NormalLogin value
         */
        Protocols.UserState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "FirstTime"] = 0;
            values[valuesById[1] = "OldBack"] = 1;
            values[valuesById[2] = "NormalLogin"] = 2;
            return values;
        })();

        /**
         * RankType enum.
         * @name Rugbull.Protocols.RankType
         * @enum {number}
         * @property {number} HugeWin=0 HugeWin value
         * @property {number} BiggestWin=1 BiggestWin value
         */
        Protocols.RankType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "HugeWin"] = 0;
            values[valuesById[1] = "BiggestWin"] = 1;
            return values;
        })();

        /**
         * ArchieveType enum.
         * @name Rugbull.Protocols.ArchieveType
         * @enum {number}
         * @property {number} TwitterFollow=0 TwitterFollow value
         * @property {number} TgChannelFollow=1 TgChannelFollow value
         * @property {number} TgGroupFollow=2 TgGroupFollow value
         * @property {number} InviteFriends=3 InviteFriends value
         * @property {number} GetFishCount=4 GetFishCount value
         * @property {number} GetCollectionCount=5 GetCollectionCount value
         * @property {number} FeedCount=6 FeedCount value
         * @property {number} SellFishCount=7 SellFishCount value
         * @property {number} SellFishCoins=8 SellFishCoins value
         * @property {number} ChatWIthBot=9 ChatWIthBot value
         * @property {number} ConnectWallet=10 ConnectWallet value
         */
        Protocols.ArchieveType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TwitterFollow"] = 0;
            values[valuesById[1] = "TgChannelFollow"] = 1;
            values[valuesById[2] = "TgGroupFollow"] = 2;
            values[valuesById[3] = "InviteFriends"] = 3;
            values[valuesById[4] = "GetFishCount"] = 4;
            values[valuesById[5] = "GetCollectionCount"] = 5;
            values[valuesById[6] = "FeedCount"] = 6;
            values[valuesById[7] = "SellFishCount"] = 7;
            values[valuesById[8] = "SellFishCoins"] = 8;
            values[valuesById[9] = "ChatWIthBot"] = 9;
            values[valuesById[10] = "ConnectWallet"] = 10;
            return values;
        })();

        /**
         * ProductType enum.
         * @name Rugbull.Protocols.ProductType
         * @enum {number}
         * @property {number} P_Normal=0 P_Normal value
         * @property {number} P_TimeLimited=1 P_TimeLimited value
         */
        Protocols.ProductType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "P_Normal"] = 0;
            values[valuesById[1] = "P_TimeLimited"] = 1;
            return values;
        })();

        /**
         * WithDrawState enum.
         * @name Rugbull.Protocols.WithDrawState
         * @enum {number}
         * @property {number} WithDrawState_None=0 WithDrawState_None value
         * @property {number} WithDrawState_RollCashWaitingForInvite=1 WithDrawState_RollCashWaitingForInvite value
         * @property {number} WithDrawState_RollCash=2 WithDrawState_RollCash value
         * @property {number} WithDrawState_RollInviteWaitingForInvite=3 WithDrawState_RollInviteWaitingForInvite value
         * @property {number} WithDrawState_RollInvite=4 WithDrawState_RollInvite value
         * @property {number} WithDrawState_Invite=5 WithDrawState_Invite value
         * @property {number} WithDrawState_Finished=6 WithDrawState_Finished value
         * @property {number} WithDrawState_WaitingForReward=7 WithDrawState_WaitingForReward value
         */
        Protocols.WithDrawState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WithDrawState_None"] = 0;
            values[valuesById[1] = "WithDrawState_RollCashWaitingForInvite"] = 1;
            values[valuesById[2] = "WithDrawState_RollCash"] = 2;
            values[valuesById[3] = "WithDrawState_RollInviteWaitingForInvite"] = 3;
            values[valuesById[4] = "WithDrawState_RollInvite"] = 4;
            values[valuesById[5] = "WithDrawState_Invite"] = 5;
            values[valuesById[6] = "WithDrawState_Finished"] = 6;
            values[valuesById[7] = "WithDrawState_WaitingForReward"] = 7;
            return values;
        })();

        /**
         * WithDrawStateLogType enum.
         * @name Rugbull.Protocols.WithDrawStateLogType
         * @enum {number}
         * @property {number} WithDrawStateLogType_None=0 WithDrawStateLogType_None value
         * @property {number} WithDrawStateLogType_Restart=1 WithDrawStateLogType_Restart value
         * @property {number} WithDrawStateLogType_MarkFinish=2 WithDrawStateLogType_MarkFinish value
         * @property {number} WithDrawStateLogType_Reject=3 WithDrawStateLogType_Reject value
         * @property {number} WithDrawStateLogType_Edit=4 WithDrawStateLogType_Edit value
         * @property {number} WithDrawStateLogType_MarkUnlocked=5 WithDrawStateLogType_MarkUnlocked value
         * @property {number} WithDrawStateLogType_Reject_Cheat=6 WithDrawStateLogType_Reject_Cheat value
         * @property {number} WithDrawStateLogType_Reject_WrongAddress=7 WithDrawStateLogType_Reject_WrongAddress value
         */
        Protocols.WithDrawStateLogType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WithDrawStateLogType_None"] = 0;
            values[valuesById[1] = "WithDrawStateLogType_Restart"] = 1;
            values[valuesById[2] = "WithDrawStateLogType_MarkFinish"] = 2;
            values[valuesById[3] = "WithDrawStateLogType_Reject"] = 3;
            values[valuesById[4] = "WithDrawStateLogType_Edit"] = 4;
            values[valuesById[5] = "WithDrawStateLogType_MarkUnlocked"] = 5;
            values[valuesById[6] = "WithDrawStateLogType_Reject_Cheat"] = 6;
            values[valuesById[7] = "WithDrawStateLogType_Reject_WrongAddress"] = 7;
            return values;
        })();

        /**
         * RejectReason enum.
         * @name Rugbull.Protocols.RejectReason
         * @enum {number}
         * @property {number} RejectReason_Cheat=0 RejectReason_Cheat value
         * @property {number} RejectReason_WrongAddress=1 RejectReason_WrongAddress value
         */
        Protocols.RejectReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RejectReason_Cheat"] = 0;
            values[valuesById[1] = "RejectReason_WrongAddress"] = 1;
            return values;
        })();

        /**
         * WalletWithDrawState enum.
         * @name Rugbull.Protocols.WalletWithDrawState
         * @enum {number}
         * @property {number} WalletWithDrawState_None=0 WalletWithDrawState_None value
         * @property {number} WalletWithDrawState_WaitingForReward=1 WalletWithDrawState_WaitingForReward value
         */
        Protocols.WalletWithDrawState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WalletWithDrawState_None"] = 0;
            values[valuesById[1] = "WalletWithDrawState_WaitingForReward"] = 1;
            return values;
        })();

        /**
         * WalletWithDrawStateLogType enum.
         * @name Rugbull.Protocols.WalletWithDrawStateLogType
         * @enum {number}
         * @property {number} WalletWithDrawStateLogType_None=0 WalletWithDrawStateLogType_None value
         * @property {number} WalletWithDrawStateLogType_Edit=1 WalletWithDrawStateLogType_Edit value
         * @property {number} WalletWithDrawStateLogType_MarkFinish=2 WalletWithDrawStateLogType_MarkFinish value
         * @property {number} WalletWithDrawStateLogType_Reject=3 WalletWithDrawStateLogType_Reject value
         * @property {number} WalletWithDrawStateLogType_Reject_Cheat=4 WalletWithDrawStateLogType_Reject_Cheat value
         * @property {number} WalletWithDrawStateLogType_Reject_WrongAddress=5 WalletWithDrawStateLogType_Reject_WrongAddress value
         */
        Protocols.WalletWithDrawStateLogType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WalletWithDrawStateLogType_None"] = 0;
            values[valuesById[1] = "WalletWithDrawStateLogType_Edit"] = 1;
            values[valuesById[2] = "WalletWithDrawStateLogType_MarkFinish"] = 2;
            values[valuesById[3] = "WalletWithDrawStateLogType_Reject"] = 3;
            values[valuesById[4] = "WalletWithDrawStateLogType_Reject_Cheat"] = 4;
            values[valuesById[5] = "WalletWithDrawStateLogType_Reject_WrongAddress"] = 5;
            return values;
        })();

        /**
         * SystemSwitchType enum.
         * @name Rugbull.Protocols.SystemSwitchType
         * @enum {number}
         * @property {number} ForceJoin=0 ForceJoin value
         * @property {number} Withdraw_RedirectToSupport=1 Withdraw_RedirectToSupport value
         */
        Protocols.SystemSwitchType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ForceJoin"] = 0;
            values[valuesById[1] = "Withdraw_RedirectToSupport"] = 1;
            return values;
        })();

        /**
         * GameStatus enum.
         * @name Rugbull.Protocols.GameStatus
         * @enum {number}
         * @property {number} GameStatus_None=0 GameStatus_None value
         * @property {number} GameStatus_Ready=1 GameStatus_Ready value
         * @property {number} GameStatus_Lock=2 GameStatus_Lock value
         * @property {number} GameStatus_InProgress=3 GameStatus_InProgress value
         * @property {number} GameStatus_Finished=4 GameStatus_Finished value
         */
        Protocols.GameStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GameStatus_None"] = 0;
            values[valuesById[1] = "GameStatus_Ready"] = 1;
            values[valuesById[2] = "GameStatus_Lock"] = 2;
            values[valuesById[3] = "GameStatus_InProgress"] = 3;
            values[valuesById[4] = "GameStatus_Finished"] = 4;
            return values;
        })();

        /**
         * UserGameStatus enum.
         * @name Rugbull.Protocols.UserGameStatus
         * @enum {number}
         * @property {number} UserGameStatus_None=0 UserGameStatus_None value
         * @property {number} UserGameStatus_Pendding=1 UserGameStatus_Pendding value
         * @property {number} UserGameStatus_InGame=2 UserGameStatus_InGame value
         */
        Protocols.UserGameStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UserGameStatus_None"] = 0;
            values[valuesById[1] = "UserGameStatus_Pendding"] = 1;
            values[valuesById[2] = "UserGameStatus_InGame"] = 2;
            return values;
        })();

        /**
         * SearchTopType enum.
         * @name Rugbull.Protocols.SearchTopType
         * @enum {number}
         * @property {number} DAY=0 DAY value
         * @property {number} MOUNTH=1 MOUNTH value
         * @property {number} YEAR=2 YEAR value
         */
        Protocols.SearchTopType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAY"] = 0;
            values[valuesById[1] = "MOUNTH"] = 1;
            values[valuesById[2] = "YEAR"] = 2;
            return values;
        })();

        /**
         * ChainType enum.
         * @name Rugbull.Protocols.ChainType
         * @enum {number}
         * @property {number} ChainType_Unknown=0 ChainType_Unknown value
         * @property {number} ChainType_Bsc=1 ChainType_Bsc value
         * @property {number} ChainType_Tron=2 ChainType_Tron value
         * @property {number} ChainType_Ton=3 ChainType_Ton value
         * @property {number} ChainType_Solana=4 ChainType_Solana value
         */
        Protocols.ChainType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ChainType_Unknown"] = 0;
            values[valuesById[1] = "ChainType_Bsc"] = 1;
            values[valuesById[2] = "ChainType_Tron"] = 2;
            values[valuesById[3] = "ChainType_Ton"] = 3;
            values[valuesById[4] = "ChainType_Solana"] = 4;
            return values;
        })();

        /**
         * OrderStatus enum.
         * @name Rugbull.Protocols.OrderStatus
         * @enum {number}
         * @property {number} OrderStatus_Waiting=0 OrderStatus_Waiting value
         * @property {number} OrderStatus_Error=1 OrderStatus_Error value
         * @property {number} OrderStatus_Purchased=2 OrderStatus_Purchased value
         * @property {number} OrderStatus_Expired=3 OrderStatus_Expired value
         * @property {number} OrderStatus_Cancelled=4 OrderStatus_Cancelled value
         */
        Protocols.OrderStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OrderStatus_Waiting"] = 0;
            values[valuesById[1] = "OrderStatus_Error"] = 1;
            values[valuesById[2] = "OrderStatus_Purchased"] = 2;
            values[valuesById[3] = "OrderStatus_Expired"] = 3;
            values[valuesById[4] = "OrderStatus_Cancelled"] = 4;
            return values;
        })();

        /**
         * SystemWalletType enum.
         * @name Rugbull.Protocols.SystemWalletType
         * @enum {number}
         * @property {number} SystemWalletType_Gather=0 SystemWalletType_Gather value
         * @property {number} SystemWalletType_PayGas=1 SystemWalletType_PayGas value
         * @property {number} SystemWalletType_PayUsdt=2 SystemWalletType_PayUsdt value
         */
        Protocols.SystemWalletType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SystemWalletType_Gather"] = 0;
            values[valuesById[1] = "SystemWalletType_PayGas"] = 1;
            values[valuesById[2] = "SystemWalletType_PayUsdt"] = 2;
            return values;
        })();

        /**
         * BlockStatus enum.
         * @name Rugbull.Protocols.BlockStatus
         * @enum {number}
         * @property {number} BlockStatus_WaitingForConfirm=0 BlockStatus_WaitingForConfirm value
         * @property {number} BlockStatusConfirmed=1 BlockStatusConfirmed value
         * @property {number} BlockStatusError=2 BlockStatusError value
         */
        Protocols.BlockStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BlockStatus_WaitingForConfirm"] = 0;
            values[valuesById[1] = "BlockStatusConfirmed"] = 1;
            values[valuesById[2] = "BlockStatusError"] = 2;
            return values;
        })();

        /**
         * CoinType enum.
         * @name Rugbull.Protocols.CoinType
         * @enum {number}
         * @property {number} CoinType_Unknown=0 CoinType_Unknown value
         * @property {number} CoinType_Coin=1 CoinType_Coin value
         * @property {number} CoinType_USDT=2 CoinType_USDT value
         * @property {number} CoinType_Ton=3 CoinType_Ton value
         */
        Protocols.CoinType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CoinType_Unknown"] = 0;
            values[valuesById[1] = "CoinType_Coin"] = 1;
            values[valuesById[2] = "CoinType_USDT"] = 2;
            values[valuesById[3] = "CoinType_Ton"] = 3;
            return values;
        })();

        /**
         * TaskShareState enum.
         * @name Rugbull.Protocols.TaskShareState
         * @enum {number}
         * @property {number} TaskShareState_None=0 TaskShareState_None value
         * @property {number} TaskShareState_NeedCompletePrev=1 TaskShareState_NeedCompletePrev value
         * @property {number} TaskShareState_WaitingForInvites=2 TaskShareState_WaitingForInvites value
         * @property {number} TaskShareState_WaitingForCommit=3 TaskShareState_WaitingForCommit value
         * @property {number} TaskShareState_Committed=4 TaskShareState_Committed value
         * @property {number} TaskShareState_Verified=5 TaskShareState_Verified value
         */
        Protocols.TaskShareState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TaskShareState_None"] = 0;
            values[valuesById[1] = "TaskShareState_NeedCompletePrev"] = 1;
            values[valuesById[2] = "TaskShareState_WaitingForInvites"] = 2;
            values[valuesById[3] = "TaskShareState_WaitingForCommit"] = 3;
            values[valuesById[4] = "TaskShareState_Committed"] = 4;
            values[valuesById[5] = "TaskShareState_Verified"] = 5;
            return values;
        })();

        /**
         * TaskShareLogType enum.
         * @name Rugbull.Protocols.TaskShareLogType
         * @enum {number}
         * @property {number} TaskShareLogType_None=0 TaskShareLogType_None value
         * @property {number} TaskShareLogType_Edit=1 TaskShareLogType_Edit value
         * @property {number} TaskShareLogType_MarkFinish=2 TaskShareLogType_MarkFinish value
         * @property {number} TaskShareLogType_Reject=3 TaskShareLogType_Reject value
         */
        Protocols.TaskShareLogType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TaskShareLogType_None"] = 0;
            values[valuesById[1] = "TaskShareLogType_Edit"] = 1;
            values[valuesById[2] = "TaskShareLogType_MarkFinish"] = 2;
            values[valuesById[3] = "TaskShareLogType_Reject"] = 3;
            return values;
        })();

        /**
         * SearchDirectType enum.
         * @name Rugbull.Protocols.SearchDirectType
         * @enum {number}
         * @property {number} SearchDirectType_Direct=0 SearchDirectType_Direct value
         * @property {number} SearchDirectType_Group=1 SearchDirectType_Group value
         */
        Protocols.SearchDirectType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SearchDirectType_Direct"] = 0;
            values[valuesById[1] = "SearchDirectType_Group"] = 1;
            return values;
        })();

        /**
         * SearchGroupType enum.
         * @name Rugbull.Protocols.SearchGroupType
         * @enum {number}
         * @property {number} SearchGroupType_Direct=0 SearchGroupType_Direct value
         * @property {number} SearchGroupType_Group=1 SearchGroupType_Group value
         * @property {number} SearchGroupType_Group_Children=2 SearchGroupType_Group_Children value
         */
        Protocols.SearchGroupType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SearchGroupType_Direct"] = 0;
            values[valuesById[1] = "SearchGroupType_Group"] = 1;
            values[valuesById[2] = "SearchGroupType_Group_Children"] = 2;
            return values;
        })();

        /**
         * SystemDataType enum.
         * @name Rugbull.Protocols.SystemDataType
         * @enum {number}
         * @property {number} SystemDataType_CashBack=0 SystemDataType_CashBack value
         * @property {number} SystemDataType_MinBSCGather=1 SystemDataType_MinBSCGather value
         * @property {number} SystemDataType_MinTronGather=2 SystemDataType_MinTronGather value
         * @property {number} SystemDataType_MinSolanaGather=3 SystemDataType_MinSolanaGather value
         */
        Protocols.SystemDataType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SystemDataType_CashBack"] = 0;
            values[valuesById[1] = "SystemDataType_MinBSCGather"] = 1;
            values[valuesById[2] = "SystemDataType_MinTronGather"] = 2;
            values[valuesById[3] = "SystemDataType_MinSolanaGather"] = 3;
            return values;
        })();

        /**
         * CoinChangeType enum.
         * @name Rugbull.Protocols.CoinChangeType
         * @enum {number}
         * @property {number} CoinChangeType_AutoAdd=0 CoinChangeType_AutoAdd value
         * @property {number} CoinChangeType_AutoSub=1 CoinChangeType_AutoSub value
         * @property {number} CoinChangeType_Add=2 CoinChangeType_Add value
         * @property {number} CoinChangeType_Sub=3 CoinChangeType_Sub value
         */
        Protocols.CoinChangeType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CoinChangeType_AutoAdd"] = 0;
            values[valuesById[1] = "CoinChangeType_AutoSub"] = 1;
            values[valuesById[2] = "CoinChangeType_Add"] = 2;
            values[valuesById[3] = "CoinChangeType_Sub"] = 3;
            return values;
        })();

        /**
         * SearchDepsitUserType enum.
         * @name Rugbull.Protocols.SearchDepsitUserType
         * @enum {number}
         * @property {number} SearchDepsitUserType_Total=0 SearchDepsitUserType_Total value
         * @property {number} SearchDepsitUserType_First=1 SearchDepsitUserType_First value
         * @property {number} SearchDepsitUserType_Second=2 SearchDepsitUserType_Second value
         * @property {number} SearchDepsitUserType_Third=3 SearchDepsitUserType_Third value
         */
        Protocols.SearchDepsitUserType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SearchDepsitUserType_Total"] = 0;
            values[valuesById[1] = "SearchDepsitUserType_First"] = 1;
            values[valuesById[2] = "SearchDepsitUserType_Second"] = 2;
            values[valuesById[3] = "SearchDepsitUserType_Third"] = 3;
            return values;
        })();

        /**
         * AgentScopeType enum.
         * @name Rugbull.Protocols.AgentScopeType
         * @enum {number}
         * @property {number} AgentScopeType_All=0 AgentScopeType_All value
         * @property {number} AgentScopeType_Level_1=1 AgentScopeType_Level_1 value
         * @property {number} AgentScopeType_Level_2=2 AgentScopeType_Level_2 value
         * @property {number} AgentScopeType_Level_3=3 AgentScopeType_Level_3 value
         */
        Protocols.AgentScopeType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AgentScopeType_All"] = 0;
            values[valuesById[1] = "AgentScopeType_Level_1"] = 1;
            values[valuesById[2] = "AgentScopeType_Level_2"] = 2;
            values[valuesById[3] = "AgentScopeType_Level_3"] = 3;
            return values;
        })();

        Protocols.PushCoin = (function() {

            /**
             * Properties of a PushCoin.
             * @memberof Rugbull.Protocols
             * @interface IPushCoin
             * @property {string|null} [Coin] PushCoin Coin
             * @property {Rugbull.Protocols.CoinType|null} [Type] PushCoin Type
             */

            /**
             * Constructs a new PushCoin.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushCoin.
             * @implements IPushCoin
             * @constructor
             * @param {Rugbull.Protocols.IPushCoin=} [properties] Properties to set
             */
            function PushCoin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushCoin Coin.
             * @member {string} Coin
             * @memberof Rugbull.Protocols.PushCoin
             * @instance
             */
            PushCoin.prototype.Coin = "";

            /**
             * PushCoin Type.
             * @member {Rugbull.Protocols.CoinType} Type
             * @memberof Rugbull.Protocols.PushCoin
             * @instance
             */
            PushCoin.prototype.Type = 0;

            /**
             * Creates a new PushCoin instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Rugbull.Protocols.IPushCoin=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushCoin} PushCoin instance
             */
            PushCoin.create = function create(properties) {
                return new PushCoin(properties);
            };

            /**
             * Encodes the specified PushCoin message. Does not implicitly {@link Rugbull.Protocols.PushCoin.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Rugbull.Protocols.IPushCoin} message PushCoin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCoin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Coin != null && Object.hasOwnProperty.call(message, "Coin"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Coin);
                if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Type);
                return writer;
            };

            /**
             * Encodes the specified PushCoin message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushCoin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Rugbull.Protocols.IPushCoin} message PushCoin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCoin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushCoin message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushCoin} PushCoin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCoin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushCoin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Coin = reader.string();
                            break;
                        }
                    case 2: {
                            message.Type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushCoin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushCoin} PushCoin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCoin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushCoin message.
             * @function verify
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushCoin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Coin != null && message.hasOwnProperty("Coin"))
                    if (!$util.isString(message.Coin))
                        return "Coin: string expected";
                if (message.Type != null && message.hasOwnProperty("Type"))
                    switch (message.Type) {
                    default:
                        return "Type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a PushCoin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushCoin} PushCoin
             */
            PushCoin.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushCoin)
                    return object;
                let message = new $root.Rugbull.Protocols.PushCoin();
                if (object.Coin != null)
                    message.Coin = String(object.Coin);
                switch (object.Type) {
                default:
                    if (typeof object.Type === "number") {
                        message.Type = object.Type;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.Type = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.Type = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.Type = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.Type = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a PushCoin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {Rugbull.Protocols.PushCoin} message PushCoin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushCoin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Coin = "";
                    object.Type = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Coin != null && message.hasOwnProperty("Coin"))
                    object.Coin = message.Coin;
                if (message.Type != null && message.hasOwnProperty("Type"))
                    object.Type = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.Type] === undefined ? message.Type : $root.Rugbull.Protocols.CoinType[message.Type] : message.Type;
                return object;
            };

            /**
             * Converts this PushCoin to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushCoin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushCoin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushCoin
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushCoin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushCoin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushCoin";
            };

            return PushCoin;
        })();

        Protocols.SCNewUserReward = (function() {

            /**
             * Properties of a SCNewUserReward.
             * @memberof Rugbull.Protocols
             * @interface ISCNewUserReward
             * @property {string|null} [Coin] SCNewUserReward Coin
             * @property {Rugbull.Protocols.CoinType|null} [Type] SCNewUserReward Type
             * @property {boolean|null} [IsPremium] SCNewUserReward IsPremium
             */

            /**
             * Constructs a new SCNewUserReward.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCNewUserReward.
             * @implements ISCNewUserReward
             * @constructor
             * @param {Rugbull.Protocols.ISCNewUserReward=} [properties] Properties to set
             */
            function SCNewUserReward(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCNewUserReward Coin.
             * @member {string} Coin
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @instance
             */
            SCNewUserReward.prototype.Coin = "";

            /**
             * SCNewUserReward Type.
             * @member {Rugbull.Protocols.CoinType} Type
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @instance
             */
            SCNewUserReward.prototype.Type = 0;

            /**
             * SCNewUserReward IsPremium.
             * @member {boolean|null|undefined} IsPremium
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @instance
             */
            SCNewUserReward.prototype.IsPremium = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * SCNewUserReward _IsPremium.
             * @member {"IsPremium"|undefined} _IsPremium
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @instance
             */
            Object.defineProperty(SCNewUserReward.prototype, "_IsPremium", {
                get: $util.oneOfGetter($oneOfFields = ["IsPremium"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SCNewUserReward instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Rugbull.Protocols.ISCNewUserReward=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCNewUserReward} SCNewUserReward instance
             */
            SCNewUserReward.create = function create(properties) {
                return new SCNewUserReward(properties);
            };

            /**
             * Encodes the specified SCNewUserReward message. Does not implicitly {@link Rugbull.Protocols.SCNewUserReward.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Rugbull.Protocols.ISCNewUserReward} message SCNewUserReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCNewUserReward.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Coin != null && Object.hasOwnProperty.call(message, "Coin"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Coin);
                if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Type);
                if (message.IsPremium != null && Object.hasOwnProperty.call(message, "IsPremium"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsPremium);
                return writer;
            };

            /**
             * Encodes the specified SCNewUserReward message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCNewUserReward.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Rugbull.Protocols.ISCNewUserReward} message SCNewUserReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCNewUserReward.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCNewUserReward message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCNewUserReward} SCNewUserReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCNewUserReward.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCNewUserReward();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Coin = reader.string();
                            break;
                        }
                    case 2: {
                            message.Type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.IsPremium = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCNewUserReward message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCNewUserReward} SCNewUserReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCNewUserReward.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCNewUserReward message.
             * @function verify
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCNewUserReward.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.Coin != null && message.hasOwnProperty("Coin"))
                    if (!$util.isString(message.Coin))
                        return "Coin: string expected";
                if (message.Type != null && message.hasOwnProperty("Type"))
                    switch (message.Type) {
                    default:
                        return "Type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium")) {
                    properties._IsPremium = 1;
                    if (typeof message.IsPremium !== "boolean")
                        return "IsPremium: boolean expected";
                }
                return null;
            };

            /**
             * Creates a SCNewUserReward message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCNewUserReward} SCNewUserReward
             */
            SCNewUserReward.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCNewUserReward)
                    return object;
                let message = new $root.Rugbull.Protocols.SCNewUserReward();
                if (object.Coin != null)
                    message.Coin = String(object.Coin);
                switch (object.Type) {
                default:
                    if (typeof object.Type === "number") {
                        message.Type = object.Type;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.Type = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.Type = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.Type = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.Type = 3;
                    break;
                }
                if (object.IsPremium != null)
                    message.IsPremium = Boolean(object.IsPremium);
                return message;
            };

            /**
             * Creates a plain object from a SCNewUserReward message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {Rugbull.Protocols.SCNewUserReward} message SCNewUserReward
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCNewUserReward.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Coin = "";
                    object.Type = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Coin != null && message.hasOwnProperty("Coin"))
                    object.Coin = message.Coin;
                if (message.Type != null && message.hasOwnProperty("Type"))
                    object.Type = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.Type] === undefined ? message.Type : $root.Rugbull.Protocols.CoinType[message.Type] : message.Type;
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium")) {
                    object.IsPremium = message.IsPremium;
                    if (options.oneofs)
                        object._IsPremium = "IsPremium";
                }
                return object;
            };

            /**
             * Converts this SCNewUserReward to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCNewUserReward.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCNewUserReward
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCNewUserReward
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCNewUserReward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCNewUserReward";
            };

            return SCNewUserReward;
        })();

        Protocols.CSSwitchCoin = (function() {

            /**
             * Properties of a CSSwitchCoin.
             * @memberof Rugbull.Protocols
             * @interface ICSSwitchCoin
             * @property {Rugbull.Protocols.CoinType|null} [Type] CSSwitchCoin Type
             */

            /**
             * Constructs a new CSSwitchCoin.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSSwitchCoin.
             * @implements ICSSwitchCoin
             * @constructor
             * @param {Rugbull.Protocols.ICSSwitchCoin=} [properties] Properties to set
             */
            function CSSwitchCoin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSSwitchCoin Type.
             * @member {Rugbull.Protocols.CoinType} Type
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @instance
             */
            CSSwitchCoin.prototype.Type = 0;

            /**
             * Creates a new CSSwitchCoin instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Rugbull.Protocols.ICSSwitchCoin=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSSwitchCoin} CSSwitchCoin instance
             */
            CSSwitchCoin.create = function create(properties) {
                return new CSSwitchCoin(properties);
            };

            /**
             * Encodes the specified CSSwitchCoin message. Does not implicitly {@link Rugbull.Protocols.CSSwitchCoin.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Rugbull.Protocols.ICSSwitchCoin} message CSSwitchCoin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSwitchCoin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
                return writer;
            };

            /**
             * Encodes the specified CSSwitchCoin message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSSwitchCoin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Rugbull.Protocols.ICSSwitchCoin} message CSSwitchCoin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSwitchCoin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSSwitchCoin message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSSwitchCoin} CSSwitchCoin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSwitchCoin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSSwitchCoin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSSwitchCoin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSSwitchCoin} CSSwitchCoin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSwitchCoin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSSwitchCoin message.
             * @function verify
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSSwitchCoin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Type != null && message.hasOwnProperty("Type"))
                    switch (message.Type) {
                    default:
                        return "Type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSSwitchCoin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSSwitchCoin} CSSwitchCoin
             */
            CSSwitchCoin.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSSwitchCoin)
                    return object;
                let message = new $root.Rugbull.Protocols.CSSwitchCoin();
                switch (object.Type) {
                default:
                    if (typeof object.Type === "number") {
                        message.Type = object.Type;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.Type = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.Type = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.Type = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.Type = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSSwitchCoin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {Rugbull.Protocols.CSSwitchCoin} message CSSwitchCoin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSSwitchCoin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Type = options.enums === String ? "CoinType_Unknown" : 0;
                if (message.Type != null && message.hasOwnProperty("Type"))
                    object.Type = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.Type] === undefined ? message.Type : $root.Rugbull.Protocols.CoinType[message.Type] : message.Type;
                return object;
            };

            /**
             * Converts this CSSwitchCoin to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSSwitchCoin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSSwitchCoin
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSSwitchCoin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSSwitchCoin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSSwitchCoin";
            };

            return CSSwitchCoin;
        })();

        Protocols.CoinInfo = (function() {

            /**
             * Properties of a CoinInfo.
             * @memberof Rugbull.Protocols
             * @interface ICoinInfo
             * @property {string|null} [Coin] CoinInfo Coin
             * @property {Rugbull.Protocols.CoinType|null} [Type] CoinInfo Type
             * @property {string|null} [Unlocked] CoinInfo Unlocked
             * @property {string|null} [MinAmount] CoinInfo MinAmount
             * @property {string|null} [Fee] CoinInfo Fee
             */

            /**
             * Constructs a new CoinInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CoinInfo.
             * @implements ICoinInfo
             * @constructor
             * @param {Rugbull.Protocols.ICoinInfo=} [properties] Properties to set
             */
            function CoinInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CoinInfo Coin.
             * @member {string|null|undefined} Coin
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            CoinInfo.prototype.Coin = null;

            /**
             * CoinInfo Type.
             * @member {Rugbull.Protocols.CoinType|null|undefined} Type
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            CoinInfo.prototype.Type = null;

            /**
             * CoinInfo Unlocked.
             * @member {string|null|undefined} Unlocked
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            CoinInfo.prototype.Unlocked = null;

            /**
             * CoinInfo MinAmount.
             * @member {string|null|undefined} MinAmount
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            CoinInfo.prototype.MinAmount = null;

            /**
             * CoinInfo Fee.
             * @member {string|null|undefined} Fee
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            CoinInfo.prototype.Fee = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * CoinInfo _Coin.
             * @member {"Coin"|undefined} _Coin
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            Object.defineProperty(CoinInfo.prototype, "_Coin", {
                get: $util.oneOfGetter($oneOfFields = ["Coin"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CoinInfo _Type.
             * @member {"Type"|undefined} _Type
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            Object.defineProperty(CoinInfo.prototype, "_Type", {
                get: $util.oneOfGetter($oneOfFields = ["Type"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CoinInfo _Unlocked.
             * @member {"Unlocked"|undefined} _Unlocked
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            Object.defineProperty(CoinInfo.prototype, "_Unlocked", {
                get: $util.oneOfGetter($oneOfFields = ["Unlocked"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CoinInfo _MinAmount.
             * @member {"MinAmount"|undefined} _MinAmount
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            Object.defineProperty(CoinInfo.prototype, "_MinAmount", {
                get: $util.oneOfGetter($oneOfFields = ["MinAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CoinInfo _Fee.
             * @member {"Fee"|undefined} _Fee
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             */
            Object.defineProperty(CoinInfo.prototype, "_Fee", {
                get: $util.oneOfGetter($oneOfFields = ["Fee"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CoinInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Rugbull.Protocols.ICoinInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CoinInfo} CoinInfo instance
             */
            CoinInfo.create = function create(properties) {
                return new CoinInfo(properties);
            };

            /**
             * Encodes the specified CoinInfo message. Does not implicitly {@link Rugbull.Protocols.CoinInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Rugbull.Protocols.ICoinInfo} message CoinInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CoinInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Coin != null && Object.hasOwnProperty.call(message, "Coin"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Coin);
                if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Type);
                if (message.Unlocked != null && Object.hasOwnProperty.call(message, "Unlocked"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Unlocked);
                if (message.MinAmount != null && Object.hasOwnProperty.call(message, "MinAmount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.MinAmount);
                if (message.Fee != null && Object.hasOwnProperty.call(message, "Fee"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Fee);
                return writer;
            };

            /**
             * Encodes the specified CoinInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.CoinInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Rugbull.Protocols.ICoinInfo} message CoinInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CoinInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CoinInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CoinInfo} CoinInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CoinInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CoinInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Coin = reader.string();
                            break;
                        }
                    case 2: {
                            message.Type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Unlocked = reader.string();
                            break;
                        }
                    case 4: {
                            message.MinAmount = reader.string();
                            break;
                        }
                    case 5: {
                            message.Fee = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CoinInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CoinInfo} CoinInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CoinInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CoinInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CoinInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.Coin != null && message.hasOwnProperty("Coin")) {
                    properties._Coin = 1;
                    if (!$util.isString(message.Coin))
                        return "Coin: string expected";
                }
                if (message.Type != null && message.hasOwnProperty("Type")) {
                    properties._Type = 1;
                    switch (message.Type) {
                    default:
                        return "Type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                }
                if (message.Unlocked != null && message.hasOwnProperty("Unlocked")) {
                    properties._Unlocked = 1;
                    if (!$util.isString(message.Unlocked))
                        return "Unlocked: string expected";
                }
                if (message.MinAmount != null && message.hasOwnProperty("MinAmount")) {
                    properties._MinAmount = 1;
                    if (!$util.isString(message.MinAmount))
                        return "MinAmount: string expected";
                }
                if (message.Fee != null && message.hasOwnProperty("Fee")) {
                    properties._Fee = 1;
                    if (!$util.isString(message.Fee))
                        return "Fee: string expected";
                }
                return null;
            };

            /**
             * Creates a CoinInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CoinInfo} CoinInfo
             */
            CoinInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CoinInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.CoinInfo();
                if (object.Coin != null)
                    message.Coin = String(object.Coin);
                switch (object.Type) {
                default:
                    if (typeof object.Type === "number") {
                        message.Type = object.Type;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.Type = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.Type = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.Type = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.Type = 3;
                    break;
                }
                if (object.Unlocked != null)
                    message.Unlocked = String(object.Unlocked);
                if (object.MinAmount != null)
                    message.MinAmount = String(object.MinAmount);
                if (object.Fee != null)
                    message.Fee = String(object.Fee);
                return message;
            };

            /**
             * Creates a plain object from a CoinInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {Rugbull.Protocols.CoinInfo} message CoinInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CoinInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.Coin != null && message.hasOwnProperty("Coin")) {
                    object.Coin = message.Coin;
                    if (options.oneofs)
                        object._Coin = "Coin";
                }
                if (message.Type != null && message.hasOwnProperty("Type")) {
                    object.Type = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.Type] === undefined ? message.Type : $root.Rugbull.Protocols.CoinType[message.Type] : message.Type;
                    if (options.oneofs)
                        object._Type = "Type";
                }
                if (message.Unlocked != null && message.hasOwnProperty("Unlocked")) {
                    object.Unlocked = message.Unlocked;
                    if (options.oneofs)
                        object._Unlocked = "Unlocked";
                }
                if (message.MinAmount != null && message.hasOwnProperty("MinAmount")) {
                    object.MinAmount = message.MinAmount;
                    if (options.oneofs)
                        object._MinAmount = "MinAmount";
                }
                if (message.Fee != null && message.hasOwnProperty("Fee")) {
                    object.Fee = message.Fee;
                    if (options.oneofs)
                        object._Fee = "Fee";
                }
                return object;
            };

            /**
             * Converts this CoinInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CoinInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CoinInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CoinInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CoinInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CoinInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CoinInfo";
            };

            return CoinInfo;
        })();

        Protocols.SCDespositInfo = (function() {

            /**
             * Properties of a SCDespositInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCDespositInfo
             * @property {Array.<Rugbull.Protocols.ICoinInfo>|null} [CoinInfos] SCDespositInfo CoinInfos
             * @property {Array.<Rugbull.Protocols.ChainType>|null} [AlieveChains] SCDespositInfo AlieveChains
             */

            /**
             * Constructs a new SCDespositInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCDespositInfo.
             * @implements ISCDespositInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCDespositInfo=} [properties] Properties to set
             */
            function SCDespositInfo(properties) {
                this.CoinInfos = [];
                this.AlieveChains = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCDespositInfo CoinInfos.
             * @member {Array.<Rugbull.Protocols.ICoinInfo>} CoinInfos
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @instance
             */
            SCDespositInfo.prototype.CoinInfos = $util.emptyArray;

            /**
             * SCDespositInfo AlieveChains.
             * @member {Array.<Rugbull.Protocols.ChainType>} AlieveChains
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @instance
             */
            SCDespositInfo.prototype.AlieveChains = $util.emptyArray;

            /**
             * Creates a new SCDespositInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Rugbull.Protocols.ISCDespositInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCDespositInfo} SCDespositInfo instance
             */
            SCDespositInfo.create = function create(properties) {
                return new SCDespositInfo(properties);
            };

            /**
             * Encodes the specified SCDespositInfo message. Does not implicitly {@link Rugbull.Protocols.SCDespositInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Rugbull.Protocols.ISCDespositInfo} message SCDespositInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCDespositInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.CoinInfos != null && message.CoinInfos.length)
                    for (let i = 0; i < message.CoinInfos.length; ++i)
                        $root.Rugbull.Protocols.CoinInfo.encode(message.CoinInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.AlieveChains != null && message.AlieveChains.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.AlieveChains.length; ++i)
                        writer.int32(message.AlieveChains[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified SCDespositInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCDespositInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Rugbull.Protocols.ISCDespositInfo} message SCDespositInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCDespositInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCDespositInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCDespositInfo} SCDespositInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCDespositInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCDespositInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.CoinInfos && message.CoinInfos.length))
                                message.CoinInfos = [];
                            message.CoinInfos.push($root.Rugbull.Protocols.CoinInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.AlieveChains && message.AlieveChains.length))
                                message.AlieveChains = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.AlieveChains.push(reader.int32());
                            } else
                                message.AlieveChains.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCDespositInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCDespositInfo} SCDespositInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCDespositInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCDespositInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCDespositInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.CoinInfos != null && message.hasOwnProperty("CoinInfos")) {
                    if (!Array.isArray(message.CoinInfos))
                        return "CoinInfos: array expected";
                    for (let i = 0; i < message.CoinInfos.length; ++i) {
                        let error = $root.Rugbull.Protocols.CoinInfo.verify(message.CoinInfos[i]);
                        if (error)
                            return "CoinInfos." + error;
                    }
                }
                if (message.AlieveChains != null && message.hasOwnProperty("AlieveChains")) {
                    if (!Array.isArray(message.AlieveChains))
                        return "AlieveChains: array expected";
                    for (let i = 0; i < message.AlieveChains.length; ++i)
                        switch (message.AlieveChains[i]) {
                        default:
                            return "AlieveChains: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a SCDespositInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCDespositInfo} SCDespositInfo
             */
            SCDespositInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCDespositInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCDespositInfo();
                if (object.CoinInfos) {
                    if (!Array.isArray(object.CoinInfos))
                        throw TypeError(".Rugbull.Protocols.SCDespositInfo.CoinInfos: array expected");
                    message.CoinInfos = [];
                    for (let i = 0; i < object.CoinInfos.length; ++i) {
                        if (typeof object.CoinInfos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCDespositInfo.CoinInfos: object expected");
                        message.CoinInfos[i] = $root.Rugbull.Protocols.CoinInfo.fromObject(object.CoinInfos[i]);
                    }
                }
                if (object.AlieveChains) {
                    if (!Array.isArray(object.AlieveChains))
                        throw TypeError(".Rugbull.Protocols.SCDespositInfo.AlieveChains: array expected");
                    message.AlieveChains = [];
                    for (let i = 0; i < object.AlieveChains.length; ++i)
                        switch (object.AlieveChains[i]) {
                        default:
                            if (typeof object.AlieveChains[i] === "number") {
                                message.AlieveChains[i] = object.AlieveChains[i];
                                break;
                            }
                        case "ChainType_Unknown":
                        case 0:
                            message.AlieveChains[i] = 0;
                            break;
                        case "ChainType_Bsc":
                        case 1:
                            message.AlieveChains[i] = 1;
                            break;
                        case "ChainType_Tron":
                        case 2:
                            message.AlieveChains[i] = 2;
                            break;
                        case "ChainType_Ton":
                        case 3:
                            message.AlieveChains[i] = 3;
                            break;
                        case "ChainType_Solana":
                        case 4:
                            message.AlieveChains[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCDespositInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {Rugbull.Protocols.SCDespositInfo} message SCDespositInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCDespositInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.CoinInfos = [];
                    object.AlieveChains = [];
                }
                if (message.CoinInfos && message.CoinInfos.length) {
                    object.CoinInfos = [];
                    for (let j = 0; j < message.CoinInfos.length; ++j)
                        object.CoinInfos[j] = $root.Rugbull.Protocols.CoinInfo.toObject(message.CoinInfos[j], options);
                }
                if (message.AlieveChains && message.AlieveChains.length) {
                    object.AlieveChains = [];
                    for (let j = 0; j < message.AlieveChains.length; ++j)
                        object.AlieveChains[j] = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.AlieveChains[j]] === undefined ? message.AlieveChains[j] : $root.Rugbull.Protocols.ChainType[message.AlieveChains[j]] : message.AlieveChains[j];
                }
                return object;
            };

            /**
             * Converts this SCDespositInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCDespositInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCDespositInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCDespositInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCDespositInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCDespositInfo";
            };

            return SCDespositInfo;
        })();

        Protocols.SCCoinInfo = (function() {

            /**
             * Properties of a SCCoinInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCCoinInfo
             * @property {Array.<Rugbull.Protocols.ICoinInfo>|null} [CoinInfos] SCCoinInfo CoinInfos
             */

            /**
             * Constructs a new SCCoinInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCCoinInfo.
             * @implements ISCCoinInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCCoinInfo=} [properties] Properties to set
             */
            function SCCoinInfo(properties) {
                this.CoinInfos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCCoinInfo CoinInfos.
             * @member {Array.<Rugbull.Protocols.ICoinInfo>} CoinInfos
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @instance
             */
            SCCoinInfo.prototype.CoinInfos = $util.emptyArray;

            /**
             * Creates a new SCCoinInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Rugbull.Protocols.ISCCoinInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCCoinInfo} SCCoinInfo instance
             */
            SCCoinInfo.create = function create(properties) {
                return new SCCoinInfo(properties);
            };

            /**
             * Encodes the specified SCCoinInfo message. Does not implicitly {@link Rugbull.Protocols.SCCoinInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Rugbull.Protocols.ISCCoinInfo} message SCCoinInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCCoinInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.CoinInfos != null && message.CoinInfos.length)
                    for (let i = 0; i < message.CoinInfos.length; ++i)
                        $root.Rugbull.Protocols.CoinInfo.encode(message.CoinInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCCoinInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCCoinInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Rugbull.Protocols.ISCCoinInfo} message SCCoinInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCCoinInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCCoinInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCCoinInfo} SCCoinInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCCoinInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCCoinInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.CoinInfos && message.CoinInfos.length))
                                message.CoinInfos = [];
                            message.CoinInfos.push($root.Rugbull.Protocols.CoinInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCCoinInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCCoinInfo} SCCoinInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCCoinInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCCoinInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCCoinInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.CoinInfos != null && message.hasOwnProperty("CoinInfos")) {
                    if (!Array.isArray(message.CoinInfos))
                        return "CoinInfos: array expected";
                    for (let i = 0; i < message.CoinInfos.length; ++i) {
                        let error = $root.Rugbull.Protocols.CoinInfo.verify(message.CoinInfos[i]);
                        if (error)
                            return "CoinInfos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCCoinInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCCoinInfo} SCCoinInfo
             */
            SCCoinInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCCoinInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCCoinInfo();
                if (object.CoinInfos) {
                    if (!Array.isArray(object.CoinInfos))
                        throw TypeError(".Rugbull.Protocols.SCCoinInfo.CoinInfos: array expected");
                    message.CoinInfos = [];
                    for (let i = 0; i < object.CoinInfos.length; ++i) {
                        if (typeof object.CoinInfos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCCoinInfo.CoinInfos: object expected");
                        message.CoinInfos[i] = $root.Rugbull.Protocols.CoinInfo.fromObject(object.CoinInfos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCCoinInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {Rugbull.Protocols.SCCoinInfo} message SCCoinInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCCoinInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.CoinInfos = [];
                if (message.CoinInfos && message.CoinInfos.length) {
                    object.CoinInfos = [];
                    for (let j = 0; j < message.CoinInfos.length; ++j)
                        object.CoinInfos[j] = $root.Rugbull.Protocols.CoinInfo.toObject(message.CoinInfos[j], options);
                }
                return object;
            };

            /**
             * Converts this SCCoinInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCCoinInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCCoinInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCCoinInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCCoinInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCCoinInfo";
            };

            return SCCoinInfo;
        })();

        Protocols.UserData = (function() {

            /**
             * Properties of a UserData.
             * @memberof Rugbull.Protocols
             * @interface IUserData
             * @property {number|Long|null} [Id] UserData Id
             * @property {boolean|null} [IsBot] UserData IsBot
             * @property {string|null} [FirstName] UserData FirstName
             * @property {string|null} [LastName] UserData LastName
             * @property {string|null} [Username] UserData Username
             * @property {string|null} [LanguageCode] UserData LanguageCode
             * @property {boolean|null} [IsPremium] UserData IsPremium
             * @property {boolean|null} [AddedToAttachmentMenu] UserData AddedToAttachmentMenu
             * @property {boolean|null} [AllowsWriteToPm] UserData AllowsWriteToPm
             * @property {string|null} [PhotoUrl] UserData PhotoUrl
             * @property {number|null} [Nation] UserData Nation
             * @property {number|null} [RecommandNation] UserData RecommandNation
             * @property {string|null} [Language] UserData Language
             */

            /**
             * Constructs a new UserData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a UserData.
             * @implements IUserData
             * @constructor
             * @param {Rugbull.Protocols.IUserData=} [properties] Properties to set
             */
            function UserData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserData Id.
             * @member {number|Long} Id
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.Id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UserData IsBot.
             * @member {boolean} IsBot
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.IsBot = false;

            /**
             * UserData FirstName.
             * @member {string} FirstName
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.FirstName = "";

            /**
             * UserData LastName.
             * @member {string} LastName
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.LastName = "";

            /**
             * UserData Username.
             * @member {string} Username
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.Username = "";

            /**
             * UserData LanguageCode.
             * @member {string} LanguageCode
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.LanguageCode = "";

            /**
             * UserData IsPremium.
             * @member {boolean} IsPremium
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.IsPremium = false;

            /**
             * UserData AddedToAttachmentMenu.
             * @member {boolean} AddedToAttachmentMenu
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.AddedToAttachmentMenu = false;

            /**
             * UserData AllowsWriteToPm.
             * @member {boolean} AllowsWriteToPm
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.AllowsWriteToPm = false;

            /**
             * UserData PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.PhotoUrl = "";

            /**
             * UserData Nation.
             * @member {number} Nation
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.Nation = 0;

            /**
             * UserData RecommandNation.
             * @member {number} RecommandNation
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.RecommandNation = 0;

            /**
             * UserData Language.
             * @member {string} Language
             * @memberof Rugbull.Protocols.UserData
             * @instance
             */
            UserData.prototype.Language = "";

            /**
             * Creates a new UserData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Rugbull.Protocols.IUserData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.UserData} UserData instance
             */
            UserData.create = function create(properties) {
                return new UserData(properties);
            };

            /**
             * Encodes the specified UserData message. Does not implicitly {@link Rugbull.Protocols.UserData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Rugbull.Protocols.IUserData} message UserData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.Id);
                if (message.IsBot != null && Object.hasOwnProperty.call(message, "IsBot"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsBot);
                if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.FirstName);
                if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.LastName);
                if (message.Username != null && Object.hasOwnProperty.call(message, "Username"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Username);
                if (message.LanguageCode != null && Object.hasOwnProperty.call(message, "LanguageCode"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.LanguageCode);
                if (message.IsPremium != null && Object.hasOwnProperty.call(message, "IsPremium"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsPremium);
                if (message.AddedToAttachmentMenu != null && Object.hasOwnProperty.call(message, "AddedToAttachmentMenu"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.AddedToAttachmentMenu);
                if (message.AllowsWriteToPm != null && Object.hasOwnProperty.call(message, "AllowsWriteToPm"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.AllowsWriteToPm);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.PhotoUrl);
                if (message.Nation != null && Object.hasOwnProperty.call(message, "Nation"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.Nation);
                if (message.RecommandNation != null && Object.hasOwnProperty.call(message, "RecommandNation"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.RecommandNation);
                if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.Language);
                return writer;
            };

            /**
             * Encodes the specified UserData message, length delimited. Does not implicitly {@link Rugbull.Protocols.UserData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Rugbull.Protocols.IUserData} message UserData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.UserData} UserData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.UserData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.IsBot = reader.bool();
                            break;
                        }
                    case 3: {
                            message.FirstName = reader.string();
                            break;
                        }
                    case 4: {
                            message.LastName = reader.string();
                            break;
                        }
                    case 5: {
                            message.Username = reader.string();
                            break;
                        }
                    case 6: {
                            message.LanguageCode = reader.string();
                            break;
                        }
                    case 7: {
                            message.IsPremium = reader.bool();
                            break;
                        }
                    case 8: {
                            message.AddedToAttachmentMenu = reader.bool();
                            break;
                        }
                    case 9: {
                            message.AllowsWriteToPm = reader.bool();
                            break;
                        }
                    case 10: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 11: {
                            message.Nation = reader.int32();
                            break;
                        }
                    case 12: {
                            message.RecommandNation = reader.int32();
                            break;
                        }
                    case 13: {
                            message.Language = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.UserData} UserData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserData message.
             * @function verify
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id) && !(message.Id && $util.isInteger(message.Id.low) && $util.isInteger(message.Id.high)))
                        return "Id: integer|Long expected";
                if (message.IsBot != null && message.hasOwnProperty("IsBot"))
                    if (typeof message.IsBot !== "boolean")
                        return "IsBot: boolean expected";
                if (message.FirstName != null && message.hasOwnProperty("FirstName"))
                    if (!$util.isString(message.FirstName))
                        return "FirstName: string expected";
                if (message.LastName != null && message.hasOwnProperty("LastName"))
                    if (!$util.isString(message.LastName))
                        return "LastName: string expected";
                if (message.Username != null && message.hasOwnProperty("Username"))
                    if (!$util.isString(message.Username))
                        return "Username: string expected";
                if (message.LanguageCode != null && message.hasOwnProperty("LanguageCode"))
                    if (!$util.isString(message.LanguageCode))
                        return "LanguageCode: string expected";
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium"))
                    if (typeof message.IsPremium !== "boolean")
                        return "IsPremium: boolean expected";
                if (message.AddedToAttachmentMenu != null && message.hasOwnProperty("AddedToAttachmentMenu"))
                    if (typeof message.AddedToAttachmentMenu !== "boolean")
                        return "AddedToAttachmentMenu: boolean expected";
                if (message.AllowsWriteToPm != null && message.hasOwnProperty("AllowsWriteToPm"))
                    if (typeof message.AllowsWriteToPm !== "boolean")
                        return "AllowsWriteToPm: boolean expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.Nation != null && message.hasOwnProperty("Nation"))
                    if (!$util.isInteger(message.Nation))
                        return "Nation: integer expected";
                if (message.RecommandNation != null && message.hasOwnProperty("RecommandNation"))
                    if (!$util.isInteger(message.RecommandNation))
                        return "RecommandNation: integer expected";
                if (message.Language != null && message.hasOwnProperty("Language"))
                    if (!$util.isString(message.Language))
                        return "Language: string expected";
                return null;
            };

            /**
             * Creates a UserData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.UserData} UserData
             */
            UserData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.UserData)
                    return object;
                let message = new $root.Rugbull.Protocols.UserData();
                if (object.Id != null)
                    if ($util.Long)
                        (message.Id = $util.Long.fromValue(object.Id)).unsigned = false;
                    else if (typeof object.Id === "string")
                        message.Id = parseInt(object.Id, 10);
                    else if (typeof object.Id === "number")
                        message.Id = object.Id;
                    else if (typeof object.Id === "object")
                        message.Id = new $util.LongBits(object.Id.low >>> 0, object.Id.high >>> 0).toNumber();
                if (object.IsBot != null)
                    message.IsBot = Boolean(object.IsBot);
                if (object.FirstName != null)
                    message.FirstName = String(object.FirstName);
                if (object.LastName != null)
                    message.LastName = String(object.LastName);
                if (object.Username != null)
                    message.Username = String(object.Username);
                if (object.LanguageCode != null)
                    message.LanguageCode = String(object.LanguageCode);
                if (object.IsPremium != null)
                    message.IsPremium = Boolean(object.IsPremium);
                if (object.AddedToAttachmentMenu != null)
                    message.AddedToAttachmentMenu = Boolean(object.AddedToAttachmentMenu);
                if (object.AllowsWriteToPm != null)
                    message.AllowsWriteToPm = Boolean(object.AllowsWriteToPm);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.Nation != null)
                    message.Nation = object.Nation | 0;
                if (object.RecommandNation != null)
                    message.RecommandNation = object.RecommandNation | 0;
                if (object.Language != null)
                    message.Language = String(object.Language);
                return message;
            };

            /**
             * Creates a plain object from a UserData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {Rugbull.Protocols.UserData} message UserData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.Id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.Id = options.longs === String ? "0" : 0;
                    object.IsBot = false;
                    object.FirstName = "";
                    object.LastName = "";
                    object.Username = "";
                    object.LanguageCode = "";
                    object.IsPremium = false;
                    object.AddedToAttachmentMenu = false;
                    object.AllowsWriteToPm = false;
                    object.PhotoUrl = "";
                    object.Nation = 0;
                    object.RecommandNation = 0;
                    object.Language = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (typeof message.Id === "number")
                        object.Id = options.longs === String ? String(message.Id) : message.Id;
                    else
                        object.Id = options.longs === String ? $util.Long.prototype.toString.call(message.Id) : options.longs === Number ? new $util.LongBits(message.Id.low >>> 0, message.Id.high >>> 0).toNumber() : message.Id;
                if (message.IsBot != null && message.hasOwnProperty("IsBot"))
                    object.IsBot = message.IsBot;
                if (message.FirstName != null && message.hasOwnProperty("FirstName"))
                    object.FirstName = message.FirstName;
                if (message.LastName != null && message.hasOwnProperty("LastName"))
                    object.LastName = message.LastName;
                if (message.Username != null && message.hasOwnProperty("Username"))
                    object.Username = message.Username;
                if (message.LanguageCode != null && message.hasOwnProperty("LanguageCode"))
                    object.LanguageCode = message.LanguageCode;
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium"))
                    object.IsPremium = message.IsPremium;
                if (message.AddedToAttachmentMenu != null && message.hasOwnProperty("AddedToAttachmentMenu"))
                    object.AddedToAttachmentMenu = message.AddedToAttachmentMenu;
                if (message.AllowsWriteToPm != null && message.hasOwnProperty("AllowsWriteToPm"))
                    object.AllowsWriteToPm = message.AllowsWriteToPm;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.Nation != null && message.hasOwnProperty("Nation"))
                    object.Nation = message.Nation;
                if (message.RecommandNation != null && message.hasOwnProperty("RecommandNation"))
                    object.RecommandNation = message.RecommandNation;
                if (message.Language != null && message.hasOwnProperty("Language"))
                    object.Language = message.Language;
                return object;
            };

            /**
             * Converts this UserData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.UserData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.UserData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.UserData";
            };

            return UserData;
        })();

        Protocols.FriendData = (function() {

            /**
             * Properties of a FriendData.
             * @memberof Rugbull.Protocols
             * @interface IFriendData
             * @property {number|null} [UserId] FriendData UserId
             * @property {string|null} [UserName] FriendData UserName
             * @property {string|null} [Coins] FriendData Coins
             * @property {string|null} [Avatar] FriendData Avatar
             * @property {boolean|null} [IsPremium] FriendData IsPremium
             */

            /**
             * Constructs a new FriendData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a FriendData.
             * @implements IFriendData
             * @constructor
             * @param {Rugbull.Protocols.IFriendData=} [properties] Properties to set
             */
            function FriendData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FriendData UserId.
             * @member {number} UserId
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            FriendData.prototype.UserId = 0;

            /**
             * FriendData UserName.
             * @member {string} UserName
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            FriendData.prototype.UserName = "";

            /**
             * FriendData Coins.
             * @member {string} Coins
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            FriendData.prototype.Coins = "";

            /**
             * FriendData Avatar.
             * @member {string} Avatar
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            FriendData.prototype.Avatar = "";

            /**
             * FriendData IsPremium.
             * @member {boolean|null|undefined} IsPremium
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            FriendData.prototype.IsPremium = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * FriendData _IsPremium.
             * @member {"IsPremium"|undefined} _IsPremium
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             */
            Object.defineProperty(FriendData.prototype, "_IsPremium", {
                get: $util.oneOfGetter($oneOfFields = ["IsPremium"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new FriendData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Rugbull.Protocols.IFriendData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.FriendData} FriendData instance
             */
            FriendData.create = function create(properties) {
                return new FriendData(properties);
            };

            /**
             * Encodes the specified FriendData message. Does not implicitly {@link Rugbull.Protocols.FriendData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Rugbull.Protocols.IFriendData} message FriendData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FriendData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserId != null && Object.hasOwnProperty.call(message, "UserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.UserId);
                if (message.UserName != null && Object.hasOwnProperty.call(message, "UserName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.UserName);
                if (message.Coins != null && Object.hasOwnProperty.call(message, "Coins"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Coins);
                if (message.Avatar != null && Object.hasOwnProperty.call(message, "Avatar"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Avatar);
                if (message.IsPremium != null && Object.hasOwnProperty.call(message, "IsPremium"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.IsPremium);
                return writer;
            };

            /**
             * Encodes the specified FriendData message, length delimited. Does not implicitly {@link Rugbull.Protocols.FriendData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Rugbull.Protocols.IFriendData} message FriendData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FriendData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FriendData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.FriendData} FriendData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FriendData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.FriendData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.UserName = reader.string();
                            break;
                        }
                    case 3: {
                            message.Coins = reader.string();
                            break;
                        }
                    case 4: {
                            message.Avatar = reader.string();
                            break;
                        }
                    case 5: {
                            message.IsPremium = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FriendData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.FriendData} FriendData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FriendData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FriendData message.
             * @function verify
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FriendData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    if (!$util.isInteger(message.UserId))
                        return "UserId: integer expected";
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    if (!$util.isString(message.UserName))
                        return "UserName: string expected";
                if (message.Coins != null && message.hasOwnProperty("Coins"))
                    if (!$util.isString(message.Coins))
                        return "Coins: string expected";
                if (message.Avatar != null && message.hasOwnProperty("Avatar"))
                    if (!$util.isString(message.Avatar))
                        return "Avatar: string expected";
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium")) {
                    properties._IsPremium = 1;
                    if (typeof message.IsPremium !== "boolean")
                        return "IsPremium: boolean expected";
                }
                return null;
            };

            /**
             * Creates a FriendData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.FriendData} FriendData
             */
            FriendData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.FriendData)
                    return object;
                let message = new $root.Rugbull.Protocols.FriendData();
                if (object.UserId != null)
                    message.UserId = object.UserId | 0;
                if (object.UserName != null)
                    message.UserName = String(object.UserName);
                if (object.Coins != null)
                    message.Coins = String(object.Coins);
                if (object.Avatar != null)
                    message.Avatar = String(object.Avatar);
                if (object.IsPremium != null)
                    message.IsPremium = Boolean(object.IsPremium);
                return message;
            };

            /**
             * Creates a plain object from a FriendData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {Rugbull.Protocols.FriendData} message FriendData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FriendData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.UserId = 0;
                    object.UserName = "";
                    object.Coins = "";
                    object.Avatar = "";
                }
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    object.UserId = message.UserId;
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    object.UserName = message.UserName;
                if (message.Coins != null && message.hasOwnProperty("Coins"))
                    object.Coins = message.Coins;
                if (message.Avatar != null && message.hasOwnProperty("Avatar"))
                    object.Avatar = message.Avatar;
                if (message.IsPremium != null && message.hasOwnProperty("IsPremium")) {
                    object.IsPremium = message.IsPremium;
                    if (options.oneofs)
                        object._IsPremium = "IsPremium";
                }
                return object;
            };

            /**
             * Converts this FriendData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.FriendData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FriendData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FriendData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.FriendData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FriendData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.FriendData";
            };

            return FriendData;
        })();

        Protocols.FriendRewardData = (function() {

            /**
             * Properties of a FriendRewardData.
             * @memberof Rugbull.Protocols
             * @interface IFriendRewardData
             * @property {string|null} [UserName] FriendRewardData UserName
             * @property {string|null} [Coins] FriendRewardData Coins
             * @property {number|null} [UserId] FriendRewardData UserId
             */

            /**
             * Constructs a new FriendRewardData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a FriendRewardData.
             * @implements IFriendRewardData
             * @constructor
             * @param {Rugbull.Protocols.IFriendRewardData=} [properties] Properties to set
             */
            function FriendRewardData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FriendRewardData UserName.
             * @member {string} UserName
             * @memberof Rugbull.Protocols.FriendRewardData
             * @instance
             */
            FriendRewardData.prototype.UserName = "";

            /**
             * FriendRewardData Coins.
             * @member {string} Coins
             * @memberof Rugbull.Protocols.FriendRewardData
             * @instance
             */
            FriendRewardData.prototype.Coins = "";

            /**
             * FriendRewardData UserId.
             * @member {number} UserId
             * @memberof Rugbull.Protocols.FriendRewardData
             * @instance
             */
            FriendRewardData.prototype.UserId = 0;

            /**
             * Creates a new FriendRewardData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Rugbull.Protocols.IFriendRewardData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.FriendRewardData} FriendRewardData instance
             */
            FriendRewardData.create = function create(properties) {
                return new FriendRewardData(properties);
            };

            /**
             * Encodes the specified FriendRewardData message. Does not implicitly {@link Rugbull.Protocols.FriendRewardData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Rugbull.Protocols.IFriendRewardData} message FriendRewardData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FriendRewardData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserName != null && Object.hasOwnProperty.call(message, "UserName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserName);
                if (message.Coins != null && Object.hasOwnProperty.call(message, "Coins"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Coins);
                if (message.UserId != null && Object.hasOwnProperty.call(message, "UserId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.UserId);
                return writer;
            };

            /**
             * Encodes the specified FriendRewardData message, length delimited. Does not implicitly {@link Rugbull.Protocols.FriendRewardData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Rugbull.Protocols.IFriendRewardData} message FriendRewardData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FriendRewardData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FriendRewardData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.FriendRewardData} FriendRewardData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FriendRewardData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.FriendRewardData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserName = reader.string();
                            break;
                        }
                    case 2: {
                            message.Coins = reader.string();
                            break;
                        }
                    case 3: {
                            message.UserId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FriendRewardData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.FriendRewardData} FriendRewardData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FriendRewardData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FriendRewardData message.
             * @function verify
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FriendRewardData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    if (!$util.isString(message.UserName))
                        return "UserName: string expected";
                if (message.Coins != null && message.hasOwnProperty("Coins"))
                    if (!$util.isString(message.Coins))
                        return "Coins: string expected";
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    if (!$util.isInteger(message.UserId))
                        return "UserId: integer expected";
                return null;
            };

            /**
             * Creates a FriendRewardData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.FriendRewardData} FriendRewardData
             */
            FriendRewardData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.FriendRewardData)
                    return object;
                let message = new $root.Rugbull.Protocols.FriendRewardData();
                if (object.UserName != null)
                    message.UserName = String(object.UserName);
                if (object.Coins != null)
                    message.Coins = String(object.Coins);
                if (object.UserId != null)
                    message.UserId = object.UserId | 0;
                return message;
            };

            /**
             * Creates a plain object from a FriendRewardData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {Rugbull.Protocols.FriendRewardData} message FriendRewardData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FriendRewardData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.UserName = "";
                    object.Coins = "";
                    object.UserId = 0;
                }
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    object.UserName = message.UserName;
                if (message.Coins != null && message.hasOwnProperty("Coins"))
                    object.Coins = message.Coins;
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    object.UserId = message.UserId;
                return object;
            };

            /**
             * Converts this FriendRewardData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.FriendRewardData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FriendRewardData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FriendRewardData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.FriendRewardData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FriendRewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.FriendRewardData";
            };

            return FriendRewardData;
        })();

        Protocols.OrderData = (function() {

            /**
             * Properties of an OrderData.
             * @memberof Rugbull.Protocols
             * @interface IOrderData
             * @property {number|null} [GoodId] OrderData GoodId
             * @property {Rugbull.Protocols.ProductType|null} [GoodType] OrderData GoodType
             * @property {number|null} [Amount] OrderData Amount
             */

            /**
             * Constructs a new OrderData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents an OrderData.
             * @implements IOrderData
             * @constructor
             * @param {Rugbull.Protocols.IOrderData=} [properties] Properties to set
             */
            function OrderData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderData GoodId.
             * @member {number} GoodId
             * @memberof Rugbull.Protocols.OrderData
             * @instance
             */
            OrderData.prototype.GoodId = 0;

            /**
             * OrderData GoodType.
             * @member {Rugbull.Protocols.ProductType} GoodType
             * @memberof Rugbull.Protocols.OrderData
             * @instance
             */
            OrderData.prototype.GoodType = 0;

            /**
             * OrderData Amount.
             * @member {number} Amount
             * @memberof Rugbull.Protocols.OrderData
             * @instance
             */
            OrderData.prototype.Amount = 0;

            /**
             * Creates a new OrderData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Rugbull.Protocols.IOrderData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.OrderData} OrderData instance
             */
            OrderData.create = function create(properties) {
                return new OrderData(properties);
            };

            /**
             * Encodes the specified OrderData message. Does not implicitly {@link Rugbull.Protocols.OrderData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Rugbull.Protocols.IOrderData} message OrderData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.GoodId != null && Object.hasOwnProperty.call(message, "GoodId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.GoodId);
                if (message.GoodType != null && Object.hasOwnProperty.call(message, "GoodType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.GoodType);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Amount);
                return writer;
            };

            /**
             * Encodes the specified OrderData message, length delimited. Does not implicitly {@link Rugbull.Protocols.OrderData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Rugbull.Protocols.IOrderData} message OrderData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.OrderData} OrderData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.OrderData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.GoodId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.GoodType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Amount = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.OrderData} OrderData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderData message.
             * @function verify
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.GoodId != null && message.hasOwnProperty("GoodId"))
                    if (!$util.isInteger(message.GoodId))
                        return "GoodId: integer expected";
                if (message.GoodType != null && message.hasOwnProperty("GoodType"))
                    switch (message.GoodType) {
                    default:
                        return "GoodType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isInteger(message.Amount))
                        return "Amount: integer expected";
                return null;
            };

            /**
             * Creates an OrderData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.OrderData} OrderData
             */
            OrderData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.OrderData)
                    return object;
                let message = new $root.Rugbull.Protocols.OrderData();
                if (object.GoodId != null)
                    message.GoodId = object.GoodId | 0;
                switch (object.GoodType) {
                default:
                    if (typeof object.GoodType === "number") {
                        message.GoodType = object.GoodType;
                        break;
                    }
                    break;
                case "P_Normal":
                case 0:
                    message.GoodType = 0;
                    break;
                case "P_TimeLimited":
                case 1:
                    message.GoodType = 1;
                    break;
                }
                if (object.Amount != null)
                    message.Amount = object.Amount | 0;
                return message;
            };

            /**
             * Creates a plain object from an OrderData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {Rugbull.Protocols.OrderData} message OrderData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.GoodId = 0;
                    object.GoodType = options.enums === String ? "P_Normal" : 0;
                    object.Amount = 0;
                }
                if (message.GoodId != null && message.hasOwnProperty("GoodId"))
                    object.GoodId = message.GoodId;
                if (message.GoodType != null && message.hasOwnProperty("GoodType"))
                    object.GoodType = options.enums === String ? $root.Rugbull.Protocols.ProductType[message.GoodType] === undefined ? message.GoodType : $root.Rugbull.Protocols.ProductType[message.GoodType] : message.GoodType;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                return object;
            };

            /**
             * Converts this OrderData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.OrderData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.OrderData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.OrderData";
            };

            return OrderData;
        })();

        Protocols.ProductTime = (function() {

            /**
             * Properties of a ProductTime.
             * @memberof Rugbull.Protocols
             * @interface IProductTime
             * @property {number|null} [Id] ProductTime Id
             * @property {number|null} [GroupId] ProductTime GroupId
             * @property {string|null} [LastRefresh] ProductTime LastRefresh
             */

            /**
             * Constructs a new ProductTime.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a ProductTime.
             * @implements IProductTime
             * @constructor
             * @param {Rugbull.Protocols.IProductTime=} [properties] Properties to set
             */
            function ProductTime(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProductTime Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.ProductTime
             * @instance
             */
            ProductTime.prototype.Id = 0;

            /**
             * ProductTime GroupId.
             * @member {number} GroupId
             * @memberof Rugbull.Protocols.ProductTime
             * @instance
             */
            ProductTime.prototype.GroupId = 0;

            /**
             * ProductTime LastRefresh.
             * @member {string} LastRefresh
             * @memberof Rugbull.Protocols.ProductTime
             * @instance
             */
            ProductTime.prototype.LastRefresh = "";

            /**
             * Creates a new ProductTime instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Rugbull.Protocols.IProductTime=} [properties] Properties to set
             * @returns {Rugbull.Protocols.ProductTime} ProductTime instance
             */
            ProductTime.create = function create(properties) {
                return new ProductTime(properties);
            };

            /**
             * Encodes the specified ProductTime message. Does not implicitly {@link Rugbull.Protocols.ProductTime.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Rugbull.Protocols.IProductTime} message ProductTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProductTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.GroupId != null && Object.hasOwnProperty.call(message, "GroupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.GroupId);
                if (message.LastRefresh != null && Object.hasOwnProperty.call(message, "LastRefresh"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.LastRefresh);
                return writer;
            };

            /**
             * Encodes the specified ProductTime message, length delimited. Does not implicitly {@link Rugbull.Protocols.ProductTime.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Rugbull.Protocols.IProductTime} message ProductTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProductTime.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ProductTime message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.ProductTime} ProductTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProductTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.ProductTime();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.GroupId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.LastRefresh = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ProductTime message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.ProductTime} ProductTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProductTime.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ProductTime message.
             * @function verify
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProductTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.GroupId != null && message.hasOwnProperty("GroupId"))
                    if (!$util.isInteger(message.GroupId))
                        return "GroupId: integer expected";
                if (message.LastRefresh != null && message.hasOwnProperty("LastRefresh"))
                    if (!$util.isString(message.LastRefresh))
                        return "LastRefresh: string expected";
                return null;
            };

            /**
             * Creates a ProductTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.ProductTime} ProductTime
             */
            ProductTime.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.ProductTime)
                    return object;
                let message = new $root.Rugbull.Protocols.ProductTime();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.GroupId != null)
                    message.GroupId = object.GroupId | 0;
                if (object.LastRefresh != null)
                    message.LastRefresh = String(object.LastRefresh);
                return message;
            };

            /**
             * Creates a plain object from a ProductTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {Rugbull.Protocols.ProductTime} message ProductTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProductTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.GroupId = 0;
                    object.LastRefresh = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.GroupId != null && message.hasOwnProperty("GroupId"))
                    object.GroupId = message.GroupId;
                if (message.LastRefresh != null && message.hasOwnProperty("LastRefresh"))
                    object.LastRefresh = message.LastRefresh;
                return object;
            };

            /**
             * Converts this ProductTime to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.ProductTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProductTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ProductTime
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.ProductTime
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProductTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.ProductTime";
            };

            return ProductTime;
        })();

        Protocols.WalletProductTime = (function() {

            /**
             * Properties of a WalletProductTime.
             * @memberof Rugbull.Protocols
             * @interface IWalletProductTime
             * @property {number|null} [Id] WalletProductTime Id
             * @property {number|null} [GroupId] WalletProductTime GroupId
             * @property {string|null} [LastRefresh] WalletProductTime LastRefresh
             */

            /**
             * Constructs a new WalletProductTime.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a WalletProductTime.
             * @implements IWalletProductTime
             * @constructor
             * @param {Rugbull.Protocols.IWalletProductTime=} [properties] Properties to set
             */
            function WalletProductTime(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WalletProductTime Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.WalletProductTime
             * @instance
             */
            WalletProductTime.prototype.Id = 0;

            /**
             * WalletProductTime GroupId.
             * @member {number} GroupId
             * @memberof Rugbull.Protocols.WalletProductTime
             * @instance
             */
            WalletProductTime.prototype.GroupId = 0;

            /**
             * WalletProductTime LastRefresh.
             * @member {string} LastRefresh
             * @memberof Rugbull.Protocols.WalletProductTime
             * @instance
             */
            WalletProductTime.prototype.LastRefresh = "";

            /**
             * Creates a new WalletProductTime instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Rugbull.Protocols.IWalletProductTime=} [properties] Properties to set
             * @returns {Rugbull.Protocols.WalletProductTime} WalletProductTime instance
             */
            WalletProductTime.create = function create(properties) {
                return new WalletProductTime(properties);
            };

            /**
             * Encodes the specified WalletProductTime message. Does not implicitly {@link Rugbull.Protocols.WalletProductTime.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Rugbull.Protocols.IWalletProductTime} message WalletProductTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WalletProductTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.GroupId != null && Object.hasOwnProperty.call(message, "GroupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.GroupId);
                if (message.LastRefresh != null && Object.hasOwnProperty.call(message, "LastRefresh"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.LastRefresh);
                return writer;
            };

            /**
             * Encodes the specified WalletProductTime message, length delimited. Does not implicitly {@link Rugbull.Protocols.WalletProductTime.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Rugbull.Protocols.IWalletProductTime} message WalletProductTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WalletProductTime.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WalletProductTime message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.WalletProductTime} WalletProductTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WalletProductTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.WalletProductTime();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.GroupId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.LastRefresh = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WalletProductTime message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.WalletProductTime} WalletProductTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WalletProductTime.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WalletProductTime message.
             * @function verify
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WalletProductTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.GroupId != null && message.hasOwnProperty("GroupId"))
                    if (!$util.isInteger(message.GroupId))
                        return "GroupId: integer expected";
                if (message.LastRefresh != null && message.hasOwnProperty("LastRefresh"))
                    if (!$util.isString(message.LastRefresh))
                        return "LastRefresh: string expected";
                return null;
            };

            /**
             * Creates a WalletProductTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.WalletProductTime} WalletProductTime
             */
            WalletProductTime.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.WalletProductTime)
                    return object;
                let message = new $root.Rugbull.Protocols.WalletProductTime();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.GroupId != null)
                    message.GroupId = object.GroupId | 0;
                if (object.LastRefresh != null)
                    message.LastRefresh = String(object.LastRefresh);
                return message;
            };

            /**
             * Creates a plain object from a WalletProductTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {Rugbull.Protocols.WalletProductTime} message WalletProductTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WalletProductTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.GroupId = 0;
                    object.LastRefresh = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.GroupId != null && message.hasOwnProperty("GroupId"))
                    object.GroupId = message.GroupId;
                if (message.LastRefresh != null && message.hasOwnProperty("LastRefresh"))
                    object.LastRefresh = message.LastRefresh;
                return object;
            };

            /**
             * Converts this WalletProductTime to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.WalletProductTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WalletProductTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WalletProductTime
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.WalletProductTime
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WalletProductTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.WalletProductTime";
            };

            return WalletProductTime;
        })();

        Protocols.TaskData = (function() {

            /**
             * Properties of a TaskData.
             * @memberof Rugbull.Protocols
             * @interface ITaskData
             * @property {number|null} [Id] TaskData Id
             * @property {Rugbull.Protocols.TaskType|null} [TaskType] TaskData TaskType
             * @property {number|null} [FinishCount] TaskData FinishCount
             * @property {boolean|null} [IsClaimed] TaskData IsClaimed
             * @property {boolean|null} [IsDoning] TaskData IsDoning
             * @property {number|null} [Count] TaskData Count
             * @property {string|null} [Title] TaskData Title
             * @property {string|null} [Reward] TaskData Reward
             */

            /**
             * Constructs a new TaskData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a TaskData.
             * @implements ITaskData
             * @constructor
             * @param {Rugbull.Protocols.ITaskData=} [properties] Properties to set
             */
            function TaskData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TaskData Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.Id = 0;

            /**
             * TaskData TaskType.
             * @member {Rugbull.Protocols.TaskType} TaskType
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.TaskType = 0;

            /**
             * TaskData FinishCount.
             * @member {number} FinishCount
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.FinishCount = 0;

            /**
             * TaskData IsClaimed.
             * @member {boolean} IsClaimed
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.IsClaimed = false;

            /**
             * TaskData IsDoning.
             * @member {boolean} IsDoning
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.IsDoning = false;

            /**
             * TaskData Count.
             * @member {number} Count
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.Count = 0;

            /**
             * TaskData Title.
             * @member {string} Title
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.Title = "";

            /**
             * TaskData Reward.
             * @member {string} Reward
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             */
            TaskData.prototype.Reward = "";

            /**
             * Creates a new TaskData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Rugbull.Protocols.ITaskData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.TaskData} TaskData instance
             */
            TaskData.create = function create(properties) {
                return new TaskData(properties);
            };

            /**
             * Encodes the specified TaskData message. Does not implicitly {@link Rugbull.Protocols.TaskData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Rugbull.Protocols.ITaskData} message TaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.TaskType != null && Object.hasOwnProperty.call(message, "TaskType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.TaskType);
                if (message.FinishCount != null && Object.hasOwnProperty.call(message, "FinishCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.FinishCount);
                if (message.IsClaimed != null && Object.hasOwnProperty.call(message, "IsClaimed"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsClaimed);
                if (message.IsDoning != null && Object.hasOwnProperty.call(message, "IsDoning"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.IsDoning);
                if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.Count);
                if (message.Title != null && Object.hasOwnProperty.call(message, "Title"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.Title);
                if (message.Reward != null && Object.hasOwnProperty.call(message, "Reward"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.Reward);
                return writer;
            };

            /**
             * Encodes the specified TaskData message, length delimited. Does not implicitly {@link Rugbull.Protocols.TaskData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Rugbull.Protocols.ITaskData} message TaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TaskData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.TaskData} TaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.TaskData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.TaskType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.FinishCount = reader.int32();
                            break;
                        }
                    case 4: {
                            message.IsClaimed = reader.bool();
                            break;
                        }
                    case 5: {
                            message.IsDoning = reader.bool();
                            break;
                        }
                    case 6: {
                            message.Count = reader.int32();
                            break;
                        }
                    case 7: {
                            message.Title = reader.string();
                            break;
                        }
                    case 8: {
                            message.Reward = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TaskData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.TaskData} TaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TaskData message.
             * @function verify
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TaskData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.TaskType != null && message.hasOwnProperty("TaskType"))
                    switch (message.TaskType) {
                    default:
                        return "TaskType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    if (!$util.isInteger(message.FinishCount))
                        return "FinishCount: integer expected";
                if (message.IsClaimed != null && message.hasOwnProperty("IsClaimed"))
                    if (typeof message.IsClaimed !== "boolean")
                        return "IsClaimed: boolean expected";
                if (message.IsDoning != null && message.hasOwnProperty("IsDoning"))
                    if (typeof message.IsDoning !== "boolean")
                        return "IsDoning: boolean expected";
                if (message.Count != null && message.hasOwnProperty("Count"))
                    if (!$util.isInteger(message.Count))
                        return "Count: integer expected";
                if (message.Title != null && message.hasOwnProperty("Title"))
                    if (!$util.isString(message.Title))
                        return "Title: string expected";
                if (message.Reward != null && message.hasOwnProperty("Reward"))
                    if (!$util.isString(message.Reward))
                        return "Reward: string expected";
                return null;
            };

            /**
             * Creates a TaskData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.TaskData} TaskData
             */
            TaskData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.TaskData)
                    return object;
                let message = new $root.Rugbull.Protocols.TaskData();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                switch (object.TaskType) {
                default:
                    if (typeof object.TaskType === "number") {
                        message.TaskType = object.TaskType;
                        break;
                    }
                    break;
                case "TASK_TYPE_NONE":
                case 0:
                    message.TaskType = 0;
                    break;
                case "TASK_TYPE_DAILY":
                case 1:
                    message.TaskType = 1;
                    break;
                case "TASK_TYPE_ACHIEVEMENT":
                case 2:
                    message.TaskType = 2;
                    break;
                case "TASK_TYPE_ADVERTISEMENT":
                case 3:
                    message.TaskType = 3;
                    break;
                }
                if (object.FinishCount != null)
                    message.FinishCount = object.FinishCount | 0;
                if (object.IsClaimed != null)
                    message.IsClaimed = Boolean(object.IsClaimed);
                if (object.IsDoning != null)
                    message.IsDoning = Boolean(object.IsDoning);
                if (object.Count != null)
                    message.Count = object.Count | 0;
                if (object.Title != null)
                    message.Title = String(object.Title);
                if (object.Reward != null)
                    message.Reward = String(object.Reward);
                return message;
            };

            /**
             * Creates a plain object from a TaskData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {Rugbull.Protocols.TaskData} message TaskData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TaskData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.TaskType = options.enums === String ? "TASK_TYPE_NONE" : 0;
                    object.FinishCount = 0;
                    object.IsClaimed = false;
                    object.IsDoning = false;
                    object.Count = 0;
                    object.Title = "";
                    object.Reward = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.TaskType != null && message.hasOwnProperty("TaskType"))
                    object.TaskType = options.enums === String ? $root.Rugbull.Protocols.TaskType[message.TaskType] === undefined ? message.TaskType : $root.Rugbull.Protocols.TaskType[message.TaskType] : message.TaskType;
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    object.FinishCount = message.FinishCount;
                if (message.IsClaimed != null && message.hasOwnProperty("IsClaimed"))
                    object.IsClaimed = message.IsClaimed;
                if (message.IsDoning != null && message.hasOwnProperty("IsDoning"))
                    object.IsDoning = message.IsDoning;
                if (message.Count != null && message.hasOwnProperty("Count"))
                    object.Count = message.Count;
                if (message.Title != null && message.hasOwnProperty("Title"))
                    object.Title = message.Title;
                if (message.Reward != null && message.hasOwnProperty("Reward"))
                    object.Reward = message.Reward;
                return object;
            };

            /**
             * Converts this TaskData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.TaskData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TaskData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TaskData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.TaskData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TaskData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.TaskData";
            };

            return TaskData;
        })();

        Protocols.FishData = (function() {

            /**
             * Properties of a FishData.
             * @memberof Rugbull.Protocols
             * @interface IFishData
             * @property {number|null} [Id] FishData Id
             * @property {number|null} [TankId] FishData TankId
             * @property {number|null} [Level] FishData Level
             * @property {boolean|null} [IsLocked] FishData IsLocked
             * @property {number|null} [VariationType] FishData VariationType
             * @property {string|null} [Ulid] FishData Ulid
             * @property {number|null} [VariateId] FishData VariateId
             * @property {number|null} [VariateIndex] FishData VariateIndex
             */

            /**
             * Constructs a new FishData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a FishData.
             * @implements IFishData
             * @constructor
             * @param {Rugbull.Protocols.IFishData=} [properties] Properties to set
             */
            function FishData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FishData Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.Id = 0;

            /**
             * FishData TankId.
             * @member {number} TankId
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.TankId = 0;

            /**
             * FishData Level.
             * @member {number} Level
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.Level = 0;

            /**
             * FishData IsLocked.
             * @member {boolean} IsLocked
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.IsLocked = false;

            /**
             * FishData VariationType.
             * @member {number} VariationType
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.VariationType = 0;

            /**
             * FishData Ulid.
             * @member {string} Ulid
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.Ulid = "";

            /**
             * FishData VariateId.
             * @member {number} VariateId
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.VariateId = 0;

            /**
             * FishData VariateIndex.
             * @member {number} VariateIndex
             * @memberof Rugbull.Protocols.FishData
             * @instance
             */
            FishData.prototype.VariateIndex = 0;

            /**
             * Creates a new FishData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Rugbull.Protocols.IFishData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.FishData} FishData instance
             */
            FishData.create = function create(properties) {
                return new FishData(properties);
            };

            /**
             * Encodes the specified FishData message. Does not implicitly {@link Rugbull.Protocols.FishData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Rugbull.Protocols.IFishData} message FishData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FishData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.TankId != null && Object.hasOwnProperty.call(message, "TankId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.TankId);
                if (message.Level != null && Object.hasOwnProperty.call(message, "Level"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Level);
                if (message.IsLocked != null && Object.hasOwnProperty.call(message, "IsLocked"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsLocked);
                if (message.VariationType != null && Object.hasOwnProperty.call(message, "VariationType"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.VariationType);
                if (message.Ulid != null && Object.hasOwnProperty.call(message, "Ulid"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Ulid);
                if (message.VariateId != null && Object.hasOwnProperty.call(message, "VariateId"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.VariateId);
                if (message.VariateIndex != null && Object.hasOwnProperty.call(message, "VariateIndex"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.VariateIndex);
                return writer;
            };

            /**
             * Encodes the specified FishData message, length delimited. Does not implicitly {@link Rugbull.Protocols.FishData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Rugbull.Protocols.IFishData} message FishData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FishData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FishData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.FishData} FishData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FishData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.FishData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.TankId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Level = reader.int32();
                            break;
                        }
                    case 4: {
                            message.IsLocked = reader.bool();
                            break;
                        }
                    case 5: {
                            message.VariationType = reader.int32();
                            break;
                        }
                    case 6: {
                            message.Ulid = reader.string();
                            break;
                        }
                    case 7: {
                            message.VariateId = reader.int32();
                            break;
                        }
                    case 8: {
                            message.VariateIndex = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FishData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.FishData} FishData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FishData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FishData message.
             * @function verify
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FishData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.TankId != null && message.hasOwnProperty("TankId"))
                    if (!$util.isInteger(message.TankId))
                        return "TankId: integer expected";
                if (message.Level != null && message.hasOwnProperty("Level"))
                    if (!$util.isInteger(message.Level))
                        return "Level: integer expected";
                if (message.IsLocked != null && message.hasOwnProperty("IsLocked"))
                    if (typeof message.IsLocked !== "boolean")
                        return "IsLocked: boolean expected";
                if (message.VariationType != null && message.hasOwnProperty("VariationType"))
                    if (!$util.isInteger(message.VariationType))
                        return "VariationType: integer expected";
                if (message.Ulid != null && message.hasOwnProperty("Ulid"))
                    if (!$util.isString(message.Ulid))
                        return "Ulid: string expected";
                if (message.VariateId != null && message.hasOwnProperty("VariateId"))
                    if (!$util.isInteger(message.VariateId))
                        return "VariateId: integer expected";
                if (message.VariateIndex != null && message.hasOwnProperty("VariateIndex"))
                    if (!$util.isInteger(message.VariateIndex))
                        return "VariateIndex: integer expected";
                return null;
            };

            /**
             * Creates a FishData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.FishData} FishData
             */
            FishData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.FishData)
                    return object;
                let message = new $root.Rugbull.Protocols.FishData();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.TankId != null)
                    message.TankId = object.TankId | 0;
                if (object.Level != null)
                    message.Level = object.Level | 0;
                if (object.IsLocked != null)
                    message.IsLocked = Boolean(object.IsLocked);
                if (object.VariationType != null)
                    message.VariationType = object.VariationType | 0;
                if (object.Ulid != null)
                    message.Ulid = String(object.Ulid);
                if (object.VariateId != null)
                    message.VariateId = object.VariateId | 0;
                if (object.VariateIndex != null)
                    message.VariateIndex = object.VariateIndex | 0;
                return message;
            };

            /**
             * Creates a plain object from a FishData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {Rugbull.Protocols.FishData} message FishData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FishData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.TankId = 0;
                    object.Level = 0;
                    object.IsLocked = false;
                    object.VariationType = 0;
                    object.Ulid = "";
                    object.VariateId = 0;
                    object.VariateIndex = 0;
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.TankId != null && message.hasOwnProperty("TankId"))
                    object.TankId = message.TankId;
                if (message.Level != null && message.hasOwnProperty("Level"))
                    object.Level = message.Level;
                if (message.IsLocked != null && message.hasOwnProperty("IsLocked"))
                    object.IsLocked = message.IsLocked;
                if (message.VariationType != null && message.hasOwnProperty("VariationType"))
                    object.VariationType = message.VariationType;
                if (message.Ulid != null && message.hasOwnProperty("Ulid"))
                    object.Ulid = message.Ulid;
                if (message.VariateId != null && message.hasOwnProperty("VariateId"))
                    object.VariateId = message.VariateId;
                if (message.VariateIndex != null && message.hasOwnProperty("VariateIndex"))
                    object.VariateIndex = message.VariateIndex;
                return object;
            };

            /**
             * Converts this FishData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.FishData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FishData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FishData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.FishData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FishData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.FishData";
            };

            return FishData;
        })();

        Protocols.RewardData = (function() {

            /**
             * Properties of a RewardData.
             * @memberof Rugbull.Protocols
             * @interface IRewardData
             * @property {number|null} [Point] RewardData Point
             * @property {Array.<number>|null} [RewardIds] RewardData RewardIds
             */

            /**
             * Constructs a new RewardData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a RewardData.
             * @implements IRewardData
             * @constructor
             * @param {Rugbull.Protocols.IRewardData=} [properties] Properties to set
             */
            function RewardData(properties) {
                this.RewardIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RewardData Point.
             * @member {number} Point
             * @memberof Rugbull.Protocols.RewardData
             * @instance
             */
            RewardData.prototype.Point = 0;

            /**
             * RewardData RewardIds.
             * @member {Array.<number>} RewardIds
             * @memberof Rugbull.Protocols.RewardData
             * @instance
             */
            RewardData.prototype.RewardIds = $util.emptyArray;

            /**
             * Creates a new RewardData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Rugbull.Protocols.IRewardData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.RewardData} RewardData instance
             */
            RewardData.create = function create(properties) {
                return new RewardData(properties);
            };

            /**
             * Encodes the specified RewardData message. Does not implicitly {@link Rugbull.Protocols.RewardData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Rugbull.Protocols.IRewardData} message RewardData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RewardData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Point != null && Object.hasOwnProperty.call(message, "Point"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Point);
                if (message.RewardIds != null && message.RewardIds.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.RewardIds.length; ++i)
                        writer.int32(message.RewardIds[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified RewardData message, length delimited. Does not implicitly {@link Rugbull.Protocols.RewardData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Rugbull.Protocols.IRewardData} message RewardData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RewardData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RewardData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.RewardData} RewardData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RewardData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.RewardData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Point = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.RewardIds && message.RewardIds.length))
                                message.RewardIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.RewardIds.push(reader.int32());
                            } else
                                message.RewardIds.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RewardData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.RewardData} RewardData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RewardData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RewardData message.
             * @function verify
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RewardData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Point != null && message.hasOwnProperty("Point"))
                    if (!$util.isInteger(message.Point))
                        return "Point: integer expected";
                if (message.RewardIds != null && message.hasOwnProperty("RewardIds")) {
                    if (!Array.isArray(message.RewardIds))
                        return "RewardIds: array expected";
                    for (let i = 0; i < message.RewardIds.length; ++i)
                        if (!$util.isInteger(message.RewardIds[i]))
                            return "RewardIds: integer[] expected";
                }
                return null;
            };

            /**
             * Creates a RewardData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.RewardData} RewardData
             */
            RewardData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.RewardData)
                    return object;
                let message = new $root.Rugbull.Protocols.RewardData();
                if (object.Point != null)
                    message.Point = object.Point | 0;
                if (object.RewardIds) {
                    if (!Array.isArray(object.RewardIds))
                        throw TypeError(".Rugbull.Protocols.RewardData.RewardIds: array expected");
                    message.RewardIds = [];
                    for (let i = 0; i < object.RewardIds.length; ++i)
                        message.RewardIds[i] = object.RewardIds[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from a RewardData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {Rugbull.Protocols.RewardData} message RewardData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RewardData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.RewardIds = [];
                if (options.defaults)
                    object.Point = 0;
                if (message.Point != null && message.hasOwnProperty("Point"))
                    object.Point = message.Point;
                if (message.RewardIds && message.RewardIds.length) {
                    object.RewardIds = [];
                    for (let j = 0; j < message.RewardIds.length; ++j)
                        object.RewardIds[j] = message.RewardIds[j];
                }
                return object;
            };

            /**
             * Converts this RewardData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.RewardData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RewardData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RewardData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.RewardData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.RewardData";
            };

            return RewardData;
        })();

        Protocols.AdTaskData = (function() {

            /**
             * Properties of an AdTaskData.
             * @memberof Rugbull.Protocols
             * @interface IAdTaskData
             * @property {number|null} [Id] AdTaskData Id
             * @property {Rugbull.Protocols.ArchieveType|null} [Type] AdTaskData Type
             * @property {number|null} [Group] AdTaskData Group
             * @property {number|null} [GroupOrder] AdTaskData GroupOrder
             * @property {number|null} [Order] AdTaskData Order
             * @property {string|null} [Title] AdTaskData Title
             * @property {string|null} [Param] AdTaskData Param
             * @property {string|null} [Count] AdTaskData Count
             * @property {string|null} [RewardCoin] AdTaskData RewardCoin
             * @property {number|null} [RewardFood] AdTaskData RewardFood
             * @property {number|null} [RewardDiamon] AdTaskData RewardDiamon
             * @property {string|null} [RewardPackRand] AdTaskData RewardPackRand
             * @property {boolean|null} [Disable] AdTaskData Disable
             * @property {string|null} [GroupTitle] AdTaskData GroupTitle
             */

            /**
             * Constructs a new AdTaskData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents an AdTaskData.
             * @implements IAdTaskData
             * @constructor
             * @param {Rugbull.Protocols.IAdTaskData=} [properties] Properties to set
             */
            function AdTaskData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdTaskData Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Id = 0;

            /**
             * AdTaskData Type.
             * @member {Rugbull.Protocols.ArchieveType} Type
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Type = 0;

            /**
             * AdTaskData Group.
             * @member {number} Group
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Group = 0;

            /**
             * AdTaskData GroupOrder.
             * @member {number} GroupOrder
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.GroupOrder = 0;

            /**
             * AdTaskData Order.
             * @member {number} Order
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Order = 0;

            /**
             * AdTaskData Title.
             * @member {string} Title
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Title = "";

            /**
             * AdTaskData Param.
             * @member {string} Param
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Param = "";

            /**
             * AdTaskData Count.
             * @member {string} Count
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Count = "";

            /**
             * AdTaskData RewardCoin.
             * @member {string} RewardCoin
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.RewardCoin = "";

            /**
             * AdTaskData RewardFood.
             * @member {number} RewardFood
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.RewardFood = 0;

            /**
             * AdTaskData RewardDiamon.
             * @member {number} RewardDiamon
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.RewardDiamon = 0;

            /**
             * AdTaskData RewardPackRand.
             * @member {string} RewardPackRand
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.RewardPackRand = "";

            /**
             * AdTaskData Disable.
             * @member {boolean} Disable
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.Disable = false;

            /**
             * AdTaskData GroupTitle.
             * @member {string} GroupTitle
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             */
            AdTaskData.prototype.GroupTitle = "";

            /**
             * Creates a new AdTaskData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Rugbull.Protocols.IAdTaskData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.AdTaskData} AdTaskData instance
             */
            AdTaskData.create = function create(properties) {
                return new AdTaskData(properties);
            };

            /**
             * Encodes the specified AdTaskData message. Does not implicitly {@link Rugbull.Protocols.AdTaskData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Rugbull.Protocols.IAdTaskData} message AdTaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdTaskData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Type);
                if (message.Group != null && Object.hasOwnProperty.call(message, "Group"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Group);
                if (message.GroupOrder != null && Object.hasOwnProperty.call(message, "GroupOrder"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.GroupOrder);
                if (message.Order != null && Object.hasOwnProperty.call(message, "Order"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.Order);
                if (message.Title != null && Object.hasOwnProperty.call(message, "Title"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Title);
                if (message.Param != null && Object.hasOwnProperty.call(message, "Param"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.Param);
                if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.Count);
                if (message.RewardCoin != null && Object.hasOwnProperty.call(message, "RewardCoin"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.RewardCoin);
                if (message.RewardFood != null && Object.hasOwnProperty.call(message, "RewardFood"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.RewardFood);
                if (message.RewardDiamon != null && Object.hasOwnProperty.call(message, "RewardDiamon"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.RewardDiamon);
                if (message.RewardPackRand != null && Object.hasOwnProperty.call(message, "RewardPackRand"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.RewardPackRand);
                if (message.Disable != null && Object.hasOwnProperty.call(message, "Disable"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.Disable);
                if (message.GroupTitle != null && Object.hasOwnProperty.call(message, "GroupTitle"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.GroupTitle);
                return writer;
            };

            /**
             * Encodes the specified AdTaskData message, length delimited. Does not implicitly {@link Rugbull.Protocols.AdTaskData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Rugbull.Protocols.IAdTaskData} message AdTaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdTaskData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AdTaskData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.AdTaskData} AdTaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdTaskData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.AdTaskData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Group = reader.int32();
                            break;
                        }
                    case 4: {
                            message.GroupOrder = reader.int32();
                            break;
                        }
                    case 5: {
                            message.Order = reader.int32();
                            break;
                        }
                    case 6: {
                            message.Title = reader.string();
                            break;
                        }
                    case 7: {
                            message.Param = reader.string();
                            break;
                        }
                    case 8: {
                            message.Count = reader.string();
                            break;
                        }
                    case 9: {
                            message.RewardCoin = reader.string();
                            break;
                        }
                    case 10: {
                            message.RewardFood = reader.int32();
                            break;
                        }
                    case 11: {
                            message.RewardDiamon = reader.int32();
                            break;
                        }
                    case 12: {
                            message.RewardPackRand = reader.string();
                            break;
                        }
                    case 13: {
                            message.Disable = reader.bool();
                            break;
                        }
                    case 14: {
                            message.GroupTitle = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AdTaskData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.AdTaskData} AdTaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdTaskData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AdTaskData message.
             * @function verify
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdTaskData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.Type != null && message.hasOwnProperty("Type"))
                    switch (message.Type) {
                    default:
                        return "Type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                        break;
                    }
                if (message.Group != null && message.hasOwnProperty("Group"))
                    if (!$util.isInteger(message.Group))
                        return "Group: integer expected";
                if (message.GroupOrder != null && message.hasOwnProperty("GroupOrder"))
                    if (!$util.isInteger(message.GroupOrder))
                        return "GroupOrder: integer expected";
                if (message.Order != null && message.hasOwnProperty("Order"))
                    if (!$util.isInteger(message.Order))
                        return "Order: integer expected";
                if (message.Title != null && message.hasOwnProperty("Title"))
                    if (!$util.isString(message.Title))
                        return "Title: string expected";
                if (message.Param != null && message.hasOwnProperty("Param"))
                    if (!$util.isString(message.Param))
                        return "Param: string expected";
                if (message.Count != null && message.hasOwnProperty("Count"))
                    if (!$util.isString(message.Count))
                        return "Count: string expected";
                if (message.RewardCoin != null && message.hasOwnProperty("RewardCoin"))
                    if (!$util.isString(message.RewardCoin))
                        return "RewardCoin: string expected";
                if (message.RewardFood != null && message.hasOwnProperty("RewardFood"))
                    if (!$util.isInteger(message.RewardFood))
                        return "RewardFood: integer expected";
                if (message.RewardDiamon != null && message.hasOwnProperty("RewardDiamon"))
                    if (!$util.isInteger(message.RewardDiamon))
                        return "RewardDiamon: integer expected";
                if (message.RewardPackRand != null && message.hasOwnProperty("RewardPackRand"))
                    if (!$util.isString(message.RewardPackRand))
                        return "RewardPackRand: string expected";
                if (message.Disable != null && message.hasOwnProperty("Disable"))
                    if (typeof message.Disable !== "boolean")
                        return "Disable: boolean expected";
                if (message.GroupTitle != null && message.hasOwnProperty("GroupTitle"))
                    if (!$util.isString(message.GroupTitle))
                        return "GroupTitle: string expected";
                return null;
            };

            /**
             * Creates an AdTaskData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.AdTaskData} AdTaskData
             */
            AdTaskData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.AdTaskData)
                    return object;
                let message = new $root.Rugbull.Protocols.AdTaskData();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                switch (object.Type) {
                default:
                    if (typeof object.Type === "number") {
                        message.Type = object.Type;
                        break;
                    }
                    break;
                case "TwitterFollow":
                case 0:
                    message.Type = 0;
                    break;
                case "TgChannelFollow":
                case 1:
                    message.Type = 1;
                    break;
                case "TgGroupFollow":
                case 2:
                    message.Type = 2;
                    break;
                case "InviteFriends":
                case 3:
                    message.Type = 3;
                    break;
                case "GetFishCount":
                case 4:
                    message.Type = 4;
                    break;
                case "GetCollectionCount":
                case 5:
                    message.Type = 5;
                    break;
                case "FeedCount":
                case 6:
                    message.Type = 6;
                    break;
                case "SellFishCount":
                case 7:
                    message.Type = 7;
                    break;
                case "SellFishCoins":
                case 8:
                    message.Type = 8;
                    break;
                case "ChatWIthBot":
                case 9:
                    message.Type = 9;
                    break;
                case "ConnectWallet":
                case 10:
                    message.Type = 10;
                    break;
                }
                if (object.Group != null)
                    message.Group = object.Group | 0;
                if (object.GroupOrder != null)
                    message.GroupOrder = object.GroupOrder | 0;
                if (object.Order != null)
                    message.Order = object.Order | 0;
                if (object.Title != null)
                    message.Title = String(object.Title);
                if (object.Param != null)
                    message.Param = String(object.Param);
                if (object.Count != null)
                    message.Count = String(object.Count);
                if (object.RewardCoin != null)
                    message.RewardCoin = String(object.RewardCoin);
                if (object.RewardFood != null)
                    message.RewardFood = object.RewardFood | 0;
                if (object.RewardDiamon != null)
                    message.RewardDiamon = object.RewardDiamon | 0;
                if (object.RewardPackRand != null)
                    message.RewardPackRand = String(object.RewardPackRand);
                if (object.Disable != null)
                    message.Disable = Boolean(object.Disable);
                if (object.GroupTitle != null)
                    message.GroupTitle = String(object.GroupTitle);
                return message;
            };

            /**
             * Creates a plain object from an AdTaskData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {Rugbull.Protocols.AdTaskData} message AdTaskData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdTaskData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.Type = options.enums === String ? "TwitterFollow" : 0;
                    object.Group = 0;
                    object.GroupOrder = 0;
                    object.Order = 0;
                    object.Title = "";
                    object.Param = "";
                    object.Count = "";
                    object.RewardCoin = "";
                    object.RewardFood = 0;
                    object.RewardDiamon = 0;
                    object.RewardPackRand = "";
                    object.Disable = false;
                    object.GroupTitle = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.Type != null && message.hasOwnProperty("Type"))
                    object.Type = options.enums === String ? $root.Rugbull.Protocols.ArchieveType[message.Type] === undefined ? message.Type : $root.Rugbull.Protocols.ArchieveType[message.Type] : message.Type;
                if (message.Group != null && message.hasOwnProperty("Group"))
                    object.Group = message.Group;
                if (message.GroupOrder != null && message.hasOwnProperty("GroupOrder"))
                    object.GroupOrder = message.GroupOrder;
                if (message.Order != null && message.hasOwnProperty("Order"))
                    object.Order = message.Order;
                if (message.Title != null && message.hasOwnProperty("Title"))
                    object.Title = message.Title;
                if (message.Param != null && message.hasOwnProperty("Param"))
                    object.Param = message.Param;
                if (message.Count != null && message.hasOwnProperty("Count"))
                    object.Count = message.Count;
                if (message.RewardCoin != null && message.hasOwnProperty("RewardCoin"))
                    object.RewardCoin = message.RewardCoin;
                if (message.RewardFood != null && message.hasOwnProperty("RewardFood"))
                    object.RewardFood = message.RewardFood;
                if (message.RewardDiamon != null && message.hasOwnProperty("RewardDiamon"))
                    object.RewardDiamon = message.RewardDiamon;
                if (message.RewardPackRand != null && message.hasOwnProperty("RewardPackRand"))
                    object.RewardPackRand = message.RewardPackRand;
                if (message.Disable != null && message.hasOwnProperty("Disable"))
                    object.Disable = message.Disable;
                if (message.GroupTitle != null && message.hasOwnProperty("GroupTitle"))
                    object.GroupTitle = message.GroupTitle;
                return object;
            };

            /**
             * Converts this AdTaskData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.AdTaskData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdTaskData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AdTaskData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.AdTaskData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdTaskData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.AdTaskData";
            };

            return AdTaskData;
        })();

        Protocols.ShareTaskData = (function() {

            /**
             * Properties of a ShareTaskData.
             * @memberof Rugbull.Protocols
             * @interface IShareTaskData
             * @property {number|null} [TaskId] ShareTaskData TaskId
             * @property {Rugbull.Protocols.TaskShareState|null} [State] ShareTaskData State
             * @property {number|null} [InvitedCount] ShareTaskData InvitedCount
             * @property {number|null} [RequireInviteCount] ShareTaskData RequireInviteCount
             * @property {number|null} [TotalCompleteCount] ShareTaskData TotalCompleteCount
             */

            /**
             * Constructs a new ShareTaskData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a ShareTaskData.
             * @implements IShareTaskData
             * @constructor
             * @param {Rugbull.Protocols.IShareTaskData=} [properties] Properties to set
             */
            function ShareTaskData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ShareTaskData TaskId.
             * @member {number} TaskId
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             */
            ShareTaskData.prototype.TaskId = 0;

            /**
             * ShareTaskData State.
             * @member {Rugbull.Protocols.TaskShareState} State
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             */
            ShareTaskData.prototype.State = 0;

            /**
             * ShareTaskData InvitedCount.
             * @member {number} InvitedCount
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             */
            ShareTaskData.prototype.InvitedCount = 0;

            /**
             * ShareTaskData RequireInviteCount.
             * @member {number} RequireInviteCount
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             */
            ShareTaskData.prototype.RequireInviteCount = 0;

            /**
             * ShareTaskData TotalCompleteCount.
             * @member {number} TotalCompleteCount
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             */
            ShareTaskData.prototype.TotalCompleteCount = 0;

            /**
             * Creates a new ShareTaskData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Rugbull.Protocols.IShareTaskData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.ShareTaskData} ShareTaskData instance
             */
            ShareTaskData.create = function create(properties) {
                return new ShareTaskData(properties);
            };

            /**
             * Encodes the specified ShareTaskData message. Does not implicitly {@link Rugbull.Protocols.ShareTaskData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Rugbull.Protocols.IShareTaskData} message ShareTaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShareTaskData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TaskId != null && Object.hasOwnProperty.call(message, "TaskId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.TaskId);
                if (message.State != null && Object.hasOwnProperty.call(message, "State"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.State);
                if (message.InvitedCount != null && Object.hasOwnProperty.call(message, "InvitedCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.InvitedCount);
                if (message.RequireInviteCount != null && Object.hasOwnProperty.call(message, "RequireInviteCount"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.RequireInviteCount);
                if (message.TotalCompleteCount != null && Object.hasOwnProperty.call(message, "TotalCompleteCount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.TotalCompleteCount);
                return writer;
            };

            /**
             * Encodes the specified ShareTaskData message, length delimited. Does not implicitly {@link Rugbull.Protocols.ShareTaskData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Rugbull.Protocols.IShareTaskData} message ShareTaskData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShareTaskData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ShareTaskData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.ShareTaskData} ShareTaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShareTaskData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.ShareTaskData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TaskId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.State = reader.int32();
                            break;
                        }
                    case 3: {
                            message.InvitedCount = reader.int32();
                            break;
                        }
                    case 4: {
                            message.RequireInviteCount = reader.int32();
                            break;
                        }
                    case 5: {
                            message.TotalCompleteCount = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ShareTaskData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.ShareTaskData} ShareTaskData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShareTaskData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ShareTaskData message.
             * @function verify
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ShareTaskData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    if (!$util.isInteger(message.TaskId))
                        return "TaskId: integer expected";
                if (message.State != null && message.hasOwnProperty("State"))
                    switch (message.State) {
                    default:
                        return "State: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.InvitedCount != null && message.hasOwnProperty("InvitedCount"))
                    if (!$util.isInteger(message.InvitedCount))
                        return "InvitedCount: integer expected";
                if (message.RequireInviteCount != null && message.hasOwnProperty("RequireInviteCount"))
                    if (!$util.isInteger(message.RequireInviteCount))
                        return "RequireInviteCount: integer expected";
                if (message.TotalCompleteCount != null && message.hasOwnProperty("TotalCompleteCount"))
                    if (!$util.isInteger(message.TotalCompleteCount))
                        return "TotalCompleteCount: integer expected";
                return null;
            };

            /**
             * Creates a ShareTaskData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.ShareTaskData} ShareTaskData
             */
            ShareTaskData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.ShareTaskData)
                    return object;
                let message = new $root.Rugbull.Protocols.ShareTaskData();
                if (object.TaskId != null)
                    message.TaskId = object.TaskId | 0;
                switch (object.State) {
                default:
                    if (typeof object.State === "number") {
                        message.State = object.State;
                        break;
                    }
                    break;
                case "TaskShareState_None":
                case 0:
                    message.State = 0;
                    break;
                case "TaskShareState_NeedCompletePrev":
                case 1:
                    message.State = 1;
                    break;
                case "TaskShareState_WaitingForInvites":
                case 2:
                    message.State = 2;
                    break;
                case "TaskShareState_WaitingForCommit":
                case 3:
                    message.State = 3;
                    break;
                case "TaskShareState_Committed":
                case 4:
                    message.State = 4;
                    break;
                case "TaskShareState_Verified":
                case 5:
                    message.State = 5;
                    break;
                }
                if (object.InvitedCount != null)
                    message.InvitedCount = object.InvitedCount | 0;
                if (object.RequireInviteCount != null)
                    message.RequireInviteCount = object.RequireInviteCount | 0;
                if (object.TotalCompleteCount != null)
                    message.TotalCompleteCount = object.TotalCompleteCount | 0;
                return message;
            };

            /**
             * Creates a plain object from a ShareTaskData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {Rugbull.Protocols.ShareTaskData} message ShareTaskData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ShareTaskData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.TaskId = 0;
                    object.State = options.enums === String ? "TaskShareState_None" : 0;
                    object.InvitedCount = 0;
                    object.RequireInviteCount = 0;
                    object.TotalCompleteCount = 0;
                }
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    object.TaskId = message.TaskId;
                if (message.State != null && message.hasOwnProperty("State"))
                    object.State = options.enums === String ? $root.Rugbull.Protocols.TaskShareState[message.State] === undefined ? message.State : $root.Rugbull.Protocols.TaskShareState[message.State] : message.State;
                if (message.InvitedCount != null && message.hasOwnProperty("InvitedCount"))
                    object.InvitedCount = message.InvitedCount;
                if (message.RequireInviteCount != null && message.hasOwnProperty("RequireInviteCount"))
                    object.RequireInviteCount = message.RequireInviteCount;
                if (message.TotalCompleteCount != null && message.hasOwnProperty("TotalCompleteCount"))
                    object.TotalCompleteCount = message.TotalCompleteCount;
                return object;
            };

            /**
             * Converts this ShareTaskData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.ShareTaskData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ShareTaskData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ShareTaskData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.ShareTaskData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ShareTaskData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.ShareTaskData";
            };

            return ShareTaskData;
        })();

        Protocols.SCFriendList = (function() {

            /**
             * Properties of a SCFriendList.
             * @memberof Rugbull.Protocols
             * @interface ISCFriendList
             * @property {Array.<Rugbull.Protocols.IFriendData>|null} [Friends] SCFriendList Friends
             * @property {number|null} [Start] SCFriendList Start
             * @property {number|null} [Limit] SCFriendList Limit
             * @property {number|null} [Total] SCFriendList Total
             */

            /**
             * Constructs a new SCFriendList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCFriendList.
             * @implements ISCFriendList
             * @constructor
             * @param {Rugbull.Protocols.ISCFriendList=} [properties] Properties to set
             */
            function SCFriendList(properties) {
                this.Friends = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCFriendList Friends.
             * @member {Array.<Rugbull.Protocols.IFriendData>} Friends
             * @memberof Rugbull.Protocols.SCFriendList
             * @instance
             */
            SCFriendList.prototype.Friends = $util.emptyArray;

            /**
             * SCFriendList Start.
             * @member {number} Start
             * @memberof Rugbull.Protocols.SCFriendList
             * @instance
             */
            SCFriendList.prototype.Start = 0;

            /**
             * SCFriendList Limit.
             * @member {number} Limit
             * @memberof Rugbull.Protocols.SCFriendList
             * @instance
             */
            SCFriendList.prototype.Limit = 0;

            /**
             * SCFriendList Total.
             * @member {number} Total
             * @memberof Rugbull.Protocols.SCFriendList
             * @instance
             */
            SCFriendList.prototype.Total = 0;

            /**
             * Creates a new SCFriendList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Rugbull.Protocols.ISCFriendList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCFriendList} SCFriendList instance
             */
            SCFriendList.create = function create(properties) {
                return new SCFriendList(properties);
            };

            /**
             * Encodes the specified SCFriendList message. Does not implicitly {@link Rugbull.Protocols.SCFriendList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Rugbull.Protocols.ISCFriendList} message SCFriendList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCFriendList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Friends != null && message.Friends.length)
                    for (let i = 0; i < message.Friends.length; ++i)
                        $root.Rugbull.Protocols.FriendData.encode(message.Friends[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.Start != null && Object.hasOwnProperty.call(message, "Start"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Start);
                if (message.Limit != null && Object.hasOwnProperty.call(message, "Limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Limit);
                if (message.Total != null && Object.hasOwnProperty.call(message, "Total"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Total);
                return writer;
            };

            /**
             * Encodes the specified SCFriendList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCFriendList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Rugbull.Protocols.ISCFriendList} message SCFriendList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCFriendList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCFriendList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCFriendList} SCFriendList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCFriendList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCFriendList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Friends && message.Friends.length))
                                message.Friends = [];
                            message.Friends.push($root.Rugbull.Protocols.FriendData.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.Start = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Limit = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Total = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCFriendList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCFriendList} SCFriendList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCFriendList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCFriendList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCFriendList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Friends != null && message.hasOwnProperty("Friends")) {
                    if (!Array.isArray(message.Friends))
                        return "Friends: array expected";
                    for (let i = 0; i < message.Friends.length; ++i) {
                        let error = $root.Rugbull.Protocols.FriendData.verify(message.Friends[i]);
                        if (error)
                            return "Friends." + error;
                    }
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    if (!$util.isInteger(message.Start))
                        return "Start: integer expected";
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    if (!$util.isInteger(message.Limit))
                        return "Limit: integer expected";
                if (message.Total != null && message.hasOwnProperty("Total"))
                    if (!$util.isInteger(message.Total))
                        return "Total: integer expected";
                return null;
            };

            /**
             * Creates a SCFriendList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCFriendList} SCFriendList
             */
            SCFriendList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCFriendList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCFriendList();
                if (object.Friends) {
                    if (!Array.isArray(object.Friends))
                        throw TypeError(".Rugbull.Protocols.SCFriendList.Friends: array expected");
                    message.Friends = [];
                    for (let i = 0; i < object.Friends.length; ++i) {
                        if (typeof object.Friends[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCFriendList.Friends: object expected");
                        message.Friends[i] = $root.Rugbull.Protocols.FriendData.fromObject(object.Friends[i]);
                    }
                }
                if (object.Start != null)
                    message.Start = object.Start | 0;
                if (object.Limit != null)
                    message.Limit = object.Limit | 0;
                if (object.Total != null)
                    message.Total = object.Total | 0;
                return message;
            };

            /**
             * Creates a plain object from a SCFriendList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {Rugbull.Protocols.SCFriendList} message SCFriendList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCFriendList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Friends = [];
                if (options.defaults) {
                    object.Start = 0;
                    object.Limit = 0;
                    object.Total = 0;
                }
                if (message.Friends && message.Friends.length) {
                    object.Friends = [];
                    for (let j = 0; j < message.Friends.length; ++j)
                        object.Friends[j] = $root.Rugbull.Protocols.FriendData.toObject(message.Friends[j], options);
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    object.Start = message.Start;
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    object.Limit = message.Limit;
                if (message.Total != null && message.hasOwnProperty("Total"))
                    object.Total = message.Total;
                return object;
            };

            /**
             * Converts this SCFriendList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCFriendList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCFriendList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCFriendList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCFriendList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCFriendList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCFriendList";
            };

            return SCFriendList;
        })();

        Protocols.CSFriendList = (function() {

            /**
             * Properties of a CSFriendList.
             * @memberof Rugbull.Protocols
             * @interface ICSFriendList
             * @property {number|null} [Start] CSFriendList Start
             * @property {number|null} [Limit] CSFriendList Limit
             */

            /**
             * Constructs a new CSFriendList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSFriendList.
             * @implements ICSFriendList
             * @constructor
             * @param {Rugbull.Protocols.ICSFriendList=} [properties] Properties to set
             */
            function CSFriendList(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSFriendList Start.
             * @member {number} Start
             * @memberof Rugbull.Protocols.CSFriendList
             * @instance
             */
            CSFriendList.prototype.Start = 0;

            /**
             * CSFriendList Limit.
             * @member {number} Limit
             * @memberof Rugbull.Protocols.CSFriendList
             * @instance
             */
            CSFriendList.prototype.Limit = 0;

            /**
             * Creates a new CSFriendList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Rugbull.Protocols.ICSFriendList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSFriendList} CSFriendList instance
             */
            CSFriendList.create = function create(properties) {
                return new CSFriendList(properties);
            };

            /**
             * Encodes the specified CSFriendList message. Does not implicitly {@link Rugbull.Protocols.CSFriendList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Rugbull.Protocols.ICSFriendList} message CSFriendList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSFriendList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Start != null && Object.hasOwnProperty.call(message, "Start"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Start);
                if (message.Limit != null && Object.hasOwnProperty.call(message, "Limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Limit);
                return writer;
            };

            /**
             * Encodes the specified CSFriendList message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSFriendList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Rugbull.Protocols.ICSFriendList} message CSFriendList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSFriendList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSFriendList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSFriendList} CSFriendList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSFriendList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSFriendList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.Start = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Limit = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSFriendList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSFriendList} CSFriendList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSFriendList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSFriendList message.
             * @function verify
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSFriendList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Start != null && message.hasOwnProperty("Start"))
                    if (!$util.isInteger(message.Start))
                        return "Start: integer expected";
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    if (!$util.isInteger(message.Limit))
                        return "Limit: integer expected";
                return null;
            };

            /**
             * Creates a CSFriendList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSFriendList} CSFriendList
             */
            CSFriendList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSFriendList)
                    return object;
                let message = new $root.Rugbull.Protocols.CSFriendList();
                if (object.Start != null)
                    message.Start = object.Start | 0;
                if (object.Limit != null)
                    message.Limit = object.Limit | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSFriendList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {Rugbull.Protocols.CSFriendList} message CSFriendList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSFriendList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Start = 0;
                    object.Limit = 0;
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    object.Start = message.Start;
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    object.Limit = message.Limit;
                return object;
            };

            /**
             * Converts this CSFriendList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSFriendList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSFriendList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSFriendList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSFriendList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSFriendList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSFriendList";
            };

            return CSFriendList;
        })();

        Protocols.CSFriend = (function() {

            /**
             * Properties of a CSFriend.
             * @memberof Rugbull.Protocols
             * @interface ICSFriend
             * @property {number|null} [UserId] CSFriend UserId
             */

            /**
             * Constructs a new CSFriend.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSFriend.
             * @implements ICSFriend
             * @constructor
             * @param {Rugbull.Protocols.ICSFriend=} [properties] Properties to set
             */
            function CSFriend(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSFriend UserId.
             * @member {number} UserId
             * @memberof Rugbull.Protocols.CSFriend
             * @instance
             */
            CSFriend.prototype.UserId = 0;

            /**
             * Creates a new CSFriend instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Rugbull.Protocols.ICSFriend=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSFriend} CSFriend instance
             */
            CSFriend.create = function create(properties) {
                return new CSFriend(properties);
            };

            /**
             * Encodes the specified CSFriend message. Does not implicitly {@link Rugbull.Protocols.CSFriend.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Rugbull.Protocols.ICSFriend} message CSFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSFriend.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserId != null && Object.hasOwnProperty.call(message, "UserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.UserId);
                return writer;
            };

            /**
             * Encodes the specified CSFriend message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSFriend.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Rugbull.Protocols.ICSFriend} message CSFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSFriend.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSFriend message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSFriend} CSFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSFriend.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSFriend();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSFriend message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSFriend} CSFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSFriend.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSFriend message.
             * @function verify
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSFriend.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    if (!$util.isInteger(message.UserId))
                        return "UserId: integer expected";
                return null;
            };

            /**
             * Creates a CSFriend message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSFriend} CSFriend
             */
            CSFriend.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSFriend)
                    return object;
                let message = new $root.Rugbull.Protocols.CSFriend();
                if (object.UserId != null)
                    message.UserId = object.UserId | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSFriend message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {Rugbull.Protocols.CSFriend} message CSFriend
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSFriend.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.UserId = 0;
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    object.UserId = message.UserId;
                return object;
            };

            /**
             * Converts this CSFriend to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSFriend
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSFriend.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSFriend
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSFriend
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSFriend.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSFriend";
            };

            return CSFriend;
        })();

        Protocols.SCFriend = (function() {

            /**
             * Properties of a SCFriend.
             * @memberof Rugbull.Protocols
             * @interface ISCFriend
             * @property {Rugbull.Protocols.IFriendData|null} [Friend] SCFriend Friend
             */

            /**
             * Constructs a new SCFriend.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCFriend.
             * @implements ISCFriend
             * @constructor
             * @param {Rugbull.Protocols.ISCFriend=} [properties] Properties to set
             */
            function SCFriend(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCFriend Friend.
             * @member {Rugbull.Protocols.IFriendData|null|undefined} Friend
             * @memberof Rugbull.Protocols.SCFriend
             * @instance
             */
            SCFriend.prototype.Friend = null;

            /**
             * Creates a new SCFriend instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Rugbull.Protocols.ISCFriend=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCFriend} SCFriend instance
             */
            SCFriend.create = function create(properties) {
                return new SCFriend(properties);
            };

            /**
             * Encodes the specified SCFriend message. Does not implicitly {@link Rugbull.Protocols.SCFriend.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Rugbull.Protocols.ISCFriend} message SCFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCFriend.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Friend != null && Object.hasOwnProperty.call(message, "Friend"))
                    $root.Rugbull.Protocols.FriendData.encode(message.Friend, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCFriend message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCFriend.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Rugbull.Protocols.ISCFriend} message SCFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCFriend.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCFriend message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCFriend} SCFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCFriend.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCFriend();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Friend = $root.Rugbull.Protocols.FriendData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCFriend message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCFriend} SCFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCFriend.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCFriend message.
             * @function verify
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCFriend.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Friend != null && message.hasOwnProperty("Friend")) {
                    let error = $root.Rugbull.Protocols.FriendData.verify(message.Friend);
                    if (error)
                        return "Friend." + error;
                }
                return null;
            };

            /**
             * Creates a SCFriend message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCFriend} SCFriend
             */
            SCFriend.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCFriend)
                    return object;
                let message = new $root.Rugbull.Protocols.SCFriend();
                if (object.Friend != null) {
                    if (typeof object.Friend !== "object")
                        throw TypeError(".Rugbull.Protocols.SCFriend.Friend: object expected");
                    message.Friend = $root.Rugbull.Protocols.FriendData.fromObject(object.Friend);
                }
                return message;
            };

            /**
             * Creates a plain object from a SCFriend message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {Rugbull.Protocols.SCFriend} message SCFriend
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCFriend.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Friend = null;
                if (message.Friend != null && message.hasOwnProperty("Friend"))
                    object.Friend = $root.Rugbull.Protocols.FriendData.toObject(message.Friend, options);
                return object;
            };

            /**
             * Converts this SCFriend to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCFriend
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCFriend.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCFriend
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCFriend
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCFriend.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCFriend";
            };

            return SCFriend;
        })();

        Protocols.PushNewFriend = (function() {

            /**
             * Properties of a PushNewFriend.
             * @memberof Rugbull.Protocols
             * @interface IPushNewFriend
             * @property {Array.<Rugbull.Protocols.IFriendRewardData>|null} [Friends] PushNewFriend Friends
             */

            /**
             * Constructs a new PushNewFriend.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushNewFriend.
             * @implements IPushNewFriend
             * @constructor
             * @param {Rugbull.Protocols.IPushNewFriend=} [properties] Properties to set
             */
            function PushNewFriend(properties) {
                this.Friends = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushNewFriend Friends.
             * @member {Array.<Rugbull.Protocols.IFriendRewardData>} Friends
             * @memberof Rugbull.Protocols.PushNewFriend
             * @instance
             */
            PushNewFriend.prototype.Friends = $util.emptyArray;

            /**
             * Creates a new PushNewFriend instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Rugbull.Protocols.IPushNewFriend=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushNewFriend} PushNewFriend instance
             */
            PushNewFriend.create = function create(properties) {
                return new PushNewFriend(properties);
            };

            /**
             * Encodes the specified PushNewFriend message. Does not implicitly {@link Rugbull.Protocols.PushNewFriend.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Rugbull.Protocols.IPushNewFriend} message PushNewFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushNewFriend.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Friends != null && message.Friends.length)
                    for (let i = 0; i < message.Friends.length; ++i)
                        $root.Rugbull.Protocols.FriendRewardData.encode(message.Friends[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PushNewFriend message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushNewFriend.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Rugbull.Protocols.IPushNewFriend} message PushNewFriend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushNewFriend.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushNewFriend message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushNewFriend} PushNewFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushNewFriend.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushNewFriend();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Friends && message.Friends.length))
                                message.Friends = [];
                            message.Friends.push($root.Rugbull.Protocols.FriendRewardData.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushNewFriend message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushNewFriend} PushNewFriend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushNewFriend.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushNewFriend message.
             * @function verify
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushNewFriend.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Friends != null && message.hasOwnProperty("Friends")) {
                    if (!Array.isArray(message.Friends))
                        return "Friends: array expected";
                    for (let i = 0; i < message.Friends.length; ++i) {
                        let error = $root.Rugbull.Protocols.FriendRewardData.verify(message.Friends[i]);
                        if (error)
                            return "Friends." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PushNewFriend message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushNewFriend} PushNewFriend
             */
            PushNewFriend.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushNewFriend)
                    return object;
                let message = new $root.Rugbull.Protocols.PushNewFriend();
                if (object.Friends) {
                    if (!Array.isArray(object.Friends))
                        throw TypeError(".Rugbull.Protocols.PushNewFriend.Friends: array expected");
                    message.Friends = [];
                    for (let i = 0; i < object.Friends.length; ++i) {
                        if (typeof object.Friends[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushNewFriend.Friends: object expected");
                        message.Friends[i] = $root.Rugbull.Protocols.FriendRewardData.fromObject(object.Friends[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PushNewFriend message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {Rugbull.Protocols.PushNewFriend} message PushNewFriend
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushNewFriend.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Friends = [];
                if (message.Friends && message.Friends.length) {
                    object.Friends = [];
                    for (let j = 0; j < message.Friends.length; ++j)
                        object.Friends[j] = $root.Rugbull.Protocols.FriendRewardData.toObject(message.Friends[j], options);
                }
                return object;
            };

            /**
             * Converts this PushNewFriend to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushNewFriend
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushNewFriend.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushNewFriend
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushNewFriend
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushNewFriend.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushNewFriend";
            };

            return PushNewFriend;
        })();

        Protocols.SCRegardsInfo = (function() {

            /**
             * Properties of a SCRegardsInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCRegardsInfo
             * @property {number|null} [TotalMembers] SCRegardsInfo TotalMembers
             * @property {number|null} [TotalDepositMembers] SCRegardsInfo TotalDepositMembers
             * @property {number|null} [TotalBetMembers] SCRegardsInfo TotalBetMembers
             */

            /**
             * Constructs a new SCRegardsInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCRegardsInfo.
             * @implements ISCRegardsInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCRegardsInfo=} [properties] Properties to set
             */
            function SCRegardsInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCRegardsInfo TotalMembers.
             * @member {number|null|undefined} TotalMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            SCRegardsInfo.prototype.TotalMembers = null;

            /**
             * SCRegardsInfo TotalDepositMembers.
             * @member {number|null|undefined} TotalDepositMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            SCRegardsInfo.prototype.TotalDepositMembers = null;

            /**
             * SCRegardsInfo TotalBetMembers.
             * @member {number|null|undefined} TotalBetMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            SCRegardsInfo.prototype.TotalBetMembers = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * SCRegardsInfo _TotalMembers.
             * @member {"TotalMembers"|undefined} _TotalMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            Object.defineProperty(SCRegardsInfo.prototype, "_TotalMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCRegardsInfo _TotalDepositMembers.
             * @member {"TotalDepositMembers"|undefined} _TotalDepositMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            Object.defineProperty(SCRegardsInfo.prototype, "_TotalDepositMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalDepositMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCRegardsInfo _TotalBetMembers.
             * @member {"TotalBetMembers"|undefined} _TotalBetMembers
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             */
            Object.defineProperty(SCRegardsInfo.prototype, "_TotalBetMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalBetMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SCRegardsInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Rugbull.Protocols.ISCRegardsInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCRegardsInfo} SCRegardsInfo instance
             */
            SCRegardsInfo.create = function create(properties) {
                return new SCRegardsInfo(properties);
            };

            /**
             * Encodes the specified SCRegardsInfo message. Does not implicitly {@link Rugbull.Protocols.SCRegardsInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Rugbull.Protocols.ISCRegardsInfo} message SCRegardsInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCRegardsInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TotalMembers != null && Object.hasOwnProperty.call(message, "TotalMembers"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.TotalMembers);
                if (message.TotalDepositMembers != null && Object.hasOwnProperty.call(message, "TotalDepositMembers"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.TotalDepositMembers);
                if (message.TotalBetMembers != null && Object.hasOwnProperty.call(message, "TotalBetMembers"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.TotalBetMembers);
                return writer;
            };

            /**
             * Encodes the specified SCRegardsInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCRegardsInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Rugbull.Protocols.ISCRegardsInfo} message SCRegardsInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCRegardsInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCRegardsInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCRegardsInfo} SCRegardsInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCRegardsInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCRegardsInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TotalMembers = reader.int32();
                            break;
                        }
                    case 2: {
                            message.TotalDepositMembers = reader.int32();
                            break;
                        }
                    case 3: {
                            message.TotalBetMembers = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCRegardsInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCRegardsInfo} SCRegardsInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCRegardsInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCRegardsInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCRegardsInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.TotalMembers != null && message.hasOwnProperty("TotalMembers")) {
                    properties._TotalMembers = 1;
                    if (!$util.isInteger(message.TotalMembers))
                        return "TotalMembers: integer expected";
                }
                if (message.TotalDepositMembers != null && message.hasOwnProperty("TotalDepositMembers")) {
                    properties._TotalDepositMembers = 1;
                    if (!$util.isInteger(message.TotalDepositMembers))
                        return "TotalDepositMembers: integer expected";
                }
                if (message.TotalBetMembers != null && message.hasOwnProperty("TotalBetMembers")) {
                    properties._TotalBetMembers = 1;
                    if (!$util.isInteger(message.TotalBetMembers))
                        return "TotalBetMembers: integer expected";
                }
                return null;
            };

            /**
             * Creates a SCRegardsInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCRegardsInfo} SCRegardsInfo
             */
            SCRegardsInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCRegardsInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCRegardsInfo();
                if (object.TotalMembers != null)
                    message.TotalMembers = object.TotalMembers | 0;
                if (object.TotalDepositMembers != null)
                    message.TotalDepositMembers = object.TotalDepositMembers | 0;
                if (object.TotalBetMembers != null)
                    message.TotalBetMembers = object.TotalBetMembers | 0;
                return message;
            };

            /**
             * Creates a plain object from a SCRegardsInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {Rugbull.Protocols.SCRegardsInfo} message SCRegardsInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCRegardsInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.TotalMembers != null && message.hasOwnProperty("TotalMembers")) {
                    object.TotalMembers = message.TotalMembers;
                    if (options.oneofs)
                        object._TotalMembers = "TotalMembers";
                }
                if (message.TotalDepositMembers != null && message.hasOwnProperty("TotalDepositMembers")) {
                    object.TotalDepositMembers = message.TotalDepositMembers;
                    if (options.oneofs)
                        object._TotalDepositMembers = "TotalDepositMembers";
                }
                if (message.TotalBetMembers != null && message.hasOwnProperty("TotalBetMembers")) {
                    object.TotalBetMembers = message.TotalBetMembers;
                    if (options.oneofs)
                        object._TotalBetMembers = "TotalBetMembers";
                }
                return object;
            };

            /**
             * Converts this SCRegardsInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCRegardsInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCRegardsInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCRegardsInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCRegardsInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCRegardsInfo";
            };

            return SCRegardsInfo;
        })();

        Protocols.CSAgentReport = (function() {

            /**
             * Properties of a CSAgentReport.
             * @memberof Rugbull.Protocols
             * @interface ICSAgentReport
             * @property {string|null} [StartTime] CSAgentReport StartTime
             * @property {string|null} [EndTime] CSAgentReport EndTime
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] CSAgentReport CoinType
             * @property {Rugbull.Protocols.AgentScopeType|null} [AgentScope] CSAgentReport AgentScope
             */

            /**
             * Constructs a new CSAgentReport.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSAgentReport.
             * @implements ICSAgentReport
             * @constructor
             * @param {Rugbull.Protocols.ICSAgentReport=} [properties] Properties to set
             */
            function CSAgentReport(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSAgentReport StartTime.
             * @member {string|null|undefined} StartTime
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            CSAgentReport.prototype.StartTime = null;

            /**
             * CSAgentReport EndTime.
             * @member {string|null|undefined} EndTime
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            CSAgentReport.prototype.EndTime = null;

            /**
             * CSAgentReport CoinType.
             * @member {Rugbull.Protocols.CoinType|null|undefined} CoinType
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            CSAgentReport.prototype.CoinType = null;

            /**
             * CSAgentReport AgentScope.
             * @member {Rugbull.Protocols.AgentScopeType|null|undefined} AgentScope
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            CSAgentReport.prototype.AgentScope = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * CSAgentReport _StartTime.
             * @member {"StartTime"|undefined} _StartTime
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            Object.defineProperty(CSAgentReport.prototype, "_StartTime", {
                get: $util.oneOfGetter($oneOfFields = ["StartTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CSAgentReport _EndTime.
             * @member {"EndTime"|undefined} _EndTime
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            Object.defineProperty(CSAgentReport.prototype, "_EndTime", {
                get: $util.oneOfGetter($oneOfFields = ["EndTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CSAgentReport _CoinType.
             * @member {"CoinType"|undefined} _CoinType
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            Object.defineProperty(CSAgentReport.prototype, "_CoinType", {
                get: $util.oneOfGetter($oneOfFields = ["CoinType"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CSAgentReport _AgentScope.
             * @member {"AgentScope"|undefined} _AgentScope
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             */
            Object.defineProperty(CSAgentReport.prototype, "_AgentScope", {
                get: $util.oneOfGetter($oneOfFields = ["AgentScope"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CSAgentReport instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Rugbull.Protocols.ICSAgentReport=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSAgentReport} CSAgentReport instance
             */
            CSAgentReport.create = function create(properties) {
                return new CSAgentReport(properties);
            };

            /**
             * Encodes the specified CSAgentReport message. Does not implicitly {@link Rugbull.Protocols.CSAgentReport.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Rugbull.Protocols.ICSAgentReport} message CSAgentReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSAgentReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.StartTime != null && Object.hasOwnProperty.call(message, "StartTime"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.StartTime);
                if (message.EndTime != null && Object.hasOwnProperty.call(message, "EndTime"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.EndTime);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CoinType);
                if (message.AgentScope != null && Object.hasOwnProperty.call(message, "AgentScope"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.AgentScope);
                return writer;
            };

            /**
             * Encodes the specified CSAgentReport message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSAgentReport.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Rugbull.Protocols.ICSAgentReport} message CSAgentReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSAgentReport.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSAgentReport message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSAgentReport} CSAgentReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSAgentReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSAgentReport();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.StartTime = reader.string();
                            break;
                        }
                    case 2: {
                            message.EndTime = reader.string();
                            break;
                        }
                    case 3: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.AgentScope = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSAgentReport message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSAgentReport} CSAgentReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSAgentReport.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSAgentReport message.
             * @function verify
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSAgentReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.StartTime != null && message.hasOwnProperty("StartTime")) {
                    properties._StartTime = 1;
                    if (!$util.isString(message.StartTime))
                        return "StartTime: string expected";
                }
                if (message.EndTime != null && message.hasOwnProperty("EndTime")) {
                    properties._EndTime = 1;
                    if (!$util.isString(message.EndTime))
                        return "EndTime: string expected";
                }
                if (message.CoinType != null && message.hasOwnProperty("CoinType")) {
                    properties._CoinType = 1;
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                }
                if (message.AgentScope != null && message.hasOwnProperty("AgentScope")) {
                    properties._AgentScope = 1;
                    switch (message.AgentScope) {
                    default:
                        return "AgentScope: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a CSAgentReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSAgentReport} CSAgentReport
             */
            CSAgentReport.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSAgentReport)
                    return object;
                let message = new $root.Rugbull.Protocols.CSAgentReport();
                if (object.StartTime != null)
                    message.StartTime = String(object.StartTime);
                if (object.EndTime != null)
                    message.EndTime = String(object.EndTime);
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                switch (object.AgentScope) {
                default:
                    if (typeof object.AgentScope === "number") {
                        message.AgentScope = object.AgentScope;
                        break;
                    }
                    break;
                case "AgentScopeType_All":
                case 0:
                    message.AgentScope = 0;
                    break;
                case "AgentScopeType_Level_1":
                case 1:
                    message.AgentScope = 1;
                    break;
                case "AgentScopeType_Level_2":
                case 2:
                    message.AgentScope = 2;
                    break;
                case "AgentScopeType_Level_3":
                case 3:
                    message.AgentScope = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSAgentReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {Rugbull.Protocols.CSAgentReport} message CSAgentReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSAgentReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.StartTime != null && message.hasOwnProperty("StartTime")) {
                    object.StartTime = message.StartTime;
                    if (options.oneofs)
                        object._StartTime = "StartTime";
                }
                if (message.EndTime != null && message.hasOwnProperty("EndTime")) {
                    object.EndTime = message.EndTime;
                    if (options.oneofs)
                        object._EndTime = "EndTime";
                }
                if (message.CoinType != null && message.hasOwnProperty("CoinType")) {
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                    if (options.oneofs)
                        object._CoinType = "CoinType";
                }
                if (message.AgentScope != null && message.hasOwnProperty("AgentScope")) {
                    object.AgentScope = options.enums === String ? $root.Rugbull.Protocols.AgentScopeType[message.AgentScope] === undefined ? message.AgentScope : $root.Rugbull.Protocols.AgentScopeType[message.AgentScope] : message.AgentScope;
                    if (options.oneofs)
                        object._AgentScope = "AgentScope";
                }
                return object;
            };

            /**
             * Converts this CSAgentReport to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSAgentReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSAgentReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSAgentReport
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSAgentReport
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSAgentReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSAgentReport";
            };

            return CSAgentReport;
        })();

        Protocols.SCAgentReport = (function() {

            /**
             * Properties of a SCAgentReport.
             * @memberof Rugbull.Protocols
             * @interface ISCAgentReport
             * @property {number|null} [NewRegistration] SCAgentReport NewRegistration
             * @property {number|null} [BetUsers] SCAgentReport BetUsers
             * @property {number|null} [FirstDepositMembers] SCAgentReport FirstDepositMembers
             * @property {string|null} [FirstDepositAmount] SCAgentReport FirstDepositAmount
             * @property {number|null} [TotalDepositMembers] SCAgentReport TotalDepositMembers
             * @property {number|null} [TotalWithdrawMembers] SCAgentReport TotalWithdrawMembers
             * @property {string|null} [TotalDepositAmount] SCAgentReport TotalDepositAmount
             * @property {string|null} [TotalWithdrawAmount] SCAgentReport TotalWithdrawAmount
             * @property {string|null} [TotalBetAmount] SCAgentReport TotalBetAmount
             * @property {string|null} [TotalRewardAmount] SCAgentReport TotalRewardAmount
             * @property {number|null} [TotalReBateMembers] SCAgentReport TotalReBateMembers
             * @property {string|null} [TotalReBateAmount] SCAgentReport TotalReBateAmount
             * @property {string|null} [DepostWithdralDif] SCAgentReport DepostWithdralDif
             * @property {string|null} [ProfitLossDif] SCAgentReport ProfitLossDif
             * @property {string|null} [StartTime] SCAgentReport StartTime
             * @property {string|null} [EndTime] SCAgentReport EndTime
             */

            /**
             * Constructs a new SCAgentReport.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCAgentReport.
             * @implements ISCAgentReport
             * @constructor
             * @param {Rugbull.Protocols.ISCAgentReport=} [properties] Properties to set
             */
            function SCAgentReport(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCAgentReport NewRegistration.
             * @member {number|null|undefined} NewRegistration
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.NewRegistration = null;

            /**
             * SCAgentReport BetUsers.
             * @member {number|null|undefined} BetUsers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.BetUsers = null;

            /**
             * SCAgentReport FirstDepositMembers.
             * @member {number|null|undefined} FirstDepositMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.FirstDepositMembers = null;

            /**
             * SCAgentReport FirstDepositAmount.
             * @member {string|null|undefined} FirstDepositAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.FirstDepositAmount = null;

            /**
             * SCAgentReport TotalDepositMembers.
             * @member {number|null|undefined} TotalDepositMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalDepositMembers = null;

            /**
             * SCAgentReport TotalWithdrawMembers.
             * @member {number|null|undefined} TotalWithdrawMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalWithdrawMembers = null;

            /**
             * SCAgentReport TotalDepositAmount.
             * @member {string|null|undefined} TotalDepositAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalDepositAmount = null;

            /**
             * SCAgentReport TotalWithdrawAmount.
             * @member {string|null|undefined} TotalWithdrawAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalWithdrawAmount = null;

            /**
             * SCAgentReport TotalBetAmount.
             * @member {string|null|undefined} TotalBetAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalBetAmount = null;

            /**
             * SCAgentReport TotalRewardAmount.
             * @member {string|null|undefined} TotalRewardAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalRewardAmount = null;

            /**
             * SCAgentReport TotalReBateMembers.
             * @member {number|null|undefined} TotalReBateMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalReBateMembers = null;

            /**
             * SCAgentReport TotalReBateAmount.
             * @member {string|null|undefined} TotalReBateAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.TotalReBateAmount = null;

            /**
             * SCAgentReport DepostWithdralDif.
             * @member {string|null|undefined} DepostWithdralDif
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.DepostWithdralDif = null;

            /**
             * SCAgentReport ProfitLossDif.
             * @member {string|null|undefined} ProfitLossDif
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.ProfitLossDif = null;

            /**
             * SCAgentReport StartTime.
             * @member {string|null|undefined} StartTime
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.StartTime = null;

            /**
             * SCAgentReport EndTime.
             * @member {string|null|undefined} EndTime
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            SCAgentReport.prototype.EndTime = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * SCAgentReport _NewRegistration.
             * @member {"NewRegistration"|undefined} _NewRegistration
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_NewRegistration", {
                get: $util.oneOfGetter($oneOfFields = ["NewRegistration"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _BetUsers.
             * @member {"BetUsers"|undefined} _BetUsers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_BetUsers", {
                get: $util.oneOfGetter($oneOfFields = ["BetUsers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _FirstDepositMembers.
             * @member {"FirstDepositMembers"|undefined} _FirstDepositMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_FirstDepositMembers", {
                get: $util.oneOfGetter($oneOfFields = ["FirstDepositMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _FirstDepositAmount.
             * @member {"FirstDepositAmount"|undefined} _FirstDepositAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_FirstDepositAmount", {
                get: $util.oneOfGetter($oneOfFields = ["FirstDepositAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalDepositMembers.
             * @member {"TotalDepositMembers"|undefined} _TotalDepositMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalDepositMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalDepositMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalWithdrawMembers.
             * @member {"TotalWithdrawMembers"|undefined} _TotalWithdrawMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalWithdrawMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalWithdrawMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalDepositAmount.
             * @member {"TotalDepositAmount"|undefined} _TotalDepositAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalDepositAmount", {
                get: $util.oneOfGetter($oneOfFields = ["TotalDepositAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalWithdrawAmount.
             * @member {"TotalWithdrawAmount"|undefined} _TotalWithdrawAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalWithdrawAmount", {
                get: $util.oneOfGetter($oneOfFields = ["TotalWithdrawAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalBetAmount.
             * @member {"TotalBetAmount"|undefined} _TotalBetAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalBetAmount", {
                get: $util.oneOfGetter($oneOfFields = ["TotalBetAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalRewardAmount.
             * @member {"TotalRewardAmount"|undefined} _TotalRewardAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalRewardAmount", {
                get: $util.oneOfGetter($oneOfFields = ["TotalRewardAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalReBateMembers.
             * @member {"TotalReBateMembers"|undefined} _TotalReBateMembers
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalReBateMembers", {
                get: $util.oneOfGetter($oneOfFields = ["TotalReBateMembers"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _TotalReBateAmount.
             * @member {"TotalReBateAmount"|undefined} _TotalReBateAmount
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_TotalReBateAmount", {
                get: $util.oneOfGetter($oneOfFields = ["TotalReBateAmount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _DepostWithdralDif.
             * @member {"DepostWithdralDif"|undefined} _DepostWithdralDif
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_DepostWithdralDif", {
                get: $util.oneOfGetter($oneOfFields = ["DepostWithdralDif"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _ProfitLossDif.
             * @member {"ProfitLossDif"|undefined} _ProfitLossDif
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_ProfitLossDif", {
                get: $util.oneOfGetter($oneOfFields = ["ProfitLossDif"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _StartTime.
             * @member {"StartTime"|undefined} _StartTime
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_StartTime", {
                get: $util.oneOfGetter($oneOfFields = ["StartTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCAgentReport _EndTime.
             * @member {"EndTime"|undefined} _EndTime
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             */
            Object.defineProperty(SCAgentReport.prototype, "_EndTime", {
                get: $util.oneOfGetter($oneOfFields = ["EndTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SCAgentReport instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Rugbull.Protocols.ISCAgentReport=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCAgentReport} SCAgentReport instance
             */
            SCAgentReport.create = function create(properties) {
                return new SCAgentReport(properties);
            };

            /**
             * Encodes the specified SCAgentReport message. Does not implicitly {@link Rugbull.Protocols.SCAgentReport.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Rugbull.Protocols.ISCAgentReport} message SCAgentReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCAgentReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.NewRegistration != null && Object.hasOwnProperty.call(message, "NewRegistration"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.NewRegistration);
                if (message.BetUsers != null && Object.hasOwnProperty.call(message, "BetUsers"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.BetUsers);
                if (message.FirstDepositMembers != null && Object.hasOwnProperty.call(message, "FirstDepositMembers"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.FirstDepositMembers);
                if (message.FirstDepositAmount != null && Object.hasOwnProperty.call(message, "FirstDepositAmount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.FirstDepositAmount);
                if (message.TotalDepositMembers != null && Object.hasOwnProperty.call(message, "TotalDepositMembers"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.TotalDepositMembers);
                if (message.TotalWithdrawMembers != null && Object.hasOwnProperty.call(message, "TotalWithdrawMembers"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.TotalWithdrawMembers);
                if (message.TotalDepositAmount != null && Object.hasOwnProperty.call(message, "TotalDepositAmount"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.TotalDepositAmount);
                if (message.TotalWithdrawAmount != null && Object.hasOwnProperty.call(message, "TotalWithdrawAmount"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.TotalWithdrawAmount);
                if (message.TotalBetAmount != null && Object.hasOwnProperty.call(message, "TotalBetAmount"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.TotalBetAmount);
                if (message.TotalRewardAmount != null && Object.hasOwnProperty.call(message, "TotalRewardAmount"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.TotalRewardAmount);
                if (message.TotalReBateMembers != null && Object.hasOwnProperty.call(message, "TotalReBateMembers"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.TotalReBateMembers);
                if (message.TotalReBateAmount != null && Object.hasOwnProperty.call(message, "TotalReBateAmount"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.TotalReBateAmount);
                if (message.DepostWithdralDif != null && Object.hasOwnProperty.call(message, "DepostWithdralDif"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.DepostWithdralDif);
                if (message.ProfitLossDif != null && Object.hasOwnProperty.call(message, "ProfitLossDif"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.ProfitLossDif);
                if (message.StartTime != null && Object.hasOwnProperty.call(message, "StartTime"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.StartTime);
                if (message.EndTime != null && Object.hasOwnProperty.call(message, "EndTime"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.EndTime);
                return writer;
            };

            /**
             * Encodes the specified SCAgentReport message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCAgentReport.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Rugbull.Protocols.ISCAgentReport} message SCAgentReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCAgentReport.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCAgentReport message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCAgentReport} SCAgentReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCAgentReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCAgentReport();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.NewRegistration = reader.int32();
                            break;
                        }
                    case 2: {
                            message.BetUsers = reader.int32();
                            break;
                        }
                    case 3: {
                            message.FirstDepositMembers = reader.int32();
                            break;
                        }
                    case 4: {
                            message.FirstDepositAmount = reader.string();
                            break;
                        }
                    case 5: {
                            message.TotalDepositMembers = reader.int32();
                            break;
                        }
                    case 6: {
                            message.TotalWithdrawMembers = reader.int32();
                            break;
                        }
                    case 7: {
                            message.TotalDepositAmount = reader.string();
                            break;
                        }
                    case 8: {
                            message.TotalWithdrawAmount = reader.string();
                            break;
                        }
                    case 9: {
                            message.TotalBetAmount = reader.string();
                            break;
                        }
                    case 10: {
                            message.TotalRewardAmount = reader.string();
                            break;
                        }
                    case 11: {
                            message.TotalReBateMembers = reader.int32();
                            break;
                        }
                    case 12: {
                            message.TotalReBateAmount = reader.string();
                            break;
                        }
                    case 13: {
                            message.DepostWithdralDif = reader.string();
                            break;
                        }
                    case 14: {
                            message.ProfitLossDif = reader.string();
                            break;
                        }
                    case 15: {
                            message.StartTime = reader.string();
                            break;
                        }
                    case 16: {
                            message.EndTime = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCAgentReport message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCAgentReport} SCAgentReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCAgentReport.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCAgentReport message.
             * @function verify
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCAgentReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.NewRegistration != null && message.hasOwnProperty("NewRegistration")) {
                    properties._NewRegistration = 1;
                    if (!$util.isInteger(message.NewRegistration))
                        return "NewRegistration: integer expected";
                }
                if (message.BetUsers != null && message.hasOwnProperty("BetUsers")) {
                    properties._BetUsers = 1;
                    if (!$util.isInteger(message.BetUsers))
                        return "BetUsers: integer expected";
                }
                if (message.FirstDepositMembers != null && message.hasOwnProperty("FirstDepositMembers")) {
                    properties._FirstDepositMembers = 1;
                    if (!$util.isInteger(message.FirstDepositMembers))
                        return "FirstDepositMembers: integer expected";
                }
                if (message.FirstDepositAmount != null && message.hasOwnProperty("FirstDepositAmount")) {
                    properties._FirstDepositAmount = 1;
                    if (!$util.isString(message.FirstDepositAmount))
                        return "FirstDepositAmount: string expected";
                }
                if (message.TotalDepositMembers != null && message.hasOwnProperty("TotalDepositMembers")) {
                    properties._TotalDepositMembers = 1;
                    if (!$util.isInteger(message.TotalDepositMembers))
                        return "TotalDepositMembers: integer expected";
                }
                if (message.TotalWithdrawMembers != null && message.hasOwnProperty("TotalWithdrawMembers")) {
                    properties._TotalWithdrawMembers = 1;
                    if (!$util.isInteger(message.TotalWithdrawMembers))
                        return "TotalWithdrawMembers: integer expected";
                }
                if (message.TotalDepositAmount != null && message.hasOwnProperty("TotalDepositAmount")) {
                    properties._TotalDepositAmount = 1;
                    if (!$util.isString(message.TotalDepositAmount))
                        return "TotalDepositAmount: string expected";
                }
                if (message.TotalWithdrawAmount != null && message.hasOwnProperty("TotalWithdrawAmount")) {
                    properties._TotalWithdrawAmount = 1;
                    if (!$util.isString(message.TotalWithdrawAmount))
                        return "TotalWithdrawAmount: string expected";
                }
                if (message.TotalBetAmount != null && message.hasOwnProperty("TotalBetAmount")) {
                    properties._TotalBetAmount = 1;
                    if (!$util.isString(message.TotalBetAmount))
                        return "TotalBetAmount: string expected";
                }
                if (message.TotalRewardAmount != null && message.hasOwnProperty("TotalRewardAmount")) {
                    properties._TotalRewardAmount = 1;
                    if (!$util.isString(message.TotalRewardAmount))
                        return "TotalRewardAmount: string expected";
                }
                if (message.TotalReBateMembers != null && message.hasOwnProperty("TotalReBateMembers")) {
                    properties._TotalReBateMembers = 1;
                    if (!$util.isInteger(message.TotalReBateMembers))
                        return "TotalReBateMembers: integer expected";
                }
                if (message.TotalReBateAmount != null && message.hasOwnProperty("TotalReBateAmount")) {
                    properties._TotalReBateAmount = 1;
                    if (!$util.isString(message.TotalReBateAmount))
                        return "TotalReBateAmount: string expected";
                }
                if (message.DepostWithdralDif != null && message.hasOwnProperty("DepostWithdralDif")) {
                    properties._DepostWithdralDif = 1;
                    if (!$util.isString(message.DepostWithdralDif))
                        return "DepostWithdralDif: string expected";
                }
                if (message.ProfitLossDif != null && message.hasOwnProperty("ProfitLossDif")) {
                    properties._ProfitLossDif = 1;
                    if (!$util.isString(message.ProfitLossDif))
                        return "ProfitLossDif: string expected";
                }
                if (message.StartTime != null && message.hasOwnProperty("StartTime")) {
                    properties._StartTime = 1;
                    if (!$util.isString(message.StartTime))
                        return "StartTime: string expected";
                }
                if (message.EndTime != null && message.hasOwnProperty("EndTime")) {
                    properties._EndTime = 1;
                    if (!$util.isString(message.EndTime))
                        return "EndTime: string expected";
                }
                return null;
            };

            /**
             * Creates a SCAgentReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCAgentReport} SCAgentReport
             */
            SCAgentReport.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCAgentReport)
                    return object;
                let message = new $root.Rugbull.Protocols.SCAgentReport();
                if (object.NewRegistration != null)
                    message.NewRegistration = object.NewRegistration | 0;
                if (object.BetUsers != null)
                    message.BetUsers = object.BetUsers | 0;
                if (object.FirstDepositMembers != null)
                    message.FirstDepositMembers = object.FirstDepositMembers | 0;
                if (object.FirstDepositAmount != null)
                    message.FirstDepositAmount = String(object.FirstDepositAmount);
                if (object.TotalDepositMembers != null)
                    message.TotalDepositMembers = object.TotalDepositMembers | 0;
                if (object.TotalWithdrawMembers != null)
                    message.TotalWithdrawMembers = object.TotalWithdrawMembers | 0;
                if (object.TotalDepositAmount != null)
                    message.TotalDepositAmount = String(object.TotalDepositAmount);
                if (object.TotalWithdrawAmount != null)
                    message.TotalWithdrawAmount = String(object.TotalWithdrawAmount);
                if (object.TotalBetAmount != null)
                    message.TotalBetAmount = String(object.TotalBetAmount);
                if (object.TotalRewardAmount != null)
                    message.TotalRewardAmount = String(object.TotalRewardAmount);
                if (object.TotalReBateMembers != null)
                    message.TotalReBateMembers = object.TotalReBateMembers | 0;
                if (object.TotalReBateAmount != null)
                    message.TotalReBateAmount = String(object.TotalReBateAmount);
                if (object.DepostWithdralDif != null)
                    message.DepostWithdralDif = String(object.DepostWithdralDif);
                if (object.ProfitLossDif != null)
                    message.ProfitLossDif = String(object.ProfitLossDif);
                if (object.StartTime != null)
                    message.StartTime = String(object.StartTime);
                if (object.EndTime != null)
                    message.EndTime = String(object.EndTime);
                return message;
            };

            /**
             * Creates a plain object from a SCAgentReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {Rugbull.Protocols.SCAgentReport} message SCAgentReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCAgentReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.NewRegistration != null && message.hasOwnProperty("NewRegistration")) {
                    object.NewRegistration = message.NewRegistration;
                    if (options.oneofs)
                        object._NewRegistration = "NewRegistration";
                }
                if (message.BetUsers != null && message.hasOwnProperty("BetUsers")) {
                    object.BetUsers = message.BetUsers;
                    if (options.oneofs)
                        object._BetUsers = "BetUsers";
                }
                if (message.FirstDepositMembers != null && message.hasOwnProperty("FirstDepositMembers")) {
                    object.FirstDepositMembers = message.FirstDepositMembers;
                    if (options.oneofs)
                        object._FirstDepositMembers = "FirstDepositMembers";
                }
                if (message.FirstDepositAmount != null && message.hasOwnProperty("FirstDepositAmount")) {
                    object.FirstDepositAmount = message.FirstDepositAmount;
                    if (options.oneofs)
                        object._FirstDepositAmount = "FirstDepositAmount";
                }
                if (message.TotalDepositMembers != null && message.hasOwnProperty("TotalDepositMembers")) {
                    object.TotalDepositMembers = message.TotalDepositMembers;
                    if (options.oneofs)
                        object._TotalDepositMembers = "TotalDepositMembers";
                }
                if (message.TotalWithdrawMembers != null && message.hasOwnProperty("TotalWithdrawMembers")) {
                    object.TotalWithdrawMembers = message.TotalWithdrawMembers;
                    if (options.oneofs)
                        object._TotalWithdrawMembers = "TotalWithdrawMembers";
                }
                if (message.TotalDepositAmount != null && message.hasOwnProperty("TotalDepositAmount")) {
                    object.TotalDepositAmount = message.TotalDepositAmount;
                    if (options.oneofs)
                        object._TotalDepositAmount = "TotalDepositAmount";
                }
                if (message.TotalWithdrawAmount != null && message.hasOwnProperty("TotalWithdrawAmount")) {
                    object.TotalWithdrawAmount = message.TotalWithdrawAmount;
                    if (options.oneofs)
                        object._TotalWithdrawAmount = "TotalWithdrawAmount";
                }
                if (message.TotalBetAmount != null && message.hasOwnProperty("TotalBetAmount")) {
                    object.TotalBetAmount = message.TotalBetAmount;
                    if (options.oneofs)
                        object._TotalBetAmount = "TotalBetAmount";
                }
                if (message.TotalRewardAmount != null && message.hasOwnProperty("TotalRewardAmount")) {
                    object.TotalRewardAmount = message.TotalRewardAmount;
                    if (options.oneofs)
                        object._TotalRewardAmount = "TotalRewardAmount";
                }
                if (message.TotalReBateMembers != null && message.hasOwnProperty("TotalReBateMembers")) {
                    object.TotalReBateMembers = message.TotalReBateMembers;
                    if (options.oneofs)
                        object._TotalReBateMembers = "TotalReBateMembers";
                }
                if (message.TotalReBateAmount != null && message.hasOwnProperty("TotalReBateAmount")) {
                    object.TotalReBateAmount = message.TotalReBateAmount;
                    if (options.oneofs)
                        object._TotalReBateAmount = "TotalReBateAmount";
                }
                if (message.DepostWithdralDif != null && message.hasOwnProperty("DepostWithdralDif")) {
                    object.DepostWithdralDif = message.DepostWithdralDif;
                    if (options.oneofs)
                        object._DepostWithdralDif = "DepostWithdralDif";
                }
                if (message.ProfitLossDif != null && message.hasOwnProperty("ProfitLossDif")) {
                    object.ProfitLossDif = message.ProfitLossDif;
                    if (options.oneofs)
                        object._ProfitLossDif = "ProfitLossDif";
                }
                if (message.StartTime != null && message.hasOwnProperty("StartTime")) {
                    object.StartTime = message.StartTime;
                    if (options.oneofs)
                        object._StartTime = "StartTime";
                }
                if (message.EndTime != null && message.hasOwnProperty("EndTime")) {
                    object.EndTime = message.EndTime;
                    if (options.oneofs)
                        object._EndTime = "EndTime";
                }
                return object;
            };

            /**
             * Converts this SCAgentReport to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCAgentReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCAgentReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCAgentReport
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCAgentReport
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCAgentReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCAgentReport";
            };

            return SCAgentReport;
        })();

        Protocols.RowData = (function() {

            /**
             * Properties of a RowData.
             * @memberof Rugbull.Protocols
             * @interface IRowData
             * @property {number|null} [id] RowData id
             * @property {Rugbull.Protocols.GameStatus|null} [state] RowData state
             * @property {string|null} [round] RowData round
             * @property {string|null} [encryption] RowData encryption
             * @property {string|null} [multiplier] RowData multiplier
             * @property {string|null} [createAt] RowData createAt
             * @property {string|null} [updatedAt] RowData updatedAt
             */

            /**
             * Constructs a new RowData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a RowData.
             * @implements IRowData
             * @constructor
             * @param {Rugbull.Protocols.IRowData=} [properties] Properties to set
             */
            function RowData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RowData id.
             * @member {number} id
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.id = 0;

            /**
             * RowData state.
             * @member {Rugbull.Protocols.GameStatus} state
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.state = 0;

            /**
             * RowData round.
             * @member {string} round
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.round = "";

            /**
             * RowData encryption.
             * @member {string} encryption
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.encryption = "";

            /**
             * RowData multiplier.
             * @member {string} multiplier
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.multiplier = "";

            /**
             * RowData createAt.
             * @member {string} createAt
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.createAt = "";

            /**
             * RowData updatedAt.
             * @member {string} updatedAt
             * @memberof Rugbull.Protocols.RowData
             * @instance
             */
            RowData.prototype.updatedAt = "";

            /**
             * Creates a new RowData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Rugbull.Protocols.IRowData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.RowData} RowData instance
             */
            RowData.create = function create(properties) {
                return new RowData(properties);
            };

            /**
             * Encodes the specified RowData message. Does not implicitly {@link Rugbull.Protocols.RowData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Rugbull.Protocols.IRowData} message RowData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RowData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
                if (message.round != null && Object.hasOwnProperty.call(message, "round"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.round);
                if (message.encryption != null && Object.hasOwnProperty.call(message, "encryption"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.encryption);
                if (message.multiplier != null && Object.hasOwnProperty.call(message, "multiplier"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.multiplier);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.createAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
                return writer;
            };

            /**
             * Encodes the specified RowData message, length delimited. Does not implicitly {@link Rugbull.Protocols.RowData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Rugbull.Protocols.IRowData} message RowData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RowData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RowData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.RowData} RowData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RowData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.RowData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.state = reader.int32();
                            break;
                        }
                    case 3: {
                            message.round = reader.string();
                            break;
                        }
                    case 4: {
                            message.encryption = reader.string();
                            break;
                        }
                    case 5: {
                            message.multiplier = reader.string();
                            break;
                        }
                    case 6: {
                            message.createAt = reader.string();
                            break;
                        }
                    case 7: {
                            message.updatedAt = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RowData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.RowData} RowData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RowData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RowData message.
             * @function verify
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RowData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    switch (message.state) {
                    default:
                        return "state: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.round != null && message.hasOwnProperty("round"))
                    if (!$util.isString(message.round))
                        return "round: string expected";
                if (message.encryption != null && message.hasOwnProperty("encryption"))
                    if (!$util.isString(message.encryption))
                        return "encryption: string expected";
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    if (!$util.isString(message.multiplier))
                        return "multiplier: string expected";
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    if (!$util.isString(message.createAt))
                        return "createAt: string expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isString(message.updatedAt))
                        return "updatedAt: string expected";
                return null;
            };

            /**
             * Creates a RowData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.RowData} RowData
             */
            RowData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.RowData)
                    return object;
                let message = new $root.Rugbull.Protocols.RowData();
                if (object.id != null)
                    message.id = object.id | 0;
                switch (object.state) {
                default:
                    if (typeof object.state === "number") {
                        message.state = object.state;
                        break;
                    }
                    break;
                case "GameStatus_None":
                case 0:
                    message.state = 0;
                    break;
                case "GameStatus_Ready":
                case 1:
                    message.state = 1;
                    break;
                case "GameStatus_Lock":
                case 2:
                    message.state = 2;
                    break;
                case "GameStatus_InProgress":
                case 3:
                    message.state = 3;
                    break;
                case "GameStatus_Finished":
                case 4:
                    message.state = 4;
                    break;
                }
                if (object.round != null)
                    message.round = String(object.round);
                if (object.encryption != null)
                    message.encryption = String(object.encryption);
                if (object.multiplier != null)
                    message.multiplier = String(object.multiplier);
                if (object.createAt != null)
                    message.createAt = String(object.createAt);
                if (object.updatedAt != null)
                    message.updatedAt = String(object.updatedAt);
                return message;
            };

            /**
             * Creates a plain object from a RowData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {Rugbull.Protocols.RowData} message RowData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RowData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.state = options.enums === String ? "GameStatus_None" : 0;
                    object.round = "";
                    object.encryption = "";
                    object.multiplier = "";
                    object.createAt = "";
                    object.updatedAt = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = options.enums === String ? $root.Rugbull.Protocols.GameStatus[message.state] === undefined ? message.state : $root.Rugbull.Protocols.GameStatus[message.state] : message.state;
                if (message.round != null && message.hasOwnProperty("round"))
                    object.round = message.round;
                if (message.encryption != null && message.hasOwnProperty("encryption"))
                    object.encryption = message.encryption;
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    object.multiplier = message.multiplier;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = message.createAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    object.updatedAt = message.updatedAt;
                return object;
            };

            /**
             * Converts this RowData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.RowData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RowData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RowData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.RowData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RowData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.RowData";
            };

            return RowData;
        })();

        Protocols.UserBetModel = (function() {

            /**
             * Properties of a UserBetModel.
             * @memberof Rugbull.Protocols
             * @interface IUserBetModel
             * @property {number|null} [Id] UserBetModel Id
             * @property {string|null} [Multiplier] UserBetModel Multiplier
             * @property {string|null} [Amount] UserBetModel Amount
             * @property {boolean|null} [IsWin] UserBetModel IsWin
             * @property {boolean|null} [AutoBuy] UserBetModel AutoBuy
             * @property {boolean|null} [IsBetting] UserBetModel IsBetting
             * @property {boolean|null} [AutoSell] UserBetModel AutoSell
             * @property {string|null} [Round] UserBetModel Round
             */

            /**
             * Constructs a new UserBetModel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a UserBetModel.
             * @implements IUserBetModel
             * @constructor
             * @param {Rugbull.Protocols.IUserBetModel=} [properties] Properties to set
             */
            function UserBetModel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserBetModel Id.
             * @member {number|null|undefined} Id
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.Id = null;

            /**
             * UserBetModel Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.Multiplier = "";

            /**
             * UserBetModel Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.Amount = "";

            /**
             * UserBetModel IsWin.
             * @member {boolean|null|undefined} IsWin
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.IsWin = null;

            /**
             * UserBetModel AutoBuy.
             * @member {boolean|null|undefined} AutoBuy
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.AutoBuy = null;

            /**
             * UserBetModel IsBetting.
             * @member {boolean|null|undefined} IsBetting
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.IsBetting = null;

            /**
             * UserBetModel AutoSell.
             * @member {boolean|null|undefined} AutoSell
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.AutoSell = null;

            /**
             * UserBetModel Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            UserBetModel.prototype.Round = "";

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * UserBetModel _Id.
             * @member {"Id"|undefined} _Id
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            Object.defineProperty(UserBetModel.prototype, "_Id", {
                get: $util.oneOfGetter($oneOfFields = ["Id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserBetModel _IsWin.
             * @member {"IsWin"|undefined} _IsWin
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            Object.defineProperty(UserBetModel.prototype, "_IsWin", {
                get: $util.oneOfGetter($oneOfFields = ["IsWin"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserBetModel _AutoBuy.
             * @member {"AutoBuy"|undefined} _AutoBuy
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            Object.defineProperty(UserBetModel.prototype, "_AutoBuy", {
                get: $util.oneOfGetter($oneOfFields = ["AutoBuy"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserBetModel _IsBetting.
             * @member {"IsBetting"|undefined} _IsBetting
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            Object.defineProperty(UserBetModel.prototype, "_IsBetting", {
                get: $util.oneOfGetter($oneOfFields = ["IsBetting"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserBetModel _AutoSell.
             * @member {"AutoSell"|undefined} _AutoSell
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             */
            Object.defineProperty(UserBetModel.prototype, "_AutoSell", {
                get: $util.oneOfGetter($oneOfFields = ["AutoSell"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UserBetModel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Rugbull.Protocols.IUserBetModel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.UserBetModel} UserBetModel instance
             */
            UserBetModel.create = function create(properties) {
                return new UserBetModel(properties);
            };

            /**
             * Encodes the specified UserBetModel message. Does not implicitly {@link Rugbull.Protocols.UserBetModel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Rugbull.Protocols.IUserBetModel} message UserBetModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserBetModel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Multiplier);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Amount);
                if (message.IsWin != null && Object.hasOwnProperty.call(message, "IsWin"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.IsWin);
                if (message.AutoBuy != null && Object.hasOwnProperty.call(message, "AutoBuy"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.AutoBuy);
                if (message.IsBetting != null && Object.hasOwnProperty.call(message, "IsBetting"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsBetting);
                if (message.AutoSell != null && Object.hasOwnProperty.call(message, "AutoSell"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.AutoSell);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.Round);
                return writer;
            };

            /**
             * Encodes the specified UserBetModel message, length delimited. Does not implicitly {@link Rugbull.Protocols.UserBetModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Rugbull.Protocols.IUserBetModel} message UserBetModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserBetModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserBetModel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.UserBetModel} UserBetModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserBetModel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.UserBetModel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 4: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 5: {
                            message.IsWin = reader.bool();
                            break;
                        }
                    case 6: {
                            message.AutoBuy = reader.bool();
                            break;
                        }
                    case 7: {
                            message.IsBetting = reader.bool();
                            break;
                        }
                    case 8: {
                            message.AutoSell = reader.bool();
                            break;
                        }
                    case 9: {
                            message.Round = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserBetModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.UserBetModel} UserBetModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserBetModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserBetModel message.
             * @function verify
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserBetModel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.Id != null && message.hasOwnProperty("Id")) {
                    properties._Id = 1;
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                }
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.IsWin != null && message.hasOwnProperty("IsWin")) {
                    properties._IsWin = 1;
                    if (typeof message.IsWin !== "boolean")
                        return "IsWin: boolean expected";
                }
                if (message.AutoBuy != null && message.hasOwnProperty("AutoBuy")) {
                    properties._AutoBuy = 1;
                    if (typeof message.AutoBuy !== "boolean")
                        return "AutoBuy: boolean expected";
                }
                if (message.IsBetting != null && message.hasOwnProperty("IsBetting")) {
                    properties._IsBetting = 1;
                    if (typeof message.IsBetting !== "boolean")
                        return "IsBetting: boolean expected";
                }
                if (message.AutoSell != null && message.hasOwnProperty("AutoSell")) {
                    properties._AutoSell = 1;
                    if (typeof message.AutoSell !== "boolean")
                        return "AutoSell: boolean expected";
                }
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                return null;
            };

            /**
             * Creates a UserBetModel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.UserBetModel} UserBetModel
             */
            UserBetModel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.UserBetModel)
                    return object;
                let message = new $root.Rugbull.Protocols.UserBetModel();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.IsWin != null)
                    message.IsWin = Boolean(object.IsWin);
                if (object.AutoBuy != null)
                    message.AutoBuy = Boolean(object.AutoBuy);
                if (object.IsBetting != null)
                    message.IsBetting = Boolean(object.IsBetting);
                if (object.AutoSell != null)
                    message.AutoSell = Boolean(object.AutoSell);
                if (object.Round != null)
                    message.Round = String(object.Round);
                return message;
            };

            /**
             * Creates a plain object from a UserBetModel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {Rugbull.Protocols.UserBetModel} message UserBetModel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserBetModel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Multiplier = "";
                    object.Amount = "";
                    object.Round = "";
                }
                if (message.Id != null && message.hasOwnProperty("Id")) {
                    object.Id = message.Id;
                    if (options.oneofs)
                        object._Id = "Id";
                }
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.IsWin != null && message.hasOwnProperty("IsWin")) {
                    object.IsWin = message.IsWin;
                    if (options.oneofs)
                        object._IsWin = "IsWin";
                }
                if (message.AutoBuy != null && message.hasOwnProperty("AutoBuy")) {
                    object.AutoBuy = message.AutoBuy;
                    if (options.oneofs)
                        object._AutoBuy = "AutoBuy";
                }
                if (message.IsBetting != null && message.hasOwnProperty("IsBetting")) {
                    object.IsBetting = message.IsBetting;
                    if (options.oneofs)
                        object._IsBetting = "IsBetting";
                }
                if (message.AutoSell != null && message.hasOwnProperty("AutoSell")) {
                    object.AutoSell = message.AutoSell;
                    if (options.oneofs)
                        object._AutoSell = "AutoSell";
                }
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                return object;
            };

            /**
             * Converts this UserBetModel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.UserBetModel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserBetModel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserBetModel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.UserBetModel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserBetModel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.UserBetModel";
            };

            return UserBetModel;
        })();

        Protocols.CSBetBuy = (function() {

            /**
             * Properties of a CSBetBuy.
             * @memberof Rugbull.Protocols
             * @interface ICSBetBuy
             * @property {number|null} [Id] CSBetBuy Id
             * @property {string|null} [Amount] CSBetBuy Amount
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] CSBetBuy CoinType
             */

            /**
             * Constructs a new CSBetBuy.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSBetBuy.
             * @implements ICSBetBuy
             * @constructor
             * @param {Rugbull.Protocols.ICSBetBuy=} [properties] Properties to set
             */
            function CSBetBuy(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSBetBuy Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.CSBetBuy
             * @instance
             */
            CSBetBuy.prototype.Id = 0;

            /**
             * CSBetBuy Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.CSBetBuy
             * @instance
             */
            CSBetBuy.prototype.Amount = "";

            /**
             * CSBetBuy CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.CSBetBuy
             * @instance
             */
            CSBetBuy.prototype.CoinType = 0;

            /**
             * Creates a new CSBetBuy instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Rugbull.Protocols.ICSBetBuy=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSBetBuy} CSBetBuy instance
             */
            CSBetBuy.create = function create(properties) {
                return new CSBetBuy(properties);
            };

            /**
             * Encodes the specified CSBetBuy message. Does not implicitly {@link Rugbull.Protocols.CSBetBuy.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Rugbull.Protocols.ICSBetBuy} message CSBetBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetBuy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CoinType);
                return writer;
            };

            /**
             * Encodes the specified CSBetBuy message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSBetBuy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Rugbull.Protocols.ICSBetBuy} message CSBetBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetBuy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSBetBuy message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSBetBuy} CSBetBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetBuy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSBetBuy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSBetBuy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSBetBuy} CSBetBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetBuy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSBetBuy message.
             * @function verify
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSBetBuy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSBetBuy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSBetBuy} CSBetBuy
             */
            CSBetBuy.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSBetBuy)
                    return object;
                let message = new $root.Rugbull.Protocols.CSBetBuy();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSBetBuy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {Rugbull.Protocols.CSBetBuy} message CSBetBuy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSBetBuy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.Amount = "";
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                return object;
            };

            /**
             * Converts this CSBetBuy to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSBetBuy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSBetBuy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSBetBuy
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSBetBuy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSBetBuy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSBetBuy";
            };

            return CSBetBuy;
        })();

        Protocols.CSBetCanncel = (function() {

            /**
             * Properties of a CSBetCanncel.
             * @memberof Rugbull.Protocols
             * @interface ICSBetCanncel
             * @property {number|null} [Id] CSBetCanncel Id
             */

            /**
             * Constructs a new CSBetCanncel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSBetCanncel.
             * @implements ICSBetCanncel
             * @constructor
             * @param {Rugbull.Protocols.ICSBetCanncel=} [properties] Properties to set
             */
            function CSBetCanncel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSBetCanncel Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @instance
             */
            CSBetCanncel.prototype.Id = 0;

            /**
             * Creates a new CSBetCanncel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Rugbull.Protocols.ICSBetCanncel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSBetCanncel} CSBetCanncel instance
             */
            CSBetCanncel.create = function create(properties) {
                return new CSBetCanncel(properties);
            };

            /**
             * Encodes the specified CSBetCanncel message. Does not implicitly {@link Rugbull.Protocols.CSBetCanncel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Rugbull.Protocols.ICSBetCanncel} message CSBetCanncel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetCanncel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                return writer;
            };

            /**
             * Encodes the specified CSBetCanncel message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSBetCanncel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Rugbull.Protocols.ICSBetCanncel} message CSBetCanncel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetCanncel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSBetCanncel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSBetCanncel} CSBetCanncel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetCanncel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSBetCanncel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSBetCanncel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSBetCanncel} CSBetCanncel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetCanncel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSBetCanncel message.
             * @function verify
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSBetCanncel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                return null;
            };

            /**
             * Creates a CSBetCanncel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSBetCanncel} CSBetCanncel
             */
            CSBetCanncel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSBetCanncel)
                    return object;
                let message = new $root.Rugbull.Protocols.CSBetCanncel();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSBetCanncel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {Rugbull.Protocols.CSBetCanncel} message CSBetCanncel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSBetCanncel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Id = 0;
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                return object;
            };

            /**
             * Converts this CSBetCanncel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSBetCanncel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSBetCanncel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSBetCanncel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSBetCanncel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSBetCanncel";
            };

            return CSBetCanncel;
        })();

        Protocols.CSBetSell = (function() {

            /**
             * Properties of a CSBetSell.
             * @memberof Rugbull.Protocols
             * @interface ICSBetSell
             * @property {number|null} [Id] CSBetSell Id
             * @property {boolean|null} [OnlySell] CSBetSell OnlySell
             */

            /**
             * Constructs a new CSBetSell.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSBetSell.
             * @implements ICSBetSell
             * @constructor
             * @param {Rugbull.Protocols.ICSBetSell=} [properties] Properties to set
             */
            function CSBetSell(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSBetSell Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.CSBetSell
             * @instance
             */
            CSBetSell.prototype.Id = 0;

            /**
             * CSBetSell OnlySell.
             * @member {boolean|null|undefined} OnlySell
             * @memberof Rugbull.Protocols.CSBetSell
             * @instance
             */
            CSBetSell.prototype.OnlySell = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * CSBetSell _OnlySell.
             * @member {"OnlySell"|undefined} _OnlySell
             * @memberof Rugbull.Protocols.CSBetSell
             * @instance
             */
            Object.defineProperty(CSBetSell.prototype, "_OnlySell", {
                get: $util.oneOfGetter($oneOfFields = ["OnlySell"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CSBetSell instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Rugbull.Protocols.ICSBetSell=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSBetSell} CSBetSell instance
             */
            CSBetSell.create = function create(properties) {
                return new CSBetSell(properties);
            };

            /**
             * Encodes the specified CSBetSell message. Does not implicitly {@link Rugbull.Protocols.CSBetSell.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Rugbull.Protocols.ICSBetSell} message CSBetSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetSell.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.OnlySell != null && Object.hasOwnProperty.call(message, "OnlySell"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.OnlySell);
                return writer;
            };

            /**
             * Encodes the specified CSBetSell message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSBetSell.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Rugbull.Protocols.ICSBetSell} message CSBetSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetSell.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSBetSell message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSBetSell} CSBetSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetSell.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSBetSell();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.OnlySell = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSBetSell message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSBetSell} CSBetSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetSell.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSBetSell message.
             * @function verify
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSBetSell.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.OnlySell != null && message.hasOwnProperty("OnlySell")) {
                    properties._OnlySell = 1;
                    if (typeof message.OnlySell !== "boolean")
                        return "OnlySell: boolean expected";
                }
                return null;
            };

            /**
             * Creates a CSBetSell message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSBetSell} CSBetSell
             */
            CSBetSell.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSBetSell)
                    return object;
                let message = new $root.Rugbull.Protocols.CSBetSell();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.OnlySell != null)
                    message.OnlySell = Boolean(object.OnlySell);
                return message;
            };

            /**
             * Creates a plain object from a CSBetSell message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {Rugbull.Protocols.CSBetSell} message CSBetSell
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSBetSell.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Id = 0;
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.OnlySell != null && message.hasOwnProperty("OnlySell")) {
                    object.OnlySell = message.OnlySell;
                    if (options.oneofs)
                        object._OnlySell = "OnlySell";
                }
                return object;
            };

            /**
             * Converts this CSBetSell to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSBetSell
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSBetSell.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSBetSell
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSBetSell
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSBetSell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSBetSell";
            };

            return CSBetSell;
        })();

        Protocols.PushUserBet = (function() {

            /**
             * Properties of a PushUserBet.
             * @memberof Rugbull.Protocols
             * @interface IPushUserBet
             * @property {Array.<Rugbull.Protocols.IUserBetModel>|null} [UserBets] PushUserBet UserBets
             */

            /**
             * Constructs a new PushUserBet.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushUserBet.
             * @implements IPushUserBet
             * @constructor
             * @param {Rugbull.Protocols.IPushUserBet=} [properties] Properties to set
             */
            function PushUserBet(properties) {
                this.UserBets = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushUserBet UserBets.
             * @member {Array.<Rugbull.Protocols.IUserBetModel>} UserBets
             * @memberof Rugbull.Protocols.PushUserBet
             * @instance
             */
            PushUserBet.prototype.UserBets = $util.emptyArray;

            /**
             * Creates a new PushUserBet instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Rugbull.Protocols.IPushUserBet=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushUserBet} PushUserBet instance
             */
            PushUserBet.create = function create(properties) {
                return new PushUserBet(properties);
            };

            /**
             * Encodes the specified PushUserBet message. Does not implicitly {@link Rugbull.Protocols.PushUserBet.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Rugbull.Protocols.IPushUserBet} message PushUserBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUserBet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserBets != null && message.UserBets.length)
                    for (let i = 0; i < message.UserBets.length; ++i)
                        $root.Rugbull.Protocols.UserBetModel.encode(message.UserBets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PushUserBet message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushUserBet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Rugbull.Protocols.IPushUserBet} message PushUserBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUserBet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushUserBet message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushUserBet} PushUserBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUserBet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushUserBet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.UserBets && message.UserBets.length))
                                message.UserBets = [];
                            message.UserBets.push($root.Rugbull.Protocols.UserBetModel.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushUserBet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushUserBet} PushUserBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUserBet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushUserBet message.
             * @function verify
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushUserBet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserBets != null && message.hasOwnProperty("UserBets")) {
                    if (!Array.isArray(message.UserBets))
                        return "UserBets: array expected";
                    for (let i = 0; i < message.UserBets.length; ++i) {
                        let error = $root.Rugbull.Protocols.UserBetModel.verify(message.UserBets[i]);
                        if (error)
                            return "UserBets." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PushUserBet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushUserBet} PushUserBet
             */
            PushUserBet.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushUserBet)
                    return object;
                let message = new $root.Rugbull.Protocols.PushUserBet();
                if (object.UserBets) {
                    if (!Array.isArray(object.UserBets))
                        throw TypeError(".Rugbull.Protocols.PushUserBet.UserBets: array expected");
                    message.UserBets = [];
                    for (let i = 0; i < object.UserBets.length; ++i) {
                        if (typeof object.UserBets[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushUserBet.UserBets: object expected");
                        message.UserBets[i] = $root.Rugbull.Protocols.UserBetModel.fromObject(object.UserBets[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PushUserBet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {Rugbull.Protocols.PushUserBet} message PushUserBet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushUserBet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.UserBets = [];
                if (message.UserBets && message.UserBets.length) {
                    object.UserBets = [];
                    for (let j = 0; j < message.UserBets.length; ++j)
                        object.UserBets[j] = $root.Rugbull.Protocols.UserBetModel.toObject(message.UserBets[j], options);
                }
                return object;
            };

            /**
             * Converts this PushUserBet to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushUserBet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushUserBet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushUserBet
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushUserBet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushUserBet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushUserBet";
            };

            return PushUserBet;
        })();

        Protocols.CSBetSet = (function() {

            /**
             * Properties of a CSBetSet.
             * @memberof Rugbull.Protocols
             * @interface ICSBetSet
             * @property {number|null} [Id] CSBetSet Id
             * @property {string|null} [Multiplier] CSBetSet Multiplier
             * @property {string|null} [Amount] CSBetSet Amount
             * @property {boolean|null} [AutoBuy] CSBetSet AutoBuy
             * @property {boolean|null} [AutoSell] CSBetSet AutoSell
             * @property {string|null} [Seed] CSBetSet Seed
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] CSBetSet CoinType
             */

            /**
             * Constructs a new CSBetSet.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSBetSet.
             * @implements ICSBetSet
             * @constructor
             * @param {Rugbull.Protocols.ICSBetSet=} [properties] Properties to set
             */
            function CSBetSet(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSBetSet Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.Id = 0;

            /**
             * CSBetSet Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.Multiplier = "";

            /**
             * CSBetSet Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.Amount = "";

            /**
             * CSBetSet AutoBuy.
             * @member {boolean} AutoBuy
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.AutoBuy = false;

            /**
             * CSBetSet AutoSell.
             * @member {boolean} AutoSell
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.AutoSell = false;

            /**
             * CSBetSet Seed.
             * @member {string} Seed
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.Seed = "";

            /**
             * CSBetSet CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             */
            CSBetSet.prototype.CoinType = 0;

            /**
             * Creates a new CSBetSet instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Rugbull.Protocols.ICSBetSet=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSBetSet} CSBetSet instance
             */
            CSBetSet.create = function create(properties) {
                return new CSBetSet(properties);
            };

            /**
             * Encodes the specified CSBetSet message. Does not implicitly {@link Rugbull.Protocols.CSBetSet.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Rugbull.Protocols.ICSBetSet} message CSBetSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Multiplier);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Amount);
                if (message.AutoBuy != null && Object.hasOwnProperty.call(message, "AutoBuy"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.AutoBuy);
                if (message.AutoSell != null && Object.hasOwnProperty.call(message, "AutoSell"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.AutoSell);
                if (message.Seed != null && Object.hasOwnProperty.call(message, "Seed"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Seed);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.CoinType);
                return writer;
            };

            /**
             * Encodes the specified CSBetSet message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSBetSet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Rugbull.Protocols.ICSBetSet} message CSBetSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBetSet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSBetSet message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSBetSet} CSBetSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSBetSet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 3: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 4: {
                            message.AutoBuy = reader.bool();
                            break;
                        }
                    case 5: {
                            message.AutoSell = reader.bool();
                            break;
                        }
                    case 6: {
                            message.Seed = reader.string();
                            break;
                        }
                    case 7: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSBetSet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSBetSet} CSBetSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBetSet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSBetSet message.
             * @function verify
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSBetSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.AutoBuy != null && message.hasOwnProperty("AutoBuy"))
                    if (typeof message.AutoBuy !== "boolean")
                        return "AutoBuy: boolean expected";
                if (message.AutoSell != null && message.hasOwnProperty("AutoSell"))
                    if (typeof message.AutoSell !== "boolean")
                        return "AutoSell: boolean expected";
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    if (!$util.isString(message.Seed))
                        return "Seed: string expected";
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSBetSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSBetSet} CSBetSet
             */
            CSBetSet.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSBetSet)
                    return object;
                let message = new $root.Rugbull.Protocols.CSBetSet();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.AutoBuy != null)
                    message.AutoBuy = Boolean(object.AutoBuy);
                if (object.AutoSell != null)
                    message.AutoSell = Boolean(object.AutoSell);
                if (object.Seed != null)
                    message.Seed = String(object.Seed);
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSBetSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {Rugbull.Protocols.CSBetSet} message CSBetSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSBetSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.Multiplier = "";
                    object.Amount = "";
                    object.AutoBuy = false;
                    object.AutoSell = false;
                    object.Seed = "";
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.AutoBuy != null && message.hasOwnProperty("AutoBuy"))
                    object.AutoBuy = message.AutoBuy;
                if (message.AutoSell != null && message.hasOwnProperty("AutoSell"))
                    object.AutoSell = message.AutoSell;
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    object.Seed = message.Seed;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                return object;
            };

            /**
             * Converts this CSBetSet to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSBetSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSBetSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSBetSet
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSBetSet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSBetSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSBetSet";
            };

            return CSBetSet;
        })();

        Protocols.ReadyData = (function() {

            /**
             * Properties of a ReadyData.
             * @memberof Rugbull.Protocols
             * @interface IReadyData
             * @property {number|null} [RemainSeconds] ReadyData RemainSeconds
             * @property {number|null} [TotalSeconds] ReadyData TotalSeconds
             */

            /**
             * Constructs a new ReadyData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a ReadyData.
             * @implements IReadyData
             * @constructor
             * @param {Rugbull.Protocols.IReadyData=} [properties] Properties to set
             */
            function ReadyData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadyData RemainSeconds.
             * @member {number} RemainSeconds
             * @memberof Rugbull.Protocols.ReadyData
             * @instance
             */
            ReadyData.prototype.RemainSeconds = 0;

            /**
             * ReadyData TotalSeconds.
             * @member {number} TotalSeconds
             * @memberof Rugbull.Protocols.ReadyData
             * @instance
             */
            ReadyData.prototype.TotalSeconds = 0;

            /**
             * Creates a new ReadyData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Rugbull.Protocols.IReadyData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.ReadyData} ReadyData instance
             */
            ReadyData.create = function create(properties) {
                return new ReadyData(properties);
            };

            /**
             * Encodes the specified ReadyData message. Does not implicitly {@link Rugbull.Protocols.ReadyData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Rugbull.Protocols.IReadyData} message ReadyData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadyData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RemainSeconds != null && Object.hasOwnProperty.call(message, "RemainSeconds"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.RemainSeconds);
                if (message.TotalSeconds != null && Object.hasOwnProperty.call(message, "TotalSeconds"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.TotalSeconds);
                return writer;
            };

            /**
             * Encodes the specified ReadyData message, length delimited. Does not implicitly {@link Rugbull.Protocols.ReadyData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Rugbull.Protocols.IReadyData} message ReadyData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadyData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadyData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.ReadyData} ReadyData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadyData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.ReadyData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.RemainSeconds = reader.float();
                            break;
                        }
                    case 2: {
                            message.TotalSeconds = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadyData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.ReadyData} ReadyData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadyData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadyData message.
             * @function verify
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadyData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    if (typeof message.RemainSeconds !== "number")
                        return "RemainSeconds: number expected";
                if (message.TotalSeconds != null && message.hasOwnProperty("TotalSeconds"))
                    if (typeof message.TotalSeconds !== "number")
                        return "TotalSeconds: number expected";
                return null;
            };

            /**
             * Creates a ReadyData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.ReadyData} ReadyData
             */
            ReadyData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.ReadyData)
                    return object;
                let message = new $root.Rugbull.Protocols.ReadyData();
                if (object.RemainSeconds != null)
                    message.RemainSeconds = Number(object.RemainSeconds);
                if (object.TotalSeconds != null)
                    message.TotalSeconds = Number(object.TotalSeconds);
                return message;
            };

            /**
             * Creates a plain object from a ReadyData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {Rugbull.Protocols.ReadyData} message ReadyData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadyData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.RemainSeconds = 0;
                    object.TotalSeconds = 0;
                }
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    object.RemainSeconds = options.json && !isFinite(message.RemainSeconds) ? String(message.RemainSeconds) : message.RemainSeconds;
                if (message.TotalSeconds != null && message.hasOwnProperty("TotalSeconds"))
                    object.TotalSeconds = options.json && !isFinite(message.TotalSeconds) ? String(message.TotalSeconds) : message.TotalSeconds;
                return object;
            };

            /**
             * Converts this ReadyData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.ReadyData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadyData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadyData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.ReadyData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadyData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.ReadyData";
            };

            return ReadyData;
        })();

        Protocols.BetData = (function() {

            /**
             * Properties of a BetData.
             * @memberof Rugbull.Protocols
             * @interface IBetData
             * @property {number|null} [CreatedAt] BetData CreatedAt
             * @property {number|null} [UserId] BetData UserId
             * @property {number|null} [Index] BetData Index
             * @property {number|null} [Round] BetData Round
             * @property {string|null} [Seed] BetData Seed
             * @property {string|null} [Amount] BetData Amount
             * @property {string|null} [SellMultiplier] BetData SellMultiplier
             * @property {boolean|null} [IsAutoBuy] BetData IsAutoBuy
             * @property {boolean|null} [IsAutoSell] BetData IsAutoSell
             * @property {string|null} [UserCoin] BetData UserCoin
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] BetData CoinType
             * @property {boolean|null} [IsBot] BetData IsBot
             * @property {string|null} [Name] BetData Name
             * @property {string|null} [PhotoUrl] BetData PhotoUrl
             * @property {string|null} [UserCoinBefore] BetData UserCoinBefore
             */

            /**
             * Constructs a new BetData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a BetData.
             * @implements IBetData
             * @constructor
             * @param {Rugbull.Protocols.IBetData=} [properties] Properties to set
             */
            function BetData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BetData CreatedAt.
             * @member {number} CreatedAt
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.CreatedAt = 0;

            /**
             * BetData UserId.
             * @member {number} UserId
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.UserId = 0;

            /**
             * BetData Index.
             * @member {number} Index
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.Index = 0;

            /**
             * BetData Round.
             * @member {number} Round
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.Round = 0;

            /**
             * BetData Seed.
             * @member {string} Seed
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.Seed = "";

            /**
             * BetData Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.Amount = "";

            /**
             * BetData SellMultiplier.
             * @member {string} SellMultiplier
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.SellMultiplier = "";

            /**
             * BetData IsAutoBuy.
             * @member {boolean} IsAutoBuy
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.IsAutoBuy = false;

            /**
             * BetData IsAutoSell.
             * @member {boolean} IsAutoSell
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.IsAutoSell = false;

            /**
             * BetData UserCoin.
             * @member {string} UserCoin
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.UserCoin = "";

            /**
             * BetData CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.CoinType = 0;

            /**
             * BetData IsBot.
             * @member {boolean} IsBot
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.IsBot = false;

            /**
             * BetData Name.
             * @member {string} Name
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.Name = "";

            /**
             * BetData PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.PhotoUrl = "";

            /**
             * BetData UserCoinBefore.
             * @member {string} UserCoinBefore
             * @memberof Rugbull.Protocols.BetData
             * @instance
             */
            BetData.prototype.UserCoinBefore = "";

            /**
             * Creates a new BetData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Rugbull.Protocols.IBetData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.BetData} BetData instance
             */
            BetData.create = function create(properties) {
                return new BetData(properties);
            };

            /**
             * Encodes the specified BetData message. Does not implicitly {@link Rugbull.Protocols.BetData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Rugbull.Protocols.IBetData} message BetData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BetData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.CreatedAt != null && Object.hasOwnProperty.call(message, "CreatedAt"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.CreatedAt);
                if (message.UserId != null && Object.hasOwnProperty.call(message, "UserId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.UserId);
                if (message.Index != null && Object.hasOwnProperty.call(message, "Index"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Index);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Round);
                if (message.Seed != null && Object.hasOwnProperty.call(message, "Seed"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Seed);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Amount);
                if (message.SellMultiplier != null && Object.hasOwnProperty.call(message, "SellMultiplier"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.SellMultiplier);
                if (message.IsAutoBuy != null && Object.hasOwnProperty.call(message, "IsAutoBuy"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.IsAutoBuy);
                if (message.IsAutoSell != null && Object.hasOwnProperty.call(message, "IsAutoSell"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.IsAutoSell);
                if (message.UserCoin != null && Object.hasOwnProperty.call(message, "UserCoin"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.UserCoin);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.CoinType);
                if (message.IsBot != null && Object.hasOwnProperty.call(message, "IsBot"))
                    writer.uint32(/* id 12, wireType 0 =*/96).bool(message.IsBot);
                if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.Name);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.PhotoUrl);
                if (message.UserCoinBefore != null && Object.hasOwnProperty.call(message, "UserCoinBefore"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.UserCoinBefore);
                return writer;
            };

            /**
             * Encodes the specified BetData message, length delimited. Does not implicitly {@link Rugbull.Protocols.BetData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Rugbull.Protocols.IBetData} message BetData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BetData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BetData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.BetData} BetData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BetData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.BetData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.CreatedAt = reader.int32();
                            break;
                        }
                    case 2: {
                            message.UserId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Index = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Round = reader.int32();
                            break;
                        }
                    case 5: {
                            message.Seed = reader.string();
                            break;
                        }
                    case 6: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 7: {
                            message.SellMultiplier = reader.string();
                            break;
                        }
                    case 8: {
                            message.IsAutoBuy = reader.bool();
                            break;
                        }
                    case 9: {
                            message.IsAutoSell = reader.bool();
                            break;
                        }
                    case 10: {
                            message.UserCoin = reader.string();
                            break;
                        }
                    case 11: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    case 12: {
                            message.IsBot = reader.bool();
                            break;
                        }
                    case 13: {
                            message.Name = reader.string();
                            break;
                        }
                    case 14: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 15: {
                            message.UserCoinBefore = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BetData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.BetData} BetData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BetData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BetData message.
             * @function verify
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BetData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.CreatedAt != null && message.hasOwnProperty("CreatedAt"))
                    if (!$util.isInteger(message.CreatedAt))
                        return "CreatedAt: integer expected";
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    if (!$util.isInteger(message.UserId))
                        return "UserId: integer expected";
                if (message.Index != null && message.hasOwnProperty("Index"))
                    if (!$util.isInteger(message.Index))
                        return "Index: integer expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isInteger(message.Round))
                        return "Round: integer expected";
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    if (!$util.isString(message.Seed))
                        return "Seed: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.SellMultiplier != null && message.hasOwnProperty("SellMultiplier"))
                    if (!$util.isString(message.SellMultiplier))
                        return "SellMultiplier: string expected";
                if (message.IsAutoBuy != null && message.hasOwnProperty("IsAutoBuy"))
                    if (typeof message.IsAutoBuy !== "boolean")
                        return "IsAutoBuy: boolean expected";
                if (message.IsAutoSell != null && message.hasOwnProperty("IsAutoSell"))
                    if (typeof message.IsAutoSell !== "boolean")
                        return "IsAutoSell: boolean expected";
                if (message.UserCoin != null && message.hasOwnProperty("UserCoin"))
                    if (!$util.isString(message.UserCoin))
                        return "UserCoin: string expected";
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.IsBot != null && message.hasOwnProperty("IsBot"))
                    if (typeof message.IsBot !== "boolean")
                        return "IsBot: boolean expected";
                if (message.Name != null && message.hasOwnProperty("Name"))
                    if (!$util.isString(message.Name))
                        return "Name: string expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.UserCoinBefore != null && message.hasOwnProperty("UserCoinBefore"))
                    if (!$util.isString(message.UserCoinBefore))
                        return "UserCoinBefore: string expected";
                return null;
            };

            /**
             * Creates a BetData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.BetData} BetData
             */
            BetData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.BetData)
                    return object;
                let message = new $root.Rugbull.Protocols.BetData();
                if (object.CreatedAt != null)
                    message.CreatedAt = object.CreatedAt | 0;
                if (object.UserId != null)
                    message.UserId = object.UserId | 0;
                if (object.Index != null)
                    message.Index = object.Index | 0;
                if (object.Round != null)
                    message.Round = object.Round | 0;
                if (object.Seed != null)
                    message.Seed = String(object.Seed);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.SellMultiplier != null)
                    message.SellMultiplier = String(object.SellMultiplier);
                if (object.IsAutoBuy != null)
                    message.IsAutoBuy = Boolean(object.IsAutoBuy);
                if (object.IsAutoSell != null)
                    message.IsAutoSell = Boolean(object.IsAutoSell);
                if (object.UserCoin != null)
                    message.UserCoin = String(object.UserCoin);
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                if (object.IsBot != null)
                    message.IsBot = Boolean(object.IsBot);
                if (object.Name != null)
                    message.Name = String(object.Name);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.UserCoinBefore != null)
                    message.UserCoinBefore = String(object.UserCoinBefore);
                return message;
            };

            /**
             * Creates a plain object from a BetData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {Rugbull.Protocols.BetData} message BetData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BetData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.CreatedAt = 0;
                    object.UserId = 0;
                    object.Index = 0;
                    object.Round = 0;
                    object.Seed = "";
                    object.Amount = "";
                    object.SellMultiplier = "";
                    object.IsAutoBuy = false;
                    object.IsAutoSell = false;
                    object.UserCoin = "";
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                    object.IsBot = false;
                    object.Name = "";
                    object.PhotoUrl = "";
                    object.UserCoinBefore = "";
                }
                if (message.CreatedAt != null && message.hasOwnProperty("CreatedAt"))
                    object.CreatedAt = message.CreatedAt;
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    object.UserId = message.UserId;
                if (message.Index != null && message.hasOwnProperty("Index"))
                    object.Index = message.Index;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    object.Seed = message.Seed;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.SellMultiplier != null && message.hasOwnProperty("SellMultiplier"))
                    object.SellMultiplier = message.SellMultiplier;
                if (message.IsAutoBuy != null && message.hasOwnProperty("IsAutoBuy"))
                    object.IsAutoBuy = message.IsAutoBuy;
                if (message.IsAutoSell != null && message.hasOwnProperty("IsAutoSell"))
                    object.IsAutoSell = message.IsAutoSell;
                if (message.UserCoin != null && message.hasOwnProperty("UserCoin"))
                    object.UserCoin = message.UserCoin;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                if (message.IsBot != null && message.hasOwnProperty("IsBot"))
                    object.IsBot = message.IsBot;
                if (message.Name != null && message.hasOwnProperty("Name"))
                    object.Name = message.Name;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.UserCoinBefore != null && message.hasOwnProperty("UserCoinBefore"))
                    object.UserCoinBefore = message.UserCoinBefore;
                return object;
            };

            /**
             * Converts this BetData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.BetData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BetData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BetData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.BetData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BetData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.BetData";
            };

            return BetData;
        })();

        Protocols.InProgressData = (function() {

            /**
             * Properties of an InProgressData.
             * @memberof Rugbull.Protocols
             * @interface IInProgressData
             * @property {number|null} [Frame] InProgressData Frame
             * @property {string|null} [Multiplier] InProgressData Multiplier
             */

            /**
             * Constructs a new InProgressData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents an InProgressData.
             * @implements IInProgressData
             * @constructor
             * @param {Rugbull.Protocols.IInProgressData=} [properties] Properties to set
             */
            function InProgressData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InProgressData Frame.
             * @member {number} Frame
             * @memberof Rugbull.Protocols.InProgressData
             * @instance
             */
            InProgressData.prototype.Frame = 0;

            /**
             * InProgressData Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.InProgressData
             * @instance
             */
            InProgressData.prototype.Multiplier = "";

            /**
             * Creates a new InProgressData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Rugbull.Protocols.IInProgressData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.InProgressData} InProgressData instance
             */
            InProgressData.create = function create(properties) {
                return new InProgressData(properties);
            };

            /**
             * Encodes the specified InProgressData message. Does not implicitly {@link Rugbull.Protocols.InProgressData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Rugbull.Protocols.IInProgressData} message InProgressData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InProgressData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Frame != null && Object.hasOwnProperty.call(message, "Frame"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Frame);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Multiplier);
                return writer;
            };

            /**
             * Encodes the specified InProgressData message, length delimited. Does not implicitly {@link Rugbull.Protocols.InProgressData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Rugbull.Protocols.IInProgressData} message InProgressData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InProgressData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InProgressData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.InProgressData} InProgressData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InProgressData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.InProgressData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Frame = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InProgressData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.InProgressData} InProgressData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InProgressData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InProgressData message.
             * @function verify
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InProgressData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Frame != null && message.hasOwnProperty("Frame"))
                    if (!$util.isInteger(message.Frame))
                        return "Frame: integer expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                return null;
            };

            /**
             * Creates an InProgressData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.InProgressData} InProgressData
             */
            InProgressData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.InProgressData)
                    return object;
                let message = new $root.Rugbull.Protocols.InProgressData();
                if (object.Frame != null)
                    message.Frame = object.Frame | 0;
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                return message;
            };

            /**
             * Creates a plain object from an InProgressData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {Rugbull.Protocols.InProgressData} message InProgressData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InProgressData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Frame = 0;
                    object.Multiplier = "";
                }
                if (message.Frame != null && message.hasOwnProperty("Frame"))
                    object.Frame = message.Frame;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                return object;
            };

            /**
             * Converts this InProgressData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.InProgressData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InProgressData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for InProgressData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.InProgressData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InProgressData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.InProgressData";
            };

            return InProgressData;
        })();

        Protocols.FinishedData = (function() {

            /**
             * Properties of a FinishedData.
             * @memberof Rugbull.Protocols
             * @interface IFinishedData
             * @property {number|null} [RemainSeconds] FinishedData RemainSeconds
             * @property {number|null} [TotalSeconds] FinishedData TotalSeconds
             * @property {string|null} [Multiplier] FinishedData Multiplier
             * @property {Array.<Rugbull.Protocols.IRoundUserInfo>|null} [Users] FinishedData Users
             * @property {string|null} [ServerHash] FinishedData ServerHash
             * @property {string|null} [EndTime] FinishedData EndTime
             * @property {number|null} [Rate] FinishedData Rate
             */

            /**
             * Constructs a new FinishedData.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a FinishedData.
             * @implements IFinishedData
             * @constructor
             * @param {Rugbull.Protocols.IFinishedData=} [properties] Properties to set
             */
            function FinishedData(properties) {
                this.Users = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FinishedData RemainSeconds.
             * @member {number} RemainSeconds
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.RemainSeconds = 0;

            /**
             * FinishedData TotalSeconds.
             * @member {number} TotalSeconds
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.TotalSeconds = 0;

            /**
             * FinishedData Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.Multiplier = "";

            /**
             * FinishedData Users.
             * @member {Array.<Rugbull.Protocols.IRoundUserInfo>} Users
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.Users = $util.emptyArray;

            /**
             * FinishedData ServerHash.
             * @member {string} ServerHash
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.ServerHash = "";

            /**
             * FinishedData EndTime.
             * @member {string} EndTime
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.EndTime = "";

            /**
             * FinishedData Rate.
             * @member {number} Rate
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             */
            FinishedData.prototype.Rate = 0;

            /**
             * Creates a new FinishedData instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Rugbull.Protocols.IFinishedData=} [properties] Properties to set
             * @returns {Rugbull.Protocols.FinishedData} FinishedData instance
             */
            FinishedData.create = function create(properties) {
                return new FinishedData(properties);
            };

            /**
             * Encodes the specified FinishedData message. Does not implicitly {@link Rugbull.Protocols.FinishedData.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Rugbull.Protocols.IFinishedData} message FinishedData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinishedData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RemainSeconds != null && Object.hasOwnProperty.call(message, "RemainSeconds"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.RemainSeconds);
                if (message.TotalSeconds != null && Object.hasOwnProperty.call(message, "TotalSeconds"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.TotalSeconds);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Multiplier);
                if (message.Users != null && message.Users.length)
                    for (let i = 0; i < message.Users.length; ++i)
                        $root.Rugbull.Protocols.RoundUserInfo.encode(message.Users[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.ServerHash != null && Object.hasOwnProperty.call(message, "ServerHash"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.ServerHash);
                if (message.EndTime != null && Object.hasOwnProperty.call(message, "EndTime"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.EndTime);
                if (message.Rate != null && Object.hasOwnProperty.call(message, "Rate"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.Rate);
                return writer;
            };

            /**
             * Encodes the specified FinishedData message, length delimited. Does not implicitly {@link Rugbull.Protocols.FinishedData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Rugbull.Protocols.IFinishedData} message FinishedData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinishedData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FinishedData message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.FinishedData} FinishedData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinishedData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.FinishedData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.RemainSeconds = reader.float();
                            break;
                        }
                    case 2: {
                            message.TotalSeconds = reader.float();
                            break;
                        }
                    case 3: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.Users && message.Users.length))
                                message.Users = [];
                            message.Users.push($root.Rugbull.Protocols.RoundUserInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.ServerHash = reader.string();
                            break;
                        }
                    case 6: {
                            message.EndTime = reader.string();
                            break;
                        }
                    case 7: {
                            message.Rate = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FinishedData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.FinishedData} FinishedData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinishedData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FinishedData message.
             * @function verify
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FinishedData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    if (typeof message.RemainSeconds !== "number")
                        return "RemainSeconds: number expected";
                if (message.TotalSeconds != null && message.hasOwnProperty("TotalSeconds"))
                    if (typeof message.TotalSeconds !== "number")
                        return "TotalSeconds: number expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Users != null && message.hasOwnProperty("Users")) {
                    if (!Array.isArray(message.Users))
                        return "Users: array expected";
                    for (let i = 0; i < message.Users.length; ++i) {
                        let error = $root.Rugbull.Protocols.RoundUserInfo.verify(message.Users[i]);
                        if (error)
                            return "Users." + error;
                    }
                }
                if (message.ServerHash != null && message.hasOwnProperty("ServerHash"))
                    if (!$util.isString(message.ServerHash))
                        return "ServerHash: string expected";
                if (message.EndTime != null && message.hasOwnProperty("EndTime"))
                    if (!$util.isString(message.EndTime))
                        return "EndTime: string expected";
                if (message.Rate != null && message.hasOwnProperty("Rate"))
                    if (!$util.isInteger(message.Rate))
                        return "Rate: integer expected";
                return null;
            };

            /**
             * Creates a FinishedData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.FinishedData} FinishedData
             */
            FinishedData.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.FinishedData)
                    return object;
                let message = new $root.Rugbull.Protocols.FinishedData();
                if (object.RemainSeconds != null)
                    message.RemainSeconds = Number(object.RemainSeconds);
                if (object.TotalSeconds != null)
                    message.TotalSeconds = Number(object.TotalSeconds);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Users) {
                    if (!Array.isArray(object.Users))
                        throw TypeError(".Rugbull.Protocols.FinishedData.Users: array expected");
                    message.Users = [];
                    for (let i = 0; i < object.Users.length; ++i) {
                        if (typeof object.Users[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.FinishedData.Users: object expected");
                        message.Users[i] = $root.Rugbull.Protocols.RoundUserInfo.fromObject(object.Users[i]);
                    }
                }
                if (object.ServerHash != null)
                    message.ServerHash = String(object.ServerHash);
                if (object.EndTime != null)
                    message.EndTime = String(object.EndTime);
                if (object.Rate != null)
                    message.Rate = object.Rate | 0;
                return message;
            };

            /**
             * Creates a plain object from a FinishedData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {Rugbull.Protocols.FinishedData} message FinishedData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FinishedData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Users = [];
                if (options.defaults) {
                    object.RemainSeconds = 0;
                    object.TotalSeconds = 0;
                    object.Multiplier = "";
                    object.ServerHash = "";
                    object.EndTime = "";
                    object.Rate = 0;
                }
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    object.RemainSeconds = options.json && !isFinite(message.RemainSeconds) ? String(message.RemainSeconds) : message.RemainSeconds;
                if (message.TotalSeconds != null && message.hasOwnProperty("TotalSeconds"))
                    object.TotalSeconds = options.json && !isFinite(message.TotalSeconds) ? String(message.TotalSeconds) : message.TotalSeconds;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Users && message.Users.length) {
                    object.Users = [];
                    for (let j = 0; j < message.Users.length; ++j)
                        object.Users[j] = $root.Rugbull.Protocols.RoundUserInfo.toObject(message.Users[j], options);
                }
                if (message.ServerHash != null && message.hasOwnProperty("ServerHash"))
                    object.ServerHash = message.ServerHash;
                if (message.EndTime != null && message.hasOwnProperty("EndTime"))
                    object.EndTime = message.EndTime;
                if (message.Rate != null && message.hasOwnProperty("Rate"))
                    object.Rate = message.Rate;
                return object;
            };

            /**
             * Converts this FinishedData to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.FinishedData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FinishedData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FinishedData
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.FinishedData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FinishedData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.FinishedData";
            };

            return FinishedData;
        })();

        Protocols.GameData2 = (function() {

            /**
             * Properties of a GameData2.
             * @memberof Rugbull.Protocols
             * @interface IGameData2
             * @property {Rugbull.Protocols.GameStatus|null} [Status] GameData2 Status
             * @property {string|null} [Round] GameData2 Round
             * @property {number|null} [DeltaTime] GameData2 DeltaTime
             * @property {Rugbull.Protocols.IReadyData|null} [Ready] GameData2 Ready
             * @property {Rugbull.Protocols.IInProgressData|null} [InProgress] GameData2 InProgress
             * @property {Rugbull.Protocols.IFinishedData|null} [Finished] GameData2 Finished
             */

            /**
             * Constructs a new GameData2.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a GameData2.
             * @implements IGameData2
             * @constructor
             * @param {Rugbull.Protocols.IGameData2=} [properties] Properties to set
             */
            function GameData2(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GameData2 Status.
             * @member {Rugbull.Protocols.GameStatus} Status
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.Status = 0;

            /**
             * GameData2 Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.Round = "";

            /**
             * GameData2 DeltaTime.
             * @member {number} DeltaTime
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.DeltaTime = 0;

            /**
             * GameData2 Ready.
             * @member {Rugbull.Protocols.IReadyData|null|undefined} Ready
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.Ready = null;

            /**
             * GameData2 InProgress.
             * @member {Rugbull.Protocols.IInProgressData|null|undefined} InProgress
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.InProgress = null;

            /**
             * GameData2 Finished.
             * @member {Rugbull.Protocols.IFinishedData|null|undefined} Finished
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            GameData2.prototype.Finished = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * GameData2 Data.
             * @member {"Ready"|"InProgress"|"Finished"|undefined} Data
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             */
            Object.defineProperty(GameData2.prototype, "Data", {
                get: $util.oneOfGetter($oneOfFields = ["Ready", "InProgress", "Finished"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GameData2 instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Rugbull.Protocols.IGameData2=} [properties] Properties to set
             * @returns {Rugbull.Protocols.GameData2} GameData2 instance
             */
            GameData2.create = function create(properties) {
                return new GameData2(properties);
            };

            /**
             * Encodes the specified GameData2 message. Does not implicitly {@link Rugbull.Protocols.GameData2.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Rugbull.Protocols.IGameData2} message GameData2 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GameData2.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Round);
                if (message.DeltaTime != null && Object.hasOwnProperty.call(message, "DeltaTime"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.DeltaTime);
                if (message.Ready != null && Object.hasOwnProperty.call(message, "Ready"))
                    $root.Rugbull.Protocols.ReadyData.encode(message.Ready, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.InProgress != null && Object.hasOwnProperty.call(message, "InProgress"))
                    $root.Rugbull.Protocols.InProgressData.encode(message.InProgress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.Finished != null && Object.hasOwnProperty.call(message, "Finished"))
                    $root.Rugbull.Protocols.FinishedData.encode(message.Finished, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GameData2 message, length delimited. Does not implicitly {@link Rugbull.Protocols.GameData2.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Rugbull.Protocols.IGameData2} message GameData2 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GameData2.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GameData2 message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.GameData2} GameData2
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GameData2.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.GameData2();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Status = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Round = reader.string();
                            break;
                        }
                    case 3: {
                            message.DeltaTime = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Ready = $root.Rugbull.Protocols.ReadyData.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.InProgress = $root.Rugbull.Protocols.InProgressData.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.Finished = $root.Rugbull.Protocols.FinishedData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GameData2 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.GameData2} GameData2
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GameData2.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GameData2 message.
             * @function verify
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GameData2.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.Status != null && message.hasOwnProperty("Status"))
                    switch (message.Status) {
                    default:
                        return "Status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.DeltaTime != null && message.hasOwnProperty("DeltaTime"))
                    if (!$util.isInteger(message.DeltaTime))
                        return "DeltaTime: integer expected";
                if (message.Ready != null && message.hasOwnProperty("Ready")) {
                    properties.Data = 1;
                    {
                        let error = $root.Rugbull.Protocols.ReadyData.verify(message.Ready);
                        if (error)
                            return "Ready." + error;
                    }
                }
                if (message.InProgress != null && message.hasOwnProperty("InProgress")) {
                    if (properties.Data === 1)
                        return "Data: multiple values";
                    properties.Data = 1;
                    {
                        let error = $root.Rugbull.Protocols.InProgressData.verify(message.InProgress);
                        if (error)
                            return "InProgress." + error;
                    }
                }
                if (message.Finished != null && message.hasOwnProperty("Finished")) {
                    if (properties.Data === 1)
                        return "Data: multiple values";
                    properties.Data = 1;
                    {
                        let error = $root.Rugbull.Protocols.FinishedData.verify(message.Finished);
                        if (error)
                            return "Finished." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GameData2 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.GameData2} GameData2
             */
            GameData2.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.GameData2)
                    return object;
                let message = new $root.Rugbull.Protocols.GameData2();
                switch (object.Status) {
                default:
                    if (typeof object.Status === "number") {
                        message.Status = object.Status;
                        break;
                    }
                    break;
                case "GameStatus_None":
                case 0:
                    message.Status = 0;
                    break;
                case "GameStatus_Ready":
                case 1:
                    message.Status = 1;
                    break;
                case "GameStatus_Lock":
                case 2:
                    message.Status = 2;
                    break;
                case "GameStatus_InProgress":
                case 3:
                    message.Status = 3;
                    break;
                case "GameStatus_Finished":
                case 4:
                    message.Status = 4;
                    break;
                }
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.DeltaTime != null)
                    message.DeltaTime = object.DeltaTime | 0;
                if (object.Ready != null) {
                    if (typeof object.Ready !== "object")
                        throw TypeError(".Rugbull.Protocols.GameData2.Ready: object expected");
                    message.Ready = $root.Rugbull.Protocols.ReadyData.fromObject(object.Ready);
                }
                if (object.InProgress != null) {
                    if (typeof object.InProgress !== "object")
                        throw TypeError(".Rugbull.Protocols.GameData2.InProgress: object expected");
                    message.InProgress = $root.Rugbull.Protocols.InProgressData.fromObject(object.InProgress);
                }
                if (object.Finished != null) {
                    if (typeof object.Finished !== "object")
                        throw TypeError(".Rugbull.Protocols.GameData2.Finished: object expected");
                    message.Finished = $root.Rugbull.Protocols.FinishedData.fromObject(object.Finished);
                }
                return message;
            };

            /**
             * Creates a plain object from a GameData2 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {Rugbull.Protocols.GameData2} message GameData2
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GameData2.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Status = options.enums === String ? "GameStatus_None" : 0;
                    object.Round = "";
                    object.DeltaTime = 0;
                }
                if (message.Status != null && message.hasOwnProperty("Status"))
                    object.Status = options.enums === String ? $root.Rugbull.Protocols.GameStatus[message.Status] === undefined ? message.Status : $root.Rugbull.Protocols.GameStatus[message.Status] : message.Status;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.DeltaTime != null && message.hasOwnProperty("DeltaTime"))
                    object.DeltaTime = message.DeltaTime;
                if (message.Ready != null && message.hasOwnProperty("Ready")) {
                    object.Ready = $root.Rugbull.Protocols.ReadyData.toObject(message.Ready, options);
                    if (options.oneofs)
                        object.Data = "Ready";
                }
                if (message.InProgress != null && message.hasOwnProperty("InProgress")) {
                    object.InProgress = $root.Rugbull.Protocols.InProgressData.toObject(message.InProgress, options);
                    if (options.oneofs)
                        object.Data = "InProgress";
                }
                if (message.Finished != null && message.hasOwnProperty("Finished")) {
                    object.Finished = $root.Rugbull.Protocols.FinishedData.toObject(message.Finished, options);
                    if (options.oneofs)
                        object.Data = "Finished";
                }
                return object;
            };

            /**
             * Converts this GameData2 to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.GameData2
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GameData2.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GameData2
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.GameData2
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GameData2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.GameData2";
            };

            return GameData2;
        })();

        Protocols.PushUserSell = (function() {

            /**
             * Properties of a PushUserSell.
             * @memberof Rugbull.Protocols
             * @interface IPushUserSell
             * @property {number|null} [UserId] PushUserSell UserId
             * @property {string|null} [Round] PushUserSell Round
             * @property {number|null} [Index] PushUserSell Index
             * @property {string|null} [Multiplier] PushUserSell Multiplier
             * @property {string|null} [Amount] PushUserSell Amount
             */

            /**
             * Constructs a new PushUserSell.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushUserSell.
             * @implements IPushUserSell
             * @constructor
             * @param {Rugbull.Protocols.IPushUserSell=} [properties] Properties to set
             */
            function PushUserSell(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushUserSell UserId.
             * @member {number} UserId
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             */
            PushUserSell.prototype.UserId = 0;

            /**
             * PushUserSell Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             */
            PushUserSell.prototype.Round = "";

            /**
             * PushUserSell Index.
             * @member {number} Index
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             */
            PushUserSell.prototype.Index = 0;

            /**
             * PushUserSell Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             */
            PushUserSell.prototype.Multiplier = "";

            /**
             * PushUserSell Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             */
            PushUserSell.prototype.Amount = "";

            /**
             * Creates a new PushUserSell instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Rugbull.Protocols.IPushUserSell=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushUserSell} PushUserSell instance
             */
            PushUserSell.create = function create(properties) {
                return new PushUserSell(properties);
            };

            /**
             * Encodes the specified PushUserSell message. Does not implicitly {@link Rugbull.Protocols.PushUserSell.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Rugbull.Protocols.IPushUserSell} message PushUserSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUserSell.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserId != null && Object.hasOwnProperty.call(message, "UserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.UserId);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Round);
                if (message.Index != null && Object.hasOwnProperty.call(message, "Index"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Index);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Multiplier);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Amount);
                return writer;
            };

            /**
             * Encodes the specified PushUserSell message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushUserSell.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Rugbull.Protocols.IPushUserSell} message PushUserSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUserSell.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushUserSell message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushUserSell} PushUserSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUserSell.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushUserSell();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Round = reader.string();
                            break;
                        }
                    case 3: {
                            message.Index = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 5: {
                            message.Amount = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushUserSell message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushUserSell} PushUserSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUserSell.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushUserSell message.
             * @function verify
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushUserSell.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    if (!$util.isInteger(message.UserId))
                        return "UserId: integer expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.Index != null && message.hasOwnProperty("Index"))
                    if (!$util.isInteger(message.Index))
                        return "Index: integer expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                return null;
            };

            /**
             * Creates a PushUserSell message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushUserSell} PushUserSell
             */
            PushUserSell.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushUserSell)
                    return object;
                let message = new $root.Rugbull.Protocols.PushUserSell();
                if (object.UserId != null)
                    message.UserId = object.UserId | 0;
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.Index != null)
                    message.Index = object.Index | 0;
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                return message;
            };

            /**
             * Creates a plain object from a PushUserSell message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {Rugbull.Protocols.PushUserSell} message PushUserSell
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushUserSell.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.UserId = 0;
                    object.Round = "";
                    object.Index = 0;
                    object.Multiplier = "";
                    object.Amount = "";
                }
                if (message.UserId != null && message.hasOwnProperty("UserId"))
                    object.UserId = message.UserId;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.Index != null && message.hasOwnProperty("Index"))
                    object.Index = message.Index;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                return object;
            };

            /**
             * Converts this PushUserSell to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushUserSell
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushUserSell.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushUserSell
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushUserSell
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushUserSell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushUserSell";
            };

            return PushUserSell;
        })();

        Protocols.CSBuy = (function() {

            /**
             * Properties of a CSBuy.
             * @memberof Rugbull.Protocols
             * @interface ICSBuy
             * @property {Rugbull.Protocols.IBetData|null} [Data] CSBuy Data
             */

            /**
             * Constructs a new CSBuy.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSBuy.
             * @implements ICSBuy
             * @constructor
             * @param {Rugbull.Protocols.ICSBuy=} [properties] Properties to set
             */
            function CSBuy(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSBuy Data.
             * @member {Rugbull.Protocols.IBetData|null|undefined} Data
             * @memberof Rugbull.Protocols.CSBuy
             * @instance
             */
            CSBuy.prototype.Data = null;

            /**
             * Creates a new CSBuy instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Rugbull.Protocols.ICSBuy=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSBuy} CSBuy instance
             */
            CSBuy.create = function create(properties) {
                return new CSBuy(properties);
            };

            /**
             * Encodes the specified CSBuy message. Does not implicitly {@link Rugbull.Protocols.CSBuy.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Rugbull.Protocols.ICSBuy} message CSBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBuy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Data != null && Object.hasOwnProperty.call(message, "Data"))
                    $root.Rugbull.Protocols.BetData.encode(message.Data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CSBuy message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSBuy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Rugbull.Protocols.ICSBuy} message CSBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSBuy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSBuy message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSBuy} CSBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBuy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSBuy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Data = $root.Rugbull.Protocols.BetData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSBuy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSBuy} CSBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSBuy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSBuy message.
             * @function verify
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSBuy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Data != null && message.hasOwnProperty("Data")) {
                    let error = $root.Rugbull.Protocols.BetData.verify(message.Data);
                    if (error)
                        return "Data." + error;
                }
                return null;
            };

            /**
             * Creates a CSBuy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSBuy} CSBuy
             */
            CSBuy.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSBuy)
                    return object;
                let message = new $root.Rugbull.Protocols.CSBuy();
                if (object.Data != null) {
                    if (typeof object.Data !== "object")
                        throw TypeError(".Rugbull.Protocols.CSBuy.Data: object expected");
                    message.Data = $root.Rugbull.Protocols.BetData.fromObject(object.Data);
                }
                return message;
            };

            /**
             * Creates a plain object from a CSBuy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {Rugbull.Protocols.CSBuy} message CSBuy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSBuy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Data = null;
                if (message.Data != null && message.hasOwnProperty("Data"))
                    object.Data = $root.Rugbull.Protocols.BetData.toObject(message.Data, options);
                return object;
            };

            /**
             * Converts this CSBuy to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSBuy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSBuy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSBuy
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSBuy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSBuy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSBuy";
            };

            return CSBuy;
        })();

        Protocols.SCBuy = (function() {

            /**
             * Properties of a SCBuy.
             * @memberof Rugbull.Protocols
             * @interface ISCBuy
             * @property {Rugbull.Protocols.UserGameStatus|null} [Status] SCBuy Status
             * @property {Rugbull.Protocols.IBetData|null} [Data] SCBuy Data
             */

            /**
             * Constructs a new SCBuy.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCBuy.
             * @implements ISCBuy
             * @constructor
             * @param {Rugbull.Protocols.ISCBuy=} [properties] Properties to set
             */
            function SCBuy(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCBuy Status.
             * @member {Rugbull.Protocols.UserGameStatus} Status
             * @memberof Rugbull.Protocols.SCBuy
             * @instance
             */
            SCBuy.prototype.Status = 0;

            /**
             * SCBuy Data.
             * @member {Rugbull.Protocols.IBetData|null|undefined} Data
             * @memberof Rugbull.Protocols.SCBuy
             * @instance
             */
            SCBuy.prototype.Data = null;

            /**
             * Creates a new SCBuy instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Rugbull.Protocols.ISCBuy=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCBuy} SCBuy instance
             */
            SCBuy.create = function create(properties) {
                return new SCBuy(properties);
            };

            /**
             * Encodes the specified SCBuy message. Does not implicitly {@link Rugbull.Protocols.SCBuy.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Rugbull.Protocols.ISCBuy} message SCBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCBuy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
                if (message.Data != null && Object.hasOwnProperty.call(message, "Data"))
                    $root.Rugbull.Protocols.BetData.encode(message.Data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCBuy message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCBuy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Rugbull.Protocols.ISCBuy} message SCBuy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCBuy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCBuy message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCBuy} SCBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCBuy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCBuy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Status = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Data = $root.Rugbull.Protocols.BetData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCBuy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCBuy} SCBuy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCBuy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCBuy message.
             * @function verify
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCBuy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Status != null && message.hasOwnProperty("Status"))
                    switch (message.Status) {
                    default:
                        return "Status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.Data != null && message.hasOwnProperty("Data")) {
                    let error = $root.Rugbull.Protocols.BetData.verify(message.Data);
                    if (error)
                        return "Data." + error;
                }
                return null;
            };

            /**
             * Creates a SCBuy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCBuy} SCBuy
             */
            SCBuy.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCBuy)
                    return object;
                let message = new $root.Rugbull.Protocols.SCBuy();
                switch (object.Status) {
                default:
                    if (typeof object.Status === "number") {
                        message.Status = object.Status;
                        break;
                    }
                    break;
                case "UserGameStatus_None":
                case 0:
                    message.Status = 0;
                    break;
                case "UserGameStatus_Pendding":
                case 1:
                    message.Status = 1;
                    break;
                case "UserGameStatus_InGame":
                case 2:
                    message.Status = 2;
                    break;
                }
                if (object.Data != null) {
                    if (typeof object.Data !== "object")
                        throw TypeError(".Rugbull.Protocols.SCBuy.Data: object expected");
                    message.Data = $root.Rugbull.Protocols.BetData.fromObject(object.Data);
                }
                return message;
            };

            /**
             * Creates a plain object from a SCBuy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {Rugbull.Protocols.SCBuy} message SCBuy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCBuy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Status = options.enums === String ? "UserGameStatus_None" : 0;
                    object.Data = null;
                }
                if (message.Status != null && message.hasOwnProperty("Status"))
                    object.Status = options.enums === String ? $root.Rugbull.Protocols.UserGameStatus[message.Status] === undefined ? message.Status : $root.Rugbull.Protocols.UserGameStatus[message.Status] : message.Status;
                if (message.Data != null && message.hasOwnProperty("Data"))
                    object.Data = $root.Rugbull.Protocols.BetData.toObject(message.Data, options);
                return object;
            };

            /**
             * Converts this SCBuy to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCBuy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCBuy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCBuy
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCBuy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCBuy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCBuy";
            };

            return SCBuy;
        })();

        Protocols.CSSell = (function() {

            /**
             * Properties of a CSSell.
             * @memberof Rugbull.Protocols
             * @interface ICSSell
             * @property {Rugbull.Protocols.IBetData|null} [Data] CSSell Data
             */

            /**
             * Constructs a new CSSell.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSSell.
             * @implements ICSSell
             * @constructor
             * @param {Rugbull.Protocols.ICSSell=} [properties] Properties to set
             */
            function CSSell(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSSell Data.
             * @member {Rugbull.Protocols.IBetData|null|undefined} Data
             * @memberof Rugbull.Protocols.CSSell
             * @instance
             */
            CSSell.prototype.Data = null;

            /**
             * Creates a new CSSell instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Rugbull.Protocols.ICSSell=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSSell} CSSell instance
             */
            CSSell.create = function create(properties) {
                return new CSSell(properties);
            };

            /**
             * Encodes the specified CSSell message. Does not implicitly {@link Rugbull.Protocols.CSSell.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Rugbull.Protocols.ICSSell} message CSSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSell.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Data != null && Object.hasOwnProperty.call(message, "Data"))
                    $root.Rugbull.Protocols.BetData.encode(message.Data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CSSell message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSSell.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Rugbull.Protocols.ICSSell} message CSSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSell.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSSell message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSSell} CSSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSell.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSSell();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Data = $root.Rugbull.Protocols.BetData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSSell message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSSell} CSSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSell.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSSell message.
             * @function verify
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSSell.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Data != null && message.hasOwnProperty("Data")) {
                    let error = $root.Rugbull.Protocols.BetData.verify(message.Data);
                    if (error)
                        return "Data." + error;
                }
                return null;
            };

            /**
             * Creates a CSSell message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSSell} CSSell
             */
            CSSell.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSSell)
                    return object;
                let message = new $root.Rugbull.Protocols.CSSell();
                if (object.Data != null) {
                    if (typeof object.Data !== "object")
                        throw TypeError(".Rugbull.Protocols.CSSell.Data: object expected");
                    message.Data = $root.Rugbull.Protocols.BetData.fromObject(object.Data);
                }
                return message;
            };

            /**
             * Creates a plain object from a CSSell message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {Rugbull.Protocols.CSSell} message CSSell
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSSell.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Data = null;
                if (message.Data != null && message.hasOwnProperty("Data"))
                    object.Data = $root.Rugbull.Protocols.BetData.toObject(message.Data, options);
                return object;
            };

            /**
             * Converts this CSSell to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSSell
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSSell.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSSell
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSSell
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSSell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSSell";
            };

            return CSSell;
        })();

        Protocols.SCSell = (function() {

            /**
             * Properties of a SCSell.
             * @memberof Rugbull.Protocols
             * @interface ISCSell
             * @property {Rugbull.Protocols.UserGameStatus|null} [Status] SCSell Status
             */

            /**
             * Constructs a new SCSell.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCSell.
             * @implements ISCSell
             * @constructor
             * @param {Rugbull.Protocols.ISCSell=} [properties] Properties to set
             */
            function SCSell(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCSell Status.
             * @member {Rugbull.Protocols.UserGameStatus} Status
             * @memberof Rugbull.Protocols.SCSell
             * @instance
             */
            SCSell.prototype.Status = 0;

            /**
             * Creates a new SCSell instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Rugbull.Protocols.ISCSell=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCSell} SCSell instance
             */
            SCSell.create = function create(properties) {
                return new SCSell(properties);
            };

            /**
             * Encodes the specified SCSell message. Does not implicitly {@link Rugbull.Protocols.SCSell.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Rugbull.Protocols.ISCSell} message SCSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCSell.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
                return writer;
            };

            /**
             * Encodes the specified SCSell message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCSell.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Rugbull.Protocols.ISCSell} message SCSell message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCSell.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCSell message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCSell} SCSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCSell.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCSell();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCSell message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCSell} SCSell
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCSell.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCSell message.
             * @function verify
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCSell.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Status != null && message.hasOwnProperty("Status"))
                    switch (message.Status) {
                    default:
                        return "Status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a SCSell message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCSell} SCSell
             */
            SCSell.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCSell)
                    return object;
                let message = new $root.Rugbull.Protocols.SCSell();
                switch (object.Status) {
                default:
                    if (typeof object.Status === "number") {
                        message.Status = object.Status;
                        break;
                    }
                    break;
                case "UserGameStatus_None":
                case 0:
                    message.Status = 0;
                    break;
                case "UserGameStatus_Pendding":
                case 1:
                    message.Status = 1;
                    break;
                case "UserGameStatus_InGame":
                case 2:
                    message.Status = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a SCSell message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {Rugbull.Protocols.SCSell} message SCSell
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCSell.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Status = options.enums === String ? "UserGameStatus_None" : 0;
                if (message.Status != null && message.hasOwnProperty("Status"))
                    object.Status = options.enums === String ? $root.Rugbull.Protocols.UserGameStatus[message.Status] === undefined ? message.Status : $root.Rugbull.Protocols.UserGameStatus[message.Status] : message.Status;
                return object;
            };

            /**
             * Converts this SCSell to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCSell
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCSell.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCSell
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCSell
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCSell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCSell";
            };

            return SCSell;
        })();

        Protocols.CSUpdateAuto = (function() {

            /**
             * Properties of a CSUpdateAuto.
             * @memberof Rugbull.Protocols
             * @interface ICSUpdateAuto
             * @property {Rugbull.Protocols.IBetData|null} [Data] CSUpdateAuto Data
             */

            /**
             * Constructs a new CSUpdateAuto.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSUpdateAuto.
             * @implements ICSUpdateAuto
             * @constructor
             * @param {Rugbull.Protocols.ICSUpdateAuto=} [properties] Properties to set
             */
            function CSUpdateAuto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSUpdateAuto Data.
             * @member {Rugbull.Protocols.IBetData|null|undefined} Data
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @instance
             */
            CSUpdateAuto.prototype.Data = null;

            /**
             * Creates a new CSUpdateAuto instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ICSUpdateAuto=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSUpdateAuto} CSUpdateAuto instance
             */
            CSUpdateAuto.create = function create(properties) {
                return new CSUpdateAuto(properties);
            };

            /**
             * Encodes the specified CSUpdateAuto message. Does not implicitly {@link Rugbull.Protocols.CSUpdateAuto.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ICSUpdateAuto} message CSUpdateAuto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpdateAuto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Data != null && Object.hasOwnProperty.call(message, "Data"))
                    $root.Rugbull.Protocols.BetData.encode(message.Data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CSUpdateAuto message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSUpdateAuto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ICSUpdateAuto} message CSUpdateAuto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpdateAuto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSUpdateAuto message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSUpdateAuto} CSUpdateAuto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpdateAuto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSUpdateAuto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Data = $root.Rugbull.Protocols.BetData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSUpdateAuto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSUpdateAuto} CSUpdateAuto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpdateAuto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSUpdateAuto message.
             * @function verify
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSUpdateAuto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Data != null && message.hasOwnProperty("Data")) {
                    let error = $root.Rugbull.Protocols.BetData.verify(message.Data);
                    if (error)
                        return "Data." + error;
                }
                return null;
            };

            /**
             * Creates a CSUpdateAuto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSUpdateAuto} CSUpdateAuto
             */
            CSUpdateAuto.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSUpdateAuto)
                    return object;
                let message = new $root.Rugbull.Protocols.CSUpdateAuto();
                if (object.Data != null) {
                    if (typeof object.Data !== "object")
                        throw TypeError(".Rugbull.Protocols.CSUpdateAuto.Data: object expected");
                    message.Data = $root.Rugbull.Protocols.BetData.fromObject(object.Data);
                }
                return message;
            };

            /**
             * Creates a plain object from a CSUpdateAuto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {Rugbull.Protocols.CSUpdateAuto} message CSUpdateAuto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSUpdateAuto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Data = null;
                if (message.Data != null && message.hasOwnProperty("Data"))
                    object.Data = $root.Rugbull.Protocols.BetData.toObject(message.Data, options);
                return object;
            };

            /**
             * Converts this CSUpdateAuto to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSUpdateAuto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSUpdateAuto
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSUpdateAuto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSUpdateAuto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSUpdateAuto";
            };

            return CSUpdateAuto;
        })();

        Protocols.SCUpdateAuto = (function() {

            /**
             * Properties of a SCUpdateAuto.
             * @memberof Rugbull.Protocols
             * @interface ISCUpdateAuto
             * @property {Rugbull.Protocols.UserGameStatus|null} [Status] SCUpdateAuto Status
             */

            /**
             * Constructs a new SCUpdateAuto.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCUpdateAuto.
             * @implements ISCUpdateAuto
             * @constructor
             * @param {Rugbull.Protocols.ISCUpdateAuto=} [properties] Properties to set
             */
            function SCUpdateAuto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCUpdateAuto Status.
             * @member {Rugbull.Protocols.UserGameStatus} Status
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @instance
             */
            SCUpdateAuto.prototype.Status = 0;

            /**
             * Creates a new SCUpdateAuto instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ISCUpdateAuto=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCUpdateAuto} SCUpdateAuto instance
             */
            SCUpdateAuto.create = function create(properties) {
                return new SCUpdateAuto(properties);
            };

            /**
             * Encodes the specified SCUpdateAuto message. Does not implicitly {@link Rugbull.Protocols.SCUpdateAuto.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ISCUpdateAuto} message SCUpdateAuto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCUpdateAuto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
                return writer;
            };

            /**
             * Encodes the specified SCUpdateAuto message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCUpdateAuto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Rugbull.Protocols.ISCUpdateAuto} message SCUpdateAuto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCUpdateAuto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCUpdateAuto message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCUpdateAuto} SCUpdateAuto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCUpdateAuto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCUpdateAuto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCUpdateAuto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCUpdateAuto} SCUpdateAuto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCUpdateAuto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCUpdateAuto message.
             * @function verify
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCUpdateAuto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Status != null && message.hasOwnProperty("Status"))
                    switch (message.Status) {
                    default:
                        return "Status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a SCUpdateAuto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCUpdateAuto} SCUpdateAuto
             */
            SCUpdateAuto.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCUpdateAuto)
                    return object;
                let message = new $root.Rugbull.Protocols.SCUpdateAuto();
                switch (object.Status) {
                default:
                    if (typeof object.Status === "number") {
                        message.Status = object.Status;
                        break;
                    }
                    break;
                case "UserGameStatus_None":
                case 0:
                    message.Status = 0;
                    break;
                case "UserGameStatus_Pendding":
                case 1:
                    message.Status = 1;
                    break;
                case "UserGameStatus_InGame":
                case 2:
                    message.Status = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a SCUpdateAuto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {Rugbull.Protocols.SCUpdateAuto} message SCUpdateAuto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCUpdateAuto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Status = options.enums === String ? "UserGameStatus_None" : 0;
                if (message.Status != null && message.hasOwnProperty("Status"))
                    object.Status = options.enums === String ? $root.Rugbull.Protocols.UserGameStatus[message.Status] === undefined ? message.Status : $root.Rugbull.Protocols.UserGameStatus[message.Status] : message.Status;
                return object;
            };

            /**
             * Converts this SCUpdateAuto to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCUpdateAuto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCUpdateAuto
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCUpdateAuto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCUpdateAuto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCUpdateAuto";
            };

            return SCUpdateAuto;
        })();

        Protocols.RoundUserInfo = (function() {

            /**
             * Properties of a RoundUserInfo.
             * @memberof Rugbull.Protocols
             * @interface IRoundUserInfo
             * @property {string|null} [Name] RoundUserInfo Name
             * @property {string|null} [PhotoUrl] RoundUserInfo PhotoUrl
             * @property {string|null} [Seed] RoundUserInfo Seed
             */

            /**
             * Constructs a new RoundUserInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a RoundUserInfo.
             * @implements IRoundUserInfo
             * @constructor
             * @param {Rugbull.Protocols.IRoundUserInfo=} [properties] Properties to set
             */
            function RoundUserInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RoundUserInfo Name.
             * @member {string} Name
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @instance
             */
            RoundUserInfo.prototype.Name = "";

            /**
             * RoundUserInfo PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @instance
             */
            RoundUserInfo.prototype.PhotoUrl = "";

            /**
             * RoundUserInfo Seed.
             * @member {string} Seed
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @instance
             */
            RoundUserInfo.prototype.Seed = "";

            /**
             * Creates a new RoundUserInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Rugbull.Protocols.IRoundUserInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.RoundUserInfo} RoundUserInfo instance
             */
            RoundUserInfo.create = function create(properties) {
                return new RoundUserInfo(properties);
            };

            /**
             * Encodes the specified RoundUserInfo message. Does not implicitly {@link Rugbull.Protocols.RoundUserInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Rugbull.Protocols.IRoundUserInfo} message RoundUserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoundUserInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Name);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.PhotoUrl);
                if (message.Seed != null && Object.hasOwnProperty.call(message, "Seed"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Seed);
                return writer;
            };

            /**
             * Encodes the specified RoundUserInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.RoundUserInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Rugbull.Protocols.IRoundUserInfo} message RoundUserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoundUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RoundUserInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.RoundUserInfo} RoundUserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoundUserInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.RoundUserInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Name = reader.string();
                            break;
                        }
                    case 2: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 3: {
                            message.Seed = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RoundUserInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.RoundUserInfo} RoundUserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoundUserInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RoundUserInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RoundUserInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Name != null && message.hasOwnProperty("Name"))
                    if (!$util.isString(message.Name))
                        return "Name: string expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    if (!$util.isString(message.Seed))
                        return "Seed: string expected";
                return null;
            };

            /**
             * Creates a RoundUserInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.RoundUserInfo} RoundUserInfo
             */
            RoundUserInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.RoundUserInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.RoundUserInfo();
                if (object.Name != null)
                    message.Name = String(object.Name);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.Seed != null)
                    message.Seed = String(object.Seed);
                return message;
            };

            /**
             * Creates a plain object from a RoundUserInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {Rugbull.Protocols.RoundUserInfo} message RoundUserInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoundUserInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Name = "";
                    object.PhotoUrl = "";
                    object.Seed = "";
                }
                if (message.Name != null && message.hasOwnProperty("Name"))
                    object.Name = message.Name;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.Seed != null && message.hasOwnProperty("Seed"))
                    object.Seed = message.Seed;
                return object;
            };

            /**
             * Converts this RoundUserInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoundUserInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoundUserInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.RoundUserInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoundUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.RoundUserInfo";
            };

            return RoundUserInfo;
        })();

        Protocols.RoundInfo = (function() {

            /**
             * Properties of a RoundInfo.
             * @memberof Rugbull.Protocols
             * @interface IRoundInfo
             * @property {string|null} [Round] RoundInfo Round
             * @property {string|null} [Multiplier] RoundInfo Multiplier
             * @property {string|null} [Time] RoundInfo Time
             * @property {string|null} [ServerSeed] RoundInfo ServerSeed
             * @property {Array.<Rugbull.Protocols.IRoundUserInfo>|null} [Users] RoundInfo Users
             * @property {string|null} [CombinedHash] RoundInfo CombinedHash
             * @property {string|null} [Hex] RoundInfo Hex
             * @property {number|null} [Rate] RoundInfo Rate
             */

            /**
             * Constructs a new RoundInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a RoundInfo.
             * @implements IRoundInfo
             * @constructor
             * @param {Rugbull.Protocols.IRoundInfo=} [properties] Properties to set
             */
            function RoundInfo(properties) {
                this.Users = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RoundInfo Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Round = "";

            /**
             * RoundInfo Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Multiplier = "";

            /**
             * RoundInfo Time.
             * @member {string} Time
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Time = "";

            /**
             * RoundInfo ServerSeed.
             * @member {string} ServerSeed
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.ServerSeed = "";

            /**
             * RoundInfo Users.
             * @member {Array.<Rugbull.Protocols.IRoundUserInfo>} Users
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Users = $util.emptyArray;

            /**
             * RoundInfo CombinedHash.
             * @member {string} CombinedHash
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.CombinedHash = "";

            /**
             * RoundInfo Hex.
             * @member {string} Hex
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Hex = "";

            /**
             * RoundInfo Rate.
             * @member {number} Rate
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             */
            RoundInfo.prototype.Rate = 0;

            /**
             * Creates a new RoundInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Rugbull.Protocols.IRoundInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.RoundInfo} RoundInfo instance
             */
            RoundInfo.create = function create(properties) {
                return new RoundInfo(properties);
            };

            /**
             * Encodes the specified RoundInfo message. Does not implicitly {@link Rugbull.Protocols.RoundInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Rugbull.Protocols.IRoundInfo} message RoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoundInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Round);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Multiplier);
                if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Time);
                if (message.ServerSeed != null && Object.hasOwnProperty.call(message, "ServerSeed"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.ServerSeed);
                if (message.Users != null && message.Users.length)
                    for (let i = 0; i < message.Users.length; ++i)
                        $root.Rugbull.Protocols.RoundUserInfo.encode(message.Users[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.CombinedHash != null && Object.hasOwnProperty.call(message, "CombinedHash"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.CombinedHash);
                if (message.Hex != null && Object.hasOwnProperty.call(message, "Hex"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.Hex);
                if (message.Rate != null && Object.hasOwnProperty.call(message, "Rate"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.Rate);
                return writer;
            };

            /**
             * Encodes the specified RoundInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.RoundInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Rugbull.Protocols.IRoundInfo} message RoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoundInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RoundInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.RoundInfo} RoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoundInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.RoundInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Round = reader.string();
                            break;
                        }
                    case 2: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 3: {
                            message.Time = reader.string();
                            break;
                        }
                    case 4: {
                            message.ServerSeed = reader.string();
                            break;
                        }
                    case 5: {
                            if (!(message.Users && message.Users.length))
                                message.Users = [];
                            message.Users.push($root.Rugbull.Protocols.RoundUserInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.CombinedHash = reader.string();
                            break;
                        }
                    case 7: {
                            message.Hex = reader.string();
                            break;
                        }
                    case 8: {
                            message.Rate = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RoundInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.RoundInfo} RoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoundInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RoundInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RoundInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Time != null && message.hasOwnProperty("Time"))
                    if (!$util.isString(message.Time))
                        return "Time: string expected";
                if (message.ServerSeed != null && message.hasOwnProperty("ServerSeed"))
                    if (!$util.isString(message.ServerSeed))
                        return "ServerSeed: string expected";
                if (message.Users != null && message.hasOwnProperty("Users")) {
                    if (!Array.isArray(message.Users))
                        return "Users: array expected";
                    for (let i = 0; i < message.Users.length; ++i) {
                        let error = $root.Rugbull.Protocols.RoundUserInfo.verify(message.Users[i]);
                        if (error)
                            return "Users." + error;
                    }
                }
                if (message.CombinedHash != null && message.hasOwnProperty("CombinedHash"))
                    if (!$util.isString(message.CombinedHash))
                        return "CombinedHash: string expected";
                if (message.Hex != null && message.hasOwnProperty("Hex"))
                    if (!$util.isString(message.Hex))
                        return "Hex: string expected";
                if (message.Rate != null && message.hasOwnProperty("Rate"))
                    if (!$util.isInteger(message.Rate))
                        return "Rate: integer expected";
                return null;
            };

            /**
             * Creates a RoundInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.RoundInfo} RoundInfo
             */
            RoundInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.RoundInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.RoundInfo();
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Time != null)
                    message.Time = String(object.Time);
                if (object.ServerSeed != null)
                    message.ServerSeed = String(object.ServerSeed);
                if (object.Users) {
                    if (!Array.isArray(object.Users))
                        throw TypeError(".Rugbull.Protocols.RoundInfo.Users: array expected");
                    message.Users = [];
                    for (let i = 0; i < object.Users.length; ++i) {
                        if (typeof object.Users[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.RoundInfo.Users: object expected");
                        message.Users[i] = $root.Rugbull.Protocols.RoundUserInfo.fromObject(object.Users[i]);
                    }
                }
                if (object.CombinedHash != null)
                    message.CombinedHash = String(object.CombinedHash);
                if (object.Hex != null)
                    message.Hex = String(object.Hex);
                if (object.Rate != null)
                    message.Rate = object.Rate | 0;
                return message;
            };

            /**
             * Creates a plain object from a RoundInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {Rugbull.Protocols.RoundInfo} message RoundInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoundInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Users = [];
                if (options.defaults) {
                    object.Round = "";
                    object.Multiplier = "";
                    object.Time = "";
                    object.ServerSeed = "";
                    object.CombinedHash = "";
                    object.Hex = "";
                    object.Rate = 0;
                }
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Time != null && message.hasOwnProperty("Time"))
                    object.Time = message.Time;
                if (message.ServerSeed != null && message.hasOwnProperty("ServerSeed"))
                    object.ServerSeed = message.ServerSeed;
                if (message.Users && message.Users.length) {
                    object.Users = [];
                    for (let j = 0; j < message.Users.length; ++j)
                        object.Users[j] = $root.Rugbull.Protocols.RoundUserInfo.toObject(message.Users[j], options);
                }
                if (message.CombinedHash != null && message.hasOwnProperty("CombinedHash"))
                    object.CombinedHash = message.CombinedHash;
                if (message.Hex != null && message.hasOwnProperty("Hex"))
                    object.Hex = message.Hex;
                if (message.Rate != null && message.hasOwnProperty("Rate"))
                    object.Rate = message.Rate;
                return object;
            };

            /**
             * Converts this RoundInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.RoundInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoundInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoundInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.RoundInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoundInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.RoundInfo";
            };

            return RoundInfo;
        })();

        Protocols.CSGetRoundInfo = (function() {

            /**
             * Properties of a CSGetRoundInfo.
             * @memberof Rugbull.Protocols
             * @interface ICSGetRoundInfo
             * @property {string|null} [Round] CSGetRoundInfo Round
             */

            /**
             * Constructs a new CSGetRoundInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSGetRoundInfo.
             * @implements ICSGetRoundInfo
             * @constructor
             * @param {Rugbull.Protocols.ICSGetRoundInfo=} [properties] Properties to set
             */
            function CSGetRoundInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSGetRoundInfo Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @instance
             */
            CSGetRoundInfo.prototype.Round = "";

            /**
             * Creates a new CSGetRoundInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ICSGetRoundInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSGetRoundInfo} CSGetRoundInfo instance
             */
            CSGetRoundInfo.create = function create(properties) {
                return new CSGetRoundInfo(properties);
            };

            /**
             * Encodes the specified CSGetRoundInfo message. Does not implicitly {@link Rugbull.Protocols.CSGetRoundInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ICSGetRoundInfo} message CSGetRoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetRoundInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Round);
                return writer;
            };

            /**
             * Encodes the specified CSGetRoundInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSGetRoundInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ICSGetRoundInfo} message CSGetRoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetRoundInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSGetRoundInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSGetRoundInfo} CSGetRoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetRoundInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSGetRoundInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Round = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSGetRoundInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSGetRoundInfo} CSGetRoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetRoundInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSGetRoundInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSGetRoundInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                return null;
            };

            /**
             * Creates a CSGetRoundInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSGetRoundInfo} CSGetRoundInfo
             */
            CSGetRoundInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSGetRoundInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.CSGetRoundInfo();
                if (object.Round != null)
                    message.Round = String(object.Round);
                return message;
            };

            /**
             * Creates a plain object from a CSGetRoundInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.CSGetRoundInfo} message CSGetRoundInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSGetRoundInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Round = "";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                return object;
            };

            /**
             * Converts this CSGetRoundInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSGetRoundInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSGetRoundInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSGetRoundInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSGetRoundInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSGetRoundInfo";
            };

            return CSGetRoundInfo;
        })();

        Protocols.SCGetRoundInfo = (function() {

            /**
             * Properties of a SCGetRoundInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCGetRoundInfo
             * @property {Rugbull.Protocols.IRoundInfo|null} [Info] SCGetRoundInfo Info
             */

            /**
             * Constructs a new SCGetRoundInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCGetRoundInfo.
             * @implements ISCGetRoundInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCGetRoundInfo=} [properties] Properties to set
             */
            function SCGetRoundInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCGetRoundInfo Info.
             * @member {Rugbull.Protocols.IRoundInfo|null|undefined} Info
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @instance
             */
            SCGetRoundInfo.prototype.Info = null;

            /**
             * Creates a new SCGetRoundInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ISCGetRoundInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCGetRoundInfo} SCGetRoundInfo instance
             */
            SCGetRoundInfo.create = function create(properties) {
                return new SCGetRoundInfo(properties);
            };

            /**
             * Encodes the specified SCGetRoundInfo message. Does not implicitly {@link Rugbull.Protocols.SCGetRoundInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ISCGetRoundInfo} message SCGetRoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetRoundInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Info != null && Object.hasOwnProperty.call(message, "Info"))
                    $root.Rugbull.Protocols.RoundInfo.encode(message.Info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCGetRoundInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCGetRoundInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.ISCGetRoundInfo} message SCGetRoundInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetRoundInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCGetRoundInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCGetRoundInfo} SCGetRoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetRoundInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCGetRoundInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Info = $root.Rugbull.Protocols.RoundInfo.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCGetRoundInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCGetRoundInfo} SCGetRoundInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetRoundInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCGetRoundInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCGetRoundInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Info != null && message.hasOwnProperty("Info")) {
                    let error = $root.Rugbull.Protocols.RoundInfo.verify(message.Info);
                    if (error)
                        return "Info." + error;
                }
                return null;
            };

            /**
             * Creates a SCGetRoundInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCGetRoundInfo} SCGetRoundInfo
             */
            SCGetRoundInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCGetRoundInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCGetRoundInfo();
                if (object.Info != null) {
                    if (typeof object.Info !== "object")
                        throw TypeError(".Rugbull.Protocols.SCGetRoundInfo.Info: object expected");
                    message.Info = $root.Rugbull.Protocols.RoundInfo.fromObject(object.Info);
                }
                return message;
            };

            /**
             * Creates a plain object from a SCGetRoundInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {Rugbull.Protocols.SCGetRoundInfo} message SCGetRoundInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCGetRoundInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Info = null;
                if (message.Info != null && message.hasOwnProperty("Info"))
                    object.Info = $root.Rugbull.Protocols.RoundInfo.toObject(message.Info, options);
                return object;
            };

            /**
             * Converts this SCGetRoundInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCGetRoundInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCGetRoundInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCGetRoundInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCGetRoundInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCGetRoundInfo";
            };

            return SCGetRoundInfo;
        })();

        Protocols.CSInvitedBy = (function() {

            /**
             * Properties of a CSInvitedBy.
             * @memberof Rugbull.Protocols
             * @interface ICSInvitedBy
             * @property {string|null} [InviteCode] CSInvitedBy InviteCode
             */

            /**
             * Constructs a new CSInvitedBy.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSInvitedBy.
             * @implements ICSInvitedBy
             * @constructor
             * @param {Rugbull.Protocols.ICSInvitedBy=} [properties] Properties to set
             */
            function CSInvitedBy(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSInvitedBy InviteCode.
             * @member {string} InviteCode
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @instance
             */
            CSInvitedBy.prototype.InviteCode = "";

            /**
             * Creates a new CSInvitedBy instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Rugbull.Protocols.ICSInvitedBy=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSInvitedBy} CSInvitedBy instance
             */
            CSInvitedBy.create = function create(properties) {
                return new CSInvitedBy(properties);
            };

            /**
             * Encodes the specified CSInvitedBy message. Does not implicitly {@link Rugbull.Protocols.CSInvitedBy.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Rugbull.Protocols.ICSInvitedBy} message CSInvitedBy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSInvitedBy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.InviteCode != null && Object.hasOwnProperty.call(message, "InviteCode"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.InviteCode);
                return writer;
            };

            /**
             * Encodes the specified CSInvitedBy message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSInvitedBy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Rugbull.Protocols.ICSInvitedBy} message CSInvitedBy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSInvitedBy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSInvitedBy message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSInvitedBy} CSInvitedBy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSInvitedBy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSInvitedBy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.InviteCode = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSInvitedBy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSInvitedBy} CSInvitedBy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSInvitedBy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSInvitedBy message.
             * @function verify
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSInvitedBy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    if (!$util.isString(message.InviteCode))
                        return "InviteCode: string expected";
                return null;
            };

            /**
             * Creates a CSInvitedBy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSInvitedBy} CSInvitedBy
             */
            CSInvitedBy.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSInvitedBy)
                    return object;
                let message = new $root.Rugbull.Protocols.CSInvitedBy();
                if (object.InviteCode != null)
                    message.InviteCode = String(object.InviteCode);
                return message;
            };

            /**
             * Creates a plain object from a CSInvitedBy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {Rugbull.Protocols.CSInvitedBy} message CSInvitedBy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSInvitedBy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.InviteCode = "";
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    object.InviteCode = message.InviteCode;
                return object;
            };

            /**
             * Converts this CSInvitedBy to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSInvitedBy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSInvitedBy
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSInvitedBy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSInvitedBy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSInvitedBy";
            };

            return CSInvitedBy;
        })();

        Protocols.PushInviteStep = (function() {

            /**
             * Properties of a PushInviteStep.
             * @memberof Rugbull.Protocols
             * @interface IPushInviteStep
             * @property {number|null} [ConfIdx] PushInviteStep ConfIdx
             * @property {number|null} [FinishCount] PushInviteStep FinishCount
             * @property {number|null} [RemainSeconds] PushInviteStep RemainSeconds
             * @property {boolean|null} [CanClaim] PushInviteStep CanClaim
             */

            /**
             * Constructs a new PushInviteStep.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushInviteStep.
             * @implements IPushInviteStep
             * @constructor
             * @param {Rugbull.Protocols.IPushInviteStep=} [properties] Properties to set
             */
            function PushInviteStep(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushInviteStep ConfIdx.
             * @member {number} ConfIdx
             * @memberof Rugbull.Protocols.PushInviteStep
             * @instance
             */
            PushInviteStep.prototype.ConfIdx = 0;

            /**
             * PushInviteStep FinishCount.
             * @member {number} FinishCount
             * @memberof Rugbull.Protocols.PushInviteStep
             * @instance
             */
            PushInviteStep.prototype.FinishCount = 0;

            /**
             * PushInviteStep RemainSeconds.
             * @member {number} RemainSeconds
             * @memberof Rugbull.Protocols.PushInviteStep
             * @instance
             */
            PushInviteStep.prototype.RemainSeconds = 0;

            /**
             * PushInviteStep CanClaim.
             * @member {boolean} CanClaim
             * @memberof Rugbull.Protocols.PushInviteStep
             * @instance
             */
            PushInviteStep.prototype.CanClaim = false;

            /**
             * Creates a new PushInviteStep instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Rugbull.Protocols.IPushInviteStep=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushInviteStep} PushInviteStep instance
             */
            PushInviteStep.create = function create(properties) {
                return new PushInviteStep(properties);
            };

            /**
             * Encodes the specified PushInviteStep message. Does not implicitly {@link Rugbull.Protocols.PushInviteStep.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Rugbull.Protocols.IPushInviteStep} message PushInviteStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushInviteStep.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ConfIdx != null && Object.hasOwnProperty.call(message, "ConfIdx"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ConfIdx);
                if (message.FinishCount != null && Object.hasOwnProperty.call(message, "FinishCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.FinishCount);
                if (message.RemainSeconds != null && Object.hasOwnProperty.call(message, "RemainSeconds"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.RemainSeconds);
                if (message.CanClaim != null && Object.hasOwnProperty.call(message, "CanClaim"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.CanClaim);
                return writer;
            };

            /**
             * Encodes the specified PushInviteStep message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushInviteStep.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Rugbull.Protocols.IPushInviteStep} message PushInviteStep message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushInviteStep.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushInviteStep message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushInviteStep} PushInviteStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushInviteStep.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushInviteStep();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ConfIdx = reader.int32();
                            break;
                        }
                    case 2: {
                            message.FinishCount = reader.int32();
                            break;
                        }
                    case 3: {
                            message.RemainSeconds = reader.int32();
                            break;
                        }
                    case 4: {
                            message.CanClaim = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushInviteStep message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushInviteStep} PushInviteStep
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushInviteStep.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushInviteStep message.
             * @function verify
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushInviteStep.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ConfIdx != null && message.hasOwnProperty("ConfIdx"))
                    if (!$util.isInteger(message.ConfIdx))
                        return "ConfIdx: integer expected";
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    if (!$util.isInteger(message.FinishCount))
                        return "FinishCount: integer expected";
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    if (!$util.isInteger(message.RemainSeconds))
                        return "RemainSeconds: integer expected";
                if (message.CanClaim != null && message.hasOwnProperty("CanClaim"))
                    if (typeof message.CanClaim !== "boolean")
                        return "CanClaim: boolean expected";
                return null;
            };

            /**
             * Creates a PushInviteStep message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushInviteStep} PushInviteStep
             */
            PushInviteStep.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushInviteStep)
                    return object;
                let message = new $root.Rugbull.Protocols.PushInviteStep();
                if (object.ConfIdx != null)
                    message.ConfIdx = object.ConfIdx | 0;
                if (object.FinishCount != null)
                    message.FinishCount = object.FinishCount | 0;
                if (object.RemainSeconds != null)
                    message.RemainSeconds = object.RemainSeconds | 0;
                if (object.CanClaim != null)
                    message.CanClaim = Boolean(object.CanClaim);
                return message;
            };

            /**
             * Creates a plain object from a PushInviteStep message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {Rugbull.Protocols.PushInviteStep} message PushInviteStep
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushInviteStep.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.ConfIdx = 0;
                    object.FinishCount = 0;
                    object.RemainSeconds = 0;
                    object.CanClaim = false;
                }
                if (message.ConfIdx != null && message.hasOwnProperty("ConfIdx"))
                    object.ConfIdx = message.ConfIdx;
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    object.FinishCount = message.FinishCount;
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    object.RemainSeconds = message.RemainSeconds;
                if (message.CanClaim != null && message.hasOwnProperty("CanClaim"))
                    object.CanClaim = message.CanClaim;
                return object;
            };

            /**
             * Converts this PushInviteStep to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushInviteStep
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushInviteStep.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushInviteStep
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushInviteStep
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushInviteStep.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushInviteStep";
            };

            return PushInviteStep;
        })();

        Protocols.CSLogin = (function() {

            /**
             * Properties of a CSLogin.
             * @memberof Rugbull.Protocols
             * @interface ICSLogin
             * @property {string|null} [InitData] CSLogin InitData
             * @property {Rugbull.Protocols.IUserData|null} [User] CSLogin User
             * @property {string|null} [UtmSource] CSLogin UtmSource
             */

            /**
             * Constructs a new CSLogin.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSLogin.
             * @implements ICSLogin
             * @constructor
             * @param {Rugbull.Protocols.ICSLogin=} [properties] Properties to set
             */
            function CSLogin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSLogin InitData.
             * @member {string} InitData
             * @memberof Rugbull.Protocols.CSLogin
             * @instance
             */
            CSLogin.prototype.InitData = "";

            /**
             * CSLogin User.
             * @member {Rugbull.Protocols.IUserData|null|undefined} User
             * @memberof Rugbull.Protocols.CSLogin
             * @instance
             */
            CSLogin.prototype.User = null;

            /**
             * CSLogin UtmSource.
             * @member {string} UtmSource
             * @memberof Rugbull.Protocols.CSLogin
             * @instance
             */
            CSLogin.prototype.UtmSource = "";

            /**
             * Creates a new CSLogin instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Rugbull.Protocols.ICSLogin=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSLogin} CSLogin instance
             */
            CSLogin.create = function create(properties) {
                return new CSLogin(properties);
            };

            /**
             * Encodes the specified CSLogin message. Does not implicitly {@link Rugbull.Protocols.CSLogin.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Rugbull.Protocols.ICSLogin} message CSLogin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLogin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.InitData != null && Object.hasOwnProperty.call(message, "InitData"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.InitData);
                if (message.User != null && Object.hasOwnProperty.call(message, "User"))
                    $root.Rugbull.Protocols.UserData.encode(message.User, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.UtmSource != null && Object.hasOwnProperty.call(message, "UtmSource"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.UtmSource);
                return writer;
            };

            /**
             * Encodes the specified CSLogin message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSLogin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Rugbull.Protocols.ICSLogin} message CSLogin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLogin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSLogin message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSLogin} CSLogin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLogin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSLogin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.InitData = reader.string();
                            break;
                        }
                    case 2: {
                            message.User = $root.Rugbull.Protocols.UserData.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.UtmSource = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSLogin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSLogin} CSLogin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLogin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSLogin message.
             * @function verify
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSLogin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.InitData != null && message.hasOwnProperty("InitData"))
                    if (!$util.isString(message.InitData))
                        return "InitData: string expected";
                if (message.User != null && message.hasOwnProperty("User")) {
                    let error = $root.Rugbull.Protocols.UserData.verify(message.User);
                    if (error)
                        return "User." + error;
                }
                if (message.UtmSource != null && message.hasOwnProperty("UtmSource"))
                    if (!$util.isString(message.UtmSource))
                        return "UtmSource: string expected";
                return null;
            };

            /**
             * Creates a CSLogin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSLogin} CSLogin
             */
            CSLogin.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSLogin)
                    return object;
                let message = new $root.Rugbull.Protocols.CSLogin();
                if (object.InitData != null)
                    message.InitData = String(object.InitData);
                if (object.User != null) {
                    if (typeof object.User !== "object")
                        throw TypeError(".Rugbull.Protocols.CSLogin.User: object expected");
                    message.User = $root.Rugbull.Protocols.UserData.fromObject(object.User);
                }
                if (object.UtmSource != null)
                    message.UtmSource = String(object.UtmSource);
                return message;
            };

            /**
             * Creates a plain object from a CSLogin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {Rugbull.Protocols.CSLogin} message CSLogin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSLogin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.InitData = "";
                    object.User = null;
                    object.UtmSource = "";
                }
                if (message.InitData != null && message.hasOwnProperty("InitData"))
                    object.InitData = message.InitData;
                if (message.User != null && message.hasOwnProperty("User"))
                    object.User = $root.Rugbull.Protocols.UserData.toObject(message.User, options);
                if (message.UtmSource != null && message.hasOwnProperty("UtmSource"))
                    object.UtmSource = message.UtmSource;
                return object;
            };

            /**
             * Converts this CSLogin to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSLogin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSLogin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSLogin
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSLogin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSLogin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSLogin";
            };

            return CSLogin;
        })();

        Protocols.SCLogin = (function() {

            /**
             * Properties of a SCLogin.
             * @memberof Rugbull.Protocols
             * @interface ISCLogin
             * @property {Rugbull.Protocols.IUserData|null} [User] SCLogin User
             * @property {Rugbull.Protocols.IUserData|null} [InvitedByUser] SCLogin InvitedByUser
             * @property {string|null} [InviteCode] SCLogin InviteCode
             * @property {string|null} [InviteUrl] SCLogin InviteUrl
             * @property {number|null} [LastOfflineIncome] SCLogin LastOfflineIncome
             * @property {number|null} [TeamId] SCLogin TeamId
             * @property {number|null} [FriendCount] SCLogin FriendCount
             * @property {string|null} [CopyUrl] SCLogin CopyUrl
             * @property {Rugbull.Protocols.UserState|null} [UserState] SCLogin UserState
             * @property {boolean|null} [Deposit] SCLogin Deposit
             * @property {Rugbull.Protocols.IRewardInfo|null} [RewardInfo] SCLogin RewardInfo
             */

            /**
             * Constructs a new SCLogin.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCLogin.
             * @implements ISCLogin
             * @constructor
             * @param {Rugbull.Protocols.ISCLogin=} [properties] Properties to set
             */
            function SCLogin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCLogin User.
             * @member {Rugbull.Protocols.IUserData|null|undefined} User
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.User = null;

            /**
             * SCLogin InvitedByUser.
             * @member {Rugbull.Protocols.IUserData|null|undefined} InvitedByUser
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.InvitedByUser = null;

            /**
             * SCLogin InviteCode.
             * @member {string} InviteCode
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.InviteCode = "";

            /**
             * SCLogin InviteUrl.
             * @member {string} InviteUrl
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.InviteUrl = "";

            /**
             * SCLogin LastOfflineIncome.
             * @member {number} LastOfflineIncome
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.LastOfflineIncome = 0;

            /**
             * SCLogin TeamId.
             * @member {number} TeamId
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.TeamId = 0;

            /**
             * SCLogin FriendCount.
             * @member {number|null|undefined} FriendCount
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.FriendCount = null;

            /**
             * SCLogin CopyUrl.
             * @member {string} CopyUrl
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.CopyUrl = "";

            /**
             * SCLogin UserState.
             * @member {Rugbull.Protocols.UserState|null|undefined} UserState
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.UserState = null;

            /**
             * SCLogin Deposit.
             * @member {boolean|null|undefined} Deposit
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.Deposit = null;

            /**
             * SCLogin RewardInfo.
             * @member {Rugbull.Protocols.IRewardInfo|null|undefined} RewardInfo
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            SCLogin.prototype.RewardInfo = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * SCLogin _FriendCount.
             * @member {"FriendCount"|undefined} _FriendCount
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            Object.defineProperty(SCLogin.prototype, "_FriendCount", {
                get: $util.oneOfGetter($oneOfFields = ["FriendCount"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCLogin _UserState.
             * @member {"UserState"|undefined} _UserState
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            Object.defineProperty(SCLogin.prototype, "_UserState", {
                get: $util.oneOfGetter($oneOfFields = ["UserState"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SCLogin _Deposit.
             * @member {"Deposit"|undefined} _Deposit
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             */
            Object.defineProperty(SCLogin.prototype, "_Deposit", {
                get: $util.oneOfGetter($oneOfFields = ["Deposit"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SCLogin instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Rugbull.Protocols.ISCLogin=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCLogin} SCLogin instance
             */
            SCLogin.create = function create(properties) {
                return new SCLogin(properties);
            };

            /**
             * Encodes the specified SCLogin message. Does not implicitly {@link Rugbull.Protocols.SCLogin.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Rugbull.Protocols.ISCLogin} message SCLogin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCLogin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.User != null && Object.hasOwnProperty.call(message, "User"))
                    $root.Rugbull.Protocols.UserData.encode(message.User, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.InvitedByUser != null && Object.hasOwnProperty.call(message, "InvitedByUser"))
                    $root.Rugbull.Protocols.UserData.encode(message.InvitedByUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.InviteCode != null && Object.hasOwnProperty.call(message, "InviteCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.InviteCode);
                if (message.InviteUrl != null && Object.hasOwnProperty.call(message, "InviteUrl"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.InviteUrl);
                if (message.LastOfflineIncome != null && Object.hasOwnProperty.call(message, "LastOfflineIncome"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.LastOfflineIncome);
                if (message.TeamId != null && Object.hasOwnProperty.call(message, "TeamId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.TeamId);
                if (message.FriendCount != null && Object.hasOwnProperty.call(message, "FriendCount"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.FriendCount);
                if (message.CopyUrl != null && Object.hasOwnProperty.call(message, "CopyUrl"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.CopyUrl);
                if (message.UserState != null && Object.hasOwnProperty.call(message, "UserState"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.UserState);
                if (message.Deposit != null && Object.hasOwnProperty.call(message, "Deposit"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.Deposit);
                if (message.RewardInfo != null && Object.hasOwnProperty.call(message, "RewardInfo"))
                    $root.Rugbull.Protocols.RewardInfo.encode(message.RewardInfo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCLogin message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCLogin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Rugbull.Protocols.ISCLogin} message SCLogin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCLogin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCLogin message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCLogin} SCLogin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCLogin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCLogin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.User = $root.Rugbull.Protocols.UserData.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.InvitedByUser = $root.Rugbull.Protocols.UserData.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.InviteCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.InviteUrl = reader.string();
                            break;
                        }
                    case 5: {
                            message.LastOfflineIncome = reader.int32();
                            break;
                        }
                    case 6: {
                            message.TeamId = reader.int32();
                            break;
                        }
                    case 7: {
                            message.FriendCount = reader.int32();
                            break;
                        }
                    case 8: {
                            message.CopyUrl = reader.string();
                            break;
                        }
                    case 9: {
                            message.UserState = reader.int32();
                            break;
                        }
                    case 10: {
                            message.Deposit = reader.bool();
                            break;
                        }
                    case 11: {
                            message.RewardInfo = $root.Rugbull.Protocols.RewardInfo.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCLogin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCLogin} SCLogin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCLogin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCLogin message.
             * @function verify
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCLogin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.User != null && message.hasOwnProperty("User")) {
                    let error = $root.Rugbull.Protocols.UserData.verify(message.User);
                    if (error)
                        return "User." + error;
                }
                if (message.InvitedByUser != null && message.hasOwnProperty("InvitedByUser")) {
                    let error = $root.Rugbull.Protocols.UserData.verify(message.InvitedByUser);
                    if (error)
                        return "InvitedByUser." + error;
                }
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    if (!$util.isString(message.InviteCode))
                        return "InviteCode: string expected";
                if (message.InviteUrl != null && message.hasOwnProperty("InviteUrl"))
                    if (!$util.isString(message.InviteUrl))
                        return "InviteUrl: string expected";
                if (message.LastOfflineIncome != null && message.hasOwnProperty("LastOfflineIncome"))
                    if (!$util.isInteger(message.LastOfflineIncome))
                        return "LastOfflineIncome: integer expected";
                if (message.TeamId != null && message.hasOwnProperty("TeamId"))
                    if (!$util.isInteger(message.TeamId))
                        return "TeamId: integer expected";
                if (message.FriendCount != null && message.hasOwnProperty("FriendCount")) {
                    properties._FriendCount = 1;
                    if (!$util.isInteger(message.FriendCount))
                        return "FriendCount: integer expected";
                }
                if (message.CopyUrl != null && message.hasOwnProperty("CopyUrl"))
                    if (!$util.isString(message.CopyUrl))
                        return "CopyUrl: string expected";
                if (message.UserState != null && message.hasOwnProperty("UserState")) {
                    properties._UserState = 1;
                    switch (message.UserState) {
                    default:
                        return "UserState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                if (message.Deposit != null && message.hasOwnProperty("Deposit")) {
                    properties._Deposit = 1;
                    if (typeof message.Deposit !== "boolean")
                        return "Deposit: boolean expected";
                }
                if (message.RewardInfo != null && message.hasOwnProperty("RewardInfo")) {
                    let error = $root.Rugbull.Protocols.RewardInfo.verify(message.RewardInfo);
                    if (error)
                        return "RewardInfo." + error;
                }
                return null;
            };

            /**
             * Creates a SCLogin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCLogin} SCLogin
             */
            SCLogin.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCLogin)
                    return object;
                let message = new $root.Rugbull.Protocols.SCLogin();
                if (object.User != null) {
                    if (typeof object.User !== "object")
                        throw TypeError(".Rugbull.Protocols.SCLogin.User: object expected");
                    message.User = $root.Rugbull.Protocols.UserData.fromObject(object.User);
                }
                if (object.InvitedByUser != null) {
                    if (typeof object.InvitedByUser !== "object")
                        throw TypeError(".Rugbull.Protocols.SCLogin.InvitedByUser: object expected");
                    message.InvitedByUser = $root.Rugbull.Protocols.UserData.fromObject(object.InvitedByUser);
                }
                if (object.InviteCode != null)
                    message.InviteCode = String(object.InviteCode);
                if (object.InviteUrl != null)
                    message.InviteUrl = String(object.InviteUrl);
                if (object.LastOfflineIncome != null)
                    message.LastOfflineIncome = object.LastOfflineIncome | 0;
                if (object.TeamId != null)
                    message.TeamId = object.TeamId | 0;
                if (object.FriendCount != null)
                    message.FriendCount = object.FriendCount | 0;
                if (object.CopyUrl != null)
                    message.CopyUrl = String(object.CopyUrl);
                switch (object.UserState) {
                default:
                    if (typeof object.UserState === "number") {
                        message.UserState = object.UserState;
                        break;
                    }
                    break;
                case "FirstTime":
                case 0:
                    message.UserState = 0;
                    break;
                case "OldBack":
                case 1:
                    message.UserState = 1;
                    break;
                case "NormalLogin":
                case 2:
                    message.UserState = 2;
                    break;
                }
                if (object.Deposit != null)
                    message.Deposit = Boolean(object.Deposit);
                if (object.RewardInfo != null) {
                    if (typeof object.RewardInfo !== "object")
                        throw TypeError(".Rugbull.Protocols.SCLogin.RewardInfo: object expected");
                    message.RewardInfo = $root.Rugbull.Protocols.RewardInfo.fromObject(object.RewardInfo);
                }
                return message;
            };

            /**
             * Creates a plain object from a SCLogin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {Rugbull.Protocols.SCLogin} message SCLogin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCLogin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.User = null;
                    object.InvitedByUser = null;
                    object.InviteCode = "";
                    object.InviteUrl = "";
                    object.LastOfflineIncome = 0;
                    object.TeamId = 0;
                    object.CopyUrl = "";
                    object.RewardInfo = null;
                }
                if (message.User != null && message.hasOwnProperty("User"))
                    object.User = $root.Rugbull.Protocols.UserData.toObject(message.User, options);
                if (message.InvitedByUser != null && message.hasOwnProperty("InvitedByUser"))
                    object.InvitedByUser = $root.Rugbull.Protocols.UserData.toObject(message.InvitedByUser, options);
                if (message.InviteCode != null && message.hasOwnProperty("InviteCode"))
                    object.InviteCode = message.InviteCode;
                if (message.InviteUrl != null && message.hasOwnProperty("InviteUrl"))
                    object.InviteUrl = message.InviteUrl;
                if (message.LastOfflineIncome != null && message.hasOwnProperty("LastOfflineIncome"))
                    object.LastOfflineIncome = message.LastOfflineIncome;
                if (message.TeamId != null && message.hasOwnProperty("TeamId"))
                    object.TeamId = message.TeamId;
                if (message.FriendCount != null && message.hasOwnProperty("FriendCount")) {
                    object.FriendCount = message.FriendCount;
                    if (options.oneofs)
                        object._FriendCount = "FriendCount";
                }
                if (message.CopyUrl != null && message.hasOwnProperty("CopyUrl"))
                    object.CopyUrl = message.CopyUrl;
                if (message.UserState != null && message.hasOwnProperty("UserState")) {
                    object.UserState = options.enums === String ? $root.Rugbull.Protocols.UserState[message.UserState] === undefined ? message.UserState : $root.Rugbull.Protocols.UserState[message.UserState] : message.UserState;
                    if (options.oneofs)
                        object._UserState = "UserState";
                }
                if (message.Deposit != null && message.hasOwnProperty("Deposit")) {
                    object.Deposit = message.Deposit;
                    if (options.oneofs)
                        object._Deposit = "Deposit";
                }
                if (message.RewardInfo != null && message.hasOwnProperty("RewardInfo"))
                    object.RewardInfo = $root.Rugbull.Protocols.RewardInfo.toObject(message.RewardInfo, options);
                return object;
            };

            /**
             * Converts this SCLogin to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCLogin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCLogin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCLogin
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCLogin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCLogin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCLogin";
            };

            return SCLogin;
        })();

        Protocols.PushUser = (function() {

            /**
             * Properties of a PushUser.
             * @memberof Rugbull.Protocols
             * @interface IPushUser
             * @property {Rugbull.Protocols.IUserData|null} [User] PushUser User
             */

            /**
             * Constructs a new PushUser.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushUser.
             * @implements IPushUser
             * @constructor
             * @param {Rugbull.Protocols.IPushUser=} [properties] Properties to set
             */
            function PushUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushUser User.
             * @member {Rugbull.Protocols.IUserData|null|undefined} User
             * @memberof Rugbull.Protocols.PushUser
             * @instance
             */
            PushUser.prototype.User = null;

            /**
             * Creates a new PushUser instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Rugbull.Protocols.IPushUser=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushUser} PushUser instance
             */
            PushUser.create = function create(properties) {
                return new PushUser(properties);
            };

            /**
             * Encodes the specified PushUser message. Does not implicitly {@link Rugbull.Protocols.PushUser.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Rugbull.Protocols.IPushUser} message PushUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.User != null && Object.hasOwnProperty.call(message, "User"))
                    $root.Rugbull.Protocols.UserData.encode(message.User, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PushUser message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Rugbull.Protocols.IPushUser} message PushUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushUser message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushUser} PushUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.User = $root.Rugbull.Protocols.UserData.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushUser} PushUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushUser message.
             * @function verify
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.User != null && message.hasOwnProperty("User")) {
                    let error = $root.Rugbull.Protocols.UserData.verify(message.User);
                    if (error)
                        return "User." + error;
                }
                return null;
            };

            /**
             * Creates a PushUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushUser} PushUser
             */
            PushUser.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushUser)
                    return object;
                let message = new $root.Rugbull.Protocols.PushUser();
                if (object.User != null) {
                    if (typeof object.User !== "object")
                        throw TypeError(".Rugbull.Protocols.PushUser.User: object expected");
                    message.User = $root.Rugbull.Protocols.UserData.fromObject(object.User);
                }
                return message;
            };

            /**
             * Creates a plain object from a PushUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {Rugbull.Protocols.PushUser} message PushUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.User = null;
                if (message.User != null && message.hasOwnProperty("User"))
                    object.User = $root.Rugbull.Protocols.UserData.toObject(message.User, options);
                return object;
            };

            /**
             * Converts this PushUser to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushUser
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushUser
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushUser";
            };

            return PushUser;
        })();

        Protocols.RewardInfo = (function() {

            /**
             * Properties of a RewardInfo.
             * @memberof Rugbull.Protocols
             * @interface IRewardInfo
             * @property {string|null} [MaxMultiplier] RewardInfo MaxMultiplier
             * @property {string|null} [InvitePerReward] RewardInfo InvitePerReward
             * @property {string|null} [NewUserReward] RewardInfo NewUserReward
             */

            /**
             * Constructs a new RewardInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a RewardInfo.
             * @implements IRewardInfo
             * @constructor
             * @param {Rugbull.Protocols.IRewardInfo=} [properties] Properties to set
             */
            function RewardInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RewardInfo MaxMultiplier.
             * @member {string} MaxMultiplier
             * @memberof Rugbull.Protocols.RewardInfo
             * @instance
             */
            RewardInfo.prototype.MaxMultiplier = "";

            /**
             * RewardInfo InvitePerReward.
             * @member {string} InvitePerReward
             * @memberof Rugbull.Protocols.RewardInfo
             * @instance
             */
            RewardInfo.prototype.InvitePerReward = "";

            /**
             * RewardInfo NewUserReward.
             * @member {string} NewUserReward
             * @memberof Rugbull.Protocols.RewardInfo
             * @instance
             */
            RewardInfo.prototype.NewUserReward = "";

            /**
             * Creates a new RewardInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Rugbull.Protocols.IRewardInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.RewardInfo} RewardInfo instance
             */
            RewardInfo.create = function create(properties) {
                return new RewardInfo(properties);
            };

            /**
             * Encodes the specified RewardInfo message. Does not implicitly {@link Rugbull.Protocols.RewardInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Rugbull.Protocols.IRewardInfo} message RewardInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RewardInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.MaxMultiplier != null && Object.hasOwnProperty.call(message, "MaxMultiplier"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.MaxMultiplier);
                if (message.InvitePerReward != null && Object.hasOwnProperty.call(message, "InvitePerReward"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.InvitePerReward);
                if (message.NewUserReward != null && Object.hasOwnProperty.call(message, "NewUserReward"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.NewUserReward);
                return writer;
            };

            /**
             * Encodes the specified RewardInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.RewardInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Rugbull.Protocols.IRewardInfo} message RewardInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RewardInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RewardInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.RewardInfo} RewardInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RewardInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.RewardInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.MaxMultiplier = reader.string();
                            break;
                        }
                    case 2: {
                            message.InvitePerReward = reader.string();
                            break;
                        }
                    case 3: {
                            message.NewUserReward = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RewardInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.RewardInfo} RewardInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RewardInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RewardInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RewardInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.MaxMultiplier != null && message.hasOwnProperty("MaxMultiplier"))
                    if (!$util.isString(message.MaxMultiplier))
                        return "MaxMultiplier: string expected";
                if (message.InvitePerReward != null && message.hasOwnProperty("InvitePerReward"))
                    if (!$util.isString(message.InvitePerReward))
                        return "InvitePerReward: string expected";
                if (message.NewUserReward != null && message.hasOwnProperty("NewUserReward"))
                    if (!$util.isString(message.NewUserReward))
                        return "NewUserReward: string expected";
                return null;
            };

            /**
             * Creates a RewardInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.RewardInfo} RewardInfo
             */
            RewardInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.RewardInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.RewardInfo();
                if (object.MaxMultiplier != null)
                    message.MaxMultiplier = String(object.MaxMultiplier);
                if (object.InvitePerReward != null)
                    message.InvitePerReward = String(object.InvitePerReward);
                if (object.NewUserReward != null)
                    message.NewUserReward = String(object.NewUserReward);
                return message;
            };

            /**
             * Creates a plain object from a RewardInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {Rugbull.Protocols.RewardInfo} message RewardInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RewardInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.MaxMultiplier = "";
                    object.InvitePerReward = "";
                    object.NewUserReward = "";
                }
                if (message.MaxMultiplier != null && message.hasOwnProperty("MaxMultiplier"))
                    object.MaxMultiplier = message.MaxMultiplier;
                if (message.InvitePerReward != null && message.hasOwnProperty("InvitePerReward"))
                    object.InvitePerReward = message.InvitePerReward;
                if (message.NewUserReward != null && message.hasOwnProperty("NewUserReward"))
                    object.NewUserReward = message.NewUserReward;
                return object;
            };

            /**
             * Converts this RewardInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.RewardInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RewardInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RewardInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.RewardInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RewardInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.RewardInfo";
            };

            return RewardInfo;
        })();

        Protocols.CSLoginWithCode = (function() {

            /**
             * Properties of a CSLoginWithCode.
             * @memberof Rugbull.Protocols
             * @interface ICSLoginWithCode
             * @property {string|null} [Code] CSLoginWithCode Code
             * @property {number|null} [ExpireAt] CSLoginWithCode ExpireAt
             * @property {string|null} [Sign] CSLoginWithCode Sign
             */

            /**
             * Constructs a new CSLoginWithCode.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSLoginWithCode.
             * @implements ICSLoginWithCode
             * @constructor
             * @param {Rugbull.Protocols.ICSLoginWithCode=} [properties] Properties to set
             */
            function CSLoginWithCode(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSLoginWithCode Code.
             * @member {string} Code
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @instance
             */
            CSLoginWithCode.prototype.Code = "";

            /**
             * CSLoginWithCode ExpireAt.
             * @member {number} ExpireAt
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @instance
             */
            CSLoginWithCode.prototype.ExpireAt = 0;

            /**
             * CSLoginWithCode Sign.
             * @member {string} Sign
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @instance
             */
            CSLoginWithCode.prototype.Sign = "";

            /**
             * Creates a new CSLoginWithCode instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Rugbull.Protocols.ICSLoginWithCode=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSLoginWithCode} CSLoginWithCode instance
             */
            CSLoginWithCode.create = function create(properties) {
                return new CSLoginWithCode(properties);
            };

            /**
             * Encodes the specified CSLoginWithCode message. Does not implicitly {@link Rugbull.Protocols.CSLoginWithCode.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Rugbull.Protocols.ICSLoginWithCode} message CSLoginWithCode message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLoginWithCode.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Code != null && Object.hasOwnProperty.call(message, "Code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Code);
                if (message.ExpireAt != null && Object.hasOwnProperty.call(message, "ExpireAt"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ExpireAt);
                if (message.Sign != null && Object.hasOwnProperty.call(message, "Sign"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Sign);
                return writer;
            };

            /**
             * Encodes the specified CSLoginWithCode message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSLoginWithCode.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Rugbull.Protocols.ICSLoginWithCode} message CSLoginWithCode message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLoginWithCode.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSLoginWithCode message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSLoginWithCode} CSLoginWithCode
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLoginWithCode.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSLoginWithCode();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Code = reader.string();
                            break;
                        }
                    case 2: {
                            message.ExpireAt = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Sign = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSLoginWithCode message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSLoginWithCode} CSLoginWithCode
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLoginWithCode.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSLoginWithCode message.
             * @function verify
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSLoginWithCode.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Code != null && message.hasOwnProperty("Code"))
                    if (!$util.isString(message.Code))
                        return "Code: string expected";
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    if (!$util.isInteger(message.ExpireAt))
                        return "ExpireAt: integer expected";
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    if (!$util.isString(message.Sign))
                        return "Sign: string expected";
                return null;
            };

            /**
             * Creates a CSLoginWithCode message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSLoginWithCode} CSLoginWithCode
             */
            CSLoginWithCode.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSLoginWithCode)
                    return object;
                let message = new $root.Rugbull.Protocols.CSLoginWithCode();
                if (object.Code != null)
                    message.Code = String(object.Code);
                if (object.ExpireAt != null)
                    message.ExpireAt = object.ExpireAt | 0;
                if (object.Sign != null)
                    message.Sign = String(object.Sign);
                return message;
            };

            /**
             * Creates a plain object from a CSLoginWithCode message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {Rugbull.Protocols.CSLoginWithCode} message CSLoginWithCode
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSLoginWithCode.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Code = "";
                    object.ExpireAt = 0;
                    object.Sign = "";
                }
                if (message.Code != null && message.hasOwnProperty("Code"))
                    object.Code = message.Code;
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    object.ExpireAt = message.ExpireAt;
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    object.Sign = message.Sign;
                return object;
            };

            /**
             * Converts this CSLoginWithCode to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSLoginWithCode.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSLoginWithCode
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSLoginWithCode
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSLoginWithCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSLoginWithCode";
            };

            return CSLoginWithCode;
        })();

        Protocols.CSLoginByGoogle = (function() {

            /**
             * Properties of a CSLoginByGoogle.
             * @memberof Rugbull.Protocols
             * @interface ICSLoginByGoogle
             * @property {string|null} [OpenId] CSLoginByGoogle OpenId
             * @property {string|null} [AccessToken] CSLoginByGoogle AccessToken
             * @property {number|null} [ExpireAt] CSLoginByGoogle ExpireAt
             * @property {string|null} [Json] CSLoginByGoogle Json
             */

            /**
             * Constructs a new CSLoginByGoogle.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSLoginByGoogle.
             * @implements ICSLoginByGoogle
             * @constructor
             * @param {Rugbull.Protocols.ICSLoginByGoogle=} [properties] Properties to set
             */
            function CSLoginByGoogle(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSLoginByGoogle OpenId.
             * @member {string} OpenId
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @instance
             */
            CSLoginByGoogle.prototype.OpenId = "";

            /**
             * CSLoginByGoogle AccessToken.
             * @member {string} AccessToken
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @instance
             */
            CSLoginByGoogle.prototype.AccessToken = "";

            /**
             * CSLoginByGoogle ExpireAt.
             * @member {number} ExpireAt
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @instance
             */
            CSLoginByGoogle.prototype.ExpireAt = 0;

            /**
             * CSLoginByGoogle Json.
             * @member {string} Json
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @instance
             */
            CSLoginByGoogle.prototype.Json = "";

            /**
             * Creates a new CSLoginByGoogle instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Rugbull.Protocols.ICSLoginByGoogle=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSLoginByGoogle} CSLoginByGoogle instance
             */
            CSLoginByGoogle.create = function create(properties) {
                return new CSLoginByGoogle(properties);
            };

            /**
             * Encodes the specified CSLoginByGoogle message. Does not implicitly {@link Rugbull.Protocols.CSLoginByGoogle.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Rugbull.Protocols.ICSLoginByGoogle} message CSLoginByGoogle message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLoginByGoogle.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.OpenId != null && Object.hasOwnProperty.call(message, "OpenId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.OpenId);
                if (message.AccessToken != null && Object.hasOwnProperty.call(message, "AccessToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.AccessToken);
                if (message.ExpireAt != null && Object.hasOwnProperty.call(message, "ExpireAt"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ExpireAt);
                if (message.Json != null && Object.hasOwnProperty.call(message, "Json"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Json);
                return writer;
            };

            /**
             * Encodes the specified CSLoginByGoogle message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSLoginByGoogle.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Rugbull.Protocols.ICSLoginByGoogle} message CSLoginByGoogle message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSLoginByGoogle.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSLoginByGoogle message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSLoginByGoogle} CSLoginByGoogle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLoginByGoogle.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSLoginByGoogle();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.OpenId = reader.string();
                            break;
                        }
                    case 2: {
                            message.AccessToken = reader.string();
                            break;
                        }
                    case 3: {
                            message.ExpireAt = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Json = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSLoginByGoogle message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSLoginByGoogle} CSLoginByGoogle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSLoginByGoogle.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSLoginByGoogle message.
             * @function verify
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSLoginByGoogle.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.OpenId != null && message.hasOwnProperty("OpenId"))
                    if (!$util.isString(message.OpenId))
                        return "OpenId: string expected";
                if (message.AccessToken != null && message.hasOwnProperty("AccessToken"))
                    if (!$util.isString(message.AccessToken))
                        return "AccessToken: string expected";
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    if (!$util.isInteger(message.ExpireAt))
                        return "ExpireAt: integer expected";
                if (message.Json != null && message.hasOwnProperty("Json"))
                    if (!$util.isString(message.Json))
                        return "Json: string expected";
                return null;
            };

            /**
             * Creates a CSLoginByGoogle message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSLoginByGoogle} CSLoginByGoogle
             */
            CSLoginByGoogle.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSLoginByGoogle)
                    return object;
                let message = new $root.Rugbull.Protocols.CSLoginByGoogle();
                if (object.OpenId != null)
                    message.OpenId = String(object.OpenId);
                if (object.AccessToken != null)
                    message.AccessToken = String(object.AccessToken);
                if (object.ExpireAt != null)
                    message.ExpireAt = object.ExpireAt | 0;
                if (object.Json != null)
                    message.Json = String(object.Json);
                return message;
            };

            /**
             * Creates a plain object from a CSLoginByGoogle message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {Rugbull.Protocols.CSLoginByGoogle} message CSLoginByGoogle
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSLoginByGoogle.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.OpenId = "";
                    object.AccessToken = "";
                    object.ExpireAt = 0;
                    object.Json = "";
                }
                if (message.OpenId != null && message.hasOwnProperty("OpenId"))
                    object.OpenId = message.OpenId;
                if (message.AccessToken != null && message.hasOwnProperty("AccessToken"))
                    object.AccessToken = message.AccessToken;
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    object.ExpireAt = message.ExpireAt;
                if (message.Json != null && message.hasOwnProperty("Json"))
                    object.Json = message.Json;
                return object;
            };

            /**
             * Converts this CSLoginByGoogle to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSLoginByGoogle.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSLoginByGoogle
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSLoginByGoogle
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSLoginByGoogle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSLoginByGoogle";
            };

            return CSLoginByGoogle;
        })();

        Protocols.SCGetToken = (function() {

            /**
             * Properties of a SCGetToken.
             * @memberof Rugbull.Protocols
             * @interface ISCGetToken
             * @property {string|null} [Token] SCGetToken Token
             */

            /**
             * Constructs a new SCGetToken.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCGetToken.
             * @implements ISCGetToken
             * @constructor
             * @param {Rugbull.Protocols.ISCGetToken=} [properties] Properties to set
             */
            function SCGetToken(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCGetToken Token.
             * @member {string} Token
             * @memberof Rugbull.Protocols.SCGetToken
             * @instance
             */
            SCGetToken.prototype.Token = "";

            /**
             * Creates a new SCGetToken instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Rugbull.Protocols.ISCGetToken=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCGetToken} SCGetToken instance
             */
            SCGetToken.create = function create(properties) {
                return new SCGetToken(properties);
            };

            /**
             * Encodes the specified SCGetToken message. Does not implicitly {@link Rugbull.Protocols.SCGetToken.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Rugbull.Protocols.ISCGetToken} message SCGetToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Token != null && Object.hasOwnProperty.call(message, "Token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Token);
                return writer;
            };

            /**
             * Encodes the specified SCGetToken message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCGetToken.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Rugbull.Protocols.ISCGetToken} message SCGetToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetToken.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCGetToken message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCGetToken} SCGetToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCGetToken();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCGetToken message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCGetToken} SCGetToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetToken.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCGetToken message.
             * @function verify
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCGetToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Token != null && message.hasOwnProperty("Token"))
                    if (!$util.isString(message.Token))
                        return "Token: string expected";
                return null;
            };

            /**
             * Creates a SCGetToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCGetToken} SCGetToken
             */
            SCGetToken.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCGetToken)
                    return object;
                let message = new $root.Rugbull.Protocols.SCGetToken();
                if (object.Token != null)
                    message.Token = String(object.Token);
                return message;
            };

            /**
             * Creates a plain object from a SCGetToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {Rugbull.Protocols.SCGetToken} message SCGetToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCGetToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Token = "";
                if (message.Token != null && message.hasOwnProperty("Token"))
                    object.Token = message.Token;
                return object;
            };

            /**
             * Converts this SCGetToken to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCGetToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCGetToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCGetToken
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCGetToken
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCGetToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCGetToken";
            };

            return SCGetToken;
        })();

        Protocols.SCOrderInfo = (function() {

            /**
             * Properties of a SCOrderInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCOrderInfo
             * @property {number|null} [Id] SCOrderInfo Id
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] SCOrderInfo ChainType
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] SCOrderInfo CoinType
             * @property {string|null} [CoinAmount] SCOrderInfo CoinAmount
             * @property {string|null} [FromAddress] SCOrderInfo FromAddress
             * @property {string|null} [ToAddress] SCOrderInfo ToAddress
             * @property {number|null} [ExpireAt] SCOrderInfo ExpireAt
             * @property {string|null} [AddressQrCode] SCOrderInfo AddressQrCode
             * @property {Rugbull.Protocols.OrderStatus|null} [Status] SCOrderInfo Status
             */

            /**
             * Constructs a new SCOrderInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCOrderInfo.
             * @implements ISCOrderInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCOrderInfo=} [properties] Properties to set
             */
            function SCOrderInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCOrderInfo Id.
             * @member {number} Id
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.Id = 0;

            /**
             * SCOrderInfo ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.ChainType = 0;

            /**
             * SCOrderInfo CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.CoinType = 0;

            /**
             * SCOrderInfo CoinAmount.
             * @member {string} CoinAmount
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.CoinAmount = "";

            /**
             * SCOrderInfo FromAddress.
             * @member {string} FromAddress
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.FromAddress = "";

            /**
             * SCOrderInfo ToAddress.
             * @member {string} ToAddress
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.ToAddress = "";

            /**
             * SCOrderInfo ExpireAt.
             * @member {number} ExpireAt
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.ExpireAt = 0;

            /**
             * SCOrderInfo AddressQrCode.
             * @member {string} AddressQrCode
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.AddressQrCode = "";

            /**
             * SCOrderInfo Status.
             * @member {Rugbull.Protocols.OrderStatus} Status
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             */
            SCOrderInfo.prototype.Status = 0;

            /**
             * Creates a new SCOrderInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Rugbull.Protocols.ISCOrderInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCOrderInfo} SCOrderInfo instance
             */
            SCOrderInfo.create = function create(properties) {
                return new SCOrderInfo(properties);
            };

            /**
             * Encodes the specified SCOrderInfo message. Does not implicitly {@link Rugbull.Protocols.SCOrderInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Rugbull.Protocols.ISCOrderInfo} message SCOrderInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCOrderInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ChainType);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CoinType);
                if (message.CoinAmount != null && Object.hasOwnProperty.call(message, "CoinAmount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.CoinAmount);
                if (message.FromAddress != null && Object.hasOwnProperty.call(message, "FromAddress"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.FromAddress);
                if (message.ToAddress != null && Object.hasOwnProperty.call(message, "ToAddress"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.ToAddress);
                if (message.ExpireAt != null && Object.hasOwnProperty.call(message, "ExpireAt"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.ExpireAt);
                if (message.AddressQrCode != null && Object.hasOwnProperty.call(message, "AddressQrCode"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.AddressQrCode);
                if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.Status);
                return writer;
            };

            /**
             * Encodes the specified SCOrderInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCOrderInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Rugbull.Protocols.ISCOrderInfo} message SCOrderInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCOrderInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCOrderInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCOrderInfo} SCOrderInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCOrderInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCOrderInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.CoinAmount = reader.string();
                            break;
                        }
                    case 5: {
                            message.FromAddress = reader.string();
                            break;
                        }
                    case 6: {
                            message.ToAddress = reader.string();
                            break;
                        }
                    case 7: {
                            message.ExpireAt = reader.int32();
                            break;
                        }
                    case 8: {
                            message.AddressQrCode = reader.string();
                            break;
                        }
                    case 9: {
                            message.Status = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCOrderInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCOrderInfo} SCOrderInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCOrderInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCOrderInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCOrderInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Id != null && message.hasOwnProperty("Id"))
                    if (!$util.isInteger(message.Id))
                        return "Id: integer expected";
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.CoinAmount != null && message.hasOwnProperty("CoinAmount"))
                    if (!$util.isString(message.CoinAmount))
                        return "CoinAmount: string expected";
                if (message.FromAddress != null && message.hasOwnProperty("FromAddress"))
                    if (!$util.isString(message.FromAddress))
                        return "FromAddress: string expected";
                if (message.ToAddress != null && message.hasOwnProperty("ToAddress"))
                    if (!$util.isString(message.ToAddress))
                        return "ToAddress: string expected";
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    if (!$util.isInteger(message.ExpireAt))
                        return "ExpireAt: integer expected";
                if (message.AddressQrCode != null && message.hasOwnProperty("AddressQrCode"))
                    if (!$util.isString(message.AddressQrCode))
                        return "AddressQrCode: string expected";
                if (message.Status != null && message.hasOwnProperty("Status"))
                    switch (message.Status) {
                    default:
                        return "Status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                return null;
            };

            /**
             * Creates a SCOrderInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCOrderInfo} SCOrderInfo
             */
            SCOrderInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCOrderInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCOrderInfo();
                if (object.Id != null)
                    message.Id = object.Id | 0;
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                if (object.CoinAmount != null)
                    message.CoinAmount = String(object.CoinAmount);
                if (object.FromAddress != null)
                    message.FromAddress = String(object.FromAddress);
                if (object.ToAddress != null)
                    message.ToAddress = String(object.ToAddress);
                if (object.ExpireAt != null)
                    message.ExpireAt = object.ExpireAt | 0;
                if (object.AddressQrCode != null)
                    message.AddressQrCode = String(object.AddressQrCode);
                switch (object.Status) {
                default:
                    if (typeof object.Status === "number") {
                        message.Status = object.Status;
                        break;
                    }
                    break;
                case "OrderStatus_Waiting":
                case 0:
                    message.Status = 0;
                    break;
                case "OrderStatus_Error":
                case 1:
                    message.Status = 1;
                    break;
                case "OrderStatus_Purchased":
                case 2:
                    message.Status = 2;
                    break;
                case "OrderStatus_Expired":
                case 3:
                    message.Status = 3;
                    break;
                case "OrderStatus_Cancelled":
                case 4:
                    message.Status = 4;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a SCOrderInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {Rugbull.Protocols.SCOrderInfo} message SCOrderInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCOrderInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Id = 0;
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                    object.CoinAmount = "";
                    object.FromAddress = "";
                    object.ToAddress = "";
                    object.ExpireAt = 0;
                    object.AddressQrCode = "";
                    object.Status = options.enums === String ? "OrderStatus_Waiting" : 0;
                }
                if (message.Id != null && message.hasOwnProperty("Id"))
                    object.Id = message.Id;
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                if (message.CoinAmount != null && message.hasOwnProperty("CoinAmount"))
                    object.CoinAmount = message.CoinAmount;
                if (message.FromAddress != null && message.hasOwnProperty("FromAddress"))
                    object.FromAddress = message.FromAddress;
                if (message.ToAddress != null && message.hasOwnProperty("ToAddress"))
                    object.ToAddress = message.ToAddress;
                if (message.ExpireAt != null && message.hasOwnProperty("ExpireAt"))
                    object.ExpireAt = message.ExpireAt;
                if (message.AddressQrCode != null && message.hasOwnProperty("AddressQrCode"))
                    object.AddressQrCode = message.AddressQrCode;
                if (message.Status != null && message.hasOwnProperty("Status"))
                    object.Status = options.enums === String ? $root.Rugbull.Protocols.OrderStatus[message.Status] === undefined ? message.Status : $root.Rugbull.Protocols.OrderStatus[message.Status] : message.Status;
                return object;
            };

            /**
             * Converts this SCOrderInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCOrderInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCOrderInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCOrderInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCOrderInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCOrderInfo";
            };

            return SCOrderInfo;
        })();

        Protocols.CSOrderInfo = (function() {

            /**
             * Properties of a CSOrderInfo.
             * @memberof Rugbull.Protocols
             * @interface ICSOrderInfo
             * @property {string|null} [Amount] CSOrderInfo Amount
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] CSOrderInfo ChainType
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] CSOrderInfo CoinType
             */

            /**
             * Constructs a new CSOrderInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSOrderInfo.
             * @implements ICSOrderInfo
             * @constructor
             * @param {Rugbull.Protocols.ICSOrderInfo=} [properties] Properties to set
             */
            function CSOrderInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSOrderInfo Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @instance
             */
            CSOrderInfo.prototype.Amount = "";

            /**
             * CSOrderInfo ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @instance
             */
            CSOrderInfo.prototype.ChainType = 0;

            /**
             * CSOrderInfo CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @instance
             */
            CSOrderInfo.prototype.CoinType = 0;

            /**
             * Creates a new CSOrderInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Rugbull.Protocols.ICSOrderInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSOrderInfo} CSOrderInfo instance
             */
            CSOrderInfo.create = function create(properties) {
                return new CSOrderInfo(properties);
            };

            /**
             * Encodes the specified CSOrderInfo message. Does not implicitly {@link Rugbull.Protocols.CSOrderInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Rugbull.Protocols.ICSOrderInfo} message CSOrderInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSOrderInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Amount);
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ChainType);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CoinType);
                return writer;
            };

            /**
             * Encodes the specified CSOrderInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSOrderInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Rugbull.Protocols.ICSOrderInfo} message CSOrderInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSOrderInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSOrderInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSOrderInfo} CSOrderInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSOrderInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSOrderInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 2: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSOrderInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSOrderInfo} CSOrderInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSOrderInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSOrderInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSOrderInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSOrderInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSOrderInfo} CSOrderInfo
             */
            CSOrderInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSOrderInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.CSOrderInfo();
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSOrderInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {Rugbull.Protocols.CSOrderInfo} message CSOrderInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSOrderInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Amount = "";
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                return object;
            };

            /**
             * Converts this CSOrderInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSOrderInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSOrderInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSOrderInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSOrderInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSOrderInfo";
            };

            return CSOrderInfo;
        })();

        Protocols.CSOrderCancel = (function() {

            /**
             * Properties of a CSOrderCancel.
             * @memberof Rugbull.Protocols
             * @interface ICSOrderCancel
             * @property {number|null} [OrderId] CSOrderCancel OrderId
             */

            /**
             * Constructs a new CSOrderCancel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSOrderCancel.
             * @implements ICSOrderCancel
             * @constructor
             * @param {Rugbull.Protocols.ICSOrderCancel=} [properties] Properties to set
             */
            function CSOrderCancel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSOrderCancel OrderId.
             * @member {number} OrderId
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @instance
             */
            CSOrderCancel.prototype.OrderId = 0;

            /**
             * Creates a new CSOrderCancel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Rugbull.Protocols.ICSOrderCancel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSOrderCancel} CSOrderCancel instance
             */
            CSOrderCancel.create = function create(properties) {
                return new CSOrderCancel(properties);
            };

            /**
             * Encodes the specified CSOrderCancel message. Does not implicitly {@link Rugbull.Protocols.CSOrderCancel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Rugbull.Protocols.ICSOrderCancel} message CSOrderCancel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSOrderCancel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.OrderId != null && Object.hasOwnProperty.call(message, "OrderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.OrderId);
                return writer;
            };

            /**
             * Encodes the specified CSOrderCancel message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSOrderCancel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Rugbull.Protocols.ICSOrderCancel} message CSOrderCancel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSOrderCancel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSOrderCancel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSOrderCancel} CSOrderCancel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSOrderCancel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSOrderCancel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.OrderId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSOrderCancel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSOrderCancel} CSOrderCancel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSOrderCancel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSOrderCancel message.
             * @function verify
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSOrderCancel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.OrderId != null && message.hasOwnProperty("OrderId"))
                    if (!$util.isInteger(message.OrderId))
                        return "OrderId: integer expected";
                return null;
            };

            /**
             * Creates a CSOrderCancel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSOrderCancel} CSOrderCancel
             */
            CSOrderCancel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSOrderCancel)
                    return object;
                let message = new $root.Rugbull.Protocols.CSOrderCancel();
                if (object.OrderId != null)
                    message.OrderId = object.OrderId | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSOrderCancel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {Rugbull.Protocols.CSOrderCancel} message CSOrderCancel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSOrderCancel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.OrderId = 0;
                if (message.OrderId != null && message.hasOwnProperty("OrderId"))
                    object.OrderId = message.OrderId;
                return object;
            };

            /**
             * Converts this CSOrderCancel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSOrderCancel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSOrderCancel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSOrderCancel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSOrderCancel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSOrderCancel";
            };

            return CSOrderCancel;
        })();

        Protocols.CSTopList = (function() {

            /**
             * Properties of a CSTopList.
             * @memberof Rugbull.Protocols
             * @interface ICSTopList
             * @property {Rugbull.Protocols.SearchTopType|null} [SearchType] CSTopList SearchType
             */

            /**
             * Constructs a new CSTopList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSTopList.
             * @implements ICSTopList
             * @constructor
             * @param {Rugbull.Protocols.ICSTopList=} [properties] Properties to set
             */
            function CSTopList(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSTopList SearchType.
             * @member {Rugbull.Protocols.SearchTopType|null|undefined} SearchType
             * @memberof Rugbull.Protocols.CSTopList
             * @instance
             */
            CSTopList.prototype.SearchType = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * CSTopList _SearchType.
             * @member {"SearchType"|undefined} _SearchType
             * @memberof Rugbull.Protocols.CSTopList
             * @instance
             */
            Object.defineProperty(CSTopList.prototype, "_SearchType", {
                get: $util.oneOfGetter($oneOfFields = ["SearchType"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CSTopList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Rugbull.Protocols.ICSTopList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSTopList} CSTopList instance
             */
            CSTopList.create = function create(properties) {
                return new CSTopList(properties);
            };

            /**
             * Encodes the specified CSTopList message. Does not implicitly {@link Rugbull.Protocols.CSTopList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Rugbull.Protocols.ICSTopList} message CSTopList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSTopList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.SearchType != null && Object.hasOwnProperty.call(message, "SearchType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.SearchType);
                return writer;
            };

            /**
             * Encodes the specified CSTopList message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSTopList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Rugbull.Protocols.ICSTopList} message CSTopList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSTopList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSTopList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSTopList} CSTopList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSTopList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSTopList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.SearchType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSTopList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSTopList} CSTopList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSTopList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSTopList message.
             * @function verify
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSTopList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.SearchType != null && message.hasOwnProperty("SearchType")) {
                    properties._SearchType = 1;
                    switch (message.SearchType) {
                    default:
                        return "SearchType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a CSTopList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSTopList} CSTopList
             */
            CSTopList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSTopList)
                    return object;
                let message = new $root.Rugbull.Protocols.CSTopList();
                switch (object.SearchType) {
                default:
                    if (typeof object.SearchType === "number") {
                        message.SearchType = object.SearchType;
                        break;
                    }
                    break;
                case "DAY":
                case 0:
                    message.SearchType = 0;
                    break;
                case "MOUNTH":
                case 1:
                    message.SearchType = 1;
                    break;
                case "YEAR":
                case 2:
                    message.SearchType = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSTopList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {Rugbull.Protocols.CSTopList} message CSTopList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSTopList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.SearchType != null && message.hasOwnProperty("SearchType")) {
                    object.SearchType = options.enums === String ? $root.Rugbull.Protocols.SearchTopType[message.SearchType] === undefined ? message.SearchType : $root.Rugbull.Protocols.SearchTopType[message.SearchType] : message.SearchType;
                    if (options.oneofs)
                        object._SearchType = "SearchType";
                }
                return object;
            };

            /**
             * Converts this CSTopList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSTopList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSTopList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSTopList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSTopList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSTopList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSTopList";
            };

            return CSTopList;
        })();

        Protocols.TopBetModel = (function() {

            /**
             * Properties of a TopBetModel.
             * @memberof Rugbull.Protocols
             * @interface ITopBetModel
             * @property {string|null} [UserName] TopBetModel UserName
             * @property {string|null} [Amount] TopBetModel Amount
             * @property {string|null} [Multiplier] TopBetModel Multiplier
             * @property {string|null} [PhotoUrl] TopBetModel PhotoUrl
             * @property {string|null} [Round] TopBetModel Round
             * @property {string|null} [Time] TopBetModel Time
             * @property {string|null} [GameRound] TopBetModel GameRound
             */

            /**
             * Constructs a new TopBetModel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a TopBetModel.
             * @implements ITopBetModel
             * @constructor
             * @param {Rugbull.Protocols.ITopBetModel=} [properties] Properties to set
             */
            function TopBetModel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TopBetModel UserName.
             * @member {string} UserName
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.UserName = "";

            /**
             * TopBetModel Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.Amount = "";

            /**
             * TopBetModel Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.Multiplier = "";

            /**
             * TopBetModel PhotoUrl.
             * @member {string} PhotoUrl
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.PhotoUrl = "";

            /**
             * TopBetModel Round.
             * @member {string} Round
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.Round = "";

            /**
             * TopBetModel Time.
             * @member {string} Time
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.Time = "";

            /**
             * TopBetModel GameRound.
             * @member {string} GameRound
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             */
            TopBetModel.prototype.GameRound = "";

            /**
             * Creates a new TopBetModel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Rugbull.Protocols.ITopBetModel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.TopBetModel} TopBetModel instance
             */
            TopBetModel.create = function create(properties) {
                return new TopBetModel(properties);
            };

            /**
             * Encodes the specified TopBetModel message. Does not implicitly {@link Rugbull.Protocols.TopBetModel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Rugbull.Protocols.ITopBetModel} message TopBetModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopBetModel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.UserName != null && Object.hasOwnProperty.call(message, "UserName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserName);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Multiplier);
                if (message.PhotoUrl != null && Object.hasOwnProperty.call(message, "PhotoUrl"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.PhotoUrl);
                if (message.Round != null && Object.hasOwnProperty.call(message, "Round"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Round);
                if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Time);
                if (message.GameRound != null && Object.hasOwnProperty.call(message, "GameRound"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.GameRound);
                return writer;
            };

            /**
             * Encodes the specified TopBetModel message, length delimited. Does not implicitly {@link Rugbull.Protocols.TopBetModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Rugbull.Protocols.ITopBetModel} message TopBetModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopBetModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TopBetModel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.TopBetModel} TopBetModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopBetModel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.TopBetModel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.UserName = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 4: {
                            message.PhotoUrl = reader.string();
                            break;
                        }
                    case 5: {
                            message.Round = reader.string();
                            break;
                        }
                    case 6: {
                            message.Time = reader.string();
                            break;
                        }
                    case 7: {
                            message.GameRound = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TopBetModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.TopBetModel} TopBetModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopBetModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TopBetModel message.
             * @function verify
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TopBetModel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    if (!$util.isString(message.UserName))
                        return "UserName: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    if (!$util.isString(message.PhotoUrl))
                        return "PhotoUrl: string expected";
                if (message.Round != null && message.hasOwnProperty("Round"))
                    if (!$util.isString(message.Round))
                        return "Round: string expected";
                if (message.Time != null && message.hasOwnProperty("Time"))
                    if (!$util.isString(message.Time))
                        return "Time: string expected";
                if (message.GameRound != null && message.hasOwnProperty("GameRound"))
                    if (!$util.isString(message.GameRound))
                        return "GameRound: string expected";
                return null;
            };

            /**
             * Creates a TopBetModel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.TopBetModel} TopBetModel
             */
            TopBetModel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.TopBetModel)
                    return object;
                let message = new $root.Rugbull.Protocols.TopBetModel();
                if (object.UserName != null)
                    message.UserName = String(object.UserName);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.PhotoUrl != null)
                    message.PhotoUrl = String(object.PhotoUrl);
                if (object.Round != null)
                    message.Round = String(object.Round);
                if (object.Time != null)
                    message.Time = String(object.Time);
                if (object.GameRound != null)
                    message.GameRound = String(object.GameRound);
                return message;
            };

            /**
             * Creates a plain object from a TopBetModel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {Rugbull.Protocols.TopBetModel} message TopBetModel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TopBetModel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.UserName = "";
                    object.Amount = "";
                    object.Multiplier = "";
                    object.PhotoUrl = "";
                    object.Round = "";
                    object.Time = "";
                    object.GameRound = "";
                }
                if (message.UserName != null && message.hasOwnProperty("UserName"))
                    object.UserName = message.UserName;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.PhotoUrl != null && message.hasOwnProperty("PhotoUrl"))
                    object.PhotoUrl = message.PhotoUrl;
                if (message.Round != null && message.hasOwnProperty("Round"))
                    object.Round = message.Round;
                if (message.Time != null && message.hasOwnProperty("Time"))
                    object.Time = message.Time;
                if (message.GameRound != null && message.hasOwnProperty("GameRound"))
                    object.GameRound = message.GameRound;
                return object;
            };

            /**
             * Converts this TopBetModel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.TopBetModel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TopBetModel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TopBetModel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.TopBetModel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TopBetModel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.TopBetModel";
            };

            return TopBetModel;
        })();

        Protocols.TopMultiplierModel = (function() {

            /**
             * Properties of a TopMultiplierModel.
             * @memberof Rugbull.Protocols
             * @interface ITopMultiplierModel
             * @property {string|null} [Multiplier] TopMultiplierModel Multiplier
             * @property {string|null} [Time] TopMultiplierModel Time
             */

            /**
             * Constructs a new TopMultiplierModel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a TopMultiplierModel.
             * @implements ITopMultiplierModel
             * @constructor
             * @param {Rugbull.Protocols.ITopMultiplierModel=} [properties] Properties to set
             */
            function TopMultiplierModel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TopMultiplierModel Multiplier.
             * @member {string} Multiplier
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @instance
             */
            TopMultiplierModel.prototype.Multiplier = "";

            /**
             * TopMultiplierModel Time.
             * @member {string} Time
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @instance
             */
            TopMultiplierModel.prototype.Time = "";

            /**
             * Creates a new TopMultiplierModel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Rugbull.Protocols.ITopMultiplierModel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.TopMultiplierModel} TopMultiplierModel instance
             */
            TopMultiplierModel.create = function create(properties) {
                return new TopMultiplierModel(properties);
            };

            /**
             * Encodes the specified TopMultiplierModel message. Does not implicitly {@link Rugbull.Protocols.TopMultiplierModel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Rugbull.Protocols.ITopMultiplierModel} message TopMultiplierModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopMultiplierModel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Multiplier != null && Object.hasOwnProperty.call(message, "Multiplier"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Multiplier);
                if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Time);
                return writer;
            };

            /**
             * Encodes the specified TopMultiplierModel message, length delimited. Does not implicitly {@link Rugbull.Protocols.TopMultiplierModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Rugbull.Protocols.ITopMultiplierModel} message TopMultiplierModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TopMultiplierModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TopMultiplierModel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.TopMultiplierModel} TopMultiplierModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopMultiplierModel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.TopMultiplierModel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Multiplier = reader.string();
                            break;
                        }
                    case 2: {
                            message.Time = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TopMultiplierModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.TopMultiplierModel} TopMultiplierModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TopMultiplierModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TopMultiplierModel message.
             * @function verify
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TopMultiplierModel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    if (!$util.isString(message.Multiplier))
                        return "Multiplier: string expected";
                if (message.Time != null && message.hasOwnProperty("Time"))
                    if (!$util.isString(message.Time))
                        return "Time: string expected";
                return null;
            };

            /**
             * Creates a TopMultiplierModel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.TopMultiplierModel} TopMultiplierModel
             */
            TopMultiplierModel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.TopMultiplierModel)
                    return object;
                let message = new $root.Rugbull.Protocols.TopMultiplierModel();
                if (object.Multiplier != null)
                    message.Multiplier = String(object.Multiplier);
                if (object.Time != null)
                    message.Time = String(object.Time);
                return message;
            };

            /**
             * Creates a plain object from a TopMultiplierModel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {Rugbull.Protocols.TopMultiplierModel} message TopMultiplierModel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TopMultiplierModel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Multiplier = "";
                    object.Time = "";
                }
                if (message.Multiplier != null && message.hasOwnProperty("Multiplier"))
                    object.Multiplier = message.Multiplier;
                if (message.Time != null && message.hasOwnProperty("Time"))
                    object.Time = message.Time;
                return object;
            };

            /**
             * Converts this TopMultiplierModel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TopMultiplierModel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TopMultiplierModel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.TopMultiplierModel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TopMultiplierModel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.TopMultiplierModel";
            };

            return TopMultiplierModel;
        })();

        Protocols.SCTopBetList = (function() {

            /**
             * Properties of a SCTopBetList.
             * @memberof Rugbull.Protocols
             * @interface ISCTopBetList
             * @property {Array.<Rugbull.Protocols.ITopBetModel>|null} [Infos] SCTopBetList Infos
             */

            /**
             * Constructs a new SCTopBetList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCTopBetList.
             * @implements ISCTopBetList
             * @constructor
             * @param {Rugbull.Protocols.ISCTopBetList=} [properties] Properties to set
             */
            function SCTopBetList(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCTopBetList Infos.
             * @member {Array.<Rugbull.Protocols.ITopBetModel>} Infos
             * @memberof Rugbull.Protocols.SCTopBetList
             * @instance
             */
            SCTopBetList.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new SCTopBetList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Rugbull.Protocols.ISCTopBetList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCTopBetList} SCTopBetList instance
             */
            SCTopBetList.create = function create(properties) {
                return new SCTopBetList(properties);
            };

            /**
             * Encodes the specified SCTopBetList message. Does not implicitly {@link Rugbull.Protocols.SCTopBetList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Rugbull.Protocols.ISCTopBetList} message SCTopBetList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTopBetList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        $root.Rugbull.Protocols.TopBetModel.encode(message.Infos[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCTopBetList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCTopBetList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Rugbull.Protocols.ISCTopBetList} message SCTopBetList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTopBetList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCTopBetList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCTopBetList} SCTopBetList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTopBetList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCTopBetList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push($root.Rugbull.Protocols.TopBetModel.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCTopBetList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCTopBetList} SCTopBetList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTopBetList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCTopBetList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCTopBetList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i) {
                        let error = $root.Rugbull.Protocols.TopBetModel.verify(message.Infos[i]);
                        if (error)
                            return "Infos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCTopBetList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCTopBetList} SCTopBetList
             */
            SCTopBetList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCTopBetList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCTopBetList();
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.SCTopBetList.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i) {
                        if (typeof object.Infos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCTopBetList.Infos: object expected");
                        message.Infos[i] = $root.Rugbull.Protocols.TopBetModel.fromObject(object.Infos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCTopBetList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {Rugbull.Protocols.SCTopBetList} message SCTopBetList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCTopBetList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = $root.Rugbull.Protocols.TopBetModel.toObject(message.Infos[j], options);
                }
                return object;
            };

            /**
             * Converts this SCTopBetList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCTopBetList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCTopBetList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCTopBetList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCTopBetList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCTopBetList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCTopBetList";
            };

            return SCTopBetList;
        })();

        Protocols.SCTopMultiplierList = (function() {

            /**
             * Properties of a SCTopMultiplierList.
             * @memberof Rugbull.Protocols
             * @interface ISCTopMultiplierList
             * @property {Rugbull.Protocols.ITopMultiplierModel|null} [Top] SCTopMultiplierList Top
             * @property {Array.<Rugbull.Protocols.ITopMultiplierModel>|null} [Infos] SCTopMultiplierList Infos
             */

            /**
             * Constructs a new SCTopMultiplierList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCTopMultiplierList.
             * @implements ISCTopMultiplierList
             * @constructor
             * @param {Rugbull.Protocols.ISCTopMultiplierList=} [properties] Properties to set
             */
            function SCTopMultiplierList(properties) {
                this.Infos = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCTopMultiplierList Top.
             * @member {Rugbull.Protocols.ITopMultiplierModel|null|undefined} Top
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @instance
             */
            SCTopMultiplierList.prototype.Top = null;

            /**
             * SCTopMultiplierList Infos.
             * @member {Array.<Rugbull.Protocols.ITopMultiplierModel>} Infos
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @instance
             */
            SCTopMultiplierList.prototype.Infos = $util.emptyArray;

            /**
             * Creates a new SCTopMultiplierList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Rugbull.Protocols.ISCTopMultiplierList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCTopMultiplierList} SCTopMultiplierList instance
             */
            SCTopMultiplierList.create = function create(properties) {
                return new SCTopMultiplierList(properties);
            };

            /**
             * Encodes the specified SCTopMultiplierList message. Does not implicitly {@link Rugbull.Protocols.SCTopMultiplierList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Rugbull.Protocols.ISCTopMultiplierList} message SCTopMultiplierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTopMultiplierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Top != null && Object.hasOwnProperty.call(message, "Top"))
                    $root.Rugbull.Protocols.TopMultiplierModel.encode(message.Top, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.Infos != null && message.Infos.length)
                    for (let i = 0; i < message.Infos.length; ++i)
                        $root.Rugbull.Protocols.TopMultiplierModel.encode(message.Infos[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCTopMultiplierList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCTopMultiplierList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Rugbull.Protocols.ISCTopMultiplierList} message SCTopMultiplierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTopMultiplierList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCTopMultiplierList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCTopMultiplierList} SCTopMultiplierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTopMultiplierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCTopMultiplierList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Top = $root.Rugbull.Protocols.TopMultiplierModel.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.Infos && message.Infos.length))
                                message.Infos = [];
                            message.Infos.push($root.Rugbull.Protocols.TopMultiplierModel.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCTopMultiplierList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCTopMultiplierList} SCTopMultiplierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTopMultiplierList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCTopMultiplierList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCTopMultiplierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Top != null && message.hasOwnProperty("Top")) {
                    let error = $root.Rugbull.Protocols.TopMultiplierModel.verify(message.Top);
                    if (error)
                        return "Top." + error;
                }
                if (message.Infos != null && message.hasOwnProperty("Infos")) {
                    if (!Array.isArray(message.Infos))
                        return "Infos: array expected";
                    for (let i = 0; i < message.Infos.length; ++i) {
                        let error = $root.Rugbull.Protocols.TopMultiplierModel.verify(message.Infos[i]);
                        if (error)
                            return "Infos." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCTopMultiplierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCTopMultiplierList} SCTopMultiplierList
             */
            SCTopMultiplierList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCTopMultiplierList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCTopMultiplierList();
                if (object.Top != null) {
                    if (typeof object.Top !== "object")
                        throw TypeError(".Rugbull.Protocols.SCTopMultiplierList.Top: object expected");
                    message.Top = $root.Rugbull.Protocols.TopMultiplierModel.fromObject(object.Top);
                }
                if (object.Infos) {
                    if (!Array.isArray(object.Infos))
                        throw TypeError(".Rugbull.Protocols.SCTopMultiplierList.Infos: array expected");
                    message.Infos = [];
                    for (let i = 0; i < object.Infos.length; ++i) {
                        if (typeof object.Infos[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCTopMultiplierList.Infos: object expected");
                        message.Infos[i] = $root.Rugbull.Protocols.TopMultiplierModel.fromObject(object.Infos[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCTopMultiplierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {Rugbull.Protocols.SCTopMultiplierList} message SCTopMultiplierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCTopMultiplierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Infos = [];
                if (options.defaults)
                    object.Top = null;
                if (message.Top != null && message.hasOwnProperty("Top"))
                    object.Top = $root.Rugbull.Protocols.TopMultiplierModel.toObject(message.Top, options);
                if (message.Infos && message.Infos.length) {
                    object.Infos = [];
                    for (let j = 0; j < message.Infos.length; ++j)
                        object.Infos[j] = $root.Rugbull.Protocols.TopMultiplierModel.toObject(message.Infos[j], options);
                }
                return object;
            };

            /**
             * Converts this SCTopMultiplierList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCTopMultiplierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCTopMultiplierList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCTopMultiplierList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCTopMultiplierList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCTopMultiplierList";
            };

            return SCTopMultiplierList;
        })();

        Protocols.SCTaskList = (function() {

            /**
             * Properties of a SCTaskList.
             * @memberof Rugbull.Protocols
             * @interface ISCTaskList
             * @property {Array.<Rugbull.Protocols.ITaskData>|null} [Tasks] SCTaskList Tasks
             */

            /**
             * Constructs a new SCTaskList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCTaskList.
             * @implements ISCTaskList
             * @constructor
             * @param {Rugbull.Protocols.ISCTaskList=} [properties] Properties to set
             */
            function SCTaskList(properties) {
                this.Tasks = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCTaskList Tasks.
             * @member {Array.<Rugbull.Protocols.ITaskData>} Tasks
             * @memberof Rugbull.Protocols.SCTaskList
             * @instance
             */
            SCTaskList.prototype.Tasks = $util.emptyArray;

            /**
             * Creates a new SCTaskList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Rugbull.Protocols.ISCTaskList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCTaskList} SCTaskList instance
             */
            SCTaskList.create = function create(properties) {
                return new SCTaskList(properties);
            };

            /**
             * Encodes the specified SCTaskList message. Does not implicitly {@link Rugbull.Protocols.SCTaskList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Rugbull.Protocols.ISCTaskList} message SCTaskList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTaskList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Tasks != null && message.Tasks.length)
                    for (let i = 0; i < message.Tasks.length; ++i)
                        $root.Rugbull.Protocols.TaskData.encode(message.Tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCTaskList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCTaskList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Rugbull.Protocols.ISCTaskList} message SCTaskList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTaskList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCTaskList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCTaskList} SCTaskList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTaskList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCTaskList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Tasks && message.Tasks.length))
                                message.Tasks = [];
                            message.Tasks.push($root.Rugbull.Protocols.TaskData.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCTaskList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCTaskList} SCTaskList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTaskList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCTaskList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCTaskList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Tasks != null && message.hasOwnProperty("Tasks")) {
                    if (!Array.isArray(message.Tasks))
                        return "Tasks: array expected";
                    for (let i = 0; i < message.Tasks.length; ++i) {
                        let error = $root.Rugbull.Protocols.TaskData.verify(message.Tasks[i]);
                        if (error)
                            return "Tasks." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCTaskList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCTaskList} SCTaskList
             */
            SCTaskList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCTaskList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCTaskList();
                if (object.Tasks) {
                    if (!Array.isArray(object.Tasks))
                        throw TypeError(".Rugbull.Protocols.SCTaskList.Tasks: array expected");
                    message.Tasks = [];
                    for (let i = 0; i < object.Tasks.length; ++i) {
                        if (typeof object.Tasks[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCTaskList.Tasks: object expected");
                        message.Tasks[i] = $root.Rugbull.Protocols.TaskData.fromObject(object.Tasks[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCTaskList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {Rugbull.Protocols.SCTaskList} message SCTaskList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCTaskList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Tasks = [];
                if (message.Tasks && message.Tasks.length) {
                    object.Tasks = [];
                    for (let j = 0; j < message.Tasks.length; ++j)
                        object.Tasks[j] = $root.Rugbull.Protocols.TaskData.toObject(message.Tasks[j], options);
                }
                return object;
            };

            /**
             * Converts this SCTaskList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCTaskList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCTaskList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCTaskList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCTaskList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCTaskList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCTaskList";
            };

            return SCTaskList;
        })();

        Protocols.CSClaimReward = (function() {

            /**
             * Properties of a CSClaimReward.
             * @memberof Rugbull.Protocols
             * @interface ICSClaimReward
             * @property {number|null} [RewardId] CSClaimReward RewardId
             */

            /**
             * Constructs a new CSClaimReward.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSClaimReward.
             * @implements ICSClaimReward
             * @constructor
             * @param {Rugbull.Protocols.ICSClaimReward=} [properties] Properties to set
             */
            function CSClaimReward(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSClaimReward RewardId.
             * @member {number} RewardId
             * @memberof Rugbull.Protocols.CSClaimReward
             * @instance
             */
            CSClaimReward.prototype.RewardId = 0;

            /**
             * Creates a new CSClaimReward instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Rugbull.Protocols.ICSClaimReward=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSClaimReward} CSClaimReward instance
             */
            CSClaimReward.create = function create(properties) {
                return new CSClaimReward(properties);
            };

            /**
             * Encodes the specified CSClaimReward message. Does not implicitly {@link Rugbull.Protocols.CSClaimReward.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Rugbull.Protocols.ICSClaimReward} message CSClaimReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimReward.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RewardId != null && Object.hasOwnProperty.call(message, "RewardId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.RewardId);
                return writer;
            };

            /**
             * Encodes the specified CSClaimReward message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSClaimReward.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Rugbull.Protocols.ICSClaimReward} message CSClaimReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimReward.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSClaimReward message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSClaimReward} CSClaimReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimReward.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSClaimReward();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.RewardId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSClaimReward message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSClaimReward} CSClaimReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimReward.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSClaimReward message.
             * @function verify
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSClaimReward.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RewardId != null && message.hasOwnProperty("RewardId"))
                    if (!$util.isInteger(message.RewardId))
                        return "RewardId: integer expected";
                return null;
            };

            /**
             * Creates a CSClaimReward message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSClaimReward} CSClaimReward
             */
            CSClaimReward.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSClaimReward)
                    return object;
                let message = new $root.Rugbull.Protocols.CSClaimReward();
                if (object.RewardId != null)
                    message.RewardId = object.RewardId | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSClaimReward message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {Rugbull.Protocols.CSClaimReward} message CSClaimReward
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSClaimReward.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.RewardId = 0;
                if (message.RewardId != null && message.hasOwnProperty("RewardId"))
                    object.RewardId = message.RewardId;
                return object;
            };

            /**
             * Converts this CSClaimReward to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSClaimReward
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSClaimReward.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSClaimReward
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSClaimReward
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSClaimReward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSClaimReward";
            };

            return CSClaimReward;
        })();

        Protocols.SCClaimReward = (function() {

            /**
             * Properties of a SCClaimReward.
             * @memberof Rugbull.Protocols
             * @interface ISCClaimReward
             * @property {string|null} [RewardCoin] SCClaimReward RewardCoin
             * @property {number|null} [RewardDiamon] SCClaimReward RewardDiamon
             * @property {number|null} [RewardFood] SCClaimReward RewardFood
             * @property {Array.<Rugbull.Protocols.IFishData>|null} [RewardFishes] SCClaimReward RewardFishes
             * @property {Rugbull.Protocols.ITaskData|null} [NextTask] SCClaimReward NextTask
             * @property {string|null} [RewardCash] SCClaimReward RewardCash
             */

            /**
             * Constructs a new SCClaimReward.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCClaimReward.
             * @implements ISCClaimReward
             * @constructor
             * @param {Rugbull.Protocols.ISCClaimReward=} [properties] Properties to set
             */
            function SCClaimReward(properties) {
                this.RewardFishes = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCClaimReward RewardCoin.
             * @member {string} RewardCoin
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.RewardCoin = "";

            /**
             * SCClaimReward RewardDiamon.
             * @member {number} RewardDiamon
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.RewardDiamon = 0;

            /**
             * SCClaimReward RewardFood.
             * @member {number} RewardFood
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.RewardFood = 0;

            /**
             * SCClaimReward RewardFishes.
             * @member {Array.<Rugbull.Protocols.IFishData>} RewardFishes
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.RewardFishes = $util.emptyArray;

            /**
             * SCClaimReward NextTask.
             * @member {Rugbull.Protocols.ITaskData|null|undefined} NextTask
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.NextTask = null;

            /**
             * SCClaimReward RewardCash.
             * @member {string} RewardCash
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             */
            SCClaimReward.prototype.RewardCash = "";

            /**
             * Creates a new SCClaimReward instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Rugbull.Protocols.ISCClaimReward=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCClaimReward} SCClaimReward instance
             */
            SCClaimReward.create = function create(properties) {
                return new SCClaimReward(properties);
            };

            /**
             * Encodes the specified SCClaimReward message. Does not implicitly {@link Rugbull.Protocols.SCClaimReward.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Rugbull.Protocols.ISCClaimReward} message SCClaimReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCClaimReward.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RewardCoin != null && Object.hasOwnProperty.call(message, "RewardCoin"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.RewardCoin);
                if (message.RewardDiamon != null && Object.hasOwnProperty.call(message, "RewardDiamon"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.RewardDiamon);
                if (message.RewardFood != null && Object.hasOwnProperty.call(message, "RewardFood"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.RewardFood);
                if (message.RewardFishes != null && message.RewardFishes.length)
                    for (let i = 0; i < message.RewardFishes.length; ++i)
                        $root.Rugbull.Protocols.FishData.encode(message.RewardFishes[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.NextTask != null && Object.hasOwnProperty.call(message, "NextTask"))
                    $root.Rugbull.Protocols.TaskData.encode(message.NextTask, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.RewardCash != null && Object.hasOwnProperty.call(message, "RewardCash"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.RewardCash);
                return writer;
            };

            /**
             * Encodes the specified SCClaimReward message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCClaimReward.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Rugbull.Protocols.ISCClaimReward} message SCClaimReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCClaimReward.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCClaimReward message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCClaimReward} SCClaimReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCClaimReward.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCClaimReward();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.RewardCoin = reader.string();
                            break;
                        }
                    case 3: {
                            message.RewardDiamon = reader.int32();
                            break;
                        }
                    case 4: {
                            message.RewardFood = reader.int32();
                            break;
                        }
                    case 6: {
                            if (!(message.RewardFishes && message.RewardFishes.length))
                                message.RewardFishes = [];
                            message.RewardFishes.push($root.Rugbull.Protocols.FishData.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.NextTask = $root.Rugbull.Protocols.TaskData.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.RewardCash = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCClaimReward message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCClaimReward} SCClaimReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCClaimReward.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCClaimReward message.
             * @function verify
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCClaimReward.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RewardCoin != null && message.hasOwnProperty("RewardCoin"))
                    if (!$util.isString(message.RewardCoin))
                        return "RewardCoin: string expected";
                if (message.RewardDiamon != null && message.hasOwnProperty("RewardDiamon"))
                    if (!$util.isInteger(message.RewardDiamon))
                        return "RewardDiamon: integer expected";
                if (message.RewardFood != null && message.hasOwnProperty("RewardFood"))
                    if (!$util.isInteger(message.RewardFood))
                        return "RewardFood: integer expected";
                if (message.RewardFishes != null && message.hasOwnProperty("RewardFishes")) {
                    if (!Array.isArray(message.RewardFishes))
                        return "RewardFishes: array expected";
                    for (let i = 0; i < message.RewardFishes.length; ++i) {
                        let error = $root.Rugbull.Protocols.FishData.verify(message.RewardFishes[i]);
                        if (error)
                            return "RewardFishes." + error;
                    }
                }
                if (message.NextTask != null && message.hasOwnProperty("NextTask")) {
                    let error = $root.Rugbull.Protocols.TaskData.verify(message.NextTask);
                    if (error)
                        return "NextTask." + error;
                }
                if (message.RewardCash != null && message.hasOwnProperty("RewardCash"))
                    if (!$util.isString(message.RewardCash))
                        return "RewardCash: string expected";
                return null;
            };

            /**
             * Creates a SCClaimReward message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCClaimReward} SCClaimReward
             */
            SCClaimReward.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCClaimReward)
                    return object;
                let message = new $root.Rugbull.Protocols.SCClaimReward();
                if (object.RewardCoin != null)
                    message.RewardCoin = String(object.RewardCoin);
                if (object.RewardDiamon != null)
                    message.RewardDiamon = object.RewardDiamon | 0;
                if (object.RewardFood != null)
                    message.RewardFood = object.RewardFood | 0;
                if (object.RewardFishes) {
                    if (!Array.isArray(object.RewardFishes))
                        throw TypeError(".Rugbull.Protocols.SCClaimReward.RewardFishes: array expected");
                    message.RewardFishes = [];
                    for (let i = 0; i < object.RewardFishes.length; ++i) {
                        if (typeof object.RewardFishes[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCClaimReward.RewardFishes: object expected");
                        message.RewardFishes[i] = $root.Rugbull.Protocols.FishData.fromObject(object.RewardFishes[i]);
                    }
                }
                if (object.NextTask != null) {
                    if (typeof object.NextTask !== "object")
                        throw TypeError(".Rugbull.Protocols.SCClaimReward.NextTask: object expected");
                    message.NextTask = $root.Rugbull.Protocols.TaskData.fromObject(object.NextTask);
                }
                if (object.RewardCash != null)
                    message.RewardCash = String(object.RewardCash);
                return message;
            };

            /**
             * Creates a plain object from a SCClaimReward message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {Rugbull.Protocols.SCClaimReward} message SCClaimReward
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCClaimReward.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.RewardFishes = [];
                if (options.defaults) {
                    object.RewardCoin = "";
                    object.RewardDiamon = 0;
                    object.RewardFood = 0;
                    object.NextTask = null;
                    object.RewardCash = "";
                }
                if (message.RewardCoin != null && message.hasOwnProperty("RewardCoin"))
                    object.RewardCoin = message.RewardCoin;
                if (message.RewardDiamon != null && message.hasOwnProperty("RewardDiamon"))
                    object.RewardDiamon = message.RewardDiamon;
                if (message.RewardFood != null && message.hasOwnProperty("RewardFood"))
                    object.RewardFood = message.RewardFood;
                if (message.RewardFishes && message.RewardFishes.length) {
                    object.RewardFishes = [];
                    for (let j = 0; j < message.RewardFishes.length; ++j)
                        object.RewardFishes[j] = $root.Rugbull.Protocols.FishData.toObject(message.RewardFishes[j], options);
                }
                if (message.NextTask != null && message.hasOwnProperty("NextTask"))
                    object.NextTask = $root.Rugbull.Protocols.TaskData.toObject(message.NextTask, options);
                if (message.RewardCash != null && message.hasOwnProperty("RewardCash"))
                    object.RewardCash = message.RewardCash;
                return object;
            };

            /**
             * Converts this SCClaimReward to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCClaimReward
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCClaimReward.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCClaimReward
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCClaimReward
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCClaimReward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCClaimReward";
            };

            return SCClaimReward;
        })();

        Protocols.PushTask = (function() {

            /**
             * Properties of a PushTask.
             * @memberof Rugbull.Protocols
             * @interface IPushTask
             * @property {Rugbull.Protocols.IRewardData|null} [RewardData] PushTask RewardData
             * @property {Array.<Rugbull.Protocols.ITaskData>|null} [Tasks] PushTask Tasks
             * @property {boolean|null} [OpenJoinChannel] PushTask OpenJoinChannel
             */

            /**
             * Constructs a new PushTask.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushTask.
             * @implements IPushTask
             * @constructor
             * @param {Rugbull.Protocols.IPushTask=} [properties] Properties to set
             */
            function PushTask(properties) {
                this.Tasks = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushTask RewardData.
             * @member {Rugbull.Protocols.IRewardData|null|undefined} RewardData
             * @memberof Rugbull.Protocols.PushTask
             * @instance
             */
            PushTask.prototype.RewardData = null;

            /**
             * PushTask Tasks.
             * @member {Array.<Rugbull.Protocols.ITaskData>} Tasks
             * @memberof Rugbull.Protocols.PushTask
             * @instance
             */
            PushTask.prototype.Tasks = $util.emptyArray;

            /**
             * PushTask OpenJoinChannel.
             * @member {boolean} OpenJoinChannel
             * @memberof Rugbull.Protocols.PushTask
             * @instance
             */
            PushTask.prototype.OpenJoinChannel = false;

            /**
             * Creates a new PushTask instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Rugbull.Protocols.IPushTask=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushTask} PushTask instance
             */
            PushTask.create = function create(properties) {
                return new PushTask(properties);
            };

            /**
             * Encodes the specified PushTask message. Does not implicitly {@link Rugbull.Protocols.PushTask.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Rugbull.Protocols.IPushTask} message PushTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RewardData != null && Object.hasOwnProperty.call(message, "RewardData"))
                    $root.Rugbull.Protocols.RewardData.encode(message.RewardData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.Tasks != null && message.Tasks.length)
                    for (let i = 0; i < message.Tasks.length; ++i)
                        $root.Rugbull.Protocols.TaskData.encode(message.Tasks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.OpenJoinChannel != null && Object.hasOwnProperty.call(message, "OpenJoinChannel"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.OpenJoinChannel);
                return writer;
            };

            /**
             * Encodes the specified PushTask message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Rugbull.Protocols.IPushTask} message PushTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushTask message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushTask} PushTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.RewardData = $root.Rugbull.Protocols.RewardData.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.Tasks && message.Tasks.length))
                                message.Tasks = [];
                            message.Tasks.push($root.Rugbull.Protocols.TaskData.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.OpenJoinChannel = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushTask} PushTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushTask message.
             * @function verify
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RewardData != null && message.hasOwnProperty("RewardData")) {
                    let error = $root.Rugbull.Protocols.RewardData.verify(message.RewardData);
                    if (error)
                        return "RewardData." + error;
                }
                if (message.Tasks != null && message.hasOwnProperty("Tasks")) {
                    if (!Array.isArray(message.Tasks))
                        return "Tasks: array expected";
                    for (let i = 0; i < message.Tasks.length; ++i) {
                        let error = $root.Rugbull.Protocols.TaskData.verify(message.Tasks[i]);
                        if (error)
                            return "Tasks." + error;
                    }
                }
                if (message.OpenJoinChannel != null && message.hasOwnProperty("OpenJoinChannel"))
                    if (typeof message.OpenJoinChannel !== "boolean")
                        return "OpenJoinChannel: boolean expected";
                return null;
            };

            /**
             * Creates a PushTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushTask} PushTask
             */
            PushTask.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushTask)
                    return object;
                let message = new $root.Rugbull.Protocols.PushTask();
                if (object.RewardData != null) {
                    if (typeof object.RewardData !== "object")
                        throw TypeError(".Rugbull.Protocols.PushTask.RewardData: object expected");
                    message.RewardData = $root.Rugbull.Protocols.RewardData.fromObject(object.RewardData);
                }
                if (object.Tasks) {
                    if (!Array.isArray(object.Tasks))
                        throw TypeError(".Rugbull.Protocols.PushTask.Tasks: array expected");
                    message.Tasks = [];
                    for (let i = 0; i < object.Tasks.length; ++i) {
                        if (typeof object.Tasks[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushTask.Tasks: object expected");
                        message.Tasks[i] = $root.Rugbull.Protocols.TaskData.fromObject(object.Tasks[i]);
                    }
                }
                if (object.OpenJoinChannel != null)
                    message.OpenJoinChannel = Boolean(object.OpenJoinChannel);
                return message;
            };

            /**
             * Creates a plain object from a PushTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {Rugbull.Protocols.PushTask} message PushTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Tasks = [];
                if (options.defaults) {
                    object.RewardData = null;
                    object.OpenJoinChannel = false;
                }
                if (message.RewardData != null && message.hasOwnProperty("RewardData"))
                    object.RewardData = $root.Rugbull.Protocols.RewardData.toObject(message.RewardData, options);
                if (message.Tasks && message.Tasks.length) {
                    object.Tasks = [];
                    for (let j = 0; j < message.Tasks.length; ++j)
                        object.Tasks[j] = $root.Rugbull.Protocols.TaskData.toObject(message.Tasks[j], options);
                }
                if (message.OpenJoinChannel != null && message.hasOwnProperty("OpenJoinChannel"))
                    object.OpenJoinChannel = message.OpenJoinChannel;
                return object;
            };

            /**
             * Converts this PushTask to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushTask
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushTask
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushTask";
            };

            return PushTask;
        })();

        Protocols.PushAdTask = (function() {

            /**
             * Properties of a PushAdTask.
             * @memberof Rugbull.Protocols
             * @interface IPushAdTask
             * @property {Array.<Rugbull.Protocols.IAdTaskData>|null} [Tasks] PushAdTask Tasks
             */

            /**
             * Constructs a new PushAdTask.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushAdTask.
             * @implements IPushAdTask
             * @constructor
             * @param {Rugbull.Protocols.IPushAdTask=} [properties] Properties to set
             */
            function PushAdTask(properties) {
                this.Tasks = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushAdTask Tasks.
             * @member {Array.<Rugbull.Protocols.IAdTaskData>} Tasks
             * @memberof Rugbull.Protocols.PushAdTask
             * @instance
             */
            PushAdTask.prototype.Tasks = $util.emptyArray;

            /**
             * Creates a new PushAdTask instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Rugbull.Protocols.IPushAdTask=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushAdTask} PushAdTask instance
             */
            PushAdTask.create = function create(properties) {
                return new PushAdTask(properties);
            };

            /**
             * Encodes the specified PushAdTask message. Does not implicitly {@link Rugbull.Protocols.PushAdTask.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Rugbull.Protocols.IPushAdTask} message PushAdTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushAdTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Tasks != null && message.Tasks.length)
                    for (let i = 0; i < message.Tasks.length; ++i)
                        $root.Rugbull.Protocols.AdTaskData.encode(message.Tasks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PushAdTask message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushAdTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Rugbull.Protocols.IPushAdTask} message PushAdTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushAdTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushAdTask message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushAdTask} PushAdTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushAdTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushAdTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.Tasks && message.Tasks.length))
                                message.Tasks = [];
                            message.Tasks.push($root.Rugbull.Protocols.AdTaskData.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushAdTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushAdTask} PushAdTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushAdTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushAdTask message.
             * @function verify
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushAdTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Tasks != null && message.hasOwnProperty("Tasks")) {
                    if (!Array.isArray(message.Tasks))
                        return "Tasks: array expected";
                    for (let i = 0; i < message.Tasks.length; ++i) {
                        let error = $root.Rugbull.Protocols.AdTaskData.verify(message.Tasks[i]);
                        if (error)
                            return "Tasks." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PushAdTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushAdTask} PushAdTask
             */
            PushAdTask.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushAdTask)
                    return object;
                let message = new $root.Rugbull.Protocols.PushAdTask();
                if (object.Tasks) {
                    if (!Array.isArray(object.Tasks))
                        throw TypeError(".Rugbull.Protocols.PushAdTask.Tasks: array expected");
                    message.Tasks = [];
                    for (let i = 0; i < object.Tasks.length; ++i) {
                        if (typeof object.Tasks[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.PushAdTask.Tasks: object expected");
                        message.Tasks[i] = $root.Rugbull.Protocols.AdTaskData.fromObject(object.Tasks[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PushAdTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {Rugbull.Protocols.PushAdTask} message PushAdTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushAdTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Tasks = [];
                if (message.Tasks && message.Tasks.length) {
                    object.Tasks = [];
                    for (let j = 0; j < message.Tasks.length; ++j)
                        object.Tasks[j] = $root.Rugbull.Protocols.AdTaskData.toObject(message.Tasks[j], options);
                }
                return object;
            };

            /**
             * Converts this PushAdTask to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushAdTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushAdTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushAdTask
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushAdTask
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushAdTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushAdTask";
            };

            return PushAdTask;
        })();

        Protocols.CSDoTask = (function() {

            /**
             * Properties of a CSDoTask.
             * @memberof Rugbull.Protocols
             * @interface ICSDoTask
             * @property {number|null} [TaskId] CSDoTask TaskId
             * @property {Rugbull.Protocols.TaskType|null} [TaskType] CSDoTask TaskType
             */

            /**
             * Constructs a new CSDoTask.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSDoTask.
             * @implements ICSDoTask
             * @constructor
             * @param {Rugbull.Protocols.ICSDoTask=} [properties] Properties to set
             */
            function CSDoTask(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSDoTask TaskId.
             * @member {number} TaskId
             * @memberof Rugbull.Protocols.CSDoTask
             * @instance
             */
            CSDoTask.prototype.TaskId = 0;

            /**
             * CSDoTask TaskType.
             * @member {Rugbull.Protocols.TaskType} TaskType
             * @memberof Rugbull.Protocols.CSDoTask
             * @instance
             */
            CSDoTask.prototype.TaskType = 0;

            /**
             * Creates a new CSDoTask instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Rugbull.Protocols.ICSDoTask=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSDoTask} CSDoTask instance
             */
            CSDoTask.create = function create(properties) {
                return new CSDoTask(properties);
            };

            /**
             * Encodes the specified CSDoTask message. Does not implicitly {@link Rugbull.Protocols.CSDoTask.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Rugbull.Protocols.ICSDoTask} message CSDoTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSDoTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TaskId != null && Object.hasOwnProperty.call(message, "TaskId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.TaskId);
                if (message.TaskType != null && Object.hasOwnProperty.call(message, "TaskType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.TaskType);
                return writer;
            };

            /**
             * Encodes the specified CSDoTask message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSDoTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Rugbull.Protocols.ICSDoTask} message CSDoTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSDoTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSDoTask message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSDoTask} CSDoTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSDoTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSDoTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TaskId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.TaskType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSDoTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSDoTask} CSDoTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSDoTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSDoTask message.
             * @function verify
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSDoTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    if (!$util.isInteger(message.TaskId))
                        return "TaskId: integer expected";
                if (message.TaskType != null && message.hasOwnProperty("TaskType"))
                    switch (message.TaskType) {
                    default:
                        return "TaskType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSDoTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSDoTask} CSDoTask
             */
            CSDoTask.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSDoTask)
                    return object;
                let message = new $root.Rugbull.Protocols.CSDoTask();
                if (object.TaskId != null)
                    message.TaskId = object.TaskId | 0;
                switch (object.TaskType) {
                default:
                    if (typeof object.TaskType === "number") {
                        message.TaskType = object.TaskType;
                        break;
                    }
                    break;
                case "TASK_TYPE_NONE":
                case 0:
                    message.TaskType = 0;
                    break;
                case "TASK_TYPE_DAILY":
                case 1:
                    message.TaskType = 1;
                    break;
                case "TASK_TYPE_ACHIEVEMENT":
                case 2:
                    message.TaskType = 2;
                    break;
                case "TASK_TYPE_ADVERTISEMENT":
                case 3:
                    message.TaskType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSDoTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {Rugbull.Protocols.CSDoTask} message CSDoTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSDoTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.TaskId = 0;
                    object.TaskType = options.enums === String ? "TASK_TYPE_NONE" : 0;
                }
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    object.TaskId = message.TaskId;
                if (message.TaskType != null && message.hasOwnProperty("TaskType"))
                    object.TaskType = options.enums === String ? $root.Rugbull.Protocols.TaskType[message.TaskType] === undefined ? message.TaskType : $root.Rugbull.Protocols.TaskType[message.TaskType] : message.TaskType;
                return object;
            };

            /**
             * Converts this CSDoTask to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSDoTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSDoTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSDoTask
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSDoTask
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSDoTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSDoTask";
            };

            return CSDoTask;
        })();

        Protocols.CSClaimRewardAchieve = (function() {

            /**
             * Properties of a CSClaimRewardAchieve.
             * @memberof Rugbull.Protocols
             * @interface ICSClaimRewardAchieve
             * @property {number|null} [TaskId] CSClaimRewardAchieve TaskId
             */

            /**
             * Constructs a new CSClaimRewardAchieve.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSClaimRewardAchieve.
             * @implements ICSClaimRewardAchieve
             * @constructor
             * @param {Rugbull.Protocols.ICSClaimRewardAchieve=} [properties] Properties to set
             */
            function CSClaimRewardAchieve(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSClaimRewardAchieve TaskId.
             * @member {number} TaskId
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @instance
             */
            CSClaimRewardAchieve.prototype.TaskId = 0;

            /**
             * Creates a new CSClaimRewardAchieve instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Rugbull.Protocols.ICSClaimRewardAchieve=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSClaimRewardAchieve} CSClaimRewardAchieve instance
             */
            CSClaimRewardAchieve.create = function create(properties) {
                return new CSClaimRewardAchieve(properties);
            };

            /**
             * Encodes the specified CSClaimRewardAchieve message. Does not implicitly {@link Rugbull.Protocols.CSClaimRewardAchieve.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Rugbull.Protocols.ICSClaimRewardAchieve} message CSClaimRewardAchieve message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimRewardAchieve.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TaskId != null && Object.hasOwnProperty.call(message, "TaskId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.TaskId);
                return writer;
            };

            /**
             * Encodes the specified CSClaimRewardAchieve message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSClaimRewardAchieve.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Rugbull.Protocols.ICSClaimRewardAchieve} message CSClaimRewardAchieve message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimRewardAchieve.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSClaimRewardAchieve message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSClaimRewardAchieve} CSClaimRewardAchieve
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimRewardAchieve.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSClaimRewardAchieve();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TaskId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSClaimRewardAchieve message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSClaimRewardAchieve} CSClaimRewardAchieve
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimRewardAchieve.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSClaimRewardAchieve message.
             * @function verify
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSClaimRewardAchieve.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    if (!$util.isInteger(message.TaskId))
                        return "TaskId: integer expected";
                return null;
            };

            /**
             * Creates a CSClaimRewardAchieve message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSClaimRewardAchieve} CSClaimRewardAchieve
             */
            CSClaimRewardAchieve.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSClaimRewardAchieve)
                    return object;
                let message = new $root.Rugbull.Protocols.CSClaimRewardAchieve();
                if (object.TaskId != null)
                    message.TaskId = object.TaskId | 0;
                return message;
            };

            /**
             * Creates a plain object from a CSClaimRewardAchieve message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {Rugbull.Protocols.CSClaimRewardAchieve} message CSClaimRewardAchieve
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSClaimRewardAchieve.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.TaskId = 0;
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    object.TaskId = message.TaskId;
                return object;
            };

            /**
             * Converts this CSClaimRewardAchieve to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSClaimRewardAchieve.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSClaimRewardAchieve
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSClaimRewardAchieve
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSClaimRewardAchieve.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSClaimRewardAchieve";
            };

            return CSClaimRewardAchieve;
        })();

        Protocols.CSClaimFollows = (function() {

            /**
             * Properties of a CSClaimFollows.
             * @memberof Rugbull.Protocols
             * @interface ICSClaimFollows
             * @property {Array.<number>|null} [TaskIds] CSClaimFollows TaskIds
             */

            /**
             * Constructs a new CSClaimFollows.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSClaimFollows.
             * @implements ICSClaimFollows
             * @constructor
             * @param {Rugbull.Protocols.ICSClaimFollows=} [properties] Properties to set
             */
            function CSClaimFollows(properties) {
                this.TaskIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSClaimFollows TaskIds.
             * @member {Array.<number>} TaskIds
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @instance
             */
            CSClaimFollows.prototype.TaskIds = $util.emptyArray;

            /**
             * Creates a new CSClaimFollows instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Rugbull.Protocols.ICSClaimFollows=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSClaimFollows} CSClaimFollows instance
             */
            CSClaimFollows.create = function create(properties) {
                return new CSClaimFollows(properties);
            };

            /**
             * Encodes the specified CSClaimFollows message. Does not implicitly {@link Rugbull.Protocols.CSClaimFollows.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Rugbull.Protocols.ICSClaimFollows} message CSClaimFollows message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimFollows.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TaskIds != null && message.TaskIds.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.TaskIds.length; ++i)
                        writer.int32(message.TaskIds[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified CSClaimFollows message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSClaimFollows.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Rugbull.Protocols.ICSClaimFollows} message CSClaimFollows message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSClaimFollows.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSClaimFollows message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSClaimFollows} CSClaimFollows
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimFollows.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSClaimFollows();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.TaskIds && message.TaskIds.length))
                                message.TaskIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.TaskIds.push(reader.int32());
                            } else
                                message.TaskIds.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSClaimFollows message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSClaimFollows} CSClaimFollows
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSClaimFollows.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSClaimFollows message.
             * @function verify
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSClaimFollows.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TaskIds != null && message.hasOwnProperty("TaskIds")) {
                    if (!Array.isArray(message.TaskIds))
                        return "TaskIds: array expected";
                    for (let i = 0; i < message.TaskIds.length; ++i)
                        if (!$util.isInteger(message.TaskIds[i]))
                            return "TaskIds: integer[] expected";
                }
                return null;
            };

            /**
             * Creates a CSClaimFollows message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSClaimFollows} CSClaimFollows
             */
            CSClaimFollows.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSClaimFollows)
                    return object;
                let message = new $root.Rugbull.Protocols.CSClaimFollows();
                if (object.TaskIds) {
                    if (!Array.isArray(object.TaskIds))
                        throw TypeError(".Rugbull.Protocols.CSClaimFollows.TaskIds: array expected");
                    message.TaskIds = [];
                    for (let i = 0; i < object.TaskIds.length; ++i)
                        message.TaskIds[i] = object.TaskIds[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSClaimFollows message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {Rugbull.Protocols.CSClaimFollows} message CSClaimFollows
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSClaimFollows.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.TaskIds = [];
                if (message.TaskIds && message.TaskIds.length) {
                    object.TaskIds = [];
                    for (let j = 0; j < message.TaskIds.length; ++j)
                        object.TaskIds[j] = message.TaskIds[j];
                }
                return object;
            };

            /**
             * Converts this CSClaimFollows to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSClaimFollows.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSClaimFollows
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSClaimFollows
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSClaimFollows.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSClaimFollows";
            };

            return CSClaimFollows;
        })();

        Protocols.SCCheckRewardCash = (function() {

            /**
             * Properties of a SCCheckRewardCash.
             * @memberof Rugbull.Protocols
             * @interface ISCCheckRewardCash
             * @property {string|null} [CheckWithdrawCash] SCCheckRewardCash CheckWithdrawCash
             */

            /**
             * Constructs a new SCCheckRewardCash.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCCheckRewardCash.
             * @implements ISCCheckRewardCash
             * @constructor
             * @param {Rugbull.Protocols.ISCCheckRewardCash=} [properties] Properties to set
             */
            function SCCheckRewardCash(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCCheckRewardCash CheckWithdrawCash.
             * @member {string} CheckWithdrawCash
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @instance
             */
            SCCheckRewardCash.prototype.CheckWithdrawCash = "";

            /**
             * Creates a new SCCheckRewardCash instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Rugbull.Protocols.ISCCheckRewardCash=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCCheckRewardCash} SCCheckRewardCash instance
             */
            SCCheckRewardCash.create = function create(properties) {
                return new SCCheckRewardCash(properties);
            };

            /**
             * Encodes the specified SCCheckRewardCash message. Does not implicitly {@link Rugbull.Protocols.SCCheckRewardCash.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Rugbull.Protocols.ISCCheckRewardCash} message SCCheckRewardCash message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCCheckRewardCash.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.CheckWithdrawCash != null && Object.hasOwnProperty.call(message, "CheckWithdrawCash"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.CheckWithdrawCash);
                return writer;
            };

            /**
             * Encodes the specified SCCheckRewardCash message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCCheckRewardCash.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Rugbull.Protocols.ISCCheckRewardCash} message SCCheckRewardCash message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCCheckRewardCash.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCCheckRewardCash message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCCheckRewardCash} SCCheckRewardCash
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCCheckRewardCash.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCCheckRewardCash();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.CheckWithdrawCash = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCCheckRewardCash message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCCheckRewardCash} SCCheckRewardCash
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCCheckRewardCash.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCCheckRewardCash message.
             * @function verify
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCCheckRewardCash.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.CheckWithdrawCash != null && message.hasOwnProperty("CheckWithdrawCash"))
                    if (!$util.isString(message.CheckWithdrawCash))
                        return "CheckWithdrawCash: string expected";
                return null;
            };

            /**
             * Creates a SCCheckRewardCash message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCCheckRewardCash} SCCheckRewardCash
             */
            SCCheckRewardCash.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCCheckRewardCash)
                    return object;
                let message = new $root.Rugbull.Protocols.SCCheckRewardCash();
                if (object.CheckWithdrawCash != null)
                    message.CheckWithdrawCash = String(object.CheckWithdrawCash);
                return message;
            };

            /**
             * Creates a plain object from a SCCheckRewardCash message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {Rugbull.Protocols.SCCheckRewardCash} message SCCheckRewardCash
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCCheckRewardCash.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.CheckWithdrawCash = "";
                if (message.CheckWithdrawCash != null && message.hasOwnProperty("CheckWithdrawCash"))
                    object.CheckWithdrawCash = message.CheckWithdrawCash;
                return object;
            };

            /**
             * Converts this SCCheckRewardCash to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCCheckRewardCash.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCCheckRewardCash
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCCheckRewardCash
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCCheckRewardCash.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCCheckRewardCash";
            };

            return SCCheckRewardCash;
        })();

        Protocols.CSCommitShareTask = (function() {

            /**
             * Properties of a CSCommitShareTask.
             * @memberof Rugbull.Protocols
             * @interface ICSCommitShareTask
             * @property {number|null} [TaskId] CSCommitShareTask TaskId
             * @property {string|null} [Link] CSCommitShareTask Link
             * @property {Uint8Array|null} [Image] CSCommitShareTask Image
             * @property {string|null} [Note] CSCommitShareTask Note
             */

            /**
             * Constructs a new CSCommitShareTask.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSCommitShareTask.
             * @implements ICSCommitShareTask
             * @constructor
             * @param {Rugbull.Protocols.ICSCommitShareTask=} [properties] Properties to set
             */
            function CSCommitShareTask(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSCommitShareTask TaskId.
             * @member {number} TaskId
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @instance
             */
            CSCommitShareTask.prototype.TaskId = 0;

            /**
             * CSCommitShareTask Link.
             * @member {string} Link
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @instance
             */
            CSCommitShareTask.prototype.Link = "";

            /**
             * CSCommitShareTask Image.
             * @member {Uint8Array} Image
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @instance
             */
            CSCommitShareTask.prototype.Image = $util.newBuffer([]);

            /**
             * CSCommitShareTask Note.
             * @member {string} Note
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @instance
             */
            CSCommitShareTask.prototype.Note = "";

            /**
             * Creates a new CSCommitShareTask instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Rugbull.Protocols.ICSCommitShareTask=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSCommitShareTask} CSCommitShareTask instance
             */
            CSCommitShareTask.create = function create(properties) {
                return new CSCommitShareTask(properties);
            };

            /**
             * Encodes the specified CSCommitShareTask message. Does not implicitly {@link Rugbull.Protocols.CSCommitShareTask.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Rugbull.Protocols.ICSCommitShareTask} message CSCommitShareTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSCommitShareTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TaskId != null && Object.hasOwnProperty.call(message, "TaskId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.TaskId);
                if (message.Link != null && Object.hasOwnProperty.call(message, "Link"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Link);
                if (message.Image != null && Object.hasOwnProperty.call(message, "Image"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.Image);
                if (message.Note != null && Object.hasOwnProperty.call(message, "Note"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Note);
                return writer;
            };

            /**
             * Encodes the specified CSCommitShareTask message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSCommitShareTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Rugbull.Protocols.ICSCommitShareTask} message CSCommitShareTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSCommitShareTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSCommitShareTask message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSCommitShareTask} CSCommitShareTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSCommitShareTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSCommitShareTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TaskId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Link = reader.string();
                            break;
                        }
                    case 3: {
                            message.Image = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.Note = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSCommitShareTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSCommitShareTask} CSCommitShareTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSCommitShareTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSCommitShareTask message.
             * @function verify
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSCommitShareTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    if (!$util.isInteger(message.TaskId))
                        return "TaskId: integer expected";
                if (message.Link != null && message.hasOwnProperty("Link"))
                    if (!$util.isString(message.Link))
                        return "Link: string expected";
                if (message.Image != null && message.hasOwnProperty("Image"))
                    if (!(message.Image && typeof message.Image.length === "number" || $util.isString(message.Image)))
                        return "Image: buffer expected";
                if (message.Note != null && message.hasOwnProperty("Note"))
                    if (!$util.isString(message.Note))
                        return "Note: string expected";
                return null;
            };

            /**
             * Creates a CSCommitShareTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSCommitShareTask} CSCommitShareTask
             */
            CSCommitShareTask.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSCommitShareTask)
                    return object;
                let message = new $root.Rugbull.Protocols.CSCommitShareTask();
                if (object.TaskId != null)
                    message.TaskId = object.TaskId | 0;
                if (object.Link != null)
                    message.Link = String(object.Link);
                if (object.Image != null)
                    if (typeof object.Image === "string")
                        $util.base64.decode(object.Image, message.Image = $util.newBuffer($util.base64.length(object.Image)), 0);
                    else if (object.Image.length >= 0)
                        message.Image = object.Image;
                if (object.Note != null)
                    message.Note = String(object.Note);
                return message;
            };

            /**
             * Creates a plain object from a CSCommitShareTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {Rugbull.Protocols.CSCommitShareTask} message CSCommitShareTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSCommitShareTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.TaskId = 0;
                    object.Link = "";
                    if (options.bytes === String)
                        object.Image = "";
                    else {
                        object.Image = [];
                        if (options.bytes !== Array)
                            object.Image = $util.newBuffer(object.Image);
                    }
                    object.Note = "";
                }
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    object.TaskId = message.TaskId;
                if (message.Link != null && message.hasOwnProperty("Link"))
                    object.Link = message.Link;
                if (message.Image != null && message.hasOwnProperty("Image"))
                    object.Image = options.bytes === String ? $util.base64.encode(message.Image, 0, message.Image.length) : options.bytes === Array ? Array.prototype.slice.call(message.Image) : message.Image;
                if (message.Note != null && message.hasOwnProperty("Note"))
                    object.Note = message.Note;
                return object;
            };

            /**
             * Converts this CSCommitShareTask to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSCommitShareTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSCommitShareTask
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSCommitShareTask
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSCommitShareTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSCommitShareTask";
            };

            return CSCommitShareTask;
        })();

        Protocols.SCShareTasks = (function() {

            /**
             * Properties of a SCShareTasks.
             * @memberof Rugbull.Protocols
             * @interface ISCShareTasks
             * @property {Array.<Rugbull.Protocols.IShareTaskData>|null} [Tasks] SCShareTasks Tasks
             */

            /**
             * Constructs a new SCShareTasks.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCShareTasks.
             * @implements ISCShareTasks
             * @constructor
             * @param {Rugbull.Protocols.ISCShareTasks=} [properties] Properties to set
             */
            function SCShareTasks(properties) {
                this.Tasks = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCShareTasks Tasks.
             * @member {Array.<Rugbull.Protocols.IShareTaskData>} Tasks
             * @memberof Rugbull.Protocols.SCShareTasks
             * @instance
             */
            SCShareTasks.prototype.Tasks = $util.emptyArray;

            /**
             * Creates a new SCShareTasks instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Rugbull.Protocols.ISCShareTasks=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCShareTasks} SCShareTasks instance
             */
            SCShareTasks.create = function create(properties) {
                return new SCShareTasks(properties);
            };

            /**
             * Encodes the specified SCShareTasks message. Does not implicitly {@link Rugbull.Protocols.SCShareTasks.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Rugbull.Protocols.ISCShareTasks} message SCShareTasks message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCShareTasks.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Tasks != null && message.Tasks.length)
                    for (let i = 0; i < message.Tasks.length; ++i)
                        $root.Rugbull.Protocols.ShareTaskData.encode(message.Tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCShareTasks message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCShareTasks.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Rugbull.Protocols.ISCShareTasks} message SCShareTasks message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCShareTasks.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCShareTasks message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCShareTasks} SCShareTasks
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCShareTasks.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCShareTasks();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Tasks && message.Tasks.length))
                                message.Tasks = [];
                            message.Tasks.push($root.Rugbull.Protocols.ShareTaskData.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCShareTasks message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCShareTasks} SCShareTasks
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCShareTasks.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCShareTasks message.
             * @function verify
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCShareTasks.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Tasks != null && message.hasOwnProperty("Tasks")) {
                    if (!Array.isArray(message.Tasks))
                        return "Tasks: array expected";
                    for (let i = 0; i < message.Tasks.length; ++i) {
                        let error = $root.Rugbull.Protocols.ShareTaskData.verify(message.Tasks[i]);
                        if (error)
                            return "Tasks." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCShareTasks message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCShareTasks} SCShareTasks
             */
            SCShareTasks.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCShareTasks)
                    return object;
                let message = new $root.Rugbull.Protocols.SCShareTasks();
                if (object.Tasks) {
                    if (!Array.isArray(object.Tasks))
                        throw TypeError(".Rugbull.Protocols.SCShareTasks.Tasks: array expected");
                    message.Tasks = [];
                    for (let i = 0; i < object.Tasks.length; ++i) {
                        if (typeof object.Tasks[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCShareTasks.Tasks: object expected");
                        message.Tasks[i] = $root.Rugbull.Protocols.ShareTaskData.fromObject(object.Tasks[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCShareTasks message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {Rugbull.Protocols.SCShareTasks} message SCShareTasks
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCShareTasks.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Tasks = [];
                if (message.Tasks && message.Tasks.length) {
                    object.Tasks = [];
                    for (let j = 0; j < message.Tasks.length; ++j)
                        object.Tasks[j] = $root.Rugbull.Protocols.ShareTaskData.toObject(message.Tasks[j], options);
                }
                return object;
            };

            /**
             * Converts this SCShareTasks to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCShareTasks
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCShareTasks.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCShareTasks
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCShareTasks
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCShareTasks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCShareTasks";
            };

            return SCShareTasks;
        })();

        Protocols.SCForceTask = (function() {

            /**
             * Properties of a SCForceTask.
             * @memberof Rugbull.Protocols
             * @interface ISCForceTask
             * @property {boolean|null} [OpenJoinChannel] SCForceTask OpenJoinChannel
             * @property {boolean|null} [IsDoning] SCForceTask IsDoning
             * @property {boolean|null} [IsClaimed] SCForceTask IsClaimed
             * @property {number|null} [TaskId] SCForceTask TaskId
             * @property {string|null} [Title] SCForceTask Title
             * @property {string|null} [Link] SCForceTask Link
             * @property {boolean|null} [IsFishished] SCForceTask IsFishished
             * @property {string|null} [Reward] SCForceTask Reward
             */

            /**
             * Constructs a new SCForceTask.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCForceTask.
             * @implements ISCForceTask
             * @constructor
             * @param {Rugbull.Protocols.ISCForceTask=} [properties] Properties to set
             */
            function SCForceTask(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCForceTask OpenJoinChannel.
             * @member {boolean} OpenJoinChannel
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.OpenJoinChannel = false;

            /**
             * SCForceTask IsDoning.
             * @member {boolean} IsDoning
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.IsDoning = false;

            /**
             * SCForceTask IsClaimed.
             * @member {boolean} IsClaimed
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.IsClaimed = false;

            /**
             * SCForceTask TaskId.
             * @member {number} TaskId
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.TaskId = 0;

            /**
             * SCForceTask Title.
             * @member {string} Title
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.Title = "";

            /**
             * SCForceTask Link.
             * @member {string} Link
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.Link = "";

            /**
             * SCForceTask IsFishished.
             * @member {boolean} IsFishished
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.IsFishished = false;

            /**
             * SCForceTask Reward.
             * @member {string} Reward
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             */
            SCForceTask.prototype.Reward = "";

            /**
             * Creates a new SCForceTask instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Rugbull.Protocols.ISCForceTask=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCForceTask} SCForceTask instance
             */
            SCForceTask.create = function create(properties) {
                return new SCForceTask(properties);
            };

            /**
             * Encodes the specified SCForceTask message. Does not implicitly {@link Rugbull.Protocols.SCForceTask.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Rugbull.Protocols.ISCForceTask} message SCForceTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCForceTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.OpenJoinChannel != null && Object.hasOwnProperty.call(message, "OpenJoinChannel"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.OpenJoinChannel);
                if (message.IsDoning != null && Object.hasOwnProperty.call(message, "IsDoning"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsDoning);
                if (message.IsClaimed != null && Object.hasOwnProperty.call(message, "IsClaimed"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsClaimed);
                if (message.TaskId != null && Object.hasOwnProperty.call(message, "TaskId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.TaskId);
                if (message.Title != null && Object.hasOwnProperty.call(message, "Title"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Title);
                if (message.Link != null && Object.hasOwnProperty.call(message, "Link"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.Link);
                if (message.IsFishished != null && Object.hasOwnProperty.call(message, "IsFishished"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsFishished);
                if (message.Reward != null && Object.hasOwnProperty.call(message, "Reward"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.Reward);
                return writer;
            };

            /**
             * Encodes the specified SCForceTask message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCForceTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Rugbull.Protocols.ISCForceTask} message SCForceTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCForceTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCForceTask message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCForceTask} SCForceTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCForceTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCForceTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.OpenJoinChannel = reader.bool();
                            break;
                        }
                    case 2: {
                            message.IsDoning = reader.bool();
                            break;
                        }
                    case 3: {
                            message.IsClaimed = reader.bool();
                            break;
                        }
                    case 4: {
                            message.TaskId = reader.int32();
                            break;
                        }
                    case 5: {
                            message.Title = reader.string();
                            break;
                        }
                    case 6: {
                            message.Link = reader.string();
                            break;
                        }
                    case 7: {
                            message.IsFishished = reader.bool();
                            break;
                        }
                    case 8: {
                            message.Reward = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCForceTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCForceTask} SCForceTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCForceTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCForceTask message.
             * @function verify
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCForceTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.OpenJoinChannel != null && message.hasOwnProperty("OpenJoinChannel"))
                    if (typeof message.OpenJoinChannel !== "boolean")
                        return "OpenJoinChannel: boolean expected";
                if (message.IsDoning != null && message.hasOwnProperty("IsDoning"))
                    if (typeof message.IsDoning !== "boolean")
                        return "IsDoning: boolean expected";
                if (message.IsClaimed != null && message.hasOwnProperty("IsClaimed"))
                    if (typeof message.IsClaimed !== "boolean")
                        return "IsClaimed: boolean expected";
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    if (!$util.isInteger(message.TaskId))
                        return "TaskId: integer expected";
                if (message.Title != null && message.hasOwnProperty("Title"))
                    if (!$util.isString(message.Title))
                        return "Title: string expected";
                if (message.Link != null && message.hasOwnProperty("Link"))
                    if (!$util.isString(message.Link))
                        return "Link: string expected";
                if (message.IsFishished != null && message.hasOwnProperty("IsFishished"))
                    if (typeof message.IsFishished !== "boolean")
                        return "IsFishished: boolean expected";
                if (message.Reward != null && message.hasOwnProperty("Reward"))
                    if (!$util.isString(message.Reward))
                        return "Reward: string expected";
                return null;
            };

            /**
             * Creates a SCForceTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCForceTask} SCForceTask
             */
            SCForceTask.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCForceTask)
                    return object;
                let message = new $root.Rugbull.Protocols.SCForceTask();
                if (object.OpenJoinChannel != null)
                    message.OpenJoinChannel = Boolean(object.OpenJoinChannel);
                if (object.IsDoning != null)
                    message.IsDoning = Boolean(object.IsDoning);
                if (object.IsClaimed != null)
                    message.IsClaimed = Boolean(object.IsClaimed);
                if (object.TaskId != null)
                    message.TaskId = object.TaskId | 0;
                if (object.Title != null)
                    message.Title = String(object.Title);
                if (object.Link != null)
                    message.Link = String(object.Link);
                if (object.IsFishished != null)
                    message.IsFishished = Boolean(object.IsFishished);
                if (object.Reward != null)
                    message.Reward = String(object.Reward);
                return message;
            };

            /**
             * Creates a plain object from a SCForceTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {Rugbull.Protocols.SCForceTask} message SCForceTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCForceTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.OpenJoinChannel = false;
                    object.IsDoning = false;
                    object.IsClaimed = false;
                    object.TaskId = 0;
                    object.Title = "";
                    object.Link = "";
                    object.IsFishished = false;
                    object.Reward = "";
                }
                if (message.OpenJoinChannel != null && message.hasOwnProperty("OpenJoinChannel"))
                    object.OpenJoinChannel = message.OpenJoinChannel;
                if (message.IsDoning != null && message.hasOwnProperty("IsDoning"))
                    object.IsDoning = message.IsDoning;
                if (message.IsClaimed != null && message.hasOwnProperty("IsClaimed"))
                    object.IsClaimed = message.IsClaimed;
                if (message.TaskId != null && message.hasOwnProperty("TaskId"))
                    object.TaskId = message.TaskId;
                if (message.Title != null && message.hasOwnProperty("Title"))
                    object.Title = message.Title;
                if (message.Link != null && message.hasOwnProperty("Link"))
                    object.Link = message.Link;
                if (message.IsFishished != null && message.hasOwnProperty("IsFishished"))
                    object.IsFishished = message.IsFishished;
                if (message.Reward != null && message.hasOwnProperty("Reward"))
                    object.Reward = message.Reward;
                return object;
            };

            /**
             * Converts this SCForceTask to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCForceTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCForceTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCForceTask
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCForceTask
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCForceTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCForceTask";
            };

            return SCForceTask;
        })();

        Protocols.SCTimeInfo = (function() {

            /**
             * Properties of a SCTimeInfo.
             * @memberof Rugbull.Protocols
             * @interface ISCTimeInfo
             * @property {number|null} [ServerUtc] SCTimeInfo ServerUtc
             */

            /**
             * Constructs a new SCTimeInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCTimeInfo.
             * @implements ISCTimeInfo
             * @constructor
             * @param {Rugbull.Protocols.ISCTimeInfo=} [properties] Properties to set
             */
            function SCTimeInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCTimeInfo ServerUtc.
             * @member {number} ServerUtc
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @instance
             */
            SCTimeInfo.prototype.ServerUtc = 0;

            /**
             * Creates a new SCTimeInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Rugbull.Protocols.ISCTimeInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCTimeInfo} SCTimeInfo instance
             */
            SCTimeInfo.create = function create(properties) {
                return new SCTimeInfo(properties);
            };

            /**
             * Encodes the specified SCTimeInfo message. Does not implicitly {@link Rugbull.Protocols.SCTimeInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Rugbull.Protocols.ISCTimeInfo} message SCTimeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTimeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ServerUtc != null && Object.hasOwnProperty.call(message, "ServerUtc"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ServerUtc);
                return writer;
            };

            /**
             * Encodes the specified SCTimeInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCTimeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Rugbull.Protocols.ISCTimeInfo} message SCTimeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCTimeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCTimeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCTimeInfo} SCTimeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTimeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCTimeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ServerUtc = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCTimeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCTimeInfo} SCTimeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCTimeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCTimeInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCTimeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ServerUtc != null && message.hasOwnProperty("ServerUtc"))
                    if (!$util.isInteger(message.ServerUtc))
                        return "ServerUtc: integer expected";
                return null;
            };

            /**
             * Creates a SCTimeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCTimeInfo} SCTimeInfo
             */
            SCTimeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCTimeInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.SCTimeInfo();
                if (object.ServerUtc != null)
                    message.ServerUtc = object.ServerUtc | 0;
                return message;
            };

            /**
             * Creates a plain object from a SCTimeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {Rugbull.Protocols.SCTimeInfo} message SCTimeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCTimeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.ServerUtc = 0;
                if (message.ServerUtc != null && message.hasOwnProperty("ServerUtc"))
                    object.ServerUtc = message.ServerUtc;
                return object;
            };

            /**
             * Converts this SCTimeInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCTimeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCTimeInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCTimeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCTimeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCTimeInfo";
            };

            return SCTimeInfo;
        })();

        Protocols.CSUpload = (function() {

            /**
             * Properties of a CSUpload.
             * @memberof Rugbull.Protocols
             * @interface ICSUpload
             * @property {string|null} [Sign] CSUpload Sign
             * @property {string|null} [Path] CSUpload Path
             * @property {Uint8Array|null} [Data] CSUpload Data
             */

            /**
             * Constructs a new CSUpload.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSUpload.
             * @implements ICSUpload
             * @constructor
             * @param {Rugbull.Protocols.ICSUpload=} [properties] Properties to set
             */
            function CSUpload(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSUpload Sign.
             * @member {string} Sign
             * @memberof Rugbull.Protocols.CSUpload
             * @instance
             */
            CSUpload.prototype.Sign = "";

            /**
             * CSUpload Path.
             * @member {string} Path
             * @memberof Rugbull.Protocols.CSUpload
             * @instance
             */
            CSUpload.prototype.Path = "";

            /**
             * CSUpload Data.
             * @member {Uint8Array} Data
             * @memberof Rugbull.Protocols.CSUpload
             * @instance
             */
            CSUpload.prototype.Data = $util.newBuffer([]);

            /**
             * Creates a new CSUpload instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Rugbull.Protocols.ICSUpload=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSUpload} CSUpload instance
             */
            CSUpload.create = function create(properties) {
                return new CSUpload(properties);
            };

            /**
             * Encodes the specified CSUpload message. Does not implicitly {@link Rugbull.Protocols.CSUpload.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Rugbull.Protocols.ICSUpload} message CSUpload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpload.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Sign != null && Object.hasOwnProperty.call(message, "Sign"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Sign);
                if (message.Path != null && Object.hasOwnProperty.call(message, "Path"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Path);
                if (message.Data != null && Object.hasOwnProperty.call(message, "Data"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.Data);
                return writer;
            };

            /**
             * Encodes the specified CSUpload message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSUpload.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Rugbull.Protocols.ICSUpload} message CSUpload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpload.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSUpload message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSUpload} CSUpload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpload.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSUpload();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Sign = reader.string();
                            break;
                        }
                    case 2: {
                            message.Path = reader.string();
                            break;
                        }
                    case 3: {
                            message.Data = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSUpload message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSUpload} CSUpload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpload.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSUpload message.
             * @function verify
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSUpload.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    if (!$util.isString(message.Sign))
                        return "Sign: string expected";
                if (message.Path != null && message.hasOwnProperty("Path"))
                    if (!$util.isString(message.Path))
                        return "Path: string expected";
                if (message.Data != null && message.hasOwnProperty("Data"))
                    if (!(message.Data && typeof message.Data.length === "number" || $util.isString(message.Data)))
                        return "Data: buffer expected";
                return null;
            };

            /**
             * Creates a CSUpload message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSUpload} CSUpload
             */
            CSUpload.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSUpload)
                    return object;
                let message = new $root.Rugbull.Protocols.CSUpload();
                if (object.Sign != null)
                    message.Sign = String(object.Sign);
                if (object.Path != null)
                    message.Path = String(object.Path);
                if (object.Data != null)
                    if (typeof object.Data === "string")
                        $util.base64.decode(object.Data, message.Data = $util.newBuffer($util.base64.length(object.Data)), 0);
                    else if (object.Data.length >= 0)
                        message.Data = object.Data;
                return message;
            };

            /**
             * Creates a plain object from a CSUpload message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {Rugbull.Protocols.CSUpload} message CSUpload
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSUpload.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Sign = "";
                    object.Path = "";
                    if (options.bytes === String)
                        object.Data = "";
                    else {
                        object.Data = [];
                        if (options.bytes !== Array)
                            object.Data = $util.newBuffer(object.Data);
                    }
                }
                if (message.Sign != null && message.hasOwnProperty("Sign"))
                    object.Sign = message.Sign;
                if (message.Path != null && message.hasOwnProperty("Path"))
                    object.Path = message.Path;
                if (message.Data != null && message.hasOwnProperty("Data"))
                    object.Data = options.bytes === String ? $util.base64.encode(message.Data, 0, message.Data.length) : options.bytes === Array ? Array.prototype.slice.call(message.Data) : message.Data;
                return object;
            };

            /**
             * Converts this CSUpload to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSUpload
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSUpload.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSUpload
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSUpload
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSUpload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSUpload";
            };

            return CSUpload;
        })();

        Protocols.CSGetPhoto = (function() {

            /**
             * Properties of a CSGetPhoto.
             * @memberof Rugbull.Protocols
             * @interface ICSGetPhoto
             * @property {number|Long|null} [TelegramId] CSGetPhoto TelegramId
             */

            /**
             * Constructs a new CSGetPhoto.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSGetPhoto.
             * @implements ICSGetPhoto
             * @constructor
             * @param {Rugbull.Protocols.ICSGetPhoto=} [properties] Properties to set
             */
            function CSGetPhoto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSGetPhoto TelegramId.
             * @member {number|Long} TelegramId
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @instance
             */
            CSGetPhoto.prototype.TelegramId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new CSGetPhoto instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Rugbull.Protocols.ICSGetPhoto=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSGetPhoto} CSGetPhoto instance
             */
            CSGetPhoto.create = function create(properties) {
                return new CSGetPhoto(properties);
            };

            /**
             * Encodes the specified CSGetPhoto message. Does not implicitly {@link Rugbull.Protocols.CSGetPhoto.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Rugbull.Protocols.ICSGetPhoto} message CSGetPhoto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetPhoto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.TelegramId != null && Object.hasOwnProperty.call(message, "TelegramId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.TelegramId);
                return writer;
            };

            /**
             * Encodes the specified CSGetPhoto message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSGetPhoto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Rugbull.Protocols.ICSGetPhoto} message CSGetPhoto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSGetPhoto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSGetPhoto message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSGetPhoto} CSGetPhoto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetPhoto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSGetPhoto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.TelegramId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSGetPhoto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSGetPhoto} CSGetPhoto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSGetPhoto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSGetPhoto message.
             * @function verify
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSGetPhoto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.TelegramId != null && message.hasOwnProperty("TelegramId"))
                    if (!$util.isInteger(message.TelegramId) && !(message.TelegramId && $util.isInteger(message.TelegramId.low) && $util.isInteger(message.TelegramId.high)))
                        return "TelegramId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CSGetPhoto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSGetPhoto} CSGetPhoto
             */
            CSGetPhoto.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSGetPhoto)
                    return object;
                let message = new $root.Rugbull.Protocols.CSGetPhoto();
                if (object.TelegramId != null)
                    if ($util.Long)
                        (message.TelegramId = $util.Long.fromValue(object.TelegramId)).unsigned = false;
                    else if (typeof object.TelegramId === "string")
                        message.TelegramId = parseInt(object.TelegramId, 10);
                    else if (typeof object.TelegramId === "number")
                        message.TelegramId = object.TelegramId;
                    else if (typeof object.TelegramId === "object")
                        message.TelegramId = new $util.LongBits(object.TelegramId.low >>> 0, object.TelegramId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a CSGetPhoto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {Rugbull.Protocols.CSGetPhoto} message CSGetPhoto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSGetPhoto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.TelegramId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.TelegramId = options.longs === String ? "0" : 0;
                if (message.TelegramId != null && message.hasOwnProperty("TelegramId"))
                    if (typeof message.TelegramId === "number")
                        object.TelegramId = options.longs === String ? String(message.TelegramId) : message.TelegramId;
                    else
                        object.TelegramId = options.longs === String ? $util.Long.prototype.toString.call(message.TelegramId) : options.longs === Number ? new $util.LongBits(message.TelegramId.low >>> 0, message.TelegramId.high >>> 0).toNumber() : message.TelegramId;
                return object;
            };

            /**
             * Converts this CSGetPhoto to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSGetPhoto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSGetPhoto
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSGetPhoto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSGetPhoto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSGetPhoto";
            };

            return CSGetPhoto;
        })();

        Protocols.SCGetPhoto = (function() {

            /**
             * Properties of a SCGetPhoto.
             * @memberof Rugbull.Protocols
             * @interface ISCGetPhoto
             * @property {string|null} [url] SCGetPhoto url
             */

            /**
             * Constructs a new SCGetPhoto.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCGetPhoto.
             * @implements ISCGetPhoto
             * @constructor
             * @param {Rugbull.Protocols.ISCGetPhoto=} [properties] Properties to set
             */
            function SCGetPhoto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCGetPhoto url.
             * @member {string} url
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @instance
             */
            SCGetPhoto.prototype.url = "";

            /**
             * Creates a new SCGetPhoto instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Rugbull.Protocols.ISCGetPhoto=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCGetPhoto} SCGetPhoto instance
             */
            SCGetPhoto.create = function create(properties) {
                return new SCGetPhoto(properties);
            };

            /**
             * Encodes the specified SCGetPhoto message. Does not implicitly {@link Rugbull.Protocols.SCGetPhoto.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Rugbull.Protocols.ISCGetPhoto} message SCGetPhoto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetPhoto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                return writer;
            };

            /**
             * Encodes the specified SCGetPhoto message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCGetPhoto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Rugbull.Protocols.ISCGetPhoto} message SCGetPhoto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCGetPhoto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCGetPhoto message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCGetPhoto} SCGetPhoto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetPhoto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCGetPhoto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCGetPhoto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCGetPhoto} SCGetPhoto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCGetPhoto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCGetPhoto message.
             * @function verify
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCGetPhoto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.url != null && message.hasOwnProperty("url"))
                    if (!$util.isString(message.url))
                        return "url: string expected";
                return null;
            };

            /**
             * Creates a SCGetPhoto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCGetPhoto} SCGetPhoto
             */
            SCGetPhoto.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCGetPhoto)
                    return object;
                let message = new $root.Rugbull.Protocols.SCGetPhoto();
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };

            /**
             * Creates a plain object from a SCGetPhoto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {Rugbull.Protocols.SCGetPhoto} message SCGetPhoto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCGetPhoto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.url = "";
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };

            /**
             * Converts this SCGetPhoto to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCGetPhoto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCGetPhoto
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCGetPhoto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCGetPhoto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCGetPhoto";
            };

            return SCGetPhoto;
        })();

        Protocols.CSUpdateLanguage = (function() {

            /**
             * Properties of a CSUpdateLanguage.
             * @memberof Rugbull.Protocols
             * @interface ICSUpdateLanguage
             * @property {string|null} [Language] CSUpdateLanguage Language
             */

            /**
             * Constructs a new CSUpdateLanguage.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSUpdateLanguage.
             * @implements ICSUpdateLanguage
             * @constructor
             * @param {Rugbull.Protocols.ICSUpdateLanguage=} [properties] Properties to set
             */
            function CSUpdateLanguage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSUpdateLanguage Language.
             * @member {string} Language
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @instance
             */
            CSUpdateLanguage.prototype.Language = "";

            /**
             * Creates a new CSUpdateLanguage instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Rugbull.Protocols.ICSUpdateLanguage=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSUpdateLanguage} CSUpdateLanguage instance
             */
            CSUpdateLanguage.create = function create(properties) {
                return new CSUpdateLanguage(properties);
            };

            /**
             * Encodes the specified CSUpdateLanguage message. Does not implicitly {@link Rugbull.Protocols.CSUpdateLanguage.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Rugbull.Protocols.ICSUpdateLanguage} message CSUpdateLanguage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpdateLanguage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Language);
                return writer;
            };

            /**
             * Encodes the specified CSUpdateLanguage message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSUpdateLanguage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Rugbull.Protocols.ICSUpdateLanguage} message CSUpdateLanguage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSUpdateLanguage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSUpdateLanguage message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSUpdateLanguage} CSUpdateLanguage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpdateLanguage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSUpdateLanguage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Language = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSUpdateLanguage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSUpdateLanguage} CSUpdateLanguage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSUpdateLanguage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSUpdateLanguage message.
             * @function verify
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSUpdateLanguage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Language != null && message.hasOwnProperty("Language"))
                    if (!$util.isString(message.Language))
                        return "Language: string expected";
                return null;
            };

            /**
             * Creates a CSUpdateLanguage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSUpdateLanguage} CSUpdateLanguage
             */
            CSUpdateLanguage.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSUpdateLanguage)
                    return object;
                let message = new $root.Rugbull.Protocols.CSUpdateLanguage();
                if (object.Language != null)
                    message.Language = String(object.Language);
                return message;
            };

            /**
             * Creates a plain object from a CSUpdateLanguage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {Rugbull.Protocols.CSUpdateLanguage} message CSUpdateLanguage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSUpdateLanguage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Language = "";
                if (message.Language != null && message.hasOwnProperty("Language"))
                    object.Language = message.Language;
                return object;
            };

            /**
             * Converts this CSUpdateLanguage to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSUpdateLanguage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSUpdateLanguage
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSUpdateLanguage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSUpdateLanguage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSUpdateLanguage";
            };

            return CSUpdateLanguage;
        })();

        Protocols.PushWalletWithDraw = (function() {

            /**
             * Properties of a PushWalletWithDraw.
             * @memberof Rugbull.Protocols
             * @interface IPushWalletWithDraw
             * @property {Rugbull.Protocols.WalletWithDrawState|null} [State] PushWalletWithDraw State
             * @property {string|null} [Cash] PushWalletWithDraw Cash
             * @property {string|null} [TargetAddress] PushWalletWithDraw TargetAddress
             * @property {string|null} [UnlockedCash] PushWalletWithDraw UnlockedCash
             * @property {number|null} [WithdrawTimes] PushWalletWithDraw WithdrawTimes
             * @property {string|null} [RequestTime] PushWalletWithDraw RequestTime
             * @property {string|null} [WalletAddress] PushWalletWithDraw WalletAddress
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] PushWalletWithDraw ChainType
             * @property {Rugbull.Protocols.CoinType|null} [CashType] PushWalletWithDraw CashType
             */

            /**
             * Constructs a new PushWalletWithDraw.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushWalletWithDraw.
             * @implements IPushWalletWithDraw
             * @constructor
             * @param {Rugbull.Protocols.IPushWalletWithDraw=} [properties] Properties to set
             */
            function PushWalletWithDraw(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushWalletWithDraw State.
             * @member {Rugbull.Protocols.WalletWithDrawState} State
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.State = 0;

            /**
             * PushWalletWithDraw Cash.
             * @member {string} Cash
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.Cash = "";

            /**
             * PushWalletWithDraw TargetAddress.
             * @member {string} TargetAddress
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.TargetAddress = "";

            /**
             * PushWalletWithDraw UnlockedCash.
             * @member {string} UnlockedCash
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.UnlockedCash = "";

            /**
             * PushWalletWithDraw WithdrawTimes.
             * @member {number} WithdrawTimes
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.WithdrawTimes = 0;

            /**
             * PushWalletWithDraw RequestTime.
             * @member {string} RequestTime
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.RequestTime = "";

            /**
             * PushWalletWithDraw WalletAddress.
             * @member {string} WalletAddress
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.WalletAddress = "";

            /**
             * PushWalletWithDraw ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.ChainType = 0;

            /**
             * PushWalletWithDraw CashType.
             * @member {Rugbull.Protocols.CoinType} CashType
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             */
            PushWalletWithDraw.prototype.CashType = 0;

            /**
             * Creates a new PushWalletWithDraw instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWalletWithDraw=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushWalletWithDraw} PushWalletWithDraw instance
             */
            PushWalletWithDraw.create = function create(properties) {
                return new PushWalletWithDraw(properties);
            };

            /**
             * Encodes the specified PushWalletWithDraw message. Does not implicitly {@link Rugbull.Protocols.PushWalletWithDraw.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWalletWithDraw} message PushWalletWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushWalletWithDraw.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.State != null && Object.hasOwnProperty.call(message, "State"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.State);
                if (message.Cash != null && Object.hasOwnProperty.call(message, "Cash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Cash);
                if (message.TargetAddress != null && Object.hasOwnProperty.call(message, "TargetAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.TargetAddress);
                if (message.UnlockedCash != null && Object.hasOwnProperty.call(message, "UnlockedCash"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.UnlockedCash);
                if (message.WithdrawTimes != null && Object.hasOwnProperty.call(message, "WithdrawTimes"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.WithdrawTimes);
                if (message.RequestTime != null && Object.hasOwnProperty.call(message, "RequestTime"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.RequestTime);
                if (message.WalletAddress != null && Object.hasOwnProperty.call(message, "WalletAddress"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.WalletAddress);
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ChainType);
                if (message.CashType != null && Object.hasOwnProperty.call(message, "CashType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.CashType);
                return writer;
            };

            /**
             * Encodes the specified PushWalletWithDraw message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushWalletWithDraw.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWalletWithDraw} message PushWalletWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushWalletWithDraw.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushWalletWithDraw message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushWalletWithDraw} PushWalletWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushWalletWithDraw.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushWalletWithDraw();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.State = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Cash = reader.string();
                            break;
                        }
                    case 3: {
                            message.TargetAddress = reader.string();
                            break;
                        }
                    case 4: {
                            message.UnlockedCash = reader.string();
                            break;
                        }
                    case 5: {
                            message.WithdrawTimes = reader.int32();
                            break;
                        }
                    case 6: {
                            message.RequestTime = reader.string();
                            break;
                        }
                    case 7: {
                            message.WalletAddress = reader.string();
                            break;
                        }
                    case 8: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 9: {
                            message.CashType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushWalletWithDraw message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushWalletWithDraw} PushWalletWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushWalletWithDraw.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushWalletWithDraw message.
             * @function verify
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushWalletWithDraw.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.State != null && message.hasOwnProperty("State"))
                    switch (message.State) {
                    default:
                        return "State: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    if (!$util.isString(message.Cash))
                        return "Cash: string expected";
                if (message.TargetAddress != null && message.hasOwnProperty("TargetAddress"))
                    if (!$util.isString(message.TargetAddress))
                        return "TargetAddress: string expected";
                if (message.UnlockedCash != null && message.hasOwnProperty("UnlockedCash"))
                    if (!$util.isString(message.UnlockedCash))
                        return "UnlockedCash: string expected";
                if (message.WithdrawTimes != null && message.hasOwnProperty("WithdrawTimes"))
                    if (!$util.isInteger(message.WithdrawTimes))
                        return "WithdrawTimes: integer expected";
                if (message.RequestTime != null && message.hasOwnProperty("RequestTime"))
                    if (!$util.isString(message.RequestTime))
                        return "RequestTime: string expected";
                if (message.WalletAddress != null && message.hasOwnProperty("WalletAddress"))
                    if (!$util.isString(message.WalletAddress))
                        return "WalletAddress: string expected";
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.CashType != null && message.hasOwnProperty("CashType"))
                    switch (message.CashType) {
                    default:
                        return "CashType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a PushWalletWithDraw message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushWalletWithDraw} PushWalletWithDraw
             */
            PushWalletWithDraw.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushWalletWithDraw)
                    return object;
                let message = new $root.Rugbull.Protocols.PushWalletWithDraw();
                switch (object.State) {
                default:
                    if (typeof object.State === "number") {
                        message.State = object.State;
                        break;
                    }
                    break;
                case "WalletWithDrawState_None":
                case 0:
                    message.State = 0;
                    break;
                case "WalletWithDrawState_WaitingForReward":
                case 1:
                    message.State = 1;
                    break;
                }
                if (object.Cash != null)
                    message.Cash = String(object.Cash);
                if (object.TargetAddress != null)
                    message.TargetAddress = String(object.TargetAddress);
                if (object.UnlockedCash != null)
                    message.UnlockedCash = String(object.UnlockedCash);
                if (object.WithdrawTimes != null)
                    message.WithdrawTimes = object.WithdrawTimes | 0;
                if (object.RequestTime != null)
                    message.RequestTime = String(object.RequestTime);
                if (object.WalletAddress != null)
                    message.WalletAddress = String(object.WalletAddress);
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                switch (object.CashType) {
                default:
                    if (typeof object.CashType === "number") {
                        message.CashType = object.CashType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CashType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CashType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CashType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CashType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a PushWalletWithDraw message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.PushWalletWithDraw} message PushWalletWithDraw
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushWalletWithDraw.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.State = options.enums === String ? "WalletWithDrawState_None" : 0;
                    object.Cash = "";
                    object.TargetAddress = "";
                    object.UnlockedCash = "";
                    object.WithdrawTimes = 0;
                    object.RequestTime = "";
                    object.WalletAddress = "";
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.CashType = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.State != null && message.hasOwnProperty("State"))
                    object.State = options.enums === String ? $root.Rugbull.Protocols.WalletWithDrawState[message.State] === undefined ? message.State : $root.Rugbull.Protocols.WalletWithDrawState[message.State] : message.State;
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    object.Cash = message.Cash;
                if (message.TargetAddress != null && message.hasOwnProperty("TargetAddress"))
                    object.TargetAddress = message.TargetAddress;
                if (message.UnlockedCash != null && message.hasOwnProperty("UnlockedCash"))
                    object.UnlockedCash = message.UnlockedCash;
                if (message.WithdrawTimes != null && message.hasOwnProperty("WithdrawTimes"))
                    object.WithdrawTimes = message.WithdrawTimes;
                if (message.RequestTime != null && message.hasOwnProperty("RequestTime"))
                    object.RequestTime = message.RequestTime;
                if (message.WalletAddress != null && message.hasOwnProperty("WalletAddress"))
                    object.WalletAddress = message.WalletAddress;
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.CashType != null && message.hasOwnProperty("CashType"))
                    object.CashType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CashType] === undefined ? message.CashType : $root.Rugbull.Protocols.CoinType[message.CashType] : message.CashType;
                return object;
            };

            /**
             * Converts this PushWalletWithDraw to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushWalletWithDraw.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushWalletWithDraw
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushWalletWithDraw
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushWalletWithDraw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushWalletWithDraw";
            };

            return PushWalletWithDraw;
        })();

        Protocols.CSWalletWithDraw = (function() {

            /**
             * Properties of a CSWalletWithDraw.
             * @memberof Rugbull.Protocols
             * @interface ICSWalletWithDraw
             * @property {string|null} [Address] CSWalletWithDraw Address
             * @property {string|null} [Amount] CSWalletWithDraw Amount
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] CSWalletWithDraw ChainType
             * @property {Rugbull.Protocols.CoinType|null} [CoinType] CSWalletWithDraw CoinType
             */

            /**
             * Constructs a new CSWalletWithDraw.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSWalletWithDraw.
             * @implements ICSWalletWithDraw
             * @constructor
             * @param {Rugbull.Protocols.ICSWalletWithDraw=} [properties] Properties to set
             */
            function CSWalletWithDraw(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSWalletWithDraw Address.
             * @member {string} Address
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @instance
             */
            CSWalletWithDraw.prototype.Address = "";

            /**
             * CSWalletWithDraw Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @instance
             */
            CSWalletWithDraw.prototype.Amount = "";

            /**
             * CSWalletWithDraw ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @instance
             */
            CSWalletWithDraw.prototype.ChainType = 0;

            /**
             * CSWalletWithDraw CoinType.
             * @member {Rugbull.Protocols.CoinType} CoinType
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @instance
             */
            CSWalletWithDraw.prototype.CoinType = 0;

            /**
             * Creates a new CSWalletWithDraw instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSWalletWithDraw=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSWalletWithDraw} CSWalletWithDraw instance
             */
            CSWalletWithDraw.create = function create(properties) {
                return new CSWalletWithDraw(properties);
            };

            /**
             * Encodes the specified CSWalletWithDraw message. Does not implicitly {@link Rugbull.Protocols.CSWalletWithDraw.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSWalletWithDraw} message CSWalletWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSWalletWithDraw.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Address);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ChainType);
                if (message.CoinType != null && Object.hasOwnProperty.call(message, "CoinType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.CoinType);
                return writer;
            };

            /**
             * Encodes the specified CSWalletWithDraw message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSWalletWithDraw.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSWalletWithDraw} message CSWalletWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSWalletWithDraw.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSWalletWithDraw message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSWalletWithDraw} CSWalletWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSWalletWithDraw.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSWalletWithDraw();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Address = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.CoinType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSWalletWithDraw message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSWalletWithDraw} CSWalletWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSWalletWithDraw.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSWalletWithDraw message.
             * @function verify
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSWalletWithDraw.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Address != null && message.hasOwnProperty("Address"))
                    if (!$util.isString(message.Address))
                        return "Address: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    switch (message.CoinType) {
                    default:
                        return "CoinType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSWalletWithDraw message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSWalletWithDraw} CSWalletWithDraw
             */
            CSWalletWithDraw.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSWalletWithDraw)
                    return object;
                let message = new $root.Rugbull.Protocols.CSWalletWithDraw();
                if (object.Address != null)
                    message.Address = String(object.Address);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                switch (object.CoinType) {
                default:
                    if (typeof object.CoinType === "number") {
                        message.CoinType = object.CoinType;
                        break;
                    }
                    break;
                case "CoinType_Unknown":
                case 0:
                    message.CoinType = 0;
                    break;
                case "CoinType_Coin":
                case 1:
                    message.CoinType = 1;
                    break;
                case "CoinType_USDT":
                case 2:
                    message.CoinType = 2;
                    break;
                case "CoinType_Ton":
                case 3:
                    message.CoinType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSWalletWithDraw message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {Rugbull.Protocols.CSWalletWithDraw} message CSWalletWithDraw
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSWalletWithDraw.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Address = "";
                    object.Amount = "";
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.CoinType = options.enums === String ? "CoinType_Unknown" : 0;
                }
                if (message.Address != null && message.hasOwnProperty("Address"))
                    object.Address = message.Address;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.CoinType != null && message.hasOwnProperty("CoinType"))
                    object.CoinType = options.enums === String ? $root.Rugbull.Protocols.CoinType[message.CoinType] === undefined ? message.CoinType : $root.Rugbull.Protocols.CoinType[message.CoinType] : message.CoinType;
                return object;
            };

            /**
             * Converts this CSWalletWithDraw to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSWalletWithDraw.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSWalletWithDraw
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSWalletWithDraw
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSWalletWithDraw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSWalletWithDraw";
            };

            return CSWalletWithDraw;
        })();

        Protocols.WalletInfo = (function() {

            /**
             * Properties of a WalletInfo.
             * @memberof Rugbull.Protocols
             * @interface IWalletInfo
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] WalletInfo ChainType
             * @property {string|null} [Address] WalletInfo Address
             */

            /**
             * Constructs a new WalletInfo.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a WalletInfo.
             * @implements IWalletInfo
             * @constructor
             * @param {Rugbull.Protocols.IWalletInfo=} [properties] Properties to set
             */
            function WalletInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WalletInfo ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.WalletInfo
             * @instance
             */
            WalletInfo.prototype.ChainType = 0;

            /**
             * WalletInfo Address.
             * @member {string} Address
             * @memberof Rugbull.Protocols.WalletInfo
             * @instance
             */
            WalletInfo.prototype.Address = "";

            /**
             * Creates a new WalletInfo instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Rugbull.Protocols.IWalletInfo=} [properties] Properties to set
             * @returns {Rugbull.Protocols.WalletInfo} WalletInfo instance
             */
            WalletInfo.create = function create(properties) {
                return new WalletInfo(properties);
            };

            /**
             * Encodes the specified WalletInfo message. Does not implicitly {@link Rugbull.Protocols.WalletInfo.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Rugbull.Protocols.IWalletInfo} message WalletInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WalletInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ChainType);
                if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Address);
                return writer;
            };

            /**
             * Encodes the specified WalletInfo message, length delimited. Does not implicitly {@link Rugbull.Protocols.WalletInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Rugbull.Protocols.IWalletInfo} message WalletInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WalletInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WalletInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.WalletInfo} WalletInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WalletInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.WalletInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WalletInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.WalletInfo} WalletInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WalletInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WalletInfo message.
             * @function verify
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WalletInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.Address != null && message.hasOwnProperty("Address"))
                    if (!$util.isString(message.Address))
                        return "Address: string expected";
                return null;
            };

            /**
             * Creates a WalletInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.WalletInfo} WalletInfo
             */
            WalletInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.WalletInfo)
                    return object;
                let message = new $root.Rugbull.Protocols.WalletInfo();
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                if (object.Address != null)
                    message.Address = String(object.Address);
                return message;
            };

            /**
             * Creates a plain object from a WalletInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {Rugbull.Protocols.WalletInfo} message WalletInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WalletInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.Address = "";
                }
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.Address != null && message.hasOwnProperty("Address"))
                    object.Address = message.Address;
                return object;
            };

            /**
             * Converts this WalletInfo to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.WalletInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WalletInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WalletInfo
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.WalletInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WalletInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.WalletInfo";
            };

            return WalletInfo;
        })();

        Protocols.CSSetWallet = (function() {

            /**
             * Properties of a CSSetWallet.
             * @memberof Rugbull.Protocols
             * @interface ICSSetWallet
             * @property {string|null} [Address] CSSetWallet Address
             */

            /**
             * Constructs a new CSSetWallet.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSSetWallet.
             * @implements ICSSetWallet
             * @constructor
             * @param {Rugbull.Protocols.ICSSetWallet=} [properties] Properties to set
             */
            function CSSetWallet(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSSetWallet Address.
             * @member {string} Address
             * @memberof Rugbull.Protocols.CSSetWallet
             * @instance
             */
            CSSetWallet.prototype.Address = "";

            /**
             * Creates a new CSSetWallet instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Rugbull.Protocols.ICSSetWallet=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSSetWallet} CSSetWallet instance
             */
            CSSetWallet.create = function create(properties) {
                return new CSSetWallet(properties);
            };

            /**
             * Encodes the specified CSSetWallet message. Does not implicitly {@link Rugbull.Protocols.CSSetWallet.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Rugbull.Protocols.ICSSetWallet} message CSSetWallet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSetWallet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Address);
                return writer;
            };

            /**
             * Encodes the specified CSSetWallet message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSSetWallet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Rugbull.Protocols.ICSSetWallet} message CSSetWallet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSSetWallet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSSetWallet message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSSetWallet} CSSetWallet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSetWallet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSSetWallet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSSetWallet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSSetWallet} CSSetWallet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSSetWallet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSSetWallet message.
             * @function verify
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSSetWallet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Address != null && message.hasOwnProperty("Address"))
                    if (!$util.isString(message.Address))
                        return "Address: string expected";
                return null;
            };

            /**
             * Creates a CSSetWallet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSSetWallet} CSSetWallet
             */
            CSSetWallet.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSSetWallet)
                    return object;
                let message = new $root.Rugbull.Protocols.CSSetWallet();
                if (object.Address != null)
                    message.Address = String(object.Address);
                return message;
            };

            /**
             * Creates a plain object from a CSSetWallet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {Rugbull.Protocols.CSSetWallet} message CSSetWallet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSSetWallet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Address = "";
                if (message.Address != null && message.hasOwnProperty("Address"))
                    object.Address = message.Address;
                return object;
            };

            /**
             * Converts this CSSetWallet to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSSetWallet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSSetWallet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSSetWallet
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSSetWallet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSSetWallet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSSetWallet";
            };

            return CSSetWallet;
        })();

        Protocols.WithdrawRecordModel = (function() {

            /**
             * Properties of a WithdrawRecordModel.
             * @memberof Rugbull.Protocols
             * @interface IWithdrawRecordModel
             * @property {string|null} [Time] WithdrawRecordModel Time
             * @property {string|null} [Amount] WithdrawRecordModel Amount
             * @property {Rugbull.Protocols.OrderStatus|null} [State] WithdrawRecordModel State
             * @property {Rugbull.Protocols.ChainType|null} [ChainType] WithdrawRecordModel ChainType
             * @property {string|null} [Address] WithdrawRecordModel Address
             * @property {number|null} [OrderId] WithdrawRecordModel OrderId
             */

            /**
             * Constructs a new WithdrawRecordModel.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a WithdrawRecordModel.
             * @implements IWithdrawRecordModel
             * @constructor
             * @param {Rugbull.Protocols.IWithdrawRecordModel=} [properties] Properties to set
             */
            function WithdrawRecordModel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WithdrawRecordModel Time.
             * @member {string} Time
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.Time = "";

            /**
             * WithdrawRecordModel Amount.
             * @member {string} Amount
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.Amount = "";

            /**
             * WithdrawRecordModel State.
             * @member {Rugbull.Protocols.OrderStatus} State
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.State = 0;

            /**
             * WithdrawRecordModel ChainType.
             * @member {Rugbull.Protocols.ChainType} ChainType
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.ChainType = 0;

            /**
             * WithdrawRecordModel Address.
             * @member {string} Address
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.Address = "";

            /**
             * WithdrawRecordModel OrderId.
             * @member {number} OrderId
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             */
            WithdrawRecordModel.prototype.OrderId = 0;

            /**
             * Creates a new WithdrawRecordModel instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Rugbull.Protocols.IWithdrawRecordModel=} [properties] Properties to set
             * @returns {Rugbull.Protocols.WithdrawRecordModel} WithdrawRecordModel instance
             */
            WithdrawRecordModel.create = function create(properties) {
                return new WithdrawRecordModel(properties);
            };

            /**
             * Encodes the specified WithdrawRecordModel message. Does not implicitly {@link Rugbull.Protocols.WithdrawRecordModel.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Rugbull.Protocols.IWithdrawRecordModel} message WithdrawRecordModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithdrawRecordModel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Time);
                if (message.Amount != null && Object.hasOwnProperty.call(message, "Amount"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Amount);
                if (message.State != null && Object.hasOwnProperty.call(message, "State"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.State);
                if (message.ChainType != null && Object.hasOwnProperty.call(message, "ChainType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ChainType);
                if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.Address);
                if (message.OrderId != null && Object.hasOwnProperty.call(message, "OrderId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.OrderId);
                return writer;
            };

            /**
             * Encodes the specified WithdrawRecordModel message, length delimited. Does not implicitly {@link Rugbull.Protocols.WithdrawRecordModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Rugbull.Protocols.IWithdrawRecordModel} message WithdrawRecordModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithdrawRecordModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WithdrawRecordModel message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.WithdrawRecordModel} WithdrawRecordModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithdrawRecordModel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.WithdrawRecordModel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Time = reader.string();
                            break;
                        }
                    case 2: {
                            message.Amount = reader.string();
                            break;
                        }
                    case 3: {
                            message.State = reader.int32();
                            break;
                        }
                    case 4: {
                            message.ChainType = reader.int32();
                            break;
                        }
                    case 5: {
                            message.Address = reader.string();
                            break;
                        }
                    case 6: {
                            message.OrderId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WithdrawRecordModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.WithdrawRecordModel} WithdrawRecordModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithdrawRecordModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WithdrawRecordModel message.
             * @function verify
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WithdrawRecordModel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Time != null && message.hasOwnProperty("Time"))
                    if (!$util.isString(message.Time))
                        return "Time: string expected";
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    if (!$util.isString(message.Amount))
                        return "Amount: string expected";
                if (message.State != null && message.hasOwnProperty("State"))
                    switch (message.State) {
                    default:
                        return "State: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    switch (message.ChainType) {
                    default:
                        return "ChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.Address != null && message.hasOwnProperty("Address"))
                    if (!$util.isString(message.Address))
                        return "Address: string expected";
                if (message.OrderId != null && message.hasOwnProperty("OrderId"))
                    if (!$util.isInteger(message.OrderId))
                        return "OrderId: integer expected";
                return null;
            };

            /**
             * Creates a WithdrawRecordModel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.WithdrawRecordModel} WithdrawRecordModel
             */
            WithdrawRecordModel.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.WithdrawRecordModel)
                    return object;
                let message = new $root.Rugbull.Protocols.WithdrawRecordModel();
                if (object.Time != null)
                    message.Time = String(object.Time);
                if (object.Amount != null)
                    message.Amount = String(object.Amount);
                switch (object.State) {
                default:
                    if (typeof object.State === "number") {
                        message.State = object.State;
                        break;
                    }
                    break;
                case "OrderStatus_Waiting":
                case 0:
                    message.State = 0;
                    break;
                case "OrderStatus_Error":
                case 1:
                    message.State = 1;
                    break;
                case "OrderStatus_Purchased":
                case 2:
                    message.State = 2;
                    break;
                case "OrderStatus_Expired":
                case 3:
                    message.State = 3;
                    break;
                case "OrderStatus_Cancelled":
                case 4:
                    message.State = 4;
                    break;
                }
                switch (object.ChainType) {
                default:
                    if (typeof object.ChainType === "number") {
                        message.ChainType = object.ChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.ChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.ChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.ChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.ChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.ChainType = 4;
                    break;
                }
                if (object.Address != null)
                    message.Address = String(object.Address);
                if (object.OrderId != null)
                    message.OrderId = object.OrderId | 0;
                return message;
            };

            /**
             * Creates a plain object from a WithdrawRecordModel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {Rugbull.Protocols.WithdrawRecordModel} message WithdrawRecordModel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WithdrawRecordModel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Time = "";
                    object.Amount = "";
                    object.State = options.enums === String ? "OrderStatus_Waiting" : 0;
                    object.ChainType = options.enums === String ? "ChainType_Unknown" : 0;
                    object.Address = "";
                    object.OrderId = 0;
                }
                if (message.Time != null && message.hasOwnProperty("Time"))
                    object.Time = message.Time;
                if (message.Amount != null && message.hasOwnProperty("Amount"))
                    object.Amount = message.Amount;
                if (message.State != null && message.hasOwnProperty("State"))
                    object.State = options.enums === String ? $root.Rugbull.Protocols.OrderStatus[message.State] === undefined ? message.State : $root.Rugbull.Protocols.OrderStatus[message.State] : message.State;
                if (message.ChainType != null && message.hasOwnProperty("ChainType"))
                    object.ChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.ChainType] === undefined ? message.ChainType : $root.Rugbull.Protocols.ChainType[message.ChainType] : message.ChainType;
                if (message.Address != null && message.hasOwnProperty("Address"))
                    object.Address = message.Address;
                if (message.OrderId != null && message.hasOwnProperty("OrderId"))
                    object.OrderId = message.OrderId;
                return object;
            };

            /**
             * Converts this WithdrawRecordModel to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WithdrawRecordModel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WithdrawRecordModel
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.WithdrawRecordModel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WithdrawRecordModel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.WithdrawRecordModel";
            };

            return WithdrawRecordModel;
        })();

        Protocols.SCWithdrawRecord = (function() {

            /**
             * Properties of a SCWithdrawRecord.
             * @memberof Rugbull.Protocols
             * @interface ISCWithdrawRecord
             * @property {Array.<Rugbull.Protocols.IWithdrawRecordModel>|null} [Records] SCWithdrawRecord Records
             * @property {number|null} [Start] SCWithdrawRecord Start
             * @property {number|null} [Limit] SCWithdrawRecord Limit
             * @property {number|null} [Total] SCWithdrawRecord Total
             */

            /**
             * Constructs a new SCWithdrawRecord.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCWithdrawRecord.
             * @implements ISCWithdrawRecord
             * @constructor
             * @param {Rugbull.Protocols.ISCWithdrawRecord=} [properties] Properties to set
             */
            function SCWithdrawRecord(properties) {
                this.Records = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCWithdrawRecord Records.
             * @member {Array.<Rugbull.Protocols.IWithdrawRecordModel>} Records
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @instance
             */
            SCWithdrawRecord.prototype.Records = $util.emptyArray;

            /**
             * SCWithdrawRecord Start.
             * @member {number} Start
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @instance
             */
            SCWithdrawRecord.prototype.Start = 0;

            /**
             * SCWithdrawRecord Limit.
             * @member {number} Limit
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @instance
             */
            SCWithdrawRecord.prototype.Limit = 0;

            /**
             * SCWithdrawRecord Total.
             * @member {number} Total
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @instance
             */
            SCWithdrawRecord.prototype.Total = 0;

            /**
             * Creates a new SCWithdrawRecord instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ISCWithdrawRecord=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCWithdrawRecord} SCWithdrawRecord instance
             */
            SCWithdrawRecord.create = function create(properties) {
                return new SCWithdrawRecord(properties);
            };

            /**
             * Encodes the specified SCWithdrawRecord message. Does not implicitly {@link Rugbull.Protocols.SCWithdrawRecord.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ISCWithdrawRecord} message SCWithdrawRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithdrawRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Records != null && message.Records.length)
                    for (let i = 0; i < message.Records.length; ++i)
                        $root.Rugbull.Protocols.WithdrawRecordModel.encode(message.Records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.Start != null && Object.hasOwnProperty.call(message, "Start"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Start);
                if (message.Limit != null && Object.hasOwnProperty.call(message, "Limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Limit);
                if (message.Total != null && Object.hasOwnProperty.call(message, "Total"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Total);
                return writer;
            };

            /**
             * Encodes the specified SCWithdrawRecord message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCWithdrawRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ISCWithdrawRecord} message SCWithdrawRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithdrawRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCWithdrawRecord message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCWithdrawRecord} SCWithdrawRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithdrawRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCWithdrawRecord();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Records && message.Records.length))
                                message.Records = [];
                            message.Records.push($root.Rugbull.Protocols.WithdrawRecordModel.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.Start = reader.int32();
                            break;
                        }
                    case 3: {
                            message.Limit = reader.int32();
                            break;
                        }
                    case 4: {
                            message.Total = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCWithdrawRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCWithdrawRecord} SCWithdrawRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithdrawRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCWithdrawRecord message.
             * @function verify
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCWithdrawRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Records != null && message.hasOwnProperty("Records")) {
                    if (!Array.isArray(message.Records))
                        return "Records: array expected";
                    for (let i = 0; i < message.Records.length; ++i) {
                        let error = $root.Rugbull.Protocols.WithdrawRecordModel.verify(message.Records[i]);
                        if (error)
                            return "Records." + error;
                    }
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    if (!$util.isInteger(message.Start))
                        return "Start: integer expected";
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    if (!$util.isInteger(message.Limit))
                        return "Limit: integer expected";
                if (message.Total != null && message.hasOwnProperty("Total"))
                    if (!$util.isInteger(message.Total))
                        return "Total: integer expected";
                return null;
            };

            /**
             * Creates a SCWithdrawRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCWithdrawRecord} SCWithdrawRecord
             */
            SCWithdrawRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCWithdrawRecord)
                    return object;
                let message = new $root.Rugbull.Protocols.SCWithdrawRecord();
                if (object.Records) {
                    if (!Array.isArray(object.Records))
                        throw TypeError(".Rugbull.Protocols.SCWithdrawRecord.Records: array expected");
                    message.Records = [];
                    for (let i = 0; i < object.Records.length; ++i) {
                        if (typeof object.Records[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCWithdrawRecord.Records: object expected");
                        message.Records[i] = $root.Rugbull.Protocols.WithdrawRecordModel.fromObject(object.Records[i]);
                    }
                }
                if (object.Start != null)
                    message.Start = object.Start | 0;
                if (object.Limit != null)
                    message.Limit = object.Limit | 0;
                if (object.Total != null)
                    message.Total = object.Total | 0;
                return message;
            };

            /**
             * Creates a plain object from a SCWithdrawRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.SCWithdrawRecord} message SCWithdrawRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCWithdrawRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Records = [];
                if (options.defaults) {
                    object.Start = 0;
                    object.Limit = 0;
                    object.Total = 0;
                }
                if (message.Records && message.Records.length) {
                    object.Records = [];
                    for (let j = 0; j < message.Records.length; ++j)
                        object.Records[j] = $root.Rugbull.Protocols.WithdrawRecordModel.toObject(message.Records[j], options);
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    object.Start = message.Start;
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    object.Limit = message.Limit;
                if (message.Total != null && message.hasOwnProperty("Total"))
                    object.Total = message.Total;
                return object;
            };

            /**
             * Converts this SCWithdrawRecord to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCWithdrawRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCWithdrawRecord
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCWithdrawRecord
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCWithdrawRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCWithdrawRecord";
            };

            return SCWithdrawRecord;
        })();

        Protocols.CSWithdrawRecord = (function() {

            /**
             * Properties of a CSWithdrawRecord.
             * @memberof Rugbull.Protocols
             * @interface ICSWithdrawRecord
             * @property {number|null} [Start] CSWithdrawRecord Start
             * @property {number|null} [Limit] CSWithdrawRecord Limit
             * @property {Rugbull.Protocols.ChainType|null} [SeachByChainType] CSWithdrawRecord SeachByChainType
             */

            /**
             * Constructs a new CSWithdrawRecord.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSWithdrawRecord.
             * @implements ICSWithdrawRecord
             * @constructor
             * @param {Rugbull.Protocols.ICSWithdrawRecord=} [properties] Properties to set
             */
            function CSWithdrawRecord(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSWithdrawRecord Start.
             * @member {number} Start
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @instance
             */
            CSWithdrawRecord.prototype.Start = 0;

            /**
             * CSWithdrawRecord Limit.
             * @member {number} Limit
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @instance
             */
            CSWithdrawRecord.prototype.Limit = 0;

            /**
             * CSWithdrawRecord SeachByChainType.
             * @member {Rugbull.Protocols.ChainType} SeachByChainType
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @instance
             */
            CSWithdrawRecord.prototype.SeachByChainType = 0;

            /**
             * Creates a new CSWithdrawRecord instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ICSWithdrawRecord=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSWithdrawRecord} CSWithdrawRecord instance
             */
            CSWithdrawRecord.create = function create(properties) {
                return new CSWithdrawRecord(properties);
            };

            /**
             * Encodes the specified CSWithdrawRecord message. Does not implicitly {@link Rugbull.Protocols.CSWithdrawRecord.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ICSWithdrawRecord} message CSWithdrawRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSWithdrawRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Start != null && Object.hasOwnProperty.call(message, "Start"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Start);
                if (message.Limit != null && Object.hasOwnProperty.call(message, "Limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Limit);
                if (message.SeachByChainType != null && Object.hasOwnProperty.call(message, "SeachByChainType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.SeachByChainType);
                return writer;
            };

            /**
             * Encodes the specified CSWithdrawRecord message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSWithdrawRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.ICSWithdrawRecord} message CSWithdrawRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSWithdrawRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSWithdrawRecord message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSWithdrawRecord} CSWithdrawRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSWithdrawRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSWithdrawRecord();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Start = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Limit = reader.int32();
                            break;
                        }
                    case 3: {
                            message.SeachByChainType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSWithdrawRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSWithdrawRecord} CSWithdrawRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSWithdrawRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSWithdrawRecord message.
             * @function verify
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSWithdrawRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Start != null && message.hasOwnProperty("Start"))
                    if (!$util.isInteger(message.Start))
                        return "Start: integer expected";
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    if (!$util.isInteger(message.Limit))
                        return "Limit: integer expected";
                if (message.SeachByChainType != null && message.hasOwnProperty("SeachByChainType"))
                    switch (message.SeachByChainType) {
                    default:
                        return "SeachByChainType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CSWithdrawRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSWithdrawRecord} CSWithdrawRecord
             */
            CSWithdrawRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSWithdrawRecord)
                    return object;
                let message = new $root.Rugbull.Protocols.CSWithdrawRecord();
                if (object.Start != null)
                    message.Start = object.Start | 0;
                if (object.Limit != null)
                    message.Limit = object.Limit | 0;
                switch (object.SeachByChainType) {
                default:
                    if (typeof object.SeachByChainType === "number") {
                        message.SeachByChainType = object.SeachByChainType;
                        break;
                    }
                    break;
                case "ChainType_Unknown":
                case 0:
                    message.SeachByChainType = 0;
                    break;
                case "ChainType_Bsc":
                case 1:
                    message.SeachByChainType = 1;
                    break;
                case "ChainType_Tron":
                case 2:
                    message.SeachByChainType = 2;
                    break;
                case "ChainType_Ton":
                case 3:
                    message.SeachByChainType = 3;
                    break;
                case "ChainType_Solana":
                case 4:
                    message.SeachByChainType = 4;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CSWithdrawRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {Rugbull.Protocols.CSWithdrawRecord} message CSWithdrawRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSWithdrawRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Start = 0;
                    object.Limit = 0;
                    object.SeachByChainType = options.enums === String ? "ChainType_Unknown" : 0;
                }
                if (message.Start != null && message.hasOwnProperty("Start"))
                    object.Start = message.Start;
                if (message.Limit != null && message.hasOwnProperty("Limit"))
                    object.Limit = message.Limit;
                if (message.SeachByChainType != null && message.hasOwnProperty("SeachByChainType"))
                    object.SeachByChainType = options.enums === String ? $root.Rugbull.Protocols.ChainType[message.SeachByChainType] === undefined ? message.SeachByChainType : $root.Rugbull.Protocols.ChainType[message.SeachByChainType] : message.SeachByChainType;
                return object;
            };

            /**
             * Converts this CSWithdrawRecord to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSWithdrawRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSWithdrawRecord
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSWithdrawRecord
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSWithdrawRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSWithdrawRecord";
            };

            return CSWithdrawRecord;
        })();

        Protocols.PushWithDraw = (function() {

            /**
             * Properties of a PushWithDraw.
             * @memberof Rugbull.Protocols
             * @interface IPushWithDraw
             * @property {Rugbull.Protocols.WithDrawState|null} [State] PushWithDraw State
             * @property {string|null} [Cash] PushWithDraw Cash
             * @property {number|null} [TargetCount] PushWithDraw TargetCount
             * @property {number|null} [FinishCount] PushWithDraw FinishCount
             * @property {number|null} [RemainSeconds] PushWithDraw RemainSeconds
             * @property {string|null} [ServiceUrl] PushWithDraw ServiceUrl
             * @property {string|null} [TargetAddress] PushWithDraw TargetAddress
             * @property {boolean|null} [SwitchOn] PushWithDraw SwitchOn
             * @property {string|null} [UnlockedCash] PushWithDraw UnlockedCash
             * @property {number|null} [WithdrawTimes] PushWithDraw WithdrawTimes
             * @property {string|null} [RequestTime] PushWithDraw RequestTime
             */

            /**
             * Constructs a new PushWithDraw.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushWithDraw.
             * @implements IPushWithDraw
             * @constructor
             * @param {Rugbull.Protocols.IPushWithDraw=} [properties] Properties to set
             */
            function PushWithDraw(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushWithDraw State.
             * @member {Rugbull.Protocols.WithDrawState} State
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.State = 0;

            /**
             * PushWithDraw Cash.
             * @member {string} Cash
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.Cash = "";

            /**
             * PushWithDraw TargetCount.
             * @member {number} TargetCount
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.TargetCount = 0;

            /**
             * PushWithDraw FinishCount.
             * @member {number} FinishCount
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.FinishCount = 0;

            /**
             * PushWithDraw RemainSeconds.
             * @member {number} RemainSeconds
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.RemainSeconds = 0;

            /**
             * PushWithDraw ServiceUrl.
             * @member {string} ServiceUrl
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.ServiceUrl = "";

            /**
             * PushWithDraw TargetAddress.
             * @member {string} TargetAddress
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.TargetAddress = "";

            /**
             * PushWithDraw SwitchOn.
             * @member {boolean} SwitchOn
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.SwitchOn = false;

            /**
             * PushWithDraw UnlockedCash.
             * @member {string} UnlockedCash
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.UnlockedCash = "";

            /**
             * PushWithDraw WithdrawTimes.
             * @member {number} WithdrawTimes
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.WithdrawTimes = 0;

            /**
             * PushWithDraw RequestTime.
             * @member {string} RequestTime
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             */
            PushWithDraw.prototype.RequestTime = "";

            /**
             * Creates a new PushWithDraw instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWithDraw=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushWithDraw} PushWithDraw instance
             */
            PushWithDraw.create = function create(properties) {
                return new PushWithDraw(properties);
            };

            /**
             * Encodes the specified PushWithDraw message. Does not implicitly {@link Rugbull.Protocols.PushWithDraw.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWithDraw} message PushWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushWithDraw.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.State != null && Object.hasOwnProperty.call(message, "State"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.State);
                if (message.Cash != null && Object.hasOwnProperty.call(message, "Cash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Cash);
                if (message.TargetCount != null && Object.hasOwnProperty.call(message, "TargetCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.TargetCount);
                if (message.FinishCount != null && Object.hasOwnProperty.call(message, "FinishCount"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.FinishCount);
                if (message.RemainSeconds != null && Object.hasOwnProperty.call(message, "RemainSeconds"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.RemainSeconds);
                if (message.ServiceUrl != null && Object.hasOwnProperty.call(message, "ServiceUrl"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.ServiceUrl);
                if (message.TargetAddress != null && Object.hasOwnProperty.call(message, "TargetAddress"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.TargetAddress);
                if (message.SwitchOn != null && Object.hasOwnProperty.call(message, "SwitchOn"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.SwitchOn);
                if (message.UnlockedCash != null && Object.hasOwnProperty.call(message, "UnlockedCash"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.UnlockedCash);
                if (message.WithdrawTimes != null && Object.hasOwnProperty.call(message, "WithdrawTimes"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.WithdrawTimes);
                if (message.RequestTime != null && Object.hasOwnProperty.call(message, "RequestTime"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.RequestTime);
                return writer;
            };

            /**
             * Encodes the specified PushWithDraw message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushWithDraw.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Rugbull.Protocols.IPushWithDraw} message PushWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushWithDraw.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushWithDraw message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushWithDraw} PushWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushWithDraw.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushWithDraw();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.State = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Cash = reader.string();
                            break;
                        }
                    case 3: {
                            message.TargetCount = reader.int32();
                            break;
                        }
                    case 4: {
                            message.FinishCount = reader.int32();
                            break;
                        }
                    case 5: {
                            message.RemainSeconds = reader.int32();
                            break;
                        }
                    case 6: {
                            message.ServiceUrl = reader.string();
                            break;
                        }
                    case 7: {
                            message.TargetAddress = reader.string();
                            break;
                        }
                    case 8: {
                            message.SwitchOn = reader.bool();
                            break;
                        }
                    case 9: {
                            message.UnlockedCash = reader.string();
                            break;
                        }
                    case 10: {
                            message.WithdrawTimes = reader.int32();
                            break;
                        }
                    case 11: {
                            message.RequestTime = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushWithDraw message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushWithDraw} PushWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushWithDraw.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushWithDraw message.
             * @function verify
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushWithDraw.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.State != null && message.hasOwnProperty("State"))
                    switch (message.State) {
                    default:
                        return "State: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    if (!$util.isString(message.Cash))
                        return "Cash: string expected";
                if (message.TargetCount != null && message.hasOwnProperty("TargetCount"))
                    if (!$util.isInteger(message.TargetCount))
                        return "TargetCount: integer expected";
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    if (!$util.isInteger(message.FinishCount))
                        return "FinishCount: integer expected";
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    if (!$util.isInteger(message.RemainSeconds))
                        return "RemainSeconds: integer expected";
                if (message.ServiceUrl != null && message.hasOwnProperty("ServiceUrl"))
                    if (!$util.isString(message.ServiceUrl))
                        return "ServiceUrl: string expected";
                if (message.TargetAddress != null && message.hasOwnProperty("TargetAddress"))
                    if (!$util.isString(message.TargetAddress))
                        return "TargetAddress: string expected";
                if (message.SwitchOn != null && message.hasOwnProperty("SwitchOn"))
                    if (typeof message.SwitchOn !== "boolean")
                        return "SwitchOn: boolean expected";
                if (message.UnlockedCash != null && message.hasOwnProperty("UnlockedCash"))
                    if (!$util.isString(message.UnlockedCash))
                        return "UnlockedCash: string expected";
                if (message.WithdrawTimes != null && message.hasOwnProperty("WithdrawTimes"))
                    if (!$util.isInteger(message.WithdrawTimes))
                        return "WithdrawTimes: integer expected";
                if (message.RequestTime != null && message.hasOwnProperty("RequestTime"))
                    if (!$util.isString(message.RequestTime))
                        return "RequestTime: string expected";
                return null;
            };

            /**
             * Creates a PushWithDraw message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushWithDraw} PushWithDraw
             */
            PushWithDraw.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushWithDraw)
                    return object;
                let message = new $root.Rugbull.Protocols.PushWithDraw();
                switch (object.State) {
                default:
                    if (typeof object.State === "number") {
                        message.State = object.State;
                        break;
                    }
                    break;
                case "WithDrawState_None":
                case 0:
                    message.State = 0;
                    break;
                case "WithDrawState_RollCashWaitingForInvite":
                case 1:
                    message.State = 1;
                    break;
                case "WithDrawState_RollCash":
                case 2:
                    message.State = 2;
                    break;
                case "WithDrawState_RollInviteWaitingForInvite":
                case 3:
                    message.State = 3;
                    break;
                case "WithDrawState_RollInvite":
                case 4:
                    message.State = 4;
                    break;
                case "WithDrawState_Invite":
                case 5:
                    message.State = 5;
                    break;
                case "WithDrawState_Finished":
                case 6:
                    message.State = 6;
                    break;
                case "WithDrawState_WaitingForReward":
                case 7:
                    message.State = 7;
                    break;
                }
                if (object.Cash != null)
                    message.Cash = String(object.Cash);
                if (object.TargetCount != null)
                    message.TargetCount = object.TargetCount | 0;
                if (object.FinishCount != null)
                    message.FinishCount = object.FinishCount | 0;
                if (object.RemainSeconds != null)
                    message.RemainSeconds = object.RemainSeconds | 0;
                if (object.ServiceUrl != null)
                    message.ServiceUrl = String(object.ServiceUrl);
                if (object.TargetAddress != null)
                    message.TargetAddress = String(object.TargetAddress);
                if (object.SwitchOn != null)
                    message.SwitchOn = Boolean(object.SwitchOn);
                if (object.UnlockedCash != null)
                    message.UnlockedCash = String(object.UnlockedCash);
                if (object.WithdrawTimes != null)
                    message.WithdrawTimes = object.WithdrawTimes | 0;
                if (object.RequestTime != null)
                    message.RequestTime = String(object.RequestTime);
                return message;
            };

            /**
             * Creates a plain object from a PushWithDraw message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {Rugbull.Protocols.PushWithDraw} message PushWithDraw
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushWithDraw.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.State = options.enums === String ? "WithDrawState_None" : 0;
                    object.Cash = "";
                    object.TargetCount = 0;
                    object.FinishCount = 0;
                    object.RemainSeconds = 0;
                    object.ServiceUrl = "";
                    object.TargetAddress = "";
                    object.SwitchOn = false;
                    object.UnlockedCash = "";
                    object.WithdrawTimes = 0;
                    object.RequestTime = "";
                }
                if (message.State != null && message.hasOwnProperty("State"))
                    object.State = options.enums === String ? $root.Rugbull.Protocols.WithDrawState[message.State] === undefined ? message.State : $root.Rugbull.Protocols.WithDrawState[message.State] : message.State;
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    object.Cash = message.Cash;
                if (message.TargetCount != null && message.hasOwnProperty("TargetCount"))
                    object.TargetCount = message.TargetCount;
                if (message.FinishCount != null && message.hasOwnProperty("FinishCount"))
                    object.FinishCount = message.FinishCount;
                if (message.RemainSeconds != null && message.hasOwnProperty("RemainSeconds"))
                    object.RemainSeconds = message.RemainSeconds;
                if (message.ServiceUrl != null && message.hasOwnProperty("ServiceUrl"))
                    object.ServiceUrl = message.ServiceUrl;
                if (message.TargetAddress != null && message.hasOwnProperty("TargetAddress"))
                    object.TargetAddress = message.TargetAddress;
                if (message.SwitchOn != null && message.hasOwnProperty("SwitchOn"))
                    object.SwitchOn = message.SwitchOn;
                if (message.UnlockedCash != null && message.hasOwnProperty("UnlockedCash"))
                    object.UnlockedCash = message.UnlockedCash;
                if (message.WithdrawTimes != null && message.hasOwnProperty("WithdrawTimes"))
                    object.WithdrawTimes = message.WithdrawTimes;
                if (message.RequestTime != null && message.hasOwnProperty("RequestTime"))
                    object.RequestTime = message.RequestTime;
                return object;
            };

            /**
             * Converts this PushWithDraw to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushWithDraw
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushWithDraw.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushWithDraw
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushWithDraw
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushWithDraw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushWithDraw";
            };

            return PushWithDraw;
        })();

        Protocols.WithDrawCashItem = (function() {

            /**
             * Properties of a WithDrawCashItem.
             * @memberof Rugbull.Protocols
             * @interface IWithDrawCashItem
             * @property {number|null} [Idx] WithDrawCashItem Idx
             * @property {string|null} [Cash] WithDrawCashItem Cash
             * @property {string|null} [Percent] WithDrawCashItem Percent
             */

            /**
             * Constructs a new WithDrawCashItem.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a WithDrawCashItem.
             * @implements IWithDrawCashItem
             * @constructor
             * @param {Rugbull.Protocols.IWithDrawCashItem=} [properties] Properties to set
             */
            function WithDrawCashItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WithDrawCashItem Idx.
             * @member {number} Idx
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @instance
             */
            WithDrawCashItem.prototype.Idx = 0;

            /**
             * WithDrawCashItem Cash.
             * @member {string} Cash
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @instance
             */
            WithDrawCashItem.prototype.Cash = "";

            /**
             * WithDrawCashItem Percent.
             * @member {string} Percent
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @instance
             */
            WithDrawCashItem.prototype.Percent = "";

            /**
             * Creates a new WithDrawCashItem instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawCashItem=} [properties] Properties to set
             * @returns {Rugbull.Protocols.WithDrawCashItem} WithDrawCashItem instance
             */
            WithDrawCashItem.create = function create(properties) {
                return new WithDrawCashItem(properties);
            };

            /**
             * Encodes the specified WithDrawCashItem message. Does not implicitly {@link Rugbull.Protocols.WithDrawCashItem.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawCashItem} message WithDrawCashItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithDrawCashItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Idx != null && Object.hasOwnProperty.call(message, "Idx"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Idx);
                if (message.Cash != null && Object.hasOwnProperty.call(message, "Cash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Cash);
                if (message.Percent != null && Object.hasOwnProperty.call(message, "Percent"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Percent);
                return writer;
            };

            /**
             * Encodes the specified WithDrawCashItem message, length delimited. Does not implicitly {@link Rugbull.Protocols.WithDrawCashItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawCashItem} message WithDrawCashItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithDrawCashItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WithDrawCashItem message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.WithDrawCashItem} WithDrawCashItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithDrawCashItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.WithDrawCashItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Idx = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Cash = reader.string();
                            break;
                        }
                    case 3: {
                            message.Percent = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WithDrawCashItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.WithDrawCashItem} WithDrawCashItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithDrawCashItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WithDrawCashItem message.
             * @function verify
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WithDrawCashItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Idx != null && message.hasOwnProperty("Idx"))
                    if (!$util.isInteger(message.Idx))
                        return "Idx: integer expected";
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    if (!$util.isString(message.Cash))
                        return "Cash: string expected";
                if (message.Percent != null && message.hasOwnProperty("Percent"))
                    if (!$util.isString(message.Percent))
                        return "Percent: string expected";
                return null;
            };

            /**
             * Creates a WithDrawCashItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.WithDrawCashItem} WithDrawCashItem
             */
            WithDrawCashItem.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.WithDrawCashItem)
                    return object;
                let message = new $root.Rugbull.Protocols.WithDrawCashItem();
                if (object.Idx != null)
                    message.Idx = object.Idx | 0;
                if (object.Cash != null)
                    message.Cash = String(object.Cash);
                if (object.Percent != null)
                    message.Percent = String(object.Percent);
                return message;
            };

            /**
             * Creates a plain object from a WithDrawCashItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {Rugbull.Protocols.WithDrawCashItem} message WithDrawCashItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WithDrawCashItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Idx = 0;
                    object.Cash = "";
                    object.Percent = "";
                }
                if (message.Idx != null && message.hasOwnProperty("Idx"))
                    object.Idx = message.Idx;
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    object.Cash = message.Cash;
                if (message.Percent != null && message.hasOwnProperty("Percent"))
                    object.Percent = message.Percent;
                return object;
            };

            /**
             * Converts this WithDrawCashItem to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WithDrawCashItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WithDrawCashItem
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.WithDrawCashItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WithDrawCashItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.WithDrawCashItem";
            };

            return WithDrawCashItem;
        })();

        Protocols.SCWithDrawCashList = (function() {

            /**
             * Properties of a SCWithDrawCashList.
             * @memberof Rugbull.Protocols
             * @interface ISCWithDrawCashList
             * @property {Array.<Rugbull.Protocols.IWithDrawCashItem>|null} [Items] SCWithDrawCashList Items
             */

            /**
             * Constructs a new SCWithDrawCashList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCWithDrawCashList.
             * @implements ISCWithDrawCashList
             * @constructor
             * @param {Rugbull.Protocols.ISCWithDrawCashList=} [properties] Properties to set
             */
            function SCWithDrawCashList(properties) {
                this.Items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCWithDrawCashList Items.
             * @member {Array.<Rugbull.Protocols.IWithDrawCashItem>} Items
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @instance
             */
            SCWithDrawCashList.prototype.Items = $util.emptyArray;

            /**
             * Creates a new SCWithDrawCashList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawCashList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCWithDrawCashList} SCWithDrawCashList instance
             */
            SCWithDrawCashList.create = function create(properties) {
                return new SCWithDrawCashList(properties);
            };

            /**
             * Encodes the specified SCWithDrawCashList message. Does not implicitly {@link Rugbull.Protocols.SCWithDrawCashList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawCashList} message SCWithDrawCashList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawCashList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Items != null && message.Items.length)
                    for (let i = 0; i < message.Items.length; ++i)
                        $root.Rugbull.Protocols.WithDrawCashItem.encode(message.Items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCWithDrawCashList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCWithDrawCashList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawCashList} message SCWithDrawCashList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawCashList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCWithDrawCashList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCWithDrawCashList} SCWithDrawCashList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawCashList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCWithDrawCashList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Items && message.Items.length))
                                message.Items = [];
                            message.Items.push($root.Rugbull.Protocols.WithDrawCashItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCWithDrawCashList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCWithDrawCashList} SCWithDrawCashList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawCashList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCWithDrawCashList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCWithDrawCashList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Items != null && message.hasOwnProperty("Items")) {
                    if (!Array.isArray(message.Items))
                        return "Items: array expected";
                    for (let i = 0; i < message.Items.length; ++i) {
                        let error = $root.Rugbull.Protocols.WithDrawCashItem.verify(message.Items[i]);
                        if (error)
                            return "Items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCWithDrawCashList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCWithDrawCashList} SCWithDrawCashList
             */
            SCWithDrawCashList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCWithDrawCashList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCWithDrawCashList();
                if (object.Items) {
                    if (!Array.isArray(object.Items))
                        throw TypeError(".Rugbull.Protocols.SCWithDrawCashList.Items: array expected");
                    message.Items = [];
                    for (let i = 0; i < object.Items.length; ++i) {
                        if (typeof object.Items[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCWithDrawCashList.Items: object expected");
                        message.Items[i] = $root.Rugbull.Protocols.WithDrawCashItem.fromObject(object.Items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCWithDrawCashList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {Rugbull.Protocols.SCWithDrawCashList} message SCWithDrawCashList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCWithDrawCashList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Items = [];
                if (message.Items && message.Items.length) {
                    object.Items = [];
                    for (let j = 0; j < message.Items.length; ++j)
                        object.Items[j] = $root.Rugbull.Protocols.WithDrawCashItem.toObject(message.Items[j], options);
                }
                return object;
            };

            /**
             * Converts this SCWithDrawCashList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCWithDrawCashList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCWithDrawCashList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCWithDrawCashList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCWithDrawCashList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCWithDrawCashList";
            };

            return SCWithDrawCashList;
        })();

        Protocols.SCWithDrawRollCash = (function() {

            /**
             * Properties of a SCWithDrawRollCash.
             * @memberof Rugbull.Protocols
             * @interface ISCWithDrawRollCash
             * @property {number|null} [Idx] SCWithDrawRollCash Idx
             * @property {string|null} [Cash] SCWithDrawRollCash Cash
             */

            /**
             * Constructs a new SCWithDrawRollCash.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCWithDrawRollCash.
             * @implements ISCWithDrawRollCash
             * @constructor
             * @param {Rugbull.Protocols.ISCWithDrawRollCash=} [properties] Properties to set
             */
            function SCWithDrawRollCash(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCWithDrawRollCash Idx.
             * @member {number} Idx
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @instance
             */
            SCWithDrawRollCash.prototype.Idx = 0;

            /**
             * SCWithDrawRollCash Cash.
             * @member {string} Cash
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @instance
             */
            SCWithDrawRollCash.prototype.Cash = "";

            /**
             * Creates a new SCWithDrawRollCash instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollCash=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCWithDrawRollCash} SCWithDrawRollCash instance
             */
            SCWithDrawRollCash.create = function create(properties) {
                return new SCWithDrawRollCash(properties);
            };

            /**
             * Encodes the specified SCWithDrawRollCash message. Does not implicitly {@link Rugbull.Protocols.SCWithDrawRollCash.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollCash} message SCWithDrawRollCash message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawRollCash.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Idx != null && Object.hasOwnProperty.call(message, "Idx"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Idx);
                if (message.Cash != null && Object.hasOwnProperty.call(message, "Cash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Cash);
                return writer;
            };

            /**
             * Encodes the specified SCWithDrawRollCash message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCWithDrawRollCash.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollCash} message SCWithDrawRollCash message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawRollCash.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCWithDrawRollCash message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCWithDrawRollCash} SCWithDrawRollCash
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawRollCash.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCWithDrawRollCash();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Idx = reader.int32();
                            break;
                        }
                    case 2: {
                            message.Cash = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCWithDrawRollCash message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCWithDrawRollCash} SCWithDrawRollCash
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawRollCash.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCWithDrawRollCash message.
             * @function verify
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCWithDrawRollCash.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Idx != null && message.hasOwnProperty("Idx"))
                    if (!$util.isInteger(message.Idx))
                        return "Idx: integer expected";
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    if (!$util.isString(message.Cash))
                        return "Cash: string expected";
                return null;
            };

            /**
             * Creates a SCWithDrawRollCash message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCWithDrawRollCash} SCWithDrawRollCash
             */
            SCWithDrawRollCash.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCWithDrawRollCash)
                    return object;
                let message = new $root.Rugbull.Protocols.SCWithDrawRollCash();
                if (object.Idx != null)
                    message.Idx = object.Idx | 0;
                if (object.Cash != null)
                    message.Cash = String(object.Cash);
                return message;
            };

            /**
             * Creates a plain object from a SCWithDrawRollCash message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {Rugbull.Protocols.SCWithDrawRollCash} message SCWithDrawRollCash
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCWithDrawRollCash.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Idx = 0;
                    object.Cash = "";
                }
                if (message.Idx != null && message.hasOwnProperty("Idx"))
                    object.Idx = message.Idx;
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    object.Cash = message.Cash;
                return object;
            };

            /**
             * Converts this SCWithDrawRollCash to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCWithDrawRollCash.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCWithDrawRollCash
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCWithDrawRollCash
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCWithDrawRollCash.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCWithDrawRollCash";
            };

            return SCWithDrawRollCash;
        })();

        Protocols.SCWithDrawRollInviteCount = (function() {

            /**
             * Properties of a SCWithDrawRollInviteCount.
             * @memberof Rugbull.Protocols
             * @interface ISCWithDrawRollInviteCount
             * @property {number|null} [Count] SCWithDrawRollInviteCount Count
             */

            /**
             * Constructs a new SCWithDrawRollInviteCount.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCWithDrawRollInviteCount.
             * @implements ISCWithDrawRollInviteCount
             * @constructor
             * @param {Rugbull.Protocols.ISCWithDrawRollInviteCount=} [properties] Properties to set
             */
            function SCWithDrawRollInviteCount(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCWithDrawRollInviteCount Count.
             * @member {number} Count
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @instance
             */
            SCWithDrawRollInviteCount.prototype.Count = 0;

            /**
             * Creates a new SCWithDrawRollInviteCount instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollInviteCount=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCWithDrawRollInviteCount} SCWithDrawRollInviteCount instance
             */
            SCWithDrawRollInviteCount.create = function create(properties) {
                return new SCWithDrawRollInviteCount(properties);
            };

            /**
             * Encodes the specified SCWithDrawRollInviteCount message. Does not implicitly {@link Rugbull.Protocols.SCWithDrawRollInviteCount.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollInviteCount} message SCWithDrawRollInviteCount message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawRollInviteCount.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Count);
                return writer;
            };

            /**
             * Encodes the specified SCWithDrawRollInviteCount message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCWithDrawRollInviteCount.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawRollInviteCount} message SCWithDrawRollInviteCount message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawRollInviteCount.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCWithDrawRollInviteCount message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCWithDrawRollInviteCount} SCWithDrawRollInviteCount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawRollInviteCount.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCWithDrawRollInviteCount();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Count = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCWithDrawRollInviteCount message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCWithDrawRollInviteCount} SCWithDrawRollInviteCount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawRollInviteCount.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCWithDrawRollInviteCount message.
             * @function verify
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCWithDrawRollInviteCount.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Count != null && message.hasOwnProperty("Count"))
                    if (!$util.isInteger(message.Count))
                        return "Count: integer expected";
                return null;
            };

            /**
             * Creates a SCWithDrawRollInviteCount message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCWithDrawRollInviteCount} SCWithDrawRollInviteCount
             */
            SCWithDrawRollInviteCount.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCWithDrawRollInviteCount)
                    return object;
                let message = new $root.Rugbull.Protocols.SCWithDrawRollInviteCount();
                if (object.Count != null)
                    message.Count = object.Count | 0;
                return message;
            };

            /**
             * Creates a plain object from a SCWithDrawRollInviteCount message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {Rugbull.Protocols.SCWithDrawRollInviteCount} message SCWithDrawRollInviteCount
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCWithDrawRollInviteCount.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Count = 0;
                if (message.Count != null && message.hasOwnProperty("Count"))
                    object.Count = message.Count;
                return object;
            };

            /**
             * Converts this SCWithDrawRollInviteCount to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCWithDrawRollInviteCount.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCWithDrawRollInviteCount
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCWithDrawRollInviteCount
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCWithDrawRollInviteCount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCWithDrawRollInviteCount";
            };

            return SCWithDrawRollInviteCount;
        })();

        Protocols.WithDrawSampleItem = (function() {

            /**
             * Properties of a WithDrawSampleItem.
             * @memberof Rugbull.Protocols
             * @interface IWithDrawSampleItem
             * @property {string|null} [Name] WithDrawSampleItem Name
             * @property {string|null} [Cash] WithDrawSampleItem Cash
             */

            /**
             * Constructs a new WithDrawSampleItem.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a WithDrawSampleItem.
             * @implements IWithDrawSampleItem
             * @constructor
             * @param {Rugbull.Protocols.IWithDrawSampleItem=} [properties] Properties to set
             */
            function WithDrawSampleItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WithDrawSampleItem Name.
             * @member {string} Name
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @instance
             */
            WithDrawSampleItem.prototype.Name = "";

            /**
             * WithDrawSampleItem Cash.
             * @member {string} Cash
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @instance
             */
            WithDrawSampleItem.prototype.Cash = "";

            /**
             * Creates a new WithDrawSampleItem instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawSampleItem=} [properties] Properties to set
             * @returns {Rugbull.Protocols.WithDrawSampleItem} WithDrawSampleItem instance
             */
            WithDrawSampleItem.create = function create(properties) {
                return new WithDrawSampleItem(properties);
            };

            /**
             * Encodes the specified WithDrawSampleItem message. Does not implicitly {@link Rugbull.Protocols.WithDrawSampleItem.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawSampleItem} message WithDrawSampleItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithDrawSampleItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Name);
                if (message.Cash != null && Object.hasOwnProperty.call(message, "Cash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Cash);
                return writer;
            };

            /**
             * Encodes the specified WithDrawSampleItem message, length delimited. Does not implicitly {@link Rugbull.Protocols.WithDrawSampleItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Rugbull.Protocols.IWithDrawSampleItem} message WithDrawSampleItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WithDrawSampleItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WithDrawSampleItem message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.WithDrawSampleItem} WithDrawSampleItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithDrawSampleItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.WithDrawSampleItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Name = reader.string();
                            break;
                        }
                    case 2: {
                            message.Cash = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WithDrawSampleItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.WithDrawSampleItem} WithDrawSampleItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WithDrawSampleItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WithDrawSampleItem message.
             * @function verify
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WithDrawSampleItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Name != null && message.hasOwnProperty("Name"))
                    if (!$util.isString(message.Name))
                        return "Name: string expected";
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    if (!$util.isString(message.Cash))
                        return "Cash: string expected";
                return null;
            };

            /**
             * Creates a WithDrawSampleItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.WithDrawSampleItem} WithDrawSampleItem
             */
            WithDrawSampleItem.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.WithDrawSampleItem)
                    return object;
                let message = new $root.Rugbull.Protocols.WithDrawSampleItem();
                if (object.Name != null)
                    message.Name = String(object.Name);
                if (object.Cash != null)
                    message.Cash = String(object.Cash);
                return message;
            };

            /**
             * Creates a plain object from a WithDrawSampleItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {Rugbull.Protocols.WithDrawSampleItem} message WithDrawSampleItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WithDrawSampleItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Name = "";
                    object.Cash = "";
                }
                if (message.Name != null && message.hasOwnProperty("Name"))
                    object.Name = message.Name;
                if (message.Cash != null && message.hasOwnProperty("Cash"))
                    object.Cash = message.Cash;
                return object;
            };

            /**
             * Converts this WithDrawSampleItem to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WithDrawSampleItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WithDrawSampleItem
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.WithDrawSampleItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WithDrawSampleItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.WithDrawSampleItem";
            };

            return WithDrawSampleItem;
        })();

        Protocols.SCWithDrawSampleList = (function() {

            /**
             * Properties of a SCWithDrawSampleList.
             * @memberof Rugbull.Protocols
             * @interface ISCWithDrawSampleList
             * @property {Array.<Rugbull.Protocols.IWithDrawSampleItem>|null} [Items] SCWithDrawSampleList Items
             */

            /**
             * Constructs a new SCWithDrawSampleList.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a SCWithDrawSampleList.
             * @implements ISCWithDrawSampleList
             * @constructor
             * @param {Rugbull.Protocols.ISCWithDrawSampleList=} [properties] Properties to set
             */
            function SCWithDrawSampleList(properties) {
                this.Items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SCWithDrawSampleList Items.
             * @member {Array.<Rugbull.Protocols.IWithDrawSampleItem>} Items
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @instance
             */
            SCWithDrawSampleList.prototype.Items = $util.emptyArray;

            /**
             * Creates a new SCWithDrawSampleList instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawSampleList=} [properties] Properties to set
             * @returns {Rugbull.Protocols.SCWithDrawSampleList} SCWithDrawSampleList instance
             */
            SCWithDrawSampleList.create = function create(properties) {
                return new SCWithDrawSampleList(properties);
            };

            /**
             * Encodes the specified SCWithDrawSampleList message. Does not implicitly {@link Rugbull.Protocols.SCWithDrawSampleList.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawSampleList} message SCWithDrawSampleList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawSampleList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Items != null && message.Items.length)
                    for (let i = 0; i < message.Items.length; ++i)
                        $root.Rugbull.Protocols.WithDrawSampleItem.encode(message.Items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SCWithDrawSampleList message, length delimited. Does not implicitly {@link Rugbull.Protocols.SCWithDrawSampleList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Rugbull.Protocols.ISCWithDrawSampleList} message SCWithDrawSampleList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SCWithDrawSampleList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SCWithDrawSampleList message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.SCWithDrawSampleList} SCWithDrawSampleList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawSampleList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.SCWithDrawSampleList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.Items && message.Items.length))
                                message.Items = [];
                            message.Items.push($root.Rugbull.Protocols.WithDrawSampleItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SCWithDrawSampleList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.SCWithDrawSampleList} SCWithDrawSampleList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SCWithDrawSampleList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SCWithDrawSampleList message.
             * @function verify
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SCWithDrawSampleList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Items != null && message.hasOwnProperty("Items")) {
                    if (!Array.isArray(message.Items))
                        return "Items: array expected";
                    for (let i = 0; i < message.Items.length; ++i) {
                        let error = $root.Rugbull.Protocols.WithDrawSampleItem.verify(message.Items[i]);
                        if (error)
                            return "Items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SCWithDrawSampleList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.SCWithDrawSampleList} SCWithDrawSampleList
             */
            SCWithDrawSampleList.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.SCWithDrawSampleList)
                    return object;
                let message = new $root.Rugbull.Protocols.SCWithDrawSampleList();
                if (object.Items) {
                    if (!Array.isArray(object.Items))
                        throw TypeError(".Rugbull.Protocols.SCWithDrawSampleList.Items: array expected");
                    message.Items = [];
                    for (let i = 0; i < object.Items.length; ++i) {
                        if (typeof object.Items[i] !== "object")
                            throw TypeError(".Rugbull.Protocols.SCWithDrawSampleList.Items: object expected");
                        message.Items[i] = $root.Rugbull.Protocols.WithDrawSampleItem.fromObject(object.Items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SCWithDrawSampleList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {Rugbull.Protocols.SCWithDrawSampleList} message SCWithDrawSampleList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SCWithDrawSampleList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.Items = [];
                if (message.Items && message.Items.length) {
                    object.Items = [];
                    for (let j = 0; j < message.Items.length; ++j)
                        object.Items[j] = $root.Rugbull.Protocols.WithDrawSampleItem.toObject(message.Items[j], options);
                }
                return object;
            };

            /**
             * Converts this SCWithDrawSampleList to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SCWithDrawSampleList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SCWithDrawSampleList
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.SCWithDrawSampleList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SCWithDrawSampleList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.SCWithDrawSampleList";
            };

            return SCWithDrawSampleList;
        })();

        Protocols.PushCashFish = (function() {

            /**
             * Properties of a PushCashFish.
             * @memberof Rugbull.Protocols
             * @interface IPushCashFish
             * @property {number|null} [Level] PushCashFish Level
             * @property {boolean|null} [IsNew] PushCashFish IsNew
             * @property {string|null} [WithdrawCash] PushCashFish WithdrawCash
             */

            /**
             * Constructs a new PushCashFish.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a PushCashFish.
             * @implements IPushCashFish
             * @constructor
             * @param {Rugbull.Protocols.IPushCashFish=} [properties] Properties to set
             */
            function PushCashFish(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushCashFish Level.
             * @member {number} Level
             * @memberof Rugbull.Protocols.PushCashFish
             * @instance
             */
            PushCashFish.prototype.Level = 0;

            /**
             * PushCashFish IsNew.
             * @member {boolean} IsNew
             * @memberof Rugbull.Protocols.PushCashFish
             * @instance
             */
            PushCashFish.prototype.IsNew = false;

            /**
             * PushCashFish WithdrawCash.
             * @member {string} WithdrawCash
             * @memberof Rugbull.Protocols.PushCashFish
             * @instance
             */
            PushCashFish.prototype.WithdrawCash = "";

            /**
             * Creates a new PushCashFish instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Rugbull.Protocols.IPushCashFish=} [properties] Properties to set
             * @returns {Rugbull.Protocols.PushCashFish} PushCashFish instance
             */
            PushCashFish.create = function create(properties) {
                return new PushCashFish(properties);
            };

            /**
             * Encodes the specified PushCashFish message. Does not implicitly {@link Rugbull.Protocols.PushCashFish.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Rugbull.Protocols.IPushCashFish} message PushCashFish message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCashFish.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Level != null && Object.hasOwnProperty.call(message, "Level"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Level);
                if (message.IsNew != null && Object.hasOwnProperty.call(message, "IsNew"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsNew);
                if (message.WithdrawCash != null && Object.hasOwnProperty.call(message, "WithdrawCash"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.WithdrawCash);
                return writer;
            };

            /**
             * Encodes the specified PushCashFish message, length delimited. Does not implicitly {@link Rugbull.Protocols.PushCashFish.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Rugbull.Protocols.IPushCashFish} message PushCashFish message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCashFish.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushCashFish message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.PushCashFish} PushCashFish
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCashFish.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.PushCashFish();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Level = reader.int32();
                            break;
                        }
                    case 2: {
                            message.IsNew = reader.bool();
                            break;
                        }
                    case 3: {
                            message.WithdrawCash = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushCashFish message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.PushCashFish} PushCashFish
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCashFish.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushCashFish message.
             * @function verify
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushCashFish.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Level != null && message.hasOwnProperty("Level"))
                    if (!$util.isInteger(message.Level))
                        return "Level: integer expected";
                if (message.IsNew != null && message.hasOwnProperty("IsNew"))
                    if (typeof message.IsNew !== "boolean")
                        return "IsNew: boolean expected";
                if (message.WithdrawCash != null && message.hasOwnProperty("WithdrawCash"))
                    if (!$util.isString(message.WithdrawCash))
                        return "WithdrawCash: string expected";
                return null;
            };

            /**
             * Creates a PushCashFish message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.PushCashFish} PushCashFish
             */
            PushCashFish.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.PushCashFish)
                    return object;
                let message = new $root.Rugbull.Protocols.PushCashFish();
                if (object.Level != null)
                    message.Level = object.Level | 0;
                if (object.IsNew != null)
                    message.IsNew = Boolean(object.IsNew);
                if (object.WithdrawCash != null)
                    message.WithdrawCash = String(object.WithdrawCash);
                return message;
            };

            /**
             * Creates a plain object from a PushCashFish message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {Rugbull.Protocols.PushCashFish} message PushCashFish
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushCashFish.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Level = 0;
                    object.IsNew = false;
                    object.WithdrawCash = "";
                }
                if (message.Level != null && message.hasOwnProperty("Level"))
                    object.Level = message.Level;
                if (message.IsNew != null && message.hasOwnProperty("IsNew"))
                    object.IsNew = message.IsNew;
                if (message.WithdrawCash != null && message.hasOwnProperty("WithdrawCash"))
                    object.WithdrawCash = message.WithdrawCash;
                return object;
            };

            /**
             * Converts this PushCashFish to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.PushCashFish
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushCashFish.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushCashFish
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.PushCashFish
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushCashFish.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.PushCashFish";
            };

            return PushCashFish;
        })();

        Protocols.CSRequestWithDraw = (function() {

            /**
             * Properties of a CSRequestWithDraw.
             * @memberof Rugbull.Protocols
             * @interface ICSRequestWithDraw
             * @property {string|null} [Address] CSRequestWithDraw Address
             */

            /**
             * Constructs a new CSRequestWithDraw.
             * @memberof Rugbull.Protocols
             * @classdesc Represents a CSRequestWithDraw.
             * @implements ICSRequestWithDraw
             * @constructor
             * @param {Rugbull.Protocols.ICSRequestWithDraw=} [properties] Properties to set
             */
            function CSRequestWithDraw(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSRequestWithDraw Address.
             * @member {string} Address
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @instance
             */
            CSRequestWithDraw.prototype.Address = "";

            /**
             * Creates a new CSRequestWithDraw instance using the specified properties.
             * @function create
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSRequestWithDraw=} [properties] Properties to set
             * @returns {Rugbull.Protocols.CSRequestWithDraw} CSRequestWithDraw instance
             */
            CSRequestWithDraw.create = function create(properties) {
                return new CSRequestWithDraw(properties);
            };

            /**
             * Encodes the specified CSRequestWithDraw message. Does not implicitly {@link Rugbull.Protocols.CSRequestWithDraw.verify|verify} messages.
             * @function encode
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSRequestWithDraw} message CSRequestWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSRequestWithDraw.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Address);
                return writer;
            };

            /**
             * Encodes the specified CSRequestWithDraw message, length delimited. Does not implicitly {@link Rugbull.Protocols.CSRequestWithDraw.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Rugbull.Protocols.ICSRequestWithDraw} message CSRequestWithDraw message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSRequestWithDraw.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CSRequestWithDraw message from the specified reader or buffer.
             * @function decode
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Rugbull.Protocols.CSRequestWithDraw} CSRequestWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSRequestWithDraw.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rugbull.Protocols.CSRequestWithDraw();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.Address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CSRequestWithDraw message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Rugbull.Protocols.CSRequestWithDraw} CSRequestWithDraw
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSRequestWithDraw.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CSRequestWithDraw message.
             * @function verify
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSRequestWithDraw.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Address != null && message.hasOwnProperty("Address"))
                    if (!$util.isString(message.Address))
                        return "Address: string expected";
                return null;
            };

            /**
             * Creates a CSRequestWithDraw message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Rugbull.Protocols.CSRequestWithDraw} CSRequestWithDraw
             */
            CSRequestWithDraw.fromObject = function fromObject(object) {
                if (object instanceof $root.Rugbull.Protocols.CSRequestWithDraw)
                    return object;
                let message = new $root.Rugbull.Protocols.CSRequestWithDraw();
                if (object.Address != null)
                    message.Address = String(object.Address);
                return message;
            };

            /**
             * Creates a plain object from a CSRequestWithDraw message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {Rugbull.Protocols.CSRequestWithDraw} message CSRequestWithDraw
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CSRequestWithDraw.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.Address = "";
                if (message.Address != null && message.hasOwnProperty("Address"))
                    object.Address = message.Address;
                return object;
            };

            /**
             * Converts this CSRequestWithDraw to JSON.
             * @function toJSON
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CSRequestWithDraw.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CSRequestWithDraw
             * @function getTypeUrl
             * @memberof Rugbull.Protocols.CSRequestWithDraw
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSRequestWithDraw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Rugbull.Protocols.CSRequestWithDraw";
            };

            return CSRequestWithDraw;
        })();

        return Protocols;
    })();

    return Rugbull;
})();

export { $root as default };
