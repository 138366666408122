import { url } from "inspector";
import  "./index.scss";
import { CSSProperties, StyleHTMLAttributes } from "react";
interface Props {
    label: string;
    url: string;
    size?: number | string;
}

const colors = [
    "#F3A683", "#F7D794", "#778BEB", "#E77F67", "#CF6A87",
    "#F19066", "#F5CD79", "#546DE5", "#596275", "#574B90",
    "#303952", "#F78FB3", "#3DC1D3", "#E15F41", "#C44569",
    "#786FA6", "#F8A5C2", "#63Cdda", "#ea8685", "#596275"
  ];

function getColorFromName(name) {
    // 将名字转换为哈希值
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // 根据哈希值选择颜色
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
}


const Avatar:React.FC<Props>  = ({label,url,size}) => {
    const bakColor = getColorFromName(label);
    let siz = '40px';
    if(size && typeof size === 'number') {
        siz = `${size}px`;
    }else if(size && typeof size === 'string') {
        siz = size;
    }

    return  <>
        {url &&<div className="avatar">
            <img src={url} alt="" /></div>}
        {!url && <div className="avatar1" style={{backgroundColor:bakColor,width:`${siz}`,height:`${siz}`}}>{label[0]}</div>}
    </>
}

export default Avatar;