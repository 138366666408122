import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import { Outlet } from "react-router";
import { Link, useMatches, useNavigate } from "react-router-dom";
import { useNotification } from "rc-notification";
import { useTranslation } from "react-i18next";
import DataViewTools from "src/views/DataView/DataViewTools";
import CurrencyChose from "src/components/CurrencyChose";
import { Rugbull } from "./network/generated/pkg.pb";
import Header from "src/components/Header";
import HeaderTopContent from "src/components/Header/HeaderTopContent";
import HowToEarnModal from "src/views/Trade/components/HowToEarnModal";
import HeaderBottomContent, {
  Multipleani,
} from "src/components/Header/HeaderBottomContent";
import Checking from "./components/Checking";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import eventEmitter from "src/utils/eventEmitter";
import ConfirmModal from "src/components/Modal/Confirm";

import "./App.scss";
import classNames from "classnames";
import anime, { set } from "animejs";
import JoinChannelModal from "./views/Trade/components/JoinChannelModal";
import { GetCoinName, GetCoinPic, Show } from "./utils";
import { changeLanguage } from "i18next";
import NoticationClose from "./components/SvgIcon/NoticationClose";
import '@ionic/react/css/core.css';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import userStore from "./utils/userStore";
import Modal from "./components/Modal";
import ChargeModal from "./views/Trade/components/ChargeModal";
import SignModal from "./views/Trade/components/SignModal";
import GameLimitModal from "./views/Trade/components/GameLimitModal";
import GameRulesModal from "./views/Trade/components/GameRulesModal";
import GamePlayModal from "./views/Trade/components/GamePlayModal";

export const LoginContext = createContext(undefined);
export const useLogin = () => useContext(LoginContext);

const App = () => {
  const [{ open, close }, holder] = useNotification();
  const { t } = useTranslation();
  const [currentSelectCurrency, setCurrentSelectCurrency] = useState<any>({
    Type: Rugbull.Protocols.CoinType.CoinType_USDT,
    Coin: 0,
  });
  const [currentLanguage, setCurrentLanguage] = useState("en-US");
  const [menuActiveKey, setMenuActiveKey] = useState('friends')
  const [visible, setVisible] = useState(false);
  const [multiple, setMultiple] = useState([]);
  const multipleListRef = useRef(null);
  const [multipleDropDown, setMultipleDropDown] = useState(false);
  const [showCoinAnimate, setshowCoinAnimate] = useState(undefined);
  const [checkedSuccess, setCheckComplete] = useState(false);
  const [loginData, setLoginData] = useState(undefined);
  const [ShowShareDrawer,setShowShareDrawer]=useState(true);
  const [JoinChannelstep,setJoinChannelstep]=useState(false);
  // 是否首次登陆
  const [isFirstLogin, setIsFirstTimeLogin] = useState(undefined);
  // 弹框显隐
  const [modalVisible, setModalVisible] = useState({
    ProvablyModal: false,
    JoinChannelModal: false,
    InsBalanceModal: false,
    HowToEarnModal: false,
    WithdrawalRecord: false,
    RoundModal: false,
    ChargeModal: true,
    SignModal: false,
    GameLimitModal: false,
    GameRulesModal:false,
    GamePlayModal:false,
  });

  const guestLoginData = useRef(null);

  const matches = useMatches();
  const OpenGoogleLogin = true;

  const tools = useRef<DataViewTools>(null);
  const hasInit = useRef(false);
  const footerNavigator = [
    {
      id: "trade",
      title: t("App.Trade"),
    },
    {
      id: "friends",
      title: t("App.Friends"),
    },
    {
      id: "deposit",
      title: t("App.Deposit"),
      // disabled: false,
    },
    {
      id: "withdraw",
      title: t("App.Withdraw"),
      // disabled: false,
    },
  ];
  const selectedKeys = matches.map((m) => m.id);

  const navigate = useNavigate();

  const isTelegram = window.Telegram?.WebApp?.initData;

  console.log("selectedKeys", selectedKeys);

  const openConnectToast = () => {
    const key = 9981;//Date.now();
    open({
      key: key,
      content: (
        <ConfirmModal
          modalKey={key}
          description={t("App.Connection_lost_do_you_want_to_reconnect")}
          close={(modalKey) => {
            close(modalKey);
          }}
          ok={async () => {
            // const success = await tools.current.reconnect();
            // console.log(success);
            // if (!success) {
            //   close(key);
            //   openConnectToast();
            // } else {
            //   close(key);
            // }
            init(() => {
              close(key);
            });
          }}
        />
      ),
      duration: 0,
    });
  };

  const getStartParams = function () {
    if (window.Telegram?.WebApp?.initDataUnsafe?.start_param)
      return window.Telegram.WebApp.initDataUnsafe.start_param;
    return null;
  };

  eventEmitter.on("login_failed", () => {
    openConnectToast();
  });

  /******* */

  // 成功弹框
  const openSuccess = (data: Rugbull.Protocols.SCOrderInfo) => {
    const key = Date.now();
    open({
      key: key,
        content: (
        <div className="success-content deposit-success-content">
          <div className="success-content-text">{t("Deposit.Deposit")}</div>
          <div className="wins-content">
            <div className="USDT"></div>
            {data.CoinAmount}
          </div>
          <div className="status-text">{t("Deposit.Success")}!</div>
        </div>
      ),
      duration: 2,
      placement: "top",
    });
  };

  // 失败弹框
  const openError = () => {
    const key = Date.now();
    open({
      key: key,
      content: (
        <div className="error-content">
          <div className="error-toast-msg-text">
            {t("Deposit.Deposit_Failed")}
          </div>
        </div>
      ),
      duration: 2,
      placement: "top",
    });
  };  

  const initListeners = () => {
    eventEmitter.on("logout", () => {
      setLoginData(undefined);
      init();
    })

    window.goplay.onType(
      "push.bet2",
      Rugbull.Protocols.PushUserBet,
      function (data: Rugbull.Protocols.PushUserBet) {
        if (!data || !data.UserBets) return;

        window.UserBets = window.UserBets || [];

        for (let i = 0; i < data.UserBets.length; i++) {
          const item = data.UserBets[i];
          const index = item.Id;
          window.UserBets[index] = item;
        }

        console.log("push.bet", data);
        eventEmitter.emit("onType", data);
      }
    );

    window.goplay.onType(
      "push.order.info",
      Rugbull.Protocols.SCOrderInfo,
      function (data: Rugbull.Protocols.SCOrderInfo) {
        if (data.Status === Rugbull.Protocols.OrderStatus.OrderStatus_Purchased) {
          openSuccess(data);
        } else {
          openError();
        }
        console.log("Push Order")
        eventEmitter.emit("orderinfo",data);
      }
    );

    window.goplay &&
      window.goplay.onType(
        "push.coin",
        Rugbull.Protocols.PushCoin,
        function (data: Rugbull.Protocols.PushCoin) {
          console.log("【push.coin】", data, data.Coin);
          setCurrentSelectCurrency({
            Coin: data.Coin,
            Type: data.Type,
          });
        }
      );

    window.goplay.onType(
      "multiplier",
      Rugbull.Protocols.PushMultiplier,

      function (data: Rugbull.Protocols.PushMultiplier) {
        console.log("onType[push.bet22]: ", data);
        // setUserBets(data.UserBets)
        setMultiple(
          data.Infos.map((v: any) => {
            return {
              label: `${v}x`,
              value: v,
            };
          })
        );
        Multipleani(multipleListRef);
      }
    );

     window.goplay &&
          window.goplay.onType(
            "gameEvent2",
            Rugbull.Protocols.GameData2,
            (data) => {
              console.log("gameEvent2", data);
              eventEmitter.emit("gameEvent2", data);
            }
          );

    eventEmitter.on("error", (msg) => {
        const key = Date.now();
        open({
        key: key,
        content: (
            <div className="error-content">
            <div className="error-toast-msg-text">
                {msg}
            </div>
            </div>
        ),
        duration: 2,
        placement: "top",
        });
    });
  }

  const init = async (func?: () => void) => {
    tools.current = new DataViewTools();
    const isConnected = await tools.current.connect();
    console.log("isConnected", isConnected);

    // 判断是否连接成功
    if (!isConnected) {
      openConnectToast();
      return;
    } else {
      // 关闭弹框
      func && func();
    }

    // console.log("(window.Telegram", window.Telegram);
    // await guestLogout();
    
    let loginData1 = undefined;

    // if(!isTelegram && OpenGoogleLogin && userStore.GetAuthCode()){
    //     console.log("HasCode", userStore.GetAuthCode());
    //     let loginResult = await tools.current.Login_LoginWithCode(
    //       Rugbull.Protocols.CSLoginWithCode.create(userStore.GetAuthCode())
    //     );
    //     let status2 = loginResult.status;
    //     let data2 = loginResult.data;
    //     console.log(status2, data2);
    //    if (status2.Code != GoPlay.Core.Protocols.StatusCode.Success) {
    //      // presentErrorToast(t(`errCode.${status2.Message}`));
    //      // loading.value = false;
    //       userStore.ClearAuthCode();
    //      return;
    //    }
    //    setModalVisible({
    //     ...modalVisible,
    //     SignModal: false,
    //    });

    //    await afterLogin(data2);
    //    return;
    // }else if(isTelegram || !OpenGoogleLogin) {
    //   const params = 
    // // !window.Telegram?.WebApp?.initData && process.env.NODE_ENV === "development" ? {
    // //       UtmSource: "",
    // //       initData:
    // //         "query_id=AAHSoXxhAAAAANKhfGFkZY6G&user=%7B%22id%22%3A1635557842%2C%22first_name%22%3A%22Wom%20Metro%2030%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22wometro%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727226180&hash=30a71e99ce995f57ddbb64bbcd5613693a1d7fe7c74edc69923b2cb9906e2f20",
    // //       User: {
    // //         id: 6627109917,
    // //         first_name: "Wom Metro 30",
    // //         last_name: "",
    // //         username: "wometro",
    // //         language_code: "en",
    // //         allows_write_to_pm: true,
    // //         is_premium: false,
    // //       },
    // //     }: 
    //     {
    //       UtmSource: "",
    //       initData: window.Telegram.WebApp.initData,
    //       User: window.Telegram.WebApp.initDataUnsafe.user,
    //     };

    //     let arg = Rugbull.Protocols.CSLogin.create({
    //       UtmSource: "",
    //       InitData: params.initData,
    //       User: {
    //         Username: params.User.username,
    //         IsBot: params.User.is_bot,
    //         Id: params.User.id,
    //         FirstName: params.User.first_name,
    //         LastName: params.User.last_name,
    //         LanguageCode: params.User.language_code,
    //         IsPremium: params.User.is_premium,
    //         AddedToAttachmentMenu: params.User.added_to_attachment_menu,
    //         AllowsWriteToPm: params.User.allows_write_to_pm,
    //         PhotoUrl: params.User.photo_url,
    //       },
    //     });

    //     console.log("params", params);
    //     console.log("arg", arg);

    //     const { status, data } = await tools.current.Login_Login(arg);

    //     console.log("Login_Login", status, data);
        
    //     loginData1 = data;
    // }

    loginData1 = await tools.current.login();
   

    if (loginData1) {
      await afterLogin(loginData1);
    }
    
    // console.log("loginData1", loginData1);
    // if(!loginData1){
    //  await guestLogin();
    // }
  };

  useEffect(() => {
  // console.log("Client Id", process.env.REACT_APP_GOOGLE_CLIENT_ID);
   if(OpenGoogleLogin){
     GoogleAuth.initialize({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scopes: ['profile', 'email', 'openid'],
      grantOfflineAccess: true,
    });
   }
    initListeners();
    init();

    if(!loginData){
      navigate("/trade");
    }
 
  }, []);

  const guestLogin = async () => {
    console.log("guestLogin");
    await tools.current.Login_LoginGuest();
  }

  const guestLogout = async () => {
    await tools.current.Login_LogoutGuest();
  }

  const googleLogin = async () => {
    //TODO: 不在telegram环境下，Google登录
     // window.location.href = "https://t.me/xpumpai_bot/game";
     console.log("GoogleAuth.signIn");
     const user = await GoogleAuth.signIn();
     console.log("GoogleAuth.signIn", user);

     let arg = Rugbull.Protocols.CSLoginByGoogle.create({
      OpenId: user.id,
      AccessToken: user.authentication.accessToken,
      //@ts-ignore
      ExpireAt: user.authentication.expires || Date.now() /1000 + 3600 * 24 * 30, /* 这是因为iOS的登录，拿不到expires属性 */
      Json: JSON.stringify(user),
    });

      console.log("arg", arg);
     let {status, data} = await tools.current.Login_GoogleLogin(arg);

     if (status.Code != GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("GoogleLogin failed", status.Message);
       userStore.ClearAuthCode();
       return;
     }
     
     userStore.SetAuthCode(data);

     let loginResult = await tools.current.Login_LoginWithCode(data);
     let status2 = loginResult.status;
     let data2 = loginResult.data;
    //  console.log(status2, data2);
     if (status2.Code != GoPlay.Core.Protocols.StatusCode.Success) {
       // presentErrorToast(t(`errCode.${status2.Message}`));
       // loading.value = false;
       return;
     }

     await afterLogin(data2);
   };

  const afterLogin = async (data:Rugbull.Protocols.SCLogin) => {
    setModalVisible({
      ...modalVisible,
      SignModal: false
    });

    // 设置登录数据
    setLoginData(data);
    console.log("setLoginData data",data);
    //设置语言
    await changeLanguage(data.User.Language);
    setCurrentLanguage(data.User.Language)
    console.log("Set language", data.User.Language);

    eventEmitter.emit("loginSuccess", data);

    //发送邀请参数
    const startParams = getStartParams();
    if (startParams) {
      const { status, data } = await tools.current.Invite_InviteBy(
        Rugbull.Protocols.CSInvitedBy.create({
          InviteCode: startParams,
        })
      );
      console.log("Invite_InviteBy", status);
    }

    const FirstTime =
        data.UserState === Rugbull.Protocols.UserState.FirstTime;
      console.log("success");
      if (!FirstTime || !isTelegram) {
        setIsFirstTimeLogin(false); // 用来显示第一次的 Coin 飞入的动画效果 false: 不展示 true: 要展示
        navigate("/trade");
        // setModalVisible({
        //   ...modalVisible,
        //   ChargeModal: true,
        // })
      } else {
        // 显示checking开屏效果
        setIsFirstTimeLogin(true);
        setshowCoinAnimate(true);
      }
  }

  const openMultipleDropDown = () => {
    setMultipleDropDown(!multipleDropDown);
  };

  const multipleList = useMemo(() => {
    return multiple;
  }, [multiple]);

  const handlecopy = () => {
    const key = Date.now();
    open({
      key: key,
        content: (
        <div className="success-content copy-success-content">
          <div className="status-text">{t("Deposit.Copied_successfully")}!</div>
        </div>
      ),
      duration: 1.5,
      placement: "top",
    });
  };

  const handleMsg = (msg) => {
    const key = Date.now();
    open({
      key: key,
        content: (
        <div className="success-content copy-success-content">
          <div className="status-text">{msg}</div>
        </div>
      ),
      duration: 1.5,
      placement: "top",
    });
  }

  const GetShareUrl = (code) => {
    const link = `https://t.me/xpumpai_bot/game?startapp=${code}`;
    const text = t("utils.text");

    return {
        link: `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(text)}`,
        text: `${text}\n${link}`
    };

  }

  const CheckLogin = ()=>{
    if(!loginData){
      setModalVisible({
        ...modalVisible,
        SignModal: true,
      });
      return false;
    }
    return true;
  }

  return (
    <LoginContext.Provider value={{ showCoinAnimate, setshowCoinAnimate, loginData
    , currentSelectCurrency,setCurrentSelectCurrency,setShowShareDrawer,ShowShareDrawer,JoinChannelstep,setJoinChannelstep,handlecopy,currentLanguage, setCurrentLanguage,
    setMenuActiveKey, menuActiveKey
    ,GetShareUrl,handleMsg ,setModalVisible ,modalVisible ,googleLogin ,setLoginData
    ,CheckLogin , guestLoginData ,multipleList ,multipleDropDown ,openMultipleDropDown,multipleListRef
    ,isTelegram}}>
      <div className="App">
        {isFirstLogin !== undefined && (
          <div className="has-success-get-login"></div>
        )}

        {
          // isFirstLogin === undefined && OpenGoogleLogin && <button className="google-login" onClick={googleLogin}>Google 登录</button>
        }

        {((!isFirstLogin && isFirstLogin !== undefined) || (!loginData )) && (
          <>
            <Header >
              <HeaderTopContent
                loginData={loginData}
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
                currentSelectCurrency={currentSelectCurrency}
                setCurrentSelectCurrency={setCurrentSelectCurrency}
                openModal={() => {
                  setModalVisible({
                    ...modalVisible,
                    HowToEarnModal: true,
                  });
                }}
                visable={true}
              />
              {/* {selectedKeys.includes("trade") && (
                <HeaderBottomContent
                  // className="pc-hidden"
                  // multiple={multiple}
                  multipleDropDown={multipleDropDown}
                  openMultipleDropDown={openMultipleDropDown}
                  multipleList={multipleList}
                  multipleListRef={multipleListRef}
                />
              )} */}
            </Header>

            <Outlet />
            <div className="app-footer">
              {footerNavigator.map((v) => {
                return (
                  <div
                    className={classNames("app-footer-item", {
                      active: selectedKeys.includes(v.id),
                    })}
                    onClick={() => {
                      // if (v.disabled) {
                      //   return;
                      // }
                      if(v.id !== 'friends' && v.id !== 'trade'){
                        if(!loginData){
                          setModalVisible({
                            ...modalVisible,
                            SignModal: true,
                          });
                          return;
                        }
                      }
                      navigate(`/${v.id}`);
                    }}
                  >
                    <div
                      className={classNames(v.id, {
                        active: selectedKeys.includes(v.id),
                      })}
                    ></div>
                    <p className="text">{v.title}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}

       {isTelegram &&
         <Checking
         visible={isFirstLogin}
         setIsFirstTimeLogin={() => {
           setshowCoinAnimate(true);
           setIsFirstTimeLogin(false);
         }}
       />
       }
{/* 
        <JoinChannelModal
          open={modalVisible.JoinChannelModal}
          onOk={() => {
            setModalVisible({
              ...modalVisible,
              JoinChannelModal: true,
            });
          }}
        /> */}
        {/* Bull1016-05 弹框 */}
        <HowToEarnModal
          open={modalVisible.HowToEarnModal}
          onOk={() => {
            setModalVisible({
              ...modalVisible,
              HowToEarnModal: false,
            });
          }}
          onCancel={() => {
            setModalVisible({
              ...modalVisible,
              HowToEarnModal: false,
            });
          }}
        />

      {
        !isTelegram && 
          <SignModal onOk={()=>{
            googleLogin();
          }} 
          onCancel={()=>{
            setModalVisible({
              ...modalVisible,
              SignModal: false,
            });
          }} 
          open={modalVisible.SignModal}/>
        
      }

      <GameLimitModal onCancel={()=>{
        setModalVisible({
          ...modalVisible,
          GameLimitModal: false,
        });
      }} onOk={()=>{}}  open={modalVisible.GameLimitModal} />

      <GameRulesModal 
      onCancel={()=>{
        setModalVisible({
          ...modalVisible,
          GameRulesModal: false,
        });
      }} onOk={()=>{}}  open={modalVisible.GameRulesModal}
      />

      <GamePlayModal  onCancel={()=>{
        setModalVisible({
          ...modalVisible,
          GamePlayModal: false,
        });
      }} onOk={()=>{}}  open={modalVisible.GamePlayModal}
      clickRule={()=>{
        setModalVisible({
          ...modalVisible,
          GameRulesModal: true,
          GamePlayModal: false,
        });
      }}/>


      <ChargeModal onCancel={()=>{
        setModalVisible({
          ...modalVisible,
          ChargeModal: false,
        });
      }} onOk={()=>{}}  open={modalVisible.ChargeModal}
      />
        {/* USDT 类型选择弹框 */}
        {/* <CurrencyChose
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          current={currentUsdt}
          dataSource={usdtData}
          onSelect={(v) => {
            setCurrentUsdt(v);
            setVisible(false);
          }}
        /> */}

        {holder}
      </div>
    </LoginContext.Provider>
  );
};

export default App;
