import React, { useState, useMemo, useEffect, useRef } from "react";
import Header from "src/components/Header";
import HeaderTopContent from "src/components/Header/HeaderTopContent";
import { rem } from "src/utils/index";
import { useNotification } from "rc-notification";
import { useTranslation } from "react-i18next";
import ReplayBottomInfo from 'src/components/ReplayerBottomInfo';
import { LoginContext, useLogin } from 'src/App';
import motion from 'src/utils/motion';
import './style.scss';
import classNames from "classnames";
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import ConfirmModal from "src/components/Modal/Confirm";
import DataViewTools from "src/views/DataView/DataViewTools";
import { Rugbull } from "src/network/generated/pkg.pb";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import anime from "animejs";
import LightWeightChart from "src/components/Chart/lightweight-charts";
import RugPull from "src/views/Trade/components/RugPull";
import { t } from 'i18next';
import { start } from "repl";
import { ShareBtn } from "../Trade/components/ShareDrawer";
import NoticationClose from "src/components/SvgIcon/NoticationClose";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface Props {

}

const Replayer: React.FC<Props> = ({

}) => {
    const { t } = useTranslation();
    const lineChartRef = useRef(null);
    const [isReplaying, setIsReplaying] = useState(false);
    const [isRugPull, setIsRugPull] = useState(false);
    const loginInfo = useLogin();

    const query = useQuery();
    const trade = query.get('trade_id').split('_');
    const tradeId = trade[0];
    const sign = trade[1];
    const isShare = query.get('share'); 
    console.log('tradeId,sign,share', tradeId,sign,isShare);

    const [{ open, close }, holder] = useNotification({
        getContainer: () => rugTrendDom.current,
        motion: motion,
        className: () => 'replayer-page-toast',
    });


    const [{ open: open2, close: close2 }, holder2] = useNotification({
        // getContainer: () => rugTrendDom.current,
        motion: motion,
        // className: () => 'replayer-page-toast',
    });

    const rugTrendDom = useRef(null);
    const [data, setData] = useState<Rugbull.Protocols.SCGetTrade>(null);

    const [modalVisible, setModalVisible] = useState({
        ProvablyModal: false,
        JoinChannelModal: false,
        InsBalanceModal: false,
        HowToEarnModal: false,
        WithdrawalRecord: false,
        RoundModal: false,
    });

    const toastWin = (mul) => {
        const key = Date.now();

        open({
            key: 'replay',
            content: (
            <div className="replayer-page-toast1">
                <div className="success-content">
                    <div className="success-content-text">
                        <div className="title">{t("Trade.You_have_sold")}!</div>
                        <div className="multiple">
                        {Number(data.Multiplier).toFixed(2)}x
                        </div>
                    </div>
                    <div className="wins-wrap">
                        <div className="title">{t("Trade.Win")}</div>
                        <div className="multiple">
                        {Number(data.WinAmount).toFixed(2)}
                        </div>
                </div>
              <NoticationClose
                onClick={() => {
                  close(key);
                }}
              />
            </div>
                </div>
                
            ),
            placement: 'bottom',
            duration: 2,
        })
    }

    const openConnectToast = () => {
        const key = Date.now();
        open2({
            key: key,
            content: (
            <ConfirmModal
                modalKey={key}
                description={t("App.Connection_lost_do_you_want_to_reconnect")}
                close={(modalKey) => {
                close2(modalKey);
                }}
                ok={async () => {
                // const success = await tools.current.reconnect();
                // console.log(success);
                // if (!success) {
                //   close(key);
                //   openConnectToast();
                // } else {
                //   close(key);
                // }
                init(() => {
                    close2(key);
                });
                }}
            />
            ),
            duration: 0,
        });
    };

    const openError = (msg) => {
        const key = Date.now();
        open2({
        key: key,
        content: (
            <div className="error-content">
            <div className="error-toast-msg-text">
                {msg}
            </div>
            </div>
        ),
        duration: 2,
        placement: "top",
        });
    }; 

    const init = async (func?: () => void) => {
        const goplay = new DataViewTools();
        const isConnected = await goplay.connect();

        // console.log(isConnected)
    
        if (!isConnected) {
          openConnectToast();
          return;
        } else {
          func && func();
        }

        let {status, data} = await goplay.Trade_GetInfo(Rugbull.Protocols.CSGetTrade.fromObject({ 
            Id: tradeId,
            Sign: sign,
        }));
        if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
            console.log("Trade_GetInfo Error", status);
            openError(status.Message);
            goplay.disconnect();
            return;
        }

        setData(data);
        goplay.disconnect();
    };


    const animateReplay = () => {
        anime({
            targets: '.replayer-video-icon',
            keyframes: [
                { opacity: 0.3 },
                { opacity: 1 },
            ],
            loop: true,
            duration: 1000,
            easing: 'linear',
        });
    };

    const clamp = (val, min, max) => {
        return Math.min(Math.max(val, min), max);
    };

    const getValue = (time) => {
        time = time / 1000;
        const min = 1;
        if (time <= 0.1) return min;
        
        // (time - 0.1) / 12
        return clamp(Math.exp((time - 0.1) / 12), min, data.RoundMultiplier);
    };

    const updateList = (list, sec, val) => {
        if (list.length <= 0 || list[list.length - 1].time !== sec) {
            list.push({
                time: sec,
                close: val,
                open: val,
                low: val,
                high: val,
            });
        }

        const lastItem = list[list.length - 1];
        lastItem.close = val;
        lastItem.high = Math.max(lastItem.high, val);
        lastItem.low = Math.min(lastItem.low, val);
        list[list.length - 1] = lastItem;

        list = list.slice(-50);
        lineChartRef.current.setData([
            ...list
        ]);
    };

    const startChart = () => {
        let list = [];
        lineChartRef.current.clear();
        lineChartRef.current.setData(list);
        let isToastWin = false;

        let intervalId = null;
        let startTime = null;
        intervalId = setInterval(() => {
            startTime = startTime || new Date().getTime();
            const now = new Date().getTime() - startTime;
            const sec = Math.floor(now / 1000);
            const val = getValue(now);
            updateList(list, sec, val);
            // console.log('update', val, list);

            if (!isToastWin && val >= Number(data.Multiplier)) {
                isToastWin = true;
                toastWin(data.Multiplier);
            }

            if (val >= Number(data.RoundMultiplier)) {
                updateList(list, sec, 0);
                clearInterval(intervalId);
                setIsRugPull(true);
                setTimeout(() => {
                    setIsRugPull(false);
                    setIsReplaying(false);
                }, 5000);
            }
        }, 100);
    };

    const handlecopy = () => {
        const key = Date.now();
        open({
          key: key,
            content: (
                <div className="success-content copy-success-content">
                <div className="status-text">{t("Deposit.Copied_successfully")}!</div>
              </div>
          ),
          duration: 1.5,
          placement: "top",
        });
    };

    const footerNavigator = [
        {
          id: "trade",
          title: t("App.Trade"),
        },
        {
          id: "friends",
          title: t("App.Friends"),
        },
        {
          id: "deposit",
          title: t("App.Deposit"),
          // disabled: false,
        },
        {
          id: "withdraw",
          title: t("App.Withdraw"),
          // disabled: false,
        },
      ];

    const matches = useMatches();
    const navigate = useNavigate();

    const selectedKeys = matches.map((m) => m.id);

    useEffect(() => {
        init();
    }, []);

    const GetShareRecordUrl = (code) => {
        const link = `${window.origin}/replayer?trade_id=${data.TradeId}_${data.Sign}`;
        const text = t("utils.text");
        
      
        return {
            link: link,
            text: `${text}\n${link}`,
        };
    }

    const GetShareUrl = (code)=>{
        return `https://t.me/xpumpai_bot/game?startapp=${code}`;
    }


    return (
        <LoginContext.Provider
            value={{
                // currentLanguage,
                // setCurrentLanguage,
                // GetShareUrl,
            }}
        >
            <div className="">
               { data && <div className="replayer-page">
                    <Header>
                        <HeaderTopContent
                            loginData={loginInfo?.loginData}
                            currentLanguage={loginInfo?.currentLanguage}
                            setCurrentLanguage={loginInfo?.setCurrentLanguage}
                            currentSelectCurrency={loginInfo?.currentSelectCurrency}
                            setCurrentSelectCurrency={loginInfo?.setCurrentSelectCurrency}
                            openModal={() => {
                                setModalVisible({
                                    ...modalVisible,
                                    HowToEarnModal: true,
                                });
                            }}
                            isShare={!isShare}
                            showRight={false}
                        />
                        <div className="replayer-page-header-bottom-content">
                            <span className="round-info">
                                {t('Trade.RoundModal.Round')} {data.Round} &nbsp;&nbsp; {data.BetTime}
                            </span>
                            <span className={classNames("rate", {

                            })}>
                               {t("Replay.RoundMultiplier")}: {data.RoundMultiplier}x
                            </span>
                        </div>
                    </Header>
                    <div className="rug-trend"
                        ref={node => rugTrendDom.current = node}
                        style={{
                            // minHeight: '180px',
                            // height: `calc(${window.innerHeight}px - ${rem(408)})`,
                        }}
                    >
                        {!isReplaying && <div className="replayer-icon-content" onClick={() => {
                            // toastWin(1.1);
                            setIsReplaying(true);
                            setTimeout(() => {
                                animateReplay();
                                startChart();
                            }, 200);
                        }}>
                             && <div className="replayer-icon"></div>
                            <p className="text">{t('Replay.Watch')}</p>
                        </div>}
                        {isReplaying && <LightWeightChart ref={(node) => {
                            lineChartRef.current = node;
                            // console.log('lineChartRef', lineChartRef, node);
                        }} />}
                        {isReplaying && <RugPull
                            visible={isRugPull}
                            number={data.RoundMultiplier}
                        />}
                        {isReplaying && <div className="replayer-video-icon"></div>}
                        {holder}
                    </div>
                    <ReplayBottomInfo data={data} handlecopy={handlecopy} isShare={!isShare} ShareUrl={GetShareUrl(data.InviteCode)}/>
                    {
                        isShare&&<div className="share-link-content">
                        <div className="share-top-icon">
                            <span className="text">{t("ShareDrawer.ShareTo")}</span>
                        </div>
                        <ShareBtn shareMsg={GetShareRecordUrl(data.InviteCode).text} handleCopy1={handlecopy}/>
                      </div>
                    }
                </div>}
                {holder2}
            </div>

            {
                isShare&&<>
            <Outlet />
            <div className="app-footer">
              {footerNavigator.map((v) => {
                return (
                  <div
                    className={classNames("app-footer-item", {
                      active: selectedKeys.includes(v.id),
                    })}
                    onClick={() => {
                      // if (v.disabled) {
                      //   return;
                      // }
                      navigate(`/${v.id}`);
                    }}
                  >
                    <div
                      className={classNames(v.id, {
                        active: selectedKeys.includes(v.id),
                      })}
                    ></div>
                    <p className="text">{v.title}</p>
                  </div>
                );
              })}
            </div>
                </>
            }
        </LoginContext.Provider>
    )
}

export default Replayer;