import { Ref } from "react";
import { Rugbull } from "src/network/generated/pkg.pb";

const AuthCode = 'AuthCode';

class UserStore {
    AuthCode: Rugbull.Protocols.CSLoginWithCode;

    constructor() {
        this.AuthCode = undefined;
    }

    public SetAuthCode(authCode:Rugbull.Protocols.CSLoginWithCode){
        window.localStorage.setItem(AuthCode, JSON.stringify(authCode));
        this.AuthCode = authCode;
    }
    
    public GetAuthCode(){
       this.AuthCode = JSON.parse(window.localStorage.getItem(AuthCode));
       return this.AuthCode;
    }

    public ClearAuthCode(){
        window.localStorage.removeItem(AuthCode);
        this.AuthCode = null;
    }

    public HasCode(){
        return this.GetAuthCode() != null;
    }

    public IsLogin(){
        return this.AuthCode;
    }
}

const userStore = new UserStore();


export default userStore;