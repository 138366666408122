import React from "react";
import './index.scss';

interface HeaderProps {
    active?: boolean;
    children?: React.ReactNode;
}

const Header : React.FC<HeaderProps> = ({
    children,active
}) => {
    return (
        <div className={`rug-header ${active ? 'active-rug' : ''}`} 
        // style={active &&{position: 'absolute'
        // ,zIndex: 9,padding: '0 10px',backgroundColor:'rgba(255,255,255,0)',width: '100%'}}
        >
            {children}
        </div>
    )
}

export default Header;