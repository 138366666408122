import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";
import CopyBtn from "src/components/Icon/CopyBtn";

import "./index.scss";
import { Rugbull } from "src/network/generated/pkg.pb";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { GetChainName, GetWithdrawState, Show } from "src/utils";
import { useTranslation } from "react-i18next";
import { useLogin } from "src/App";

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};

const GameRulesModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
  const {currentLanguage, handlecopy, loginData}=useLogin();
  
  const uid = loginData?.User?.Id ?? "";
  return (
    <Modal
      title={t('GameRules.Title')}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="how-to-earn-modal"
      style={{
        maxHeight: "80%",
      }}
    >
      <div className="how-to-earn-modal-content">
        <div className="uid-block">
          UID: {uid}
          <CopyToClipboard
            text={uid}
            onCopy={() => handlecopy()}
          >
            <CopyBtn className="copy-btn" />
          </CopyToClipboard>
        </div>
        <div className="text-1">
          <p>{t("HowToEarnModal.text_1")}</p>
        </div>
        <div className="text-2">{t("HowToEarnModal.text_2")}</div>
        <div className="text-3-title">{t('GameRules.Easy')}</div>
        <div className="text-3 step-title">{t("HowToEarnModal.Step_01")}</div>
        <div className={"screen-shoot-2 play-screenshot_"+currentLanguage}></div>
        <div className="text-4">{t('GameRules.Step_01')}</div>

        
        <div className="text-5 step-title">{t("HowToEarnModal.Step_02")}</div>
        <div className={" play-buy_"+currentLanguage}></div>
        <div className="text-6">{t('GameRules.Step_02')}</div>

        
        <div className="text-7 step-title">{t("HowToEarnModal.Step_03")}</div>
        <div className="screen-shoot-1 game_play_en-US"></div>
        <div className="text-8">{t('GameRules.Step_03')}</div>

        
        <div className="text-7 step-title">{t("GameRules.Step_04_Title")}</div>
        <div className={" play-sell_"+currentLanguage}></div>
        <div className="text-8">{t('GameRules.Step_04')}<br/><br/>
        {t('GameRules.Step_04_1')}
        </div>

      <div className="text-7 step-title">{t('GameRules.More')}</div>
      <div className="text-more">
        <div>{t('GameRules.More_01')}</div>
        <div>{t('GameRules.More_02')}</div>
        <div>{t('GameRules.More_03')}</div>
      </div>
      <div className="text-game">
        <div className="text-game-title">{t('GameRules.GameFeatures')}</div>
        <div className="text-game-1">
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.TardeAndRward')}</div>
          <div className="text-game-txt">
            <span>{t('GameRules.TardeAndRward_01')}</span>
            <span>{t('GameRules.TardeAndRward_02')}</span>
            <span>{t('GameRules.TardeAndRward_03')}</span>
            <span>{t('GameRules.TardeAndRward_04')}</span>
            <span>{t('GameRules.TardeAndRward_05')}</span>
            <span>{t('GameRules.TardeAndRward_06')}</span>
          </div>
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.AutoTardeAndRward')}</div>
        <div className="text-game-txt">
          <span>{t('GameRules.AutoTardeAndRward_01')}</span>
          <span>{t('GameRules.AutoTardeAndRward_02')}</span>
          <span>{t('GameRules.AutoTardeAndRward_03')}</span>
        </div>
        
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.RTTardeAndRward')}</div>
          <div className="text-game-txt">
          <span>{t('GameRules.RTTardeAndRward_01')}</span>
          <span>{t('GameRules.RTTardeAndRward_02')}</span>
        </div>
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.FreeTrade')}</div>
          <div className="text-game-txt">
          <span>{t('GameRules.FreeTrade_01')}</span>
        </div>
        
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.GameFairRand')}</div>
          <div className="text-game-txt">
          <span>{t('GameRules.GameFairRand_01')}</span>
          <span>{t('GameRules.GameFairRand_02')}</span>
          <span>{t('GameRules.GameFairRand_03')}</span>
        </div>
        </div>
        <div className="text-game-1">
          <div className="text-game-1-title">{t('GameRules.Other')}</div>
          <div className="text-game-txt">
          <span>{t('GameRules.Other_01')}</span>
          <span>{t('GameRules.Other_02')}</span>
        </div>
        </div>
        </div>
      </div>
      
    </Modal>
  );
};

export default GameRulesModal;
