import React, { useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";
import CopyBtn from "src/components/Icon/CopyBtn";
import NoticationClose from "src/components/SvgIcon/NoticationClose";

import "./index.scss";
import { Trans, useTranslation } from "react-i18next";
import { useLogin } from "src/App";
import classNames from 'classnames';
import { OpenLink } from "src/utils";
import { ChannelUrl, GroupUrl, SupportUrl, TwitterUrl } from "src/consts";
import { useNavigate } from "react-router-dom";
import { useClickAway } from "ahooks";

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};

const ChargeModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const {setMenuActiveKey,isTelegram} = useLogin();
  // const isTelegram = window.Telegram?.WebApp?.initData;

  useClickAway(() => {
    onCancel();
  },ref);

  return (
    <Modal 
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="charge-modal"
      style={{
        maxHeight: "80%",
      }}
    >
      <div className="charge-content" ref={ref}>
        <NoticationClose
            className="close-btn"
            onClick={() => {
              onCancel();
            }}
          />
        <div className="icon charge"></div>
        <div className="text">{t('Charge.Text')}</div>
        <div className="text1">{t('Charge.Text1')}</div>
        <div className="bottom">
        <div className="go-btn" onClick={()=>{
            onCancel();
            if(isTelegram){
              navigate('/deposit');
            }else{
              setMenuActiveKey('deposit');
            }
        }}>{t('Charge.Go')}</div>
        <div className="go-btn customer-service" onClick={()=>{
            onCancel();
            OpenLink(SupportUrl);
        }}>
          <div className="icon support"></div>
          <div className="support-text">{t('Charge.Support')}</div>
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChargeModal;
