import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";
import CopyBtn from "src/components/Icon/CopyBtn";

import "./index.scss";
import { Rugbull } from "src/network/generated/pkg.pb";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { GetChainName, GetWithdrawState, Show } from "src/utils";
import { useTranslation } from "react-i18next";

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};

const SignModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
  useEffect(() => {
  }, []);

  return (
    <Modal
      title={t('Sign.In')}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="sign-modal"
      style={{
        maxHeight: "80%",
      }}
    >
        <div className="sign-modal-content">
          <div className="sign-btn">
            <div className="google"></div>
            <div className="sign-btn-item" onClick={()=>{onOk();}}>{t('Sign.Desc')}</div>
          </div>
        </div>
    </Modal>
  );
};

export default SignModal;
