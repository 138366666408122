import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import LanguageChose from "src/components/CurrencyChose/LanguageChose";
import ArrowDown from "../Icon/ArrowDown";
import CurrencyChose from "../CurrencyChose";
import { Rugbull } from "src/network/generated/pkg.pb";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { GetCoinName, GetCoinPic, Show } from "src/utils";
import { useLogin } from "src/App";
import { set } from "animejs";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import userStore from "src/utils/userStore";
import Avatar from "../Avatar";
import eventEmitter from "src/utils/eventEmitter";

// 可选的语言集合
const languageData = [
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "العربية",
    value: "ar-SA",
  },
  {
    label: "বাংলা",
    value: "bn-BD",
  },
  {
    label: "español",
    value: "es-ES",
  },
  {
    label: "Filipino",
    value: "fil-PH",
  },
  {
    label: "français",
    value: "fr-FR",
  },
  {
    label: "हिंदी",
    value: "hi-In",
  },
  {
    label: "Bahasa Indonesia",
    value: "id-ID",
  },
  {
    label: "日本語",
    value: "ja-JP",
  },
  {
    label: "한국인",
    value: "ko-KR",
  },
  {
    label: "Bahasa Melayu",
    value: "ms-MY",
  },
  {
    label: "မြန်မာဘာသာ",
    value: "my-MM",
  },
  {
    label: "português ",
    value: "pt-PT",
  },
  {
    label: "русский ",
    value: "ru-RU",
  },
  {
    label: "ภาษาไทย",
    value: "th-Th",
  },
  {
    label: "Tiếng Việt",
    value: "vi-VN",
  },
  {
    label: "中文（简体）",
    value: "zh-SG",
  },
  {
    label: "中文（繁体）",
    value: "zh-TW",
  },
];

const HeaderTopContent = ({
  loginData=null, 
  currentLanguage=null, 
  setCurrentLanguage=null, 
  currentSelectCurrency=null, 
  setCurrentSelectCurrency=null,
  openModal = () => {},
  isShare=null,
  visable = false,
  showRight = true
}) => {
  const tools = new DataViewTools();
  const { t } = useTranslation();
  const {menuActiveKey, setMenuActiveKey,setModalVisible,modalVisible ,setLoginData,CheckLogin,isTelegram} = useLogin();

  const [Coindatas, setCoindatas] = useState([]);
  const [ curLang, setCurLang ] = useState(languageData[0].value);
  const [menuOpen, setMenuOpen] = useState(false);

  const isLogined = loginData?.User;
  if (currentLanguage === null) {
    currentLanguage = curLang;
    setCurrentLanguage = setCurLang;
  }

  console.log("isLogined", loginData);
  
  const Coin_GetCoinInfos = async () => {
    let { status, data } = await tools.Coin_GetCoinInfos();
    if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("Coin_GetCoinInfos Error");
      Show(status);
      return;
    }
    setCoindatas(data.CoinInfos);
  };
  // 语言选择弹框
  const [LanguageVisiblevisible, setLanguageVisible] = useState(false);
  // 货币选择框
  const [CurrencyVisiblevisible, setCurrencyVisible] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const navigate = useNavigate();
  // console.log('Share...',isShare,isLogined);

  // console.log('login',isLogined,userStore.IsLogin());

  // const isTelegram = false;


  const [showMapList, setShowMapList] = useState(window.innerWidth > 768);
  
    useEffect(() => {
      const handleResize = () => {
        setShowMapList(window.innerWidth > 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  const ResetMenuActiveKey = () => {
    setMenuActiveKey('friends');
  }

  const MenuList = [
    {
      id: "friends",
      title: t("App.Friends"),
      onClick: () => {
        setMenuActiveKey('friends');
        setMenuOpen(false);
      }
    },
    {
      id: "deposit",
      title: t("App.Deposit"),
      onClick: () => {
        if(!CheckLogin()){
          return;
        }
        setMenuActiveKey('deposit');
        setMenuOpen(false);
      }
    },
    {
      id: "withdraw",
      title: t("App.Withdraw"),
      onClick: () => {
        if(!CheckLogin()){
          return;
        }
        setMenuActiveKey('withdraw');
        setMenuOpen(false);
      }
    },
  ];

  const MenuList2 = [
    {
      id: "limit",
      title: t('GameLimit.Title'),
      onClick: () => {
        setModalVisible({
          ...modalVisible,
          GameLimitModal: true
        });
        setMenuOpen(false);
      }
    },
    {
      id: "howtoplay",
      title: t('GamePlay.Title'),
      onClick: () => {
        setModalVisible({
          ...modalVisible,
          GamePlayModal: true
        });
        setMenuOpen(false);
      }
    },
    {
      id: "rule",
      title: t('GameRules.Title'),
      onClick: () => {
        setModalVisible({
          ...modalVisible,
          GameRulesModal: true
        });
        setMenuOpen(false);
      }
    },
    {
      id: "language",
      title: <>
        {t('CurrencyChose.Language')}: {currentLanguage}
      </>,
      onClick: () => {
        setLanguageVisible(true);
        setMenuOpen(false);
      }
    },
  ]

  const MenuList3 = [
    {
      id: "logout",
      title: t('Sign.Out'),
      onClick:  async () => {
        setMenuOpen(false);
        Logout();
      }
    },
  ]

  const Logout = async () => {
    await tools.logout();
    setLoginData(null);
    eventEmitter.emit('logout');
  }

  return (
    <>
      <div className={classNames("header-top-content")}>
        {
          (!isShare && !visable)? (
            <div className="back-btn" onClick={() => navigate('/')}>
              <i className="back-icon"></i>                
            </div>
          ) : null
        }
        <div className="logo-wrap">
          <div className="logo"></div>
          {isLogined && <div className="help" onClick={openModal}></div>}
        </div>
        {
          <div className="header-top-content-right">
          {
           (!loginData && !isTelegram && visable) && <>
           <div className="Sign" onClick={()=>{setModalVisible({
            ...modalVisible,
            SignModal: true
           })}}>{t('Sign In')}</div>
           </> 
          //  :
          //  <div className="Photo"><Avatar size={'1rem'} label={loginData.User.Username} url={loginData.User.PhotoUrl}/> {loginData.User.Username}</div>
          }
          {visable && loginData && <div
            className="select"
            onClick={() => {
              setCurrencyVisible(true);
              Coin_GetCoinInfos();
            }}
            style={{ cursor: "pointer" }}
          >
            <span
              className={classNames("icon " + GetCoinPic(currentSelectCurrency.Type))}
            ></span>
            {/* <span className={classNames('icon', currentSelectCurrency.value)}></span> */}
            <span className="select-label">{currentSelectCurrency.Coin}</span>
            <span className="drop-down-icon">
              <ArrowDown></ArrowDown>
            </span>
          </div>}
          {
            !visable &&  <div
            className="language-btn"
            onClick={() => setLanguageVisible(true)}
          >
            {currentLanguage}
          </div>
          }
         {
          visable &&  <div className="menu-container">
          <div className="menu-icon" onClick={openMenu}></div>
          <div className={classNames("menu-list", {
            open: menuOpen
          })}>
            {
              loginData && !isTelegram &&
              <>
                <div className={classNames("menu-list-item", {
                active: menuActiveKey === 'login'
              })} onClick={()=>{}}>
                 <div
                className='icon'
              >
              <Avatar size={'35px'} label={loginData.User.Username} url={loginData.User.PhotoUrl} />
              </div>
              <p className="text">{loginData.User.Username}</p>
              
              </div>
              <div className="line" />
              </>
             
            }  

            {
             !isTelegram && showMapList &&  <>
              {
              MenuList.map(v => {
                return (
                  <div className={classNames("menu-list-item", {
                    active: menuActiveKey === v.id
                  })} onClick={v.onClick}>
                     <div
                    className={classNames(v.id, {
                      active: menuActiveKey === v.id,
                    },'icon')}
                  ></div>
                  <p className="text">{v.title}</p>
                  </div>
                )
              })
             }
              <div className="line" />
             </>
            }
           
            {
              MenuList2.filter(o=>!(isTelegram && o.id === 'play')).map(v => {
                return (
                  <div className={classNames("menu-list-item", {
                    active: menuActiveKey === v.id
                  })} onClick={v.onClick} style={{backgroundColor: '#202630'}}>
                     <div
                    className={classNames(v.id, {
                      active: menuActiveKey === v.id,
                    })}
                  ></div>
                  <p className="text">{v.title}</p>
                  </div>
                )
              })
            }
            {
              
            }
            {
              !isTelegram && loginData &&
              <>
              <div className="line" />
              {
                MenuList3.map(v => {
                  return (
                    <div className={classNames("menu-list-item", {
                      active: menuActiveKey === v.id
                    })} onClick={v.onClick} style={{backgroundColor: '#202630'}}>
                       <div
                      className={classNames(v.id, {
                        active: menuActiveKey === v.id,
                      })}
                    ></div>
                    <p className="text">{v.title}</p>
                    </div>
                  )
                })
              }
              </>
            }
          </div>
        </div>
         }
        </div>
        }
      </div>
      <LanguageChose
        visible={LanguageVisiblevisible }
        isLogined={isLogined}
        onClose={() => {
          setLanguageVisible(false);
        }}
        current={currentLanguage}
        dataSource={languageData}
        onSelect={(v) => {
          setCurrentLanguage(v);
          setLanguageVisible(false);
        }}
      />
      {loginData  && <CurrencyChose
        visible={CurrencyVisiblevisible}
        onClose={() => {
          setCurrencyVisible(false);
        }}
        current={currentSelectCurrency}
        dataSource={Coindatas}
        onSelect={(v) => {
          setCurrentSelectCurrency(v);
          setCurrencyVisible(false);
        }}
      />}
    </>
  );
};

export default HeaderTopContent;
